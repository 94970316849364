import profile from './profile';
import albums from './albums';
import utilsInterface from './utilsInterface';
import reservationDetail from './reservationDetail';
import cart from './cart';
import yourOrder from './yourOrder';
import menu from "./menu";
import clients from "./clients";
import table from "./table";

const modules = {
  table,
  clients,
  profile,
  albums,
  utilsInterface,
  reservationDetail,
  cart,
  yourOrder,
  menu
};

export default modules;
