<template>
  <header>
    <transition name="appear">
      <div class="wrap-menu-list">
        <AdminMenu
          @choose-item="$emit('choose-item', $event)"
          @display-menu="$emit('display-menu', $event)"
        ></AdminMenu>
      </div>
    </transition>
    <v-toolbar dark app fixed>
      <v-toolbar-title class="d-flex">
        <v-toolbar-side-icon @click.stop="handleShowMenu" />
        <div class="logo-brand" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="doRefresh">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn icon class="mx-3" @click="doFullScreen">
        <v-icon v-text="isFullScreen ? 'fullscreen_exit': 'fullscreen'" />
      </v-btn>
      <!-- <v-btn icon>
        <v-icon v-text="isOnLine ? 'wifi' : 'wifi_off'" />
      </v-btn> -->
      <QuestionMenu />
      <AdminNotifications />
      <MemberMenu
        @onChangeProfile="$emit('onChangeProfile')"
        @select="$emit('select-headquater', $event)"
        :name="name"
        :photo="photo"
        :email="email"
        :headquaters="headquaters"
      />
    </v-toolbar>
  </header>
</template>

<script>
import QuestionMenu from "@/components/menus/QuestionMenu";
import MemberMenu from "@/components/menus/MemberMenu";
import AdminNotifications from "@/components/menus/AdminNotifications";
import AdminMenu from "@/components/menus/AdminMenu/AdminMenu.vue";

export default {
  name: "AdminHeader",
  components: {
    QuestionMenu,
    MemberMenu,
    AdminMenu,
    AdminNotifications
  },
  data: () => ({
    showMenu: false,
    search: "",
    loading: false,
    notfound: false,
    isFullScreen: false
  }),
  props: {
    headquaters: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    photo: {
      type: String,
      default: ""
    }
  },
  mounted() {
    window.addEventListener("overlay", this.handleOverlayChange);
    window.addEventListener("newHeadquater", this.handleNewHeadquater);
  },
  beforeDestroy() {
    window.removeEventListener("overlay", this.handleOverlayChange);
    window.addEventListener("newHeadquater", this.handleNewHeadquater);
  },
  computed: {
    isOnLine() {
      return navigator.onLine;
    }
  },
  methods: {
    handleNewHeadquater(e) {
      const headquater = e.detail;
      window.user[headquater.id] = headquater;
      this.headquaters.push(headquater);
    },
    handleOverlayChange(e) {
      this.showMenu = e.detail;
    },
    handleShowMenu() {
      this.showMenu = !this.showMenu;
      // this.$emit("display-menu", this.showMenu);
      this.showMemberMenu = false;
      this.$store.commit("menu/setStateMenu", this.showMenu);
    },
    handleClearField(e) {
      const onAppend = e.target.classList.contains("v-input__icon--append");
      if (onAppend) {
        this.loading = false;
        this.notfound = false;
        this.search = "";
      }
    },
    handleClickBtnSearch() {
      this.loading = true;
      this.notfound = false;
      this.$emit("searching", this.search);
      setTimeout(() => {
        this.loading = false;
        this.notfound = true;
      }, 4000);
    },
    handleChooseItem(item) {
      this.showMenu = false;
      this.$emit("display-menu", this.showMenu);
      this.$emit("choose-item", item);
    },
    doRefresh() {
      window.location.reload();
    },
    async doFullScreen() {
      if (document.fullscreenElement) {
        this.isFullScreen = false;
        return document.exitFullscreen();
      }
      this.isFullScreen = true;
      return document.documentElement.requestFullscreen();
    }
  }
};
</script>
<style scoped lang="scss">
@import "./style.scss";
</style>