<style scoped lang="scss">
.confirm-container {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 25px;
  padding-bottom: 0px;
}
.confirm-container__title {
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: 100;
  color: white;
}
.confirm-container__date-text,
.confirm-container__persons-hour-text {
  font-size: 16px;
  color: #ffffffcf;
  line-height: 1.5;
  margin-bottom: 2px;
}
</style>

<template>
  <div class="confirm-container">
    <h1 class="confirm-container__title">
      Confirmación
    </h1>
    <p class="confirm-container__date-text">
      {{ date }} / {{ address }}
    </p>
    <p class="confirm-container__persons-hour-text">
      {{ time }} - {{ persons }} personas
    </p>
  </div>
</template>

<script>
export default {
 name: 'ConfirmationReserv',
 props: {
    date: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
     hour: {
      type: String,
      default: ''
    },
    persons: {
      type: String,
      default: ''
    }
  },
  data() {
    return { time: this.hour };
  },
  watch: {
    hour(val) {
      this.time = val;
    }
  }
};

</script>
