<style scoped lang="scss">
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import BasicInput from "@inputs/BasicInput";
import validators from "@utils/validators";
import LogoHeader from "@components/Headers/LogoRed";
import requests from "@/helpers/requests";

const { checkPhoneNumber } = requests;

const { validEmail, validPassword, validName, validCellPhone } = validators;
export default {
  name: "RegisterForm",
  components: { BasicInput, LogoHeader },
  computed: {
    loginLink() {
      let result = "/login";
      const { router } = window;
      const { currentRoute = {} } = router;
      const { hash = "" } = currentRoute;
      if (hash) {
        hash.includes("#continueUrl=");
        result = `${result}${hash}`;
      }
      return result;
    }
  },
  data() {
    return {
      validity: {
        email: false,
        password: false,
        invalidForm: true,
        phone: false,
        name: false
      },
      email: "",
      password: "",
      name: "",
      phone: "",
      passwordMsg: "Escribe minimo 6 caracteres maximo 20, sin espacios",
      emailMsg: "Escribe una direccion de correo electrónico valida",
      nameMsg: "Escribe minimo 3 caracteres maximo 50",
      phoneMsg: "Escribe tu numero de celular",
      loading: false
    };
  },
  mounted() {
    window.addEventListener("ready", this.userReady);
  },
  beforeDestroy() {
    window.removeEventListener("ready", this.userReady);
  },
  methods: {
    userReady() {
      this.loading = false;
    },
    handleFieldChange(field, value) {
      if (field === "name") {
        this.validity.name = validName(value);
        this.name = value;
      }

      if (field === "phone") {
        this.validity.phone = validCellPhone(value);
        this.phone = value;
      }

      if (field === "email") {
        this.validity.email = validEmail(value);
        this.email = value;
        this.emailMsg = "Escribe una direccion de correo electrónico valida";
      }
      if (field === "password") {
        this.validity.password = validPassword(value);
        this.password = value;
      }
      const { password, email, name, phone } = this.validity;
      this.validity.invalidForm = !(
        password === true &&
        email === true &&
        name === true &&
        phone === true
      );
    },
    async handleSubmit(e) {
      e.preventDefault();
      const isValid = this.validity.invalidForm === false;
      if (isValid) {
        this.loading = true;
        const response = await checkPhoneNumber(this.phone);
        const notFound = response.exists === false;
        if (notFound) {
          window.auth
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(() => {
              const { name, phone } = this;
              // this.loading = false;
              window.justRegister = { name, phone };
            })
            .catch(error => {
              const { code, message } = error;
              console.log("message ", message);
              const alreadyUsed = code === "auth/email-already-in-use";
              if (alreadyUsed) {
                this.emailMsg =
                  "Este correo ya fue registrado, intenta con otro.";
                this.validity.email = false;
                this.loading = false;
              }
            });
        } else {
          this.phoneMsg =
            "Este numero de telefono ya fue registrado, intenta con otro.";
          this.validity.phone = false;
          this.loading = false;
        }
      }
    }
  }
};
</script>
