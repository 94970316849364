import Vue from 'vue';
import { TYPE_MEDIA } from '../../../constants';
import mixin from '@/mixins';

const { MenuMixin } = mixin;

export default Vue.extend({
  name: 'AdminGallery',

  components: {
    MediaDescription: () => import('@/components/generals/MediaDescription'),
    UploadMediaModal: () => import('@/components/generals/MediaDescription/UploadMediaModal'),
    UpdateFile: () => import('@components/generals/MediaDescription/MediaManager/UpdateFile')
  },

  mixins: [MenuMixin],

  data: () => ({
    typeMedia: TYPE_MEDIA.IMAGE,
    tabs: [
      { label: 'Fotos', type: TYPE_MEDIA.IMAGE, status: true }
      // { label: 'Videos', type: TYPE_MEDIA.VIDEO, status: false }
    ],
    modalStatus: false
  }),

  created() {
    this.managerTabs(0);
  },

  methods: {
    managerTabs(index) {
      this.tabs.forEach((element, i) => {
        this.tabs[i].status = false;
      });

      this.tabs[index].status = true;
    },

    modalManager() {
      this.modalStatus = !this.modalStatus;
    },

    modalEmmit(e) {
      this.modalStatus = e;
    }
  }
});
