<style scoped lang="scss">
@import "style";
</style>
<template src="./template.html"></template>
<script>

import AdminHeader from "@components/Headers/AdminHeader";
import AdminTables from "@views/Admin/Tables";
import Footer from "@components/generals/Footer";
import { notifyEvent } from "@/utils/notifyEvent";
import STORE from '@/store/types.js';

export default {
  name: "AdminDashboard",
  components: { AdminHeader, AdminTables, GeoChefFooter: Footer },
  data() {
    const { name = "", email = "", photo = "", photoURL = "", headquaters = {} } = window.user;
    const headquater = this.getHeadquaterSelectedFromUserProfile();
    return {
      name,
      email,
      photo: photo || photoURL,
      headquaters,
      headquater,
      showOverlay: false,
      formatHeadquaters: Object.values(headquaters)
    };
  },
  computed: {
    getPhoto() {
      return this.$store.getters[STORE.GETTERS.PROFILE.GET_PROFILE].profile.photoURL;
      // return profile.getters.getProfile().profile.photoURL;
    }
  },
  created() {
    this.$store.commit(STORE.MUTATIONS.PROFILE.SET_PROFILE, { photoURL: window.user.photoURL });
    // profile.mutations.setProfile({
    //   photoURL: window.user.photoURL
    // });
  },
  beforeMount() {
    this.checkPrevHeadquater();
  },
  methods: {
    checkPrevHeadquater() {
      const isHeadquaterInUrl = window.router.currentRoute.query.hasOwnProperty("headquater") && window.router.currentRoute.query.headquater !== "";
      if (isHeadquaterInUrl) {
        const id = window.router.currentRoute.query.headquater;
        const hasPrevHeadquater = window.user.headquaters.hasOwnProperty(id);
        if (hasPrevHeadquater) {
          Object.keys(window.user.headquaters).forEach(key => {
            window.user.headquaters[key].selected = key === id;
          });
        }
      }
    },
    setHeadquaterQuery(headquater) {
      window.router.replace({ query: { id: headquater } });
    },
    async handleChooseItem(path) {
      const { id } = this.getHeadquaterSelectedFromUserProfile();
      this.$router.push({
        path,
        query: {
          id
        }
      });
    },
    async handleSelectHeadquater(headquater) {
      console.log("handleSelectHeadquater");
      console.log(headquater);
      notifyEvent({ name: "headquaterSelected", detail: headquater });
      this.setHeadquaterQuery(headquater.id);
      this.updateSelectedHeadQuater(headquater.id);
    },
    handleShowOverlay(show) {
      return notifyEvent({ name: "toggleOverlay", detail: show });
    },
    handleSignout() {
      this.logout();
    },
    setNewHeadquater(id) {
      Object.keys(window.user.headquaters).forEach(key => {
        window.user.headquaters[key].selected = false;
      });
      const headquater = Object.keys(window.user.headquaters).find(key => key === id);
      window.user.headquaters[headquater].selected = true;
    }
  }
};
</script>
