<template>
  <Cart></Cart>
</template>
<script>
  import Cart from "@/components/Cart";

  export default {
    name: "Dashboard",
    components: {
      Cart
    },
    data: () => ({}),
    created() {

    },
    methods: {}
  };
</script>
