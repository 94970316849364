const getters = {
  getAlbumsGetter(state) {
    return state.albums;
  },

  getPanoramicsGetter(state) {
    return state.panoramics;
  },

  renderPanoramicsGetter(state) {
    return state.renderPanoramic;
  },

  statusShowFileUpdateGetter(state) {
    return state.modalFileUpdate.show;
  },

  getDataFileUpdateGetter(state) {
    return state.modalFileUpdate.dataUpdate;
  }
};

export default getters;
