<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import { notifyEvent } from "@/utils/notifyEvent";
  import speech from "@/utils/speech";
  import surePromise from "@utils/surePromise";
  import uuid from "@utils/uuid";
  import requests from "@/helpers/requests";
  import mixins from "@/mixins";

  const { fetchMenu, updateProfile } = requests;
  const { DateManager } = mixins;

  export default {
    name: "WaiterDashboard",
    components: {
      EmptySection: () => import("@/components/generals/EmptySection"),
      WaiterHeader: () => import("@/components/Headers/WaiterHeader/index.vue"),
      WaiterTableTabs: () => import("@/components/generals/WaiterTableTabs"),
      RestaurantsList: () => import("@components/lists/RestaurantsList.vue"),
      ReservationState: () => import("@components/generals/ReservationState.vue"),
      CategoriesList: () => import("@/components/lists/CategoriesList.vue"),
      TableState: () => import("@components/generals/TableState.vue"),
      TakeOrderModal: () => import("@components/generals/TakeOrderModal"),
      Invoice: () => import("@components/generals/Invoice"),
      BottomNav: () => import("@components/footers/BottomNav.vue"),
      WaiterMenu: () => import("@components/menus/WaiterMenu/WaiterMenu.vue")
    },
    filters: {
      filterByStatus(data, status) {
        return data.filter(item => item.status === status);
      }
    },
    mixins: [DateManager],
    data() {
      return {
        menus: [],
        waiterMenuQauter: {},
        ctrlModalMenuWaiter: false,
        ctrlTabs: {
          reservations: true,
          menu: false,
          tables: false,
          chat: false
        },
        iva: 0,
        tab: 0,
        tableStatus: null,
        tableStateKey: uuid(),
        fab: false,
        openQR: false,
        loading: false,
        openReservationState: false,
        headquaterSelected: "",
        tableUnsuscription: null,
        orderUnsuscribe: null,
        tableSelected: null,
        reservationSelected: {},
        restaurants: [],
        notifications: [],
        reservations: [],
        openHeadquaterList: false,
        tables: [],
        categories: [],
        headquaters: [],
        headquaterList: [],
        showLoadMoreSpinner: false,
        notMoreItems: false,
        searchUserDialog: false,
        rol: "",
        fetchOptions: { limit: 10 },
        displayNewOrder: false
      };
    },
    watch: {
      async tab(val) {
        const onReserv = val === 0;
        const onAvailable = val === 1;
        const onBusy = val === 2;
        this.reservations = [];
        this.restaurants = [];
        this.fetchOptions = { limit: 10 };
        const headquater = this.headquaters.find(item => item.selected === true);
        if (onReserv) {
          this.loading = true;
          const { ok, error, result } = await surePromise(requests.fetchReservationsByHeadquater(this.fetchOptions, headquater.id));
          if (ok) return this.handleReservationsResponse(result);
          this.handleReservationsResponseError(error);
        }
        if (onAvailable || onBusy) this.setSubscription();
      },
      tables() {
        if (this.$refs.waiterTabs) {
          this.tableStatus = this.$refs.waiterTabs.getSelectedTable();
          this.tableStateKey = uuid();
        }
      }
    },
    beforeDestroy() {
      if (this.tableUnsuscription) this.tableUnsuscription();
      if (this.orderUnsuscribe) this.orderUnsuscribe();
      window.removeEventListener("notification", this.handleNewNotification);
    },
    created() {
      this.headquaterList = Object.keys(window.user.workingAt);
      this.headquaters = this.mapHeadquaters(this.headquaterList);
      this.headquaterSelected = this.getHeadquaterSelected(this.headquaters);
    },
    mounted() {
      document.getElementById("app").style.backgroundColor = "#16202f";
      this.requestReservation();
      this.headquater = this.headquaters.find(item => item.selected === true);
      this.setSubscription();
      this.rol = localStorage.getItem("selectedRol");
      window.addEventListener("notification", this.handleNewNotification);
      this.watchOrders();
    },
    methods: {
      watchOrders() {
        const db = window.firebase.firestore();
        const ReservationsRef = db.collection("Reservations");
        ReservationsRef.where("belongsTo", "==", this.headquater.id)
          .where("status", "==", "PENDING")
          .onSnapshot(async snapshots => {
              snapshots.docChanges().forEach((change) => {
                if (change.type === "added") {
                  this.requestReservation();
                  this.displayNewOrder = true;
                }
            });
          });
      },
      mapHeadquaters(list) {
        return list.map(key => {
          const { name = "", id = "", logo = "", belongsTo = "" } = window.user.workingAt[key];
          return {
            name,
            id,
            logo,
            selected: false,
            belongsTo
          };
        });
      },
      getHeadquaterSelected(headquaters) {
        if (headquaters.length) {
          const headquater = Object.values(window.user.workingAt).find(item => item.selected === true);
          if (headquater) {
            headquaters.forEach(quater => {
              const found = quater.id === headquater.id;
              if (found) quater.selected = true;
            });
            const havaSomeHeadquaterSelected = headquaters.some(quater => quater.selected);
            if (havaSomeHeadquaterSelected) return headquater.id;
          }
          const { headquater: defaultHeadquater = "" } = window.router.currentRoute.query;
          const hasDefaultHeadquater = defaultHeadquater !== "";
          if (hasDefaultHeadquater) {
            const headquater = headquaters.find(({ id }) => id === defaultHeadquater);
            if (headquater) {
              headquater.selected = true;
              return headquater.id;
            }
          }
          const [firstHeadquater = {}] = headquaters;
          firstHeadquater.selected = true;
          return firstHeadquater.id;
        }
      },
      findMenu(id) {
        return fetchMenu(id);
      },
      async selectedTab(selectedTab) {
        this.ctrlTabs = {
          reservations: false,
          menu: false,
          tables: false,
          chat: false
        };
        this.ctrlTabs[selectedTab] = true;
        if (selectedTab === "menu") {
          this.ctrlModalMenuWaiter = true;
        }
      },
      closeWaiterModalMenu(tap) {
        this.ctrlTabs = {
          reservations: false,
          menu: false,
          tables: false,
          chat: false
        };
        this.ctrlModalMenuWaiter = false;
        this.ctrlTabs[tap] = true;
      },
      handleConfirmRequest(id) {
        requests.confirmUserRequest(id);
      },
      handleNewNotification(e) {
        const { detail = {} } = e;
        const { notification = {} } = detail;
        const { type = "" } = notification;
        this.notifications.unshift(notification);
        if (window.user.activeNotification) {
          if (navigator.vibrate) navigator.vibrate([300, 100, 300]);
          const isWaiterRequest = type === "WAITER_REQUEST";
          if (isWaiterRequest) {
            const { table = "", body = "" } = notification;
            speech(`La mesa ${table} dice: ${body}`);
          } else {
            const { title = "" } = notification;
            speech(title);
          }
        }
      },
      handleConfirmedOrder() {
        this.tableStatus = null;
      },
      async handleOrder(user) {
        const { notificationId } = user;
        if (this.$refs.takeOrderModal) this.$refs.takeOrderModal.setProcessing(true);
        const { ok } = await surePromise(
          this.updateTable(this.tableSelected, {
            status: "BUSY",
            waiter: { id: window.user.uid, active: true },
            currentClient: user.id
          })
        );
        if (ok) {
          this.success = true;
          if (this.$refs.takeOrderModal) this.$refs.takeOrderModal.setProcessing(true);
          const { id = "", belongsTo = "" } = this.headquaters.find(headquater => headquater.selected) || {};
          const { ok: showMenuSuccess } = await surePromise(
            requests.showMenu(notificationId || user.id, {
              restaurant: belongsTo,
              headquater: id,
              table: this.tableSelected,
              user: user.id
            })
          );
          if (showMenuSuccess) {
            this.searchUserDialog = false;
            this.tableSelected = null;
            this.success = true;
            return;
          }
          this.error = true;
        }
        this.error = true;
      },
      async handleOrderAnonymus() {
        if (this.$refs.tableOrderModal) this.$refs.tableOrderModal.processing = true;
        const { ok } = await surePromise(
          this.updateTable(this.tableSelected, {
            status: "BUSY",
            waiter: { id: window.user.uid, active: true },
            currentClient: "ANONYMUS"
          })
        );
        if (ok) {
          this.searchUserDialog = false;
          this.success = true;
          if (this.$refs.tableOrderModal) this.$refs.tableOrderModal.processing = false;
          const { id = "", belongsTo = "" } = this.headquaters.find(headquater => headquater.selected) || {};
          const table = this.tableSelected;
          this.tableSelected = null;
          this.searchUserDialog = false;
          window.open(`${window.location.origin}/restaurant/${belongsTo}/${id}?table=${table}`, "_blank");
        } else {
          this.error = true;
        }
      },
      handleCloseTableState() {
        this.tableStatus = null;
      },
      async updateTable(id, payload) {
        const { waiter = {}, status = "", currentClient = "" } = payload;
        const db = window.firebase.firestore();
        const TablesRef = db.collection("Tables");
        const table = await TablesRef.doc(id)
          .get()
          .then(doc => doc.data());
        const { activesWaiters } = table;
        if (waiter && waiter.id) activesWaiters[waiter.id] = waiter.active;
        const data = {};
        if (status) data.status = status;
        if (currentClient) data.currentClient = currentClient;
        if (activesWaiters) data.activesWaiters = activesWaiters;
        return TablesRef.doc(id).update(data);
      },
      handleCanceledReservation(item) {
        this.restaurants = this.restaurants.filter(reservation => reservation.id !== item);
        this.openReservationState = false;
      },
      async handleConfirmedReservation(item, tableSelected) {
        const { madeBy = {} } = this.reservationSelected;
        const { notificationId = "" } = madeBy || {};
        const { ok } = await surePromise(
          this.updateTable(tableSelected, {
            status: "BUSY",
            waiter: { id: window.user.uid, active: true },
            currentClient: madeBy.id
          })
        );
        if (ok) {
          this.success = true;
        } else {
          this.error = true;
          return;
        }
        const { id = "" } = this.headquaters.find(headquater => headquater.selected) || {};
        const { restaurant = "" } = this.reservations.find(reserv => reserv.id === item);
        const { ok: showMenuSuccess, result: showMenuResult } = await surePromise(
          requests.showMenu(notificationId || madeBy.id, {
            restaurant,
            headquater: id,
            table: tableSelected,
            user: madeBy.id
          })
        );
        if (showMenuSuccess) return this.handleResponseShowMenu(showMenuResult, item);
        this.error = true;
      },
      handleResponseShowMenu(response, item) {
        this.restaurants.forEach(reservation => {
          const found = reservation.id === item;
          if (found) {
            reservation.status = "CONFIRM";
          }
        });
        this.openReservationState = false;
      },
      handleOpenReservation(selected) {
        this.openReservationState = true;
        this.reservationSelected = this.reservations.find(item => item.id === selected);
        const { persons = "", hour = "", address, dateDisplay, madeBy, id = "" } = this.reservationSelected;
        const { phone = "", name = "", email = "", points = 0, photoURL = "" } = madeBy;
        this.$refs.reservationState.whatsapp = phone;
        this.$refs.reservationState.name = name;
        this.$refs.reservationState.email = email;
        this.$refs.reservationState.points = points;
        this.$refs.reservationState.image = photoURL;
        this.$refs.reservationState.persons = persons;
        this.$refs.reservationState.hour = hour;
        this.$refs.reservationState.address = address;
        this.$refs.reservationState.date = dateDisplay;
        this.$refs.reservationState.id = id;
      },
      requestReservation() {
        this.reservations = [];
        this.restaurants = [];
        this.fetchOptions = { limit: 10 };
        this.loading = true;
        const headquater = this.headquaters.find(item => item.selected === true);
        requests
          .fetchReservationsByHeadquater(this.fetchOptions, headquater.id)
          .then(response => this.handleReservationsResponse(response))
          .catch(this.handleReservationsResponseError);
      },
      handleSelectHeadquater(items) {
        this.headquater = items.find(item => item.selected === true) || {};
        this.headquaters.forEach(item => {
          item.selected = item.id === this.headquater.id;
        });
        this.openHeadquaterList = false;
        this.requestReservation();
        this.setSubscription();
      },
      handleGoProfile() {
        window.router.push({ name: "profile" });
      },
      async tryLogout() {
        const { error: updateProfileException } = await surePromise(updateProfile({ notifications: {} }));
        if (updateProfileException) {
          this.error = true;
          return;
        }
        const { error } = await surePromise(window.firebase.auth().signOut());
        if (error) {
          this.error = true;
          return;
        }
        window.router.push({ name: "login" });
        notifyEvent({ name: "logout", detail: {} });
      },
      requestMoreItems() {
        this.showLoadMoreSpinner = true;
        if (this.hasOptions) {
          this.notMoreItems = true;
          return;
        }
        if (this.tab === 0) {
          const headquater = this.headquaters.find(item => item.selected === true);
          requests
            .fetchReservationsByHeadquater(this.fetchOptions, headquater.id)
            .then(response => this.handleReservationsResponse(response, "loadmore"))
            .catch(this.handleReservationsResponseError);
        }
      },
      handleReservationsResponseError() {
        this.loading = false;
        this.showLoadMoreSpinner = false;
      },
      async handleReservationsResponse(response, mode) {
        const headquater = this.headquaters.find(item => item.selected);
        const result = [];
        this.notMoreItems = response.length === 0;
        if (this.notMoreItems) {
          this.showLoadMoreSpinner = false;
          this.loading = false;
          return;
        }
        for (let i = 0; i < response.length; i += 1) {
          const current = response[i];
          current.reservations = current;
          current.restaurant = current.restaurant;
          current.name = current.madeBy.name;
          current.description = `Puntos: ${current.madeBy.points}`;
          // eslint-disable-next-line no-unused-expressions
          if (current.madeBy.phone) current.description; // += `  -  ${current.madeBy.phone}`
          if (current.madeBy.photoURL) current.logo = current.madeBy.photoURL;
          if (headquater) current.address = headquater.name;
          result.push(current);
        }

        if (mode === "loadmore") {
          this.restaurants = this.restaurants.concat(result);
          this.reservations = this.reservations.concat(response);
          this.showLoadMoreSpinner = false;
        } else {
          this.restaurants = result;
          this.reservations = response;
        }
        this.fetchOptions.startAt = response[response.length - 1].id;
        this.loading = false;
      },
      handleOpenSearchDialog(open) {
        this.searchUserDialog = open;
      }
    }
  };
</script>
