<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import MenuCard from "@components/cards/MenuCard";
  import { mapState } from "vuex";
  import onPWA from "@/utils/onPWA.js";
  import SocialMediaList from "@lists/SocialMediaList";
  import LocationMap from "@components/maps/LocationMap";
  import OrderStatus from "@components/Cart/OrderStatus";
  import OrderSuccess from "@components/Cart/OrderSuccess";
  import STORE from "@/store/types";
  import mixins from "@/mixins";

  const { DateManager } = mixins;

  export default {
    name: "Cart",
    props: {
      isModal: {
        type: Boolean,
        default: true
      }
    },
    mixins: [DateManager],
    components: { MenuCard, SocialMediaList, LocationMap, OrderStatus, OrderSuccess },
    computed: {
      ...mapState({
        openCart: state => state.cart.show_cart,
        cart_items: state => state.cart.cart,
        showSuccessOrder: state => state.cart.showSuccessOrder,
        currentOrder: state => state.cart.current_order,
        subtotal() {
          let total = 0;
          for (const i in this.cart_items) {
            if (this.cart_items[i].amount > 0) {
              total += this.cart_items[i].price * this.cart_items[i].amount;
            }
          }
          return total;
        }
      })
    },
    data() {
      return {
        onPWA: onPWA(),
        showCart: this.openCart,
        showOrder: false,
        orderSuscribe: undefined,
        orderFindSuscribe: undefined,
        orderStatus: "",
        currentStep: 1,
        order: {},
        center: {
          lat: 0,
          lng: 0
        },
        location: {
          lat: 0,
          lng: 0
        }
      };
    },
    created() {
      this.showCart = this.openCart;
      this.$store.dispatch(STORE.ACTIONS.CART.OPEN_CART);
      // REQUESTED
      // PENDING
      // COMPLETE
      this.watchOrder();
      if (this.currentOrder) {
        this.initSuscriptions(this.currentOrder);
      }
    },
    mounted() {
      this.loading = false;
    },
    methods: {
      async closeOrder() {
        await window.db.collection("Deliverys")
          .doc(this.currentOrder).set({ status: "FINISHED" }, { merge: true });
        this.showOrder = false;
        this.$store.dispatch("cart/current_order", false);
      },
      async successGetPosition(position) {
        const self = this;
        this.location.lat = position.coords.latitude;
        this.location.lng = position.coords.longitude;

        this.headquater = await window.db.collection("Headquaters").where("id", "==", this.order.headquater).limit(1).get();
        this.headquater = this.headquater.docs[0].data();
        this.center.lat = this.headquater.l.latitude;
        this.center.lng = this.headquater.l.longitude;

        setTimeout(() => { self.$refs.orderStatus.$refs.howToArriveMap.getRoute(); }, 100);
      },
      failGetPosition() {
        this.error = true;
        this.loading = false;
      },
      watchOrder() {
        const self = this;
        if (this.orderFindSuscribe) {
          this.orderFindSuscribe();
        }
        const yesterday = this.subtractDay(new Date(), 1);
        const tomorrow = this.addDay(new Date(), 1);
        this.orderFindSuscribe = window.db.collection("Deliverys")
          .where("user_id", "==", window.user.uid)
          .where("date", ">", yesterday)
          .where("date", "<", tomorrow)
          .where("status", "in", ["REQUESTED", "PENDING", "COMPLETE"])
          .orderBy("date")
          .onSnapshot(snapshots => {
            for (const change of snapshots.docChanges()) {
              const data = change.doc.data();
              console.log(`CURRENT ORDER ${data.id}`);
              // if (!self.currentOrder) {
                self.order = data;
                self.showOrder = true;
                self.$store.dispatch("cart/current_order", change.doc.id);
                self.checkCurrentStep(data);
                break;
              // }
            }

            if (!self.showOrder) {
              self.$store.dispatch("cart/current_order", false);
            }
          });
      },
      closeCart() {
        this.$router.push("/home");
        this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_CART);
        this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_SUCCESS_ORDER);
      },
      cancelCart() {
        this.showOrder = false;
        this.$store.dispatch("cart/current_order", false);
      },
      goToYourOrder() {
        this.$router.push("/your-order");
      },
      initSuscriptions(newOrder) {
        const self = this;
        console.log("initSuscriptions");
        if (this.orderSuscribe) {
          this.orderSuscribe();
        }
        if (newOrder) {
          this.orderSuscribe = window.db.collection("Deliverys")
            .where("id", "==", newOrder)
            .onSnapshot(snapshots => {
              for (const change of snapshots.docChanges()) {
                const data = change.doc.data();
                self.order = data;

                const options = {
                  enableHighAccuracy: true,
                  // timeout: 5000,
                  maximumAge: 0
                };

                if (change.type === "modified") {
                  console.log("ORDER MODIFIED");
                } else if (change.type === "added") {
                  console.log("ORDER ADDED");
                  self.orderStatus = data.status;
                }
                self.checkCurrentStep(data);

                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(self.successGetPosition, self.failGetPosition, options);
                }
              }
            });
        }
      },
      checkCurrentStep(order) {
        console.log(order);
        switch (order.status) {
          case "REQUESTED": {
            this.currentStep = 1;
            this.$store.commit("utilsInterface/toastStatusMutation", {
              show: true,
              status: "success",
              message: "Su orden fue recivida"
            });
            break;
          }
          case "PENDING": {
            this.currentStep = 2;
            this.$store.commit("utilsInterface/toastStatusMutation", {
              show: true,
              status: "success",
              message: "Su orden se esta procesando"
            });
            break;
          }
          case "COMPLETE": {
            this.currentStep = 3;
            this.$store.commit("utilsInterface/toastStatusMutation", {
              show: true,
              status: "success",
              message: "Su orden esta lista"
            });
            break;
          }
          case "FINISHED": {
            this.$store.dispatch("cart/current_order", false);
            this.watchOrder();
            break;
          }
          case "CANCEL": {
            console.log("order canceled");
            this.$store.dispatch("cart/current_order", false);
            this.watchOrder();
            this.showOrder = false;
            this.showCart = true;
            break;
          }
          default: {
            //
          }
        }
      },
      getOrderId(order) {
        const date = new Date(order.createAt);
        const h = date.getHours().toString();
        const m = date.getMinutes().toString();
        const s = date.getSeconds().toString();
        return h + m + s;
      }
    },
    watch: {
      openCart(newVal) {
        this.showCart = newVal;
      },
      currentOrder(newOrder) {
        this.initSuscriptions(newOrder);
      }
    }
  };
</script>
