const mutations = {
  loadingStatusMutation(state, payload) {
    state.loadingStatus = payload;
  },

  toastStatusMutation(state, payload) {
    state.toastStatus = payload;
  }
};

export default mutations;
