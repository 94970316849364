<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import BarsChart from "@/components/charts/BarsChart";
import CircleChart from "@/components/charts/CircleChart";
import AccountingCard from "@/components/cards/AccountingCard";

export default {
  name: "AccountingTabs",
  components: {
    AccountingCard,
    CircleChart,
    BarsChart
  },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    accounting: {
      type: Object,
      default: () => ({})
    },
    dates: {
      type: Array,
      default: () => []
    },
    pieset: {
      type: Array,
      default: () => []
    },
    dataset: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentTab: 0,
      error: false,
      loading: false
    };
  },
  computed: {
    revenue() {
      return this.accounting.payrolls.filter(payroll => this.filterByType(payroll, "REVENUE")).reduce(this.countValues, 0);
    },
    egress() {
      return this.accounting.payrolls.filter(payroll => this.filterByType(payroll, "EGRESS")).reduce(this.countValues, 0);
    },
    hasPayrolls() {
      return this.accounting && this.accounting.payrolls && this.accounting.payrolls.length > 0;
    },
    piesetHasData() {
      return this.pieset.length !== 0;
    }
  },
  methods: {
    countValues(accumulator, payroll) {
      accumulator += payroll.value;
      return accumulator;
    },
    filterByType(payroll, type) {
      return payroll.type === type;
    }
  }
};
</script>