<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import TimeLineChart from "@components/charts/TimeLineChart";
import mixins from "@/mixins";
const { Accounting } = mixins;

export default {
  name: "CardBusiness",
  components: { TimeLineChart },
  mixins: [Accounting],
  props: {
    type: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: () => ({})
    },
    headquater: {
      type: Object,
      default: () => ({})
    },
    caption: {
      type: String,
      default: ""
    }
  },
  watch: {
    data(val) {
      if (this.isSell) {
        this.hasDataset = val && val.hasOwnProperty("dataset") && val.dataset.length !== 0;
      }
      return val;
    }
  },
  computed: {
    hasData() {
      return this.total > 0;
    },
    total() {
      const { dataset = [] } = this.data;
      const calc = dataset.reduce((accumulator, value) => this.accumulatePrices(accumulator, value), 0);
      return calc;
    },
    isWaiter() {
      return this.type === "waiters";
    },
    isTable() {
      return this.type === "tables";
    },
    isSell() {
      return this.type === "sells";
    },
    isOrder() {
      return this.type === "orders";
    },
    isAccounting() {
      return this.type === "accounting";
    }
  },
  data() {
    return {
      date: null,
      menu: false,
      hasDataset: false
    };
  },
  created() {
    const [day, month, year] = new Date().toLocaleDateString("es-CO", { timeZone: "America/Bogota" }).split("/");
    this.date = `${year}-${month}-${day}`;
    const isSells = this.type === "sells";
    if (isSells) {
      this.data.loading = true;
    }
  },
  mounted() {
    const isSells = this.type === "sells";
    if (isSells) {
      this.$refs.menu.save(this.date);
      this.$emit("date", this.date, true);
    }
  },
  methods: {
    accumulatePrices(accumulator, value) {
      const [, price] = value;
      accumulator += price;
      return accumulator;
    },
    handlePickerDateChange(year) {
      this.date = year;
    },
    confirmDate() {
      this.$refs.menu.save(this.date);
      this.$emit("date", this.date);
    }
  }
};
</script>
