<template>
  <div class="container-belling-admin__wrap-user-info">
    <div
      class="container-belling-admin__wrap-user-info-img"
      :class="{ 'icon-size': !user.photoURL }"
      :style="{ 'background-image': `url(${user.image || user.photoURL || defaultPhoto})`, 'background-size': user.image ? 'cover' : '' }"
    ></div>
    <div class="container-belling-admin__wrap-user-info-content">
      <p class="container-belling-admin__wrap-user-info-content-name">{{ user.name }}</p>

      <p v-if="user.hasOwnProperty('points')" class="container-belling-admin__wrap-user-info-content-points">Puntos: {{ user.points }}</p>

      <p v-if="user.hasOwnProperty('price')" class="container-belling-admin__wrap-user-info-content-price">
        <span>Precio:</span>
        ${{ user.price | filterMoney }}
      </p>

      <p v-if="user.hasOwnProperty('address')" class="container-belling-admin__wrap-user-info-content-address">
        <span>Dirección:</span>
        {{ user.address }}
      </p>

      <p v-if="user.email" class="container-belling-admin__wrap-user-info-content-email">
        <span>Correo:</span>
        <span>{{ user.email }}</span>
      </p>

      <p v-if="user.waiter" class="container-belling-admin__wrap-user-info-content-email">
        <span>Mesero</span>
      </p>

      <p v-if="user.phone" class="container-belling-admin__wrap-user-info-content-number">
        <span>Numero:</span>
        <span>{{ user.phone }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import defaultPhoto from "@/assets/icons/account.svg";

export default {
  name: "UserBasicInfo",
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      defaultPhoto
    };
  }
};
</script>

<style scoped lang="scss">
.container-belling-admin {
  &__wrap-user-info {
    color: #161f2f;
    display: flex;
  }

  &__wrap-user-info-img {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-position: center;
    background-size: contain;
    background-color: white;
  }
  &__wrap-user-info-content {
    margin-left: 0px;
    position: relative;
    left: 10px;
    p {
      margin-bottom: 2px;
      line-height: 1.2;
    }
  }
  &__wrap-user-info-content-name {
    font-family: Gibson-Bold;
    font-size: 20px;
  }

  &__wrap-user-info-content-price span {
    font-family: Gibson-Bold;
    font-size: 17px;
  }

  &__wrap-user-info-content-number {
    font-size: 17px;
  }

  &__wrap-user-info-content-email,
  &__wrap-user-info-content-number,
  &__wrap-user-info-content-address {
    span {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  .icon-size {
    background-size: 29px;
  }
}
</style>
