<style lang="scss" scoped>
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import BasicInput from "@inputs/BasicInput";
import AccountingForm from "@/components/forms/AccountingForm";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import validators from "@utils/validators";
const { createAccounting, startBoxflow, closeAccounting, finishBoxFlow } = requests;
const { validNumber, validPassword } = validators;

export default {
  name: "BoxFlowForm",
  components: { AccountingForm, BasicInput },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    accounting: {
      type: String,
      default: () => ""
    },
    flowInitializated: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    onFlowInit: {
      type: Function,
      default: () =>
        function onFlowInit() {
          return null;
        }
    },
    onFlowEnd: {
      type: Function,
      default: () =>
        function onFlowEnd() {
          return null;
        }
    }
  },
  data() {
    return {
      currentTab: 0,
      reset: false,
      initialMount: "",
      password: "",
      loading: false,
      error: false,
      success: false,
      invalidPassword: false,
      validity: {
        initialMount: false,
        password: false
      }
    };
  },
  created() {
    this.initialMount = this.initialValue;
  },
  methods: {
    async confirmPassword(password = "") {
      const { email } = window.user;
      // eslint-disable-next-line no-undef,new-cap
      const credential = new firebase.firebase_.auth.EmailAuthProvider.credential(email, password);
      // eslint-disable-next-line no-undef
      const { ok, error, result } = await surePromise(firebase.auth().currentUser.reauthenticateAndRetrieveDataWithCredential(credential));
      if (ok) return Promise.resolve(result);
      return Promise.reject(error);
    },
    async completeFlow() {
      const { id = "" } = this.headquater;
      this.loading = true;
      const { error: confirmPassowrdException } = await surePromise(this.confirmPassword(this.password));
      if (confirmPassowrdException) {
        this.loading = false;
        const wrongPassword = confirmPassowrdException.code === "auth/wrong-password";
        if (wrongPassword) {
          this.invalidPassword = true;
          return;
        }
        this.invalidPassword = false;
        this.error = true;
        return;
      }

      const { ok } = await surePromise(finishBoxFlow(id));
      if (ok) {
        const { error: closeAccountingException } = await surePromise(closeAccounting({ id: this.accounting, belongsTo: this.headquater.id }));
        this.loading = false;
        if (closeAccountingException) {
          this.error = true;
          return;
        }
        this.success = true;
        return this.onFlowEnd(this.endMount);
      }
      this.loading = false;
      this.error = true;
    },
    async initializeFlow() {
      this.loading = true;
      const { id = "" } = this.headquater;
      const { ok } = await surePromise(startBoxflow({ id }));
      if (ok) {
        const { error: createAccountingException, result } = await surePromise(
          createAccounting({ belongsTo: id, initialValue: Number(this.initialMount) })
        );
        this.loading = false;
        if (createAccountingException) {
          this.error = true;
          return;
        }
        this.success = true;
        return this.onFlowInit(this.initialMount, result);
      }
      this.loading = false;
      this.error = true;
    },
    handleFieldChange(field, value) {
      const isInitialMount = field === "initialMount";
      if (isInitialMount) {
        this.validity[field] = validNumber(value) && Number(value) > 0;
      } else {
        this.validity[field] = validPassword(value);
      }
      this[field] = value;
    },
    async resetState() {
      Object.assign(this.$data, this.$options.data());
      this.reset = true;
      await this.$nextTick();
      this.reset = false;
    }
  }
};
</script>