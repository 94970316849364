import { BASE } from "./index";
import buildUrlQuery from "@utils/buildUrlQuery.js";

const orders = {
  addDishesToOrder(id, payload) {
    const token = window.localStorage.getItem("token") || "";
    const url = `${BASE}/order/update/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        token
      }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  aggregateOrderByDateRange(query = {}) {
    const token = window.localStorage.getItem("token") || "";
    const params = buildUrlQuery(query);
    const url = `${BASE}/order/aggregate/by/range?${params}`;
    return fetch(url, {
      method: "GET",
      headers: { token }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  cancelOrder(id = "", data = {}) {
    const token = window.localStorage.getItem("token") || "";
    const url = `${BASE}/order/cancel/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        token,
        "content-type": "application/json"
      }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  createOrder(payload = {}) {
    const token = window.localStorage.getItem("token") || "";
    const url = `${BASE}/order/create`;
    return fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token
      },
      body: JSON.stringify({ ...payload })
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res.json());
      return res.json();
    });
  },

  dispatchOrder(payload) {
    const { tableId, orderId, discount, service, iva = {} } = payload;
    const update = { iva };
    update.discount = Number(discount);
    update.service = Number(service);
    const token = window.localStorage.getItem("token") || "";
    const url = `${BASE}/order/dispatch/${orderId}/${tableId}`;
    return fetch(url, {
      method: "POST",
      headers: {
        token,
        "content-type": "application/json"
      },
      body: JSON.stringify({ update })
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  fetchOrder(id = "") {
    const url = `${BASE}/order/${id}`;
    const token = window.localStorage.getItem("token");
    return fetch(url, {
      method: "GET",
      headers: { token }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

   fetchOrders(query = {}) {
    const params = buildUrlQuery(query);
    const url = `${BASE}/orders?${params}`;
    const token = window.localStorage.getItem("token");
    return fetch(url, {
      method: "GET",
      headers: { token }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  getOrderByDate(query = {}) {
    const token = window.localStorage.getItem("token") || "";
    const params = buildUrlQuery(query);
    const url = `${BASE}/order/aggregate/by/date?${params}`;
    return fetch(url, {
      method: "GET",
      headers: { token }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  populateOrders(data = {}) {
    const url = `${BASE}/orders/batch`;
    const token = window.localStorage.getItem("token");
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        token
      }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  removeDishFromOrder(order = "", dish = "") {
    const token = window.localStorage.getItem("token") || "";
    const url = `${BASE}/order/${order}/remove/${dish}`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        token
      }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  },

  updateHeadquater(id = "", data = {}) {
    const token = window.localStorage.getItem("token") || "";
    const url = `${BASE}/order/update/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        token
      }
    }).then(res => {
      if (res.status !== 200) return Promise.reject(res);
      return res.json();
    });
  }
};

export default orders;
