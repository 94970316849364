var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',{staticClass:"update-avatar",style:({
     'bottom': _vm.position.bottom,
      'right': _vm.position.right,
      'top': _vm.position.top,
      'left': _vm.position.left})},[_vm._m(0),_c('input',{attrs:{"id":"input-avatar","type":"file","accept":"jpg, png"},on:{"change":function($event){return _vm.updateAvatar($event.target.files)}}})]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"update-avatar",style:({
     'bottom': _vm.position.bottom,
      'right': _vm.position.right,
      'top': _vm.position.top,
      'left': _vm.position.left})},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"input-avatar"}},[_c('span',{staticClass:"update-avatar__icon-camera"})])}]

export { render, staticRenderFns }