<template src="./template.html"></template>

<style scoped lang="scss">
  @import "style";
</style>

<script>
  import moment from "moment";
  import DeliveryDetail from "@components/generals/DeliveryBoard/DeliveryDetail";
  import CancelDescriptionModal from "@components/modals/CancelDescriptionModal";
  import EstimatedTimeModal from "@components/modals/EstimatedTimeModal";

  export default {
    name: "DeliveryDataTable",
    props: {
      orderHeaders: {
        type: Array,
        default: () => []
      },
      orders: {
        type: Array,
        default: () => []
      },
      cards: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      },
      processOrder: {
        type: Function,
        default: () => {
        }
      },
      cancelOrder: {
        type: Function,
        default: () => {
        }
      },
      onSelectedOrder: {
        type: Function,
        default: () => {
        }
      }
    },
    components: { DeliveryDetail, CancelDescriptionModal, EstimatedTimeModal },
    filters: {
      getOrderId(order) {
        const date = new Date(order.createAt);
        const h = date.getHours().toString();
        const m = date.getMinutes().toString();
        const s = date.getSeconds().toString();
        return h + m + s;
      },
      format_hour(val) {
        return moment(new Date(val)).format("HH:mm");
      }
    },
    created() {

    },
    methods: {
      calculateBasePrice(dishes = []) {
        let total = 0;
        for (const i in dishes) {
          const item = dishes[i];
          if (item.promotion) {
            total += item.discount * item.price;
          } else {
            total += item.amount * item.price;
          }
        }
        return total;
      },
      calculateItemsTotal(dishes = []) {
        let items = 0;
        // eslint-disable-next-line no-unused-vars
        for (const i in dishes) {
          items += 1;
        }
        return items;
      },
      getAmountTotal(item) {
        const total = this.calculateBasePrice(item.dishes);
        return total + item.iva.computedValue;
      },
      getStatusColor(status) {
        for (const i in this.cards) {
          if (this.cards[i].id === status) {
            return this.cards[i].color;
          }
        }
      },
      getStatusName(status) {
        for (const i in this.cards) {
          if (this.cards[i].id === status) {
            return this.cards[i].name;
          }
        }
      },
      async handleProcessOrder(order) {
        this.selectedOrder = order;
        if (order.domicile) {
          this.showEstimatedTimeModal = true;
        } else {
          this.updating = true;
          await this.processOrder(order);
          this.updating = false;
        }
      },

      async handleEstimatedTime() {
        this.showEstimatedTimeModal = false;
        await this.processOrder(this.selectedOrder, this.estimatedTime);
      },

      updateEstimatedTime(time) {
        this.estimatedTime = time;
      },

      async handleCancelOrder(order) {
        this.showConfirmBox = true;
        this.selectedOrder = order;
      },

      handleConfirmCancelReservation(message = "") {
        this.showConfirmBox = false;
        this.cancelOrder({ order: this.selectedOrder, message });
      },

      selectOrder(order) {
        this.currentOrder = order;
        this.onSelectedOrder(order);
      }
    },
    data() {
      return {
        updating: false,
        canceling: false,
        showConfirmBox: false,
        selectedOrder: {},
        estimatedTime: "",
        showEstimatedTimeModal: false,
        currentOrder: ""
      };
    }
  };
</script>
