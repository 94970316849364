import Chartkick from "vue-chartkick";
import Vue from "vue";
import Chart from "chart.js";

Chartkick.configure({ language: "es" });
Chartkick.options = {
  library: {
    maintainAspectRatio: false
  }
};

Vue.use(Chartkick.use(Chart));
