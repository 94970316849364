import { BASE } from "./index";
const updateEmployeeFromHeadquater = (payload = {}) => {
  const { headquater = "", type = "", employee } = payload;
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/user/update/employee/${employee}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify({ type, headquater })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default updateEmployeeFromHeadquater;
