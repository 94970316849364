<style lang="scss" scoped>
@import "~@/styles/_colors.scss";
.search-user-input {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
  height: 50px;
  border-radius: 12px;
  margin-bottom: 25px;
  font-family: Gibson-Bold;
}
.wrap-invitate-form {
  background-color: white !important;
  color: $secondary !important;
  height: 500px;
  width: 100%;
  border-radius: 12px;
  padding: 30px;
  padding-top: 40px;
  &__btn-submit {
    border-radius: 10px;
    font-family: Gibson-Bold;
    width: 100%;
    height: 50px;
    margin-left: 0px;
    position: relative;
    top: -23px;
  }
  &__spinner {
    text-align: center;
    position: relative;
    top: 0px;
  }
  &__field {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  &__label {
    font-size: 20px;
    font-family: Gibson-Bold;
    position: relative;
  }
  &__title {
    padding-bottom: 10px;
  }
}
</style>

<template>
  <v-card class="wrap-invitate-form">
    <v-form>
      <h1 class="wrap-invitate-form__title">Asignación de sede</h1>
      <p class="wrap-invitate-form__text">
        Selecciona el rol donde quieres asignar al colaborador para la sede
        <b>{{headquater.name}}</b>.
      </p>

      <span class="wrap-invitate-form__label">Correo electronico</span>
      <v-combobox
        v-model="select"
        :loading="searching"
        :items="items"
        :search-input.sync="search"
        class="wrap-invitate-form__field search-user-input"
        append-icon
        cache-items
        flat
        hide-no-data
        hide-details
        label="Correo electronico"
        solo
        @change="handleSelectUser"
        @input="handleInput"
      ></v-combobox>

      <span class="wrap-invitate-form__label">Rol del colaborador</span>
      <BasicSelect
        :disabled="loading == true"
        label="Rol"
        class="wrap-invitate-form__field"
        :data="roles"
        :reset="reset"
        @change="handleChangeRol"
      ></BasicSelect>

      <div class="wrap-invitate-form__spinner">
        <v-progress-circular indeterminate color="primary" v-if="loading === true"></v-progress-circular>
      </div>

      <v-btn
        @click="submitForm"
        v-show="loading === false"
        color="primary"
        class="wrap-invitate-form__btn-submit"
        :disabled="loading == true || valid === false"
      >Enviar</v-btn>
    </v-form>
  </v-card>
</template>

<script>
import BasicSelect from "@components/selects/BasicSelect";
import validators from "@utils/validators";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

const { validEmail } = validators;

export default {
  name: "InvitateForm",
  components: { BasicSelect },
  props: {
    reset: {
      type: Boolean,
      default: false
    },
    headquater: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    valid() {
      const { validity = {} } = this;
      function checkAllFieldsAreValids(field) {
        return validity[field] === true;
      }
      return Object.keys(validity).every(checkAllFieldsAreValids);
    }
  },
  watch: {
    reset() {
      this.loading = false;
      this.email = "";
      this.searching = false;
      this.items = [];
      this.states = [];
      this.users = [];
      this.search = null;
      this.select = null;
      this.confirmed = false;
      this.type = "";
      this.selected = "";
      this.validity = {
        email: false,
        type: false
      };
    },
    search(val) {
      const result = val && val !== this.select && this.querySelections(val);
      this.validity.email = validEmail(val);
      return result;
    }
  },
  data() {
    return {
      loading: false,
      email: "",
      searching: false,
      items: [],
      states: [],
      users: [],
      search: null,
      select: null,
      confirmed: false,
      type: "",
      selected: "",
      roles: [
        {
          name: "Mesero",
          id: "WAITER"
        },
        {
          name: "Chef",
          id: "CHEF"
        }
      ],
      validity: {
        email: false,
        type: false
      }
    };
  },
  mounted() {
    const isOwner = window.user.roles.includes("OWNER") || window.user.roles.includes("ADMIN_GEOCHEF");
    if (isOwner) {
      this.roles.push({
        name: "Administrador",
        id: "ADMIN"
      });
    }
  },
  methods: {
    async submitForm() {
      const { email = "", type = "", headquater = {} } = this;
      const { id = "" } = headquater;
      this.loading = true;
      const { ok, error, result } = await surePromise(
        requests.invitate({
          id,
          email,
          type
        })
      );
      console.log({ ok, error, result });
      this.loading = false;
      if (ok) {
        this.$emit("success");
      } else {
        this.$emit("error");
      }
    },
    resolveQuery(query) {
      return query.empty === false;
    },
    async querySelections(v) {
      this.searching = true;
      const db = window.firebase.firestore();
      const UserRef = db.collection("Users");
      const emails = [];
      const keywords = v.toLowerCase();
      keywords.split(" ").forEach(word => {
        word = word
          .replace("/", "")
          .replace(".", "")
          .replace("@", "");
        if (word) {
          emails.push(UserRef.where(`_email.${word}`, "==", true).get());
        }
      });
      const resolves = await Promise.all(emails);
      const match = resolves.find(this.resolveQuery);
      this.searching = false;
      if (!match) {
        this.confirmed = false;
        return;
      }
      const result = [];
      match.docs.forEach(doc => {
        const { email, id } = doc.data();
        result.push({
          email,
          id
        });
      });
      this.states = result.map(doc => doc.email);
      this.items = this.states.filter(e => (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1);
      result.forEach(item => {
        const notFound = !this.users.find(user => user.id === item.id);
        if (notFound) this.users.push(item);
      });
    },
    handleChangeInputText(value) {
      console.log({ value });
    },
    handleInput() {
      if (!this.select) this.confirmed = false;
    },
    handleChangeRol(value) {
      this.validity.type = true;
      this.type = value;
    },
    handleSelectUser(value) {
      this.validity.email = true;
      this.email = value;
      this.confirmed = true;
    }
  }
};
</script>
