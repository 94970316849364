import { BASE } from "./index";
const fetchListOfCategories = (list = []) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/categories/list`;
  return fetch(url, {
    method: "POST",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify({ list })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default fetchListOfCategories;
