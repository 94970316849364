import { BASE } from "./index";
const invitate = (data = {}) => {
  const { id = "", email = "", type = "" } = data;
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/headquater/invitate/${id}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify({ type, email }),
    headers: {
      "content-type": "application/json",
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default invitate;
