<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import QuestionItem from "@components/generals/QuestionItem";
import CategoriesList from "@/components/lists/CategoriesList";
import CategoriesModal from "@/components/modals/CategoriesModal";
import PayuModal from "@/components/modals/PayuModal";
import HeadquaterListModal from "@/components/modals/HeadquaterListModal";
import defaultPhoto from "@/assets/icons/account.svg";
import VClamp from "vue-clamp";
import UpdateAvatar from "@/components/generals/UpdateAvatar/UpdateAvatar.vue";
import UpdatePassword from "@/components/forms/UpdatePassword/UpdatePassword.vue";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

const { updateProfile } = requests;

export default {
  name: "MemberMenu",
  components: {
    PayuModal,
    CategoriesModal,
    CategoriesList,
    defaultPhoto,
    QuestionItem,
    HeadquaterListModal,
    VClamp,
    UpdateAvatar,
    UpdatePassword
  },
  props: {
    headquaters: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    rol: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: "Administrador"
    },
    email: {
      type: String,
      default: ""
    },
    photo: {
      type: String,
      default: ""
    }
  },
  created() {
    this.activeNotification = Boolean(window.user.activeNotification);
  },
  data() {
    return {
      activeNotification: false,
      showUpdatePassword: false,
      showPayuModal: false,
      loading: false,
      snackBar: {
        showSnackbar: false,
        bottom: true,
        right: true,
        timeOut: 2500,
        color: "error",
        text: "Tenemos un error en este momento"
      },
      defaultPhoto,
      iconUpdateAvatar: {
        bottom: "277px",
        right: "96px"
      },
      openHeadquaterList: false,
      openSettingsModal: false,
      openSubscritionModal: false
    };
  },
  computed: {
    headquaterItem() {
      const { rol = "" } = this;
      let result = "";
      const isAdmin = rol === "Admin";
      const isChef = rol === "Chef";
      if (isAdmin) result = "Administrar sedes";
      if (isChef) result = "Seleccionar sede";
      return result;
    }
  },
  methods: {
    handleProfile() {
      localStorage.removeItem('selectedRol');
      this.openSettingsModal = false;
      this.$emit('onChangeProfile');
      this.$router.push({
        name: 'dashboard'
      });
    },
    async handleUpdateProfile() {
      const hasDifference = this.activeNotification !== window.user.activeNotification;
      if (hasDifference) {
        this.loading = true;
        const { activeNotification } = this;
        const { ok } = await surePromise(updateProfile({ activeNotification }));
        this.loading = false;
        if (ok) {
          window.user.activeNotification = this.activeNotification;
          this.handleSnackBar("success", "Operacion completada con exito");
        } else {
          this.handleSnackBar("error");
        }
      }
      this.openSettingsModal = false;
    },
    async handleClosePayuModal() {
      this.showPayuModal = false;
      await this.$nextTick();
      this.openSettingsModal = true;
    },
    handleSnackBar(color = "error", text = "Ocurrió un error, intenta más tarde") {
      this.snackBar = Object.assign(this.snackBar, {
        showSnackbar: true,
        color,
        text
      });
    },
    handleModalUpdatePassword() {
      this.showUpdatePassword = true;
      this.openSettingsModal = false;
    },
    handleModalPayU() {
      this.showPayuModal = true;
      this.openSettingsModal = false;
    },
    handleCloseUpdatePassword() {
      this.showUpdatePassword = false;
      this.openSettingsModal = true;
    },
    handleUpdateAvatar(responseAvatar) {
      if (responseAvatar) {
        this.handleSnackBar("success", "Operación completada con éxito");
        return;
      }
      this.handleSnackBar("error", "Ocurrió un error, intenta más tarde");
    },
    handleSelectHeadquater(items) {
      this.showMemberMenu = false;
      const selected = items.find(item => item.selected);
      this.$emit("select", selected);
    },
    handleClickLogout() {
      this.$emit("signout");
      this.logout();
    }
  }
};
</script>
