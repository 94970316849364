/* eslint-disable */
import refreshToken from "./refreshToken";

const afterwareRequest = requests => {
  const result = {};
  for (const key of Object.keys(requests)) {
    const request = requests[key];
    result[key] = function() {
      const args = arguments;
      return request
        .apply({}, args)
        .then(response => Promise.resolve(response))
        .catch(async response => {
          const data = await (response.json ? response.json() : Promise.resolve());
          const expired = data && data.code === "tokenExpired";
          if (expired) {
            return refreshToken()
              .then(() => request.apply({}, args))
              .catch(() => Promise.reject(response));
          }
          return Promise.reject(response);
        });
    };
  }
  return result;
};
export default afterwareRequest;
