import { BASE } from "./index";

const uploadMedia = async (payload) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/file/create`;

  return fetch(url, {
    method: "POST",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  })
  .catch(error => {
    console.error(error);
  });
};

export default uploadMedia;
