<style lang="scss" scoped>
  @import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
  import NotificationItem from "@components/generals/NotificationItem";
  import BasicInput from "@inputs/BasicInput";
  import SearchInput from "@inputs/SearchInput";
  import EmptySection from "@/components/generals/EmptySection";
  import AssignWaiterModal from "@/components/modals/AssignWaiterModal";
  import STORE from "@/store/types";
  import uuid from "@utils/uuid";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";

  const { fetchUserByBatch, updateTable } = requests;

  export default {
    name: "AdminNotifications",
    components: { BasicInput, SearchInput, NotificationItem, EmptySection, AssignWaiterModal },
    props: {},
    data() {
      return {
        displayMenu: false,
        notifications: [],
        showNotification: false,
        tableName: "",
        hasWaiter: true,
        headquater: this.getHeadquaterSelectedFromUserProfile(),
        tableUnsuscription: null,
        tables: [],
        waiters: [],
        displayAssignModal: false,
        assignWaiterKey: uuid(),
        activeWaiter: "",
        tableSelected: {}
      };
    },
    watch: {
      displayMenu(bool) {
        if (!bool) {
          for (const notification of this.notifications) {
            if (!notification.table) {
              notification.readed = true;
            } else if (notification.table && notification.hasWaiter === true) {
              notification.readed = true;
            }
          }
        }
      }
    },
    computed: {
      hasWaiters() {
        return this.waiters.length > 0;
      },
      notificationsNotReaded() {
        let total = 0;
        for (const notification of this.notifications) {
          if (!notification.readed) {
            total++;
          }
        }
        return total;
      }
    },
    mounted() {
      this.waiters = this.getWaiters();
      this.setSubscription();
      window.addEventListener("overlay", this.handleOverlayChange);
      window.addEventListener("newOrder", this.handleNewNotification);
      window.addEventListener("qrReader", this.handleNewNotification);
      window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    },
    beforeDestroy() {
      window.removeEventListener("overlay", this.handleOverlayChange);
      window.removeEventListener("qrReader", this.handleOverlayChange);
      window.removeEventListener("newOrder", this.handleNewNotification);
      window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    },
    methods: {
      async getWaiters() {
        const { waiters: batch } = this.headquater;
        const { ok, result = [] } = await surePromise(fetchUserByBatch({ batch }));
        if (ok) {
          result.push({ ...window.user });
          this.waiters = result;
        }
      },
      getActiveWaiter(actives) {
        const keys = Object.keys(actives);
        const waiter = keys.find(key => Boolean(actives[key]));
        if (waiter) {
          const { id = "" } = actives[waiter];
          return id;
        }
        return "";
      },
      handleAssignWaiter(table = {}) {
        console.log(table);
        const { activesWaiters = {} } = table;
        this.activeWaiter = this.getActiveWaiter(activesWaiters);
        this.tableSelected = table;
        this.displayAssignModal = true;
        this.assignWaiterKey = uuid();
      },
      handleAssignCloseModal() {
        this.displayAssignModal = false;
        this.assignWaiterKey = uuid();
      },
      async handleSelectHeadquater(e) {
        const headquater = e.detail;
        this.headquater = headquater;
        this.setSubscription();
        this.waiters = this.getWaiters();
      },
      handleNewNotification(e) {
        console.log("nueva orden", e);
        this.hasWaiter = true;
        if (e.detail.idTable) {
          this.filterTable(e.detail.idTable);
        }
        this.notifications.unshift({
          title: e.detail.title || "Nuevo pedido",
          id: e.detail.payload,
          user: e.detail.user,
          date: e.detail.date ? new Date(e.detail.date) : new Date(),
          table: e.detail.table || this.tableName,
          hasWaiter: this.hasWaiter,
          origin: e.detail.origin,
          idTable: e.detail.idTable,
          readed: false
        });
      },
      filterTable(table) {
        // eslint-disable-next-line array-callback-return
        const tables = this.tables.filter(value => {
          if (value.id === table) return value;
        });
        console.log("esta es la orden con la tablet", tables);
        this.$store.dispatch(STORE.ACTIONS.TABLE.TABLE_ORDER, tables[0]);
        this.tableName = tables[0].name;
        if (tables.activesWaiters) {
          for (const [key, value] of Object.entries(tables.activesWaiters)) {
            console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
          }
        } else {
          this.hasWaiter = false;
        }
      },
      assignWaiter(id, table) {
        // eslint-disable-next-line array-callback-return
        const tables = this.tables.filter(value => {
          if (value.id === table) return value;
        });

        const table_ = tables[0];

        console.log("assignWaiter");
        console.log(table);
        console.log(tables);


        this.handleOccupy(table_);

        // eslint-disable-next-line array-callback-return
        this.notifications.map(value => {
          if (value.id === id) {
            value.hasWaiter = true;
          }
        });
      },
      async handleOccupy(table = {}) {
        const { id = "" } = table;
        const status = "BUSY";
        const activesWaiters = { ...table.activesWaiters };
        const isWaiter = window.localStorage.getItem("selectedRol") === "WAITER";
        if (isWaiter) activesWaiters[window.user.uid] = true;
        const update = { status, activesWaiters };
        // table.loading = true;
        this.tables = [...this.tables];
        // this.locations = this.groupByLocation(this.tables);
        const { ok, error } = await surePromise(updateTable(id, update));
        // table.loading = false;
        if (ok) {
          this.handleAssignWaiter(table);
        } else {
          //
        }
      }
    }
  };
</script>
