import Vue from 'vue';

export default Vue.extend({
  name: 'Ticket',
  props: {
    dishes: {
      type: Object,
      default: () => ({})
    },
    currentOrder: {
      type: Object,
      default: () => ({
        user: {}
      })
    }
  },
  data: () => ({
    main: [],
    drinks: [],
    dessert: [],
    mainCategory: "32ceb392-f5ae-4811-ba97-e43539a69a7a",
    dessertCategory: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
    drinksCategory: "9c4e239e-6e8e-4447-937e-53a234d038e8"
  }),
  watch: {
    dishes(val) {
      const { main, drinks, dessert } = this.filterDishes(val);
      this.main = main;
      this.drink = drinks;
      this.dessert = dessert;
      return val;
    }
  },
  created() {
    const { main, drinks, dessert } = this.filterDishes(this.dishes);
    this.main = main;
    this.drinks = drinks;
    this.dessert = dessert;
  },
  methods: {
    filterDishes(dishes) {
      const menu = this.getMapedDishes(dishes);
      const main = menu.filter(item => item.categories[this.mainCategory]);
      const drinks = menu.filter(item => item.categories[this.drinksCategory]);
      const dessert = menu.filter(item => item.categories[this.dessertCategory]);
      return {
        main,
        drinks,
        dessert
      };
    },
    getMapedDishes(dishes) {
      return Object.keys(dishes).map(id => {
        const { name, categories, suggestion } = dishes[id];
        const { amount } = dishes[id];
        return {
          name,
          id,
          categories,
          amount,
          suggestion
        };
      });
    }
  }
});
