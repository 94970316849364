<style scoped lang="scss">
.modal__content {
  position: relative;
  &__btn-close {
    background-image: url("~@assets/icons/close-red.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
    z-index: 1;
    float: right;
    position: relative;
    right: 10px;
    top: 10px;
  }
}
</style>

<template>
  <div>
    <v-dialog class="modal" v-model="open" max-width="400" persistent>
      <v-btn @click="closeModal" icon flat class="modal__content__btn-close"></v-btn>
      <div class="modal__content">
        <InvitateForm
          @success="handleInvitationSuccess"
          @error="error = true"
          :headquater="headquater"
          :reset="resetForm"
        ></InvitateForm>
      </div>
    </v-dialog>

    <v-snackbar
      v-model="error"
      color="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >Ocurrio un error, intenta mas tarde.</v-snackbar>

    <v-snackbar
      v-model="success"
      color="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >Operacion completada con exito.</v-snackbar>
  </div>
</template>

<script>
import InvitateForm from "@forms/InvitateForm";

export default {
  name: "InvitateModal",
  components: { InvitateForm },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    headquater: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: this.display,
      success: false,
      error: false,
      resetForm: false
    };
  },
  watch: {
    display(val) {
      this.open = val;
    }
  },
  methods: {
    handleInvitationSuccess() {
      this.success = true;
      this.open = false;
    },
    closeModal() {
      this.open = false;
      this.$emit("close");
      this.resetForm = true;
    }
  }
};
</script>

