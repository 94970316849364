import Vue from "vue";
import { truncateFilter } from "@/utils/truncateFilter";
import { getNamePanoramic } from "@/utils/getNamePanoramic";
import filterMoney from "@/utils/filterMoney";
import abbrNumber from "@/utils/abbrNumber";
import filterDate from "@/utils/filterDate";
import convertMoment from "@/utils/convertMoment";
import filterPaidMethod from "@/utils/filterPaidMethod";
import calculatePrice from "@/utils/calculatePrice";
import formatNumeric from "@/utils/formatNumeric";

Vue.filter("convertMoment", convertMoment);
Vue.filter("filterMoney", filterMoney);
Vue.filter("abbrNumber", val => abbrNumber(Number(val), 2));
Vue.filter("dollarSign", val => `$${val}`);
Vue.filter("filterDate", filterDate);
Vue.filter("filterPaidMethod", filterPaidMethod);
Vue.filter("calculatePrice", calculatePrice);
Vue.filter("truncate", truncateFilter);
Vue.filter("getNamePanoramic", getNamePanoramic);
Vue.filter("numeral", formatNumeric);
