<template src="./template.html"></template>
<script>
import StarList from "@lists/StarList";
import defaultLogo from "@assets/default-logo.png";
import defaultBanner from "@assets/default-banner.png";
import ScheduleList from "@components/lists/ScheduleList";
import LanguageModal from "@components/modals/LanguageModal";
import VClamp from "vue-clamp";
import onPWA from "@/utils/onPWA";

export default {
  name: "RestaurantHeader",
  components: {
    StarList,
    ScheduleList,
    VClamp,
    LanguageModal
  },
  props: {
    onlyWaiterMenu: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ""
    },
    headquaters: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    logo: {
      type: String,
      default: defaultLogo
    },
    stars: {
      type: Number,
      default: 1
    },
    banner: {
      default: defaultBanner,
      type: String
    },
    isFavorite: {
      type: Boolean,
      default: false
    },
    showReserv: {
      type: Boolean,
      default: true
    },
    showOrder: {
      type: Boolean,
      default: false
    },
    covidFree: {
      type: Boolean,
      default: false
    },
    paypal: {
      type: Object,
      default: () => ({
        clientId: ""
      })
    },
    payu: {
      type: Object,
      default: () => ({
        accountId: "",
        merchantId: "",
        apiKey: ""
      })
    },
    wifi: {
      type: Boolean,
      default: false
    },
    schedule: {
      type: Object,
      default: () => ({})
    },
    dayweek: {
      type: String,
      default: ""
    },
    activeOrder: {
      type: Boolean,
      default: false
    },
    amountOrder: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      favorite: this.isFavorite,
      orderActive: this.activeOrder,
      orderAmount: this.amountOrder,
      openLanguageModal: false,
      onPWA: onPWA(),
      currentTime: "",
      formatedTo: ""
    };
  },
  computed: {
    isOpen() {
      let openNow = false;
      const horary = this.schedule[this.dayweek];
      if (horary) {
        const daytime = this.getDayTime();
        const { to = "" } = horary[daytime] || {};
        // eslint-disable-next-line no-multi-assign
        const hour = this.currentTime = this.getCurrentTime();
        // eslint-disable-next-line no-multi-assign
        const formatedTo = this.formatedTo = this.formatHourAnMinutes(to);
        openNow = this.timeAGreatteThanTimeB(formatedTo, hour);
        return openNow;
      }
      return openNow;
    }
  },
  watch: {
    amountOrder(val) {
      this.orderAmount = val;
    },
    activeOrder(val) {
      this.activeOrder = val;
    }
  },
  mounted() {},
  methods: {
    showLangModal() {
      this.openLanguageModal = true;
    },
    convert12To24(time = "") {
      console.log("convert12To24");
      const plainTime = time
        .replace("pm", "")
        .replace("am", "")
        .split(":");
      const pm = time.includes("pm");
      if (pm) {
        plainTime[0] = `${Number(plainTime[0]) + 12}`;
      }
      if (pm === false) {
        plainTime[0] = `0${plainTime[0]}`;
      }
      return plainTime;
    },
    formatHourAnMinutes(time) {
      let result = "";
      const plainTime = this.convert12To24(time);
      result = `${plainTime[0]}:${plainTime[1]}`;
      return result;
    },
    getDayTime() {
      const today = new Date();
      const curHr = today.getHours();
      if (curHr < 12) return "morning";
      if (curHr < 18) return "afternoon";
      return "evening";
    },
    timeAGreatteThanTimeB(a, b) {
      const dateA = new Date();
      const dateB = new Date();
      dateA.setHours(a.split(":")[0]);
      dateA.setMinutes(a.split(":")[1]);
      dateB.setHours(b.split(":")[0]);
      dateB.setMinutes(b.split(":")[1]);
      return dateA > dateB;
    },
    getCurrentTime() {
      const currentTime = new Date();
      return `${currentTime.getHours()}:${currentTime.getMinutes()}`;
    },
    handleLike() {
      this.favorite = !this.favorite;
      if (this.favorite) {
        this.$emit("like", this.id);
      } else {
        this.$emit("dislike", this.id);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "./style.scss";
</style>
