const mutations = {
  updateReservationState(state, reservation) {
    state.detail = reservation.detail;
    state.isActive = reservation.isActive;
    state.mode = reservation.mode;
  },
  setDetailName(state, name) {
    state.detail.name = name;
  },
  setDetailNote(state, note) {
    state.detail.user.note = note;
  },
  setDetailPhone(state, phone) {
    state.detail.user.phone = phone;
  },
  setDetailTimestamp(state, timestamp) {
    state.detail.dateTimeStamp.seconds = timestamp;
  }
};

export default mutations;
