<style lang="scss" scoped>
.none-data-container {
  color: gray;
  position: relative;
  top: 0px;
}
.wrap-spinner {
  position: relative;
  top: 15px;
}
</style>

<template>
  <div>
    <area-chart
      v-if="loading === false && noneData === false"
      :colors="[color]"
      label="Valor"
      :curve="true"
      width="535px"
      height="125px"
      prefix="$"
      :discrete="true"
      thousands=","
      :library="chartOptions"
      :data="dataset"
    ></area-chart>

    <div v-if="noneData && loading === false" class="container text-xs-center none-data-container">
      <span>No hay ningun dato aun.</span>
    </div>

    <v-container class="text-xs-center wrap-spinner" v-if="loading === true">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TimeLineChart",
  computed: {
    noneData() {
      return this.dataset.length === 0;
    }
  },
  props: {
    color: {
      type: String,
      default: "#fbe5e7"
    },
    dataset: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartOptions: {
        scales: {
          yAxes: [
            {
              display: false
            }
          ]
        }
      }
    };
  },
  methods: {}
};
</script>
