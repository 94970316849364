import { BASE } from "./index";

const deleteAlbum = (albumIid = "", headquaterId = "") => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/album/delete/${albumIid}/${headquaterId}`;
  return fetch(url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      token
    }
  })
  .then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  })
  .catch(error => {
    console.error('ERROR: ', error);
  });
};

export default deleteAlbum;
