<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import PickerDate from "@/components/generals/PickerDate";
import mixins from "@/mixins";
const { DateManager } = mixins;

export default {
  name: "DateRange",
  components: { PickerDate },
  mixins: [DateManager],
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    topDate() {
      const NOW = new Date();
      const onFirstStage = this.currentTab === 0;
      if (onFirstStage && this.initial) {
        const onSameDate = this.sameMonth(new Date(this.initial), NOW) && this.sameYear(new Date(this.initial), NOW);
        if (onSameDate) return this.generateIsoDate();
      }
      return "";
    },
    nextDate() {
      if (this.initial) {
        const [year, month, day] = this.initial.split("-");
        const calcDay = this.fixPrefixDigit(Number(day) + 1);
        return `${year}-${month}-${calcDay}`;
      }
      return "";
    }
  },
  watch: {
    display(val) {
      this.open = val;
    },
    open(val) {
      const isClose = val === false;
      if (isClose) {
        this.resetState();
      }
    }
  },
  data() {
    return {
      open: this.display,
      initial: null,
      end: null,
      currentTab: 0
    };
  },
  created() {
    this.initial = this.generateIsoDate();
  },
  methods: {
    resetState() {
      Object.assign(this.$data, this.$options.data());
    },
    handleConfirmDate(which, value) {
      const isInitial = which === "initial";
      if (isInitial) {
        this.currentTab = 1;
        this.initial = value;
        return;
      }
      this.currentTab = 1;
      this.end = value;
      this.$emit("done", { initial: this.initial, end: value });
      this.$emit("close");
    },
    handleBackToInitial() {
      this.currentTab = 0;
    },
    handleCancel() {
      this.$emit("close");
    }
  }
};
</script>