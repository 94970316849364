<style lang="scss" scoped>
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import BasicSelect from "@/components/selects/BasicSelect";
import BasicInput from "@inputs/BasicInput";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import validators from "@utils/validators";
const { createPayroll } = requests;
const { validNumber, validText } = validators;

export default {
  name: "AccountingForm",
  components: { BasicInput, BasicSelect },
  props: {
    accounting: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  computed: {
    isValid() {
      if (this.descriptionField) return this.validity.value && this.validity.description;
      return this.validity.value && this.validity.paidMethod;
    }
  },
  data() {
    return {
      reset: false,
      valueField: "",
      descriptionField: "",
      paidMethod: "CASH",
      loading: false,
      paidMethods: [
        {
          id: "CASH",
          name: "Efectivo"
        },
        {
          id: "CREDIT_CARD",
          name: "Tarjeta de credito"
        },
        {
          id: "DEBIT_CARD",
          name: "Tarjeta de debito"
        },
        {
          id: "PSE",
          name: "Transferencia bancaria"
        }
      ],
      validity: {
        paidMethod: false,
        value: false,
        description: false
      }
    };
  },
  methods: {
    async resetState() {
      Object.assign(this.$data, this.$options.data());
      this.reset = true;
      await this.$nextTick();
      this.reset = false;
    },
    async handleCancel() {
      await this.resetState();
      this.$emit("cancel");
    },
    handleChangePaymenthod(option) {
      this.validity.paidMethod = Boolean(option);
      this.paidMethod = option;
    },
    async handleSubmit() {
      const { valueField: value, descriptionField: description, type = "", accounting = "", paidMethod = "" } = this;
      this.loading = true;
      const { ok, result } = await surePromise(
        createPayroll({
          type: type.toUpperCase(),
          value: Number(value),
          description,
          paidMethod,
          accounting
        })
      );
      this.loading = false;
      if (ok) {
        this.$emit("submit", result);
        await this.resetState();
      }
    },
    handleFieldChange(field, value) {
      const isDescription = field === "description";
      if (isDescription) {
        this.validity[field] = validText(value);
        return;
      }
      this.validity[field] = validNumber(value) && Number(value) > 0;
      this.valueField = value;
    }
  }
};
</script>
