import Vue from 'vue';
import STORE from '@/store/types';

export default Vue.extend({
  name: 'ToastStore',

  computed: {
    getToastStore() {
      return this.$store.getters[STORE.GETTERS.UTILS_INTERFACE.GET_TOAST_STATUS];
    }
  }
});
