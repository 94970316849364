<style scoped lang="scss">
.frame-container__wrap-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <section class="frame-container scroll-bar">
    <div v-if="loading" class="frame-container__wrap-loader">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <iframe v-else style="width: 100%; height: calc(100vh - 100px);" :src="source" frameborder="0"></iframe>
  </section>
</template>

<script>
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

export default {
  name: "ArticleFrame",
  data() {
    return {
      loading: false,
      source: "",
      ready: false
    };
  },
  async created() {
    this.loading = true;
    const { id = "" } = window.router.currentRoute.params;
    const { ok, result } = await surePromise(requests.fetchArticle(id));
    this.loading = false;
    if (ok) {
      this.source = result.url;
    }
  }
};
</script>