import { BASE } from "./index";

const closeAccounting = async payload => {
  const { id = "" } = payload;
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/accounting/close/${id}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify(payload)
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default closeAccounting;
