<template src="./template.html"></template>

<style scoped lang="scss">
  @import "style";
</style>

<script>
  import moment from "moment";
  import RouteRestaurantMap from "@components/maps/RouteRestaurantMap";

  export default {
    name: "DeliveryDetail",
    components: { RouteRestaurantMap },
    props: {
      updating: {
        type: Boolean,
        default: false
      },
      canceling: {
        type: Boolean,
        default: false
      },
      order: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {
          name: "",
          hour: "",
          persons: "",
          status: "",
          address: "",
          date: "",
          description: "",
          logo: "",
          id: "",
          user: {},
          dishes: []
        }
      },
      processOrder: {
        type: Function,
        default: () => {
        }
      }
    },
    watch: {
      async showDialog(newVal) {
        if (newVal) {
          console.log("asdasd");
          const self = this;
          setTimeout(async () => {
            self.$refs.howToArriveMap.getRoute();
            const headquaterRef = await window.db.collection("Headquaters").doc(self.order.headquater).get();
            self.headquater = headquaterRef.data();
            self.location.lat = self.order.location.latitude;
            self.location.lng = self.order.location.longitude;
            self.center.lat = self.headquater.l.latitude;
            self.center.lng = self.headquater.l.longitude;
            setTimeout(() => {
              self.$refs.howToArriveMap.getRoute();
              self.startMapSuscription();
            }, 100);
          }, 500);
        }
      }
    },
    async created() {
      //
    },
    filters: {
      format_hour(val) {
        return moment(new Date(val)).format("HH:mm");
      },
      format_date(val) {
        return moment(val).format("LL");
      }
    },
    data() {
      return {
        mapWatcher: null,
        showDialog: false,
        center: {
          type: Object,
          default: {
            lat: 0,
            lng: 0
          }
        },
        location: {
          type: Object,
          default: {
            lat: 0,
            lng: 0
          }
        },
        headquater: {
          type: Object,
          default: () => ({
            //
          })
        }
      };
    },
    computed: {},
    methods: {
      startMapSuscription() {
        const self = this;
        if (this.mapWatcher) {
          this.mapWatcher();
        }

        this.mapWatcher = window.db.collection("Deliverys")
          .where("id", "==", self.order.id)
          .onSnapshot(snapshots => {
            snapshots.docChanges().forEach((change) => {
              if (change.type === "modified") {
                const data = change.doc.data();
                console.log("self.location");
                console.log(data.location);
                self.$refs.howToArriveMap.location.lat = data.location.latitude;
                self.$refs.howToArriveMap.location.lng = data.location.longitude;
                self.$refs.howToArriveMap.getRoute();
              }
            });
          });
      },
      async process(order) {
        await this.processOrder(order);
        this.showDialog = false;
      },
      async cancel(order) {
        this.$emit("processCancel", order);
        this.showDialog = false;
      },
      close() {
        this.showDialog = false;
      },
      getQuantity() {
        if (this.order.dishes) {
          let i = 0;
          // eslint-disable-next-line no-unused-vars
          for (const dish of this.order.dishes) {
            i++;
          }
          return i;
        }
        return 0;
      }
    }
  };
</script>