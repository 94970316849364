<style scoped lang="scss">
.tab-selected {
  color: #ed2236;
}

.v-item-group {
  margin: 0px auto;
  position: fixed;
  z-index: 10;

  .v-btn {
    min-width: 66px;
  }
}

@media (max-width: 1080px) {
  .v-item-group {
    border-radius: 15px;
    bottom: 10px;
    overflow-x: auto;
    width: calc(100% - 40px);
    position: fixed;
    left: 20px;

    .v-btn {
      min-width: 66px;
    }
  }
}
</style>

<template>
  <v-container
    :active.sync="selected"
    align-center
    justify-center
    row
    fill-heigh
    class="container-nav"
  >
    <v-bottom-nav id="nav-bottom" :value="true" style="z-index: 500000" fixed>
      <v-btn color="primary" flat name="reservations" @click="() => handleSelect('reservations')">
        <span>{{ $t("lang.BottomNav.reservations") }}</span>
        <img v-if="selected !== 'reservations'" src="@/assets/icons/calendar.svg" />
        <img v-else-if="selected === 'reservations'" src="@/assets/icons/calendar-active.svg" />
      </v-btn>
      <v-btn
        color="primary"
        flat
        name="restaurants"
        @click="() => handleSelect(isWaiter ? 'menu' : 'restaurants')"
      >
        <span v-if="isWaiter">Menu</span>
        <span v-else-if="!isWaiter">{{ $t("lang.BottomNav.restaurants") }}</span>
        <div v-if="isWaiter">
          <img v-if="selected !== 'menu'" src="../../assets/icons/BottomNavWaiter/menu.svg" />
          <img v-else-if="selected === 'menu'" src="../../assets/icons/BottomNavWaiter/Menu_on.svg" />
        </div>
        <div v-else-if="!isWaiter">
          <img v-if="selected !== 'restaurants'" src="@/assets/icons/cutlery.svg" />
          <img v-else-if="selected === 'restaurants'" src="@/assets/icons/cutlery-active.svg" />
        </div>
      </v-btn>
      <v-btn
        v-if="isWaiter"
        color="primary"
        flat
        name="tables"
        @click="() => handleSelect('tables')"
      >
        <span>Mesas</span>
        <img v-if="selected !== 'tables'" src="../../assets/icons/BottomNavWaiter/mesas.svg" />
        <img
          v-else-if="selected === 'tables'"
          src="../../assets/icons/BottomNavWaiter/Mesas_on.svg"
        />
      </v-btn>
      <v-btn
        v-if="!isWaiter"
        color="primary"
        flat
        name="favorites"
        @click="() => handleSelect(isWaiter ? 'chat' : 'favorites')"
      >
        <span>{{ $t("lang.BottomNav.favorites") }}</span>
        <img v-if="selected !== 'favorites'" src="@/assets/icons/favorite-outline.svg" />
        <img v-else src="@/assets/icons/favorite-active-outline.svg" />
      </v-btn>
    </v-bottom-nav>
  </v-container>
</template>
<script>
export default {
  name: "BottomNav",
  props: {
    rol: {
      type: String,
      default: ""
    },
    focus: {
      type: String,
      default: "restaurants"
    }
  },
  data() {
    return { selected: this.focus };
  },
  watch: {
    focus(value) {
      this.selected = value;
      const tab = this.$el.querySelector(`[name=${value}]`);
      if (tab) tab.click(false);
    }
  },
  computed: {
    isWaiter() {
      /*
        console.log("-----> ROLES", window.user.roles.includes("WAITER"));
        return window.user.roles.includes("WAITER");
        */
      return this.rol === "WAITER";
    }
  },
  created() {
    const isWaiter = this.rol === "WAITER";
    if (isWaiter) {
      this.selected = "reservations";
    } else {
      this.selected = "restaurants";
    }
    // window.user.roles.includes("WAITER") ? this.selected = "reservations" : "";
  },
  methods: {
    handleSelect(payload) {
      this.selected = payload;
      // const tab = this.$el.querySelector(`[name=${payload}]`);
      // if (tab) tab.click();
      this.$emit("select", payload);
    }
  }
};
</script>
