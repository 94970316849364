<style scoped lang="scss">
  @import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
  import UserBasicInfo from "@components/generals/UserBasicInfo";
  import Invoice from "@components/generals/Invoice";
  import SendInvoiceModal from "@components/modals/SendInvoiceModal";
  import EditOrderModal from "@components/modals/EditOrderModal";
  import PaymentModal from "@/components/modals/PaymentModal";
  import EmptySection from "@/components/generals/EmptySection";
  import DishesList from "@/components/lists/DishesList";
  import ConfirmModal from "@components/modals/ConfirmModal";
  import calculatePrice from "@/utils/calculatePrice.js";
  import defaultPhoto from "@/assets/icons/account.svg";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";
  import calcPercantage from "@/utils/calcPercentage";
  import Ticket from "@/components/generals/Ticket/Ticket.vue";
  import uuid from "@utils/uuid";

  export default {
    name: "BillingAdmin",
    components: {
      Invoice,
      SendInvoiceModal,
      ConfirmModal,
      EditOrderModal,
      UserBasicInfo,
      EmptySection,
      DishesList,
      PaymentModal,
      Ticket
    },
    filters: {
      moneySymbol(val) {
        return `$${val}`;
      },
      filterTotalWithIva(total, iva) {
        return calcPercantage(total, iva);
      }
    },
    props: {
      noBtnAdd: {
        type: Boolean,
        default: false
      },
      headquater: {
        type: Object,
        default: () => ({})
      },
      table: {
        type: Object,
        default: () => ({
          currentOrder: {
            createAt: {
              _seconds: 0
            }
          }
        })
      },
      tables: {
        type: Array,
        default: () => []
      },
      order: {
        type: Object,
        default: () => ({})
      },
      user: {
        type: Object,
        default: () => ({})
      },
      hideInvoice: {
        type: Boolean,
        default: false
      },
      reset: {
        type: Boolean,
        default: false
      },
      mode: {
        type: String,
        default: "order"
      },
      serv: {
        type: Number,
        default: 10000
      },
      hasService: {
        type: Boolean,
        default: false
      },
      addProductToFreeTable: {
        type: Function,
        default: function addProductToFreeTable() {
          return null;
        }
      }
    },
    data() {
      return {
        paidMethod: "CASH",
        anonymusUser: { name: "Cliente anonimo" },
        dishListKey: uuid(),
        withService: false,
        withIva: false,
        service: 10000,
        discount: 0,
        iva: 0,
        computedIva: 0,
        sendingInvoice: false,
        displayInvoiceModal: false,
        displayPaymentModal: false,
        displayAvailableTablesModal: false,
        showConfirmBox: false,
        defaultPhoto,
        loading: false,
        deleting: false,
        error: false,
        success: false,
        computedDomicile: 0,
        computedDouceur: 0
      };
    },
    created() {
      const { iva = {}, price = 0 } = this.order;
      const { active = true, value = 8, computedValue = 0 } = iva;
      this.withIva = active;
      this.iva = value;
      const hasImplicitIva = active && value && computedValue;
      const basePrice = hasImplicitIva ? price - computedValue : price;
      this.computedIva = calcPercantage(basePrice, Number(this.iva));
    },
    computed: {
      hasOrder() {
        return this.table.hasOwnProperty("currentOrder") && this.table.currentOrder !== "" && this.table.currentOrder.id;
      },
      hasWaiter() {
        return (
          Boolean(Object.keys(this.table || {}).length) &&
          Object.keys(this.table.activesWaiters || {}).some(waiter => Boolean(this.table.activesWaiters[waiter]))
        );
      },
      isBusy() {
        return this.table.hasOwnProperty("status") && this.table.status === "BUSY";
      },
      isFree() {
        return this.table.hasOwnProperty("status") && this.table.status === "FREE";
      },
      hasUser() {
        return Boolean(Object.keys(this.user || {}).length);
      },
      isPopulate() {
        return Boolean(Object.keys(this.order || {}).length) && Boolean(Object.keys(this.order.dishes || {}).length);
      },
      total() {
        return this.calculateTotal();
      },
      calculateTotalWithIva() {
        const total = this.calculateTotal();
        return total;
      }
    },
    watch: {
      withService() {
        this.calculateTotal();
      },
      withIva(val) {
        const noIva = val === false;
        this.calculateTotal();
        if (noIva) this.computedIva = 0;
      },
      iva() {
        this.calculateTotal();
      },
      service() {
        this.calculateTotal();
      },
      order() {
        this.calculateServiceAndDiscountFromOrder();
      }
    },
    mounted() {
      this.calculateServiceAndDiscountFromOrder();
    },
    methods: {
      tableUpdated() {

      },
      getDishes() {
        if (this.order.status !== "CANCEL") {
          return Object.values(this.order.dishes || {});
        }
        return [];
      },
      printTicket() {
        this.$htmlToPaper("ticket");
      },
      handleSwitchTable(state, table) {
        this.$emit("switching", state, table);
      },
      resetState() {
        console.log("resetState");
        console.log(this.$data);
        console.log(this.$options.data());
        Object.assign(this.$data, this.$options.data());
      },
      async handleConfirmDelete() {
        const { id = "" } = this.order;
        this.deleting = true;
        this.showConfirmBox = false;
        const { ok } = await surePromise(requests.cancelOrder(id));
        this.deleting = false;
        if (ok) {
          this.success = true;
          return this.$emit("cancelOrder");
        }
        this.error = true;
      },
      handleSaveOrder() {
        this.$emit("save", this.getOrderCalcule());
      },
      getOrderCalcule() {
        const { service, discount, withService, withIva, computedIva, iva: ivaValue, total } = this;
        return {
          service,
          discount,
          withService,
          withIva,
          computedIva,
          price: total,
          iva: {
            active: withIva,
            value: Number(ivaValue)
          }
        };
      },
      calculateServiceAndDiscountFromOrder() {
        const { discount, service } = this.order;
        if (service) {
          this.withService = true;
          this.service = service;
        } else {
          this.withService = false;
          this.service = 10000;
        }
        if (discount) {
          this.discount = discount;
        } else {
          this.discount = 0;
        }
      },
      printInvoice() {
        this.$htmlToPaper("invoice");
      },
      handleConfirmOrder(e) {
        console.log({ e });
      },
      handleAddProduct() {
        const hasOrder = Boolean(this.order) && Boolean(this.order.id);
        if (hasOrder) {
          const headquater = this.headquater.id;
          return window.router.push({
            path: `/dashboard/order?table=${this.table.id}&name=${this.table.name}&headquater=${headquater}&order=${this.order.id}`
          });
        }
        window.router.push({ path: `/dashboard/order?table=${this.table.id}&name=${this.table.name}` });
      },
      handleRemovedDish(id = "") {
        delete this.order.dishes[id];
        this.calculateTotal();
        this.$emit("removeDish", id);
        this.dishListKey = uuid();
      },
      calculateTotal() {
        const order = { ...this.order };
        delete order.iva;
        const basePrice = calculatePrice(this.order.dishes, order);
        let result = basePrice;
        const totalChange = this.order.price !== result;
        const hasDiscount = this.order.hasOwnProperty("discount");
        const hasService = this.order.hasOwnProperty("service");
        const hasIva = this.order.hasOwnProperty("iva");
        const discountChange = hasDiscount && Number(this.discount) !== Number(this.order.discount);
        const serviceChange = hasService && Number(this.service) !== Number(this.order.service);
        const ivaChange = (hasIva && Number(this.iva) !== Number(this.order.iva.value)) || (hasIva && this.order.iva.active !== this.withIva);
        const hasChange = totalChange || discountChange || serviceChange || ivaChange;
        const hasDomicile = this.headquater.hasOwnProperty("domicile");

        if (this.withService) {
          result += Number(this.service);
        }
        if (hasChange) {
          result -= Number(this.discount);
        } else if (!hasDiscount) {
          result -= Number(this.discount);
        }
        if (hasDomicile && order.order_type === "domicilio") {
          const domicile = Number(this.headquater.hasOwnProperty("domicile") ? this.headquater.domicile : 0);
          // result += domicile;
          // result += order.order_douceur;
          this.computedDomicile = domicile;
          this.computedDouceur = order.order_douceur;
        } else {
          this.computedDomicile = 0;
          this.computedDouceur = 0;
        }
        this.computedIva = calcPercantage(result, Number(this.iva)); // the iva calcule must be the last step
        if (this.withIva) {
          result += this.computedIva;
        }
        return result;
      },
      selectedPaymenPethod(method) {
        this.paidMethod = method;
      },
      async handleDispatchOrder() {
        const { id: orderId } = this.order;
        const { id: tableId } = this.table;
        const { discount, withService, service, iva: value, withIva: active, paidMethod } = this;
        this.loading = true;
        const { ok } = await surePromise(
          requests.dispatchOrder({
            tableId,
            orderId,
            discount: Number(discount),
            service: withService ? service : 0,
            iva: {
              value: Number(value),
              active
            },
            paidMethod
          })
        );
        this.loading = false;
        console.info(ok);
        if (ok) {
          this.success = true;
          // this.resetState();
          // this.$emit("paymentSuccess");
          return this.success;
        }
        this.error = true;
      },
      onPaymenSuccess() {
        this.resetState();
        this.$emit("paymentSuccess");
      },
      toggleShowPaymentModal() {
        this.displayPaymentModal = !this.displayPaymentModal;
        return true;
      }
    }
  };
</script>
