const requestWebcheckout = payload => {
  const url = "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/";
  return new Promise(resolve => {
    const http = new XMLHttpRequest();
    http.open("POST", url);
    // http.setRequestHeader('Content-type', 'application/json');
    http.send(JSON.stringify({ ...payload }));
    http.onload = () => resolve(http.responseText);
  });
};

export default requestWebcheckout;
