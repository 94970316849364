const getters = {
  get_cart(state) {
    return state.cart;
  },
  show_cart(state) {
    return state.show_cart;
  }
};

export default getters;
