<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
export default {
  name: "AccountingCard",
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    caption: {
      type: String,
      default: ""
    },
    initialValue: {
      type: Number,
      default: 0
    },
    revenue: {
      type: Number,
      default: 0
    },
    egress: {
      type: Number,
      default: 0
    }
  },
  computed: {
    total() {
      return this.revenue - this.egress;
    }
  }
};
</script>
