<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import OrderCard from "@components/cards/OrderCard";
import EmptySection from "@/components/generals/EmptySection";

export default {
  name: "PanelDashboard",
  components: { OrderCard, EmptySection },
  props: {
    caption: {
      type: String,
      default: () => ""
    },
    type: {
      type: String,
      default: () => ""
    },
    counter: {
      type: Number,
      default: () => 0
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isEmpty() {
      return this.data.length === 0;
    }
  },
  methods: {
    getOrderId(order) {
      const date = order.createAt.toDate();
      const h = date.getHours().toString();
      const m = date.getMinutes().toString();
      const s = date.getSeconds().toString();
      return h + m + s;
    },
    getOrderName(order) {
      return order.hasOwnProperty("table") && order.table.name ? order.table.name : "";
    },
    getDispacherName(order) {
      return order.hasOwnProperty("dispatchedBy") ? order.dispatchedBy.name : "";
    },
    handleMoveTo(event, id) {
      this.$emit("moveTo", event, id, this.type);
    },
    mapToValues(data = {}) {
      return Object.values(data);
    },
    async handleMarkedDish(dish, order) {
      this.$emit("changeDishStatus", { dish, order });
    }
  }
};
</script>
