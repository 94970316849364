import { BASE } from "./index";
const createOrder = (payload = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/order/create`;
  return fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token
    },
    body: JSON.stringify({ ...payload })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res.json());
    return res.json();
  });
};
export default createOrder;
