<style scoped lang="scss">
.item-card__background {
  width: 100%;
  height: 125px;
  background-color: #fbfbfb;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.item-card-selected {
  background: #ed2236 !important;
}
.item-card {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  box-shadow: 1px 2px 2px #e4e4e4;
  transition: all 0.35s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.35s;
  &:hover .item-card__content {
    background: #ed2236;
  }
  .item-card__content {
    background: #16202f;
    padding-left: 10px;
    padding-right: 10px;
    height: 70px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    transition: all 0.35s;
  }
  .item-card__content__title {
    max-height: 40px;
    margin-bottom: 5px;
    margin-top: 8px;
    font-family: Gibson;
    font-weight: 800;
    letter-spacing: 1.2px;
    font-size: 17px;
    line-height: 1;
  }
  .item-card__content__subtitle {
    max-width: 153px;
    line-height: 1.3;
    position: relative;
    top: -2px;
  }
  &:hover {
    box-shadow: 3px 3px 10px #e4e4e4;
  }
}
</style>


<template>
  <div class="item-card" @click="$emit('select', id)">
    <div
      class="item-card__background"
      :style="{'background-image': `url(${image || defaultImage})`, 'background-size': !image ? '': size }"
    ></div>
    <div class="item-card__content" :class="{'item-card-selected': selected}">
      <p class="item-card__content__title" :title="title">
        <v-clamp autoresize :max-lines="2">{{ title }}</v-clamp>
      </p>
      <p class="item-card__content__subtitle" :title="subtitle">
        <v-clamp autoresize :max-lines="1">{{ subtitle }}</v-clamp>
      </p>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import defaultImage from "@/assets/icons/account.svg";

export default {
  name: "ItemCard",
  components: { VClamp },
  props: {
    size: {
      type: String,
      default: "100% - 73px"
    },
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultImage
    };
  }
};
</script>

