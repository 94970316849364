<template>
  <v-card>
    <v-footer class="footer font-weight-medium" height="50">
      <p
        class="footer__text"
      >© Copyright {{ new Date().getFullYear() }} | GeoChef - Todos los derechos reservados</p>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style type="scss" scoped>
.footer__text {
  margin: 0 auto;
  color: #333;
  font-size: 14px;
}
</style>