<style scoped lang="scss">
  @import "style";
</style>
<template src="./template.html"></template>
<script>
  import Loading from "@components/generals/Loading";
  import ChefHeader from "@components/Headers/ChefHeader";
  import PanelDashboard from "@components/panels/PanelDashboard";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";
  import speech from "@/utils/speech";
  import uuid from "@utils/uuid";
  import mixins from "@/mixins";

  const { DateManager } = mixins;
  const { updateOrder, populateOrders, updateDelivery } = requests;

  export default {
    name: "ChefDashboard",
    components: {
      Loading,
      PanelDashboard,
      ChefHeader
    },
    mixins: [DateManager],
    data() {
      const { workingAt = {}, name, email, photo } = window.user;
      const headquaters = Object.values(workingAt);
      const [headquaterSelected] = headquaters;
      headquaterSelected.selected = true;
      const completeKey = uuid();
      const pendingKey = uuid();
      const requestedKey = uuid();
      return {
        headquaters,
        completeKey,
        pendingKey,
        requestedKey,
        suscriptions: [],
        suscriptions_delivery: [],
        pendingNotifications: [],
        headquaterSelected,
        photo,
        orders: { REQUESTED: [], COMPLETE: [], PENDING: [] },
        waiters: {},
        tables: {},
        error: false,
        success: false,
        email,
        name,
        loading: true
      };
    },
    async mounted() {
      this.loading = false;
      this.suscriptions = await this.orderSuscription(this.headquaterSelected, this.onOrderChange);
      this.suscriptions_delivery = await this.deliverySuscription(this.headquaterSelected, this.onDeliveryChange);
      window.addEventListener("headquaterSelected", this.handleHeadquaterSelectedChange);
      window.addEventListener("newOrder", this.handleNewNotification);
      window.addEventListener("updateOrder", this.handleUpdateNotification);
    },
    beforeDestroy() {
      this.cancelSuscriptions();
      window.removeEventListener("headquaterSelected", this.handleHeadquaterSelectedChange);
      window.removeEventListener("newOrder", this.handleNewNotification);
      window.removeEventListener("updateOrder", this.handleUpdateNotification);
    },
    methods: {
      cancelSuscriptions() {
        const [pending, requested, completed] = this.suscriptions;
        pending();
        requested();
        completed();
      },
      filterByStatus(data, status) {
        return data.filter(item => status === item.status);
      },
      countOrdersByStatus(orders, status) {
        return this.filterByStatus(orders, status).length;
      },
      async onOrderChange(orders, type) {
        await this.populateOrders(
          orders.map(_order => _order.id),
          type
        );
      },
      async onDeliveryChange(orders, type) {
        await this.populateDeliveryOrders(
          orders,
          type
        );
      },
      async moveOrderTo(newStatus = "", order, oldStatus) {
        console.log(`${oldStatus} -> ${newStatus}`);

        if (order.type === "delivery") {
          let changeStatus = "";
          if (newStatus === "REQUESTED") {
            changeStatus = "REQUESTED";
          } else if (newStatus === "PENDING") {
            changeStatus = "PENDING";
          } else if (newStatus === "COMPLETE") {
            changeStatus = "COMPLETE";
          }

          if (changeStatus) {
            order.loading = true;
            const { ok } = await surePromise(updateDelivery(order.id, { status: changeStatus }));
            order.loading = false;

            if (ok) {
              order.status = changeStatus;
              const index = this.orders[oldStatus].findIndex(_order => _order.id === order.id);
              this.orders[oldStatus].splice(index, 1);
            }
          }
        } else {
          const { id = "", status = "" } = order;
          const oldStatus = status;
          const item = this.orders[status].find(_order => _order.id === id);
          order.loading = true;
          this.completeKey = uuid();
          this.pendingKey = uuid();
          this.requestedKey = uuid();
          const { ok } = await surePromise(updateOrder(id, { status: newStatus }));
          item.loading = false;
          const index = this.orders[oldStatus].findIndex(_order => _order.id === id);
          this.orders[oldStatus].splice(index, 1);
          this.completeKey = uuid();
          this.pendingKey = uuid();
          this.requestedKey = uuid();
          if (ok) {
            this.success = true;
            return;
          }
          this.error = true;
        }
      },
      async fetchAllUsers(promises, order) {
        const operations = await Promise.all(promises);
        return operations
          .filter(operation => operation.ok)
          .map(operation => operation.result)
          .forEach(waiter => {
            this.waiters[waiter.id] = waiter;
            order.dispatchedBy = waiter;
          });
      },
      removeAllOrders(newOrders) {
        newOrders.forEach(item => {
          const found = this.orders.findIndex(order => order.id === item.id) !== -1;
          if (found) this.orders.splice(found, 1);
        });
      },
      clearAllOrder(batch = []) {
        batch.forEach(id => {
          const { status = "", index } = this.getOrderSpot(id);
          if (status) this.orders[status].splice(index, 1);
        });
      },
      getPendingNotifications() {
        return Object.keys(this.pendingNotifications)
          .map(id => this.getOrder(id))
          .filter(Boolean);
      },
      deleteNotifications(orders) {
        orders.forEach(order => {
          delete this.pendingNotifications[order.id];
        });
      },
      resumeDishesList(dishes = {}) {
        const hasDishes = Object.keys(dishes).length > 0;
        if (hasDishes) {
          let text = " Pedido: ";
          Object.keys(dishes).forEach(key => {
            const dish = dishes[key];
            const { amount, name, suggestion = "" } = dish;
            text += `   Platillo: ${name}, Cantidad ${amount} `;
            if (suggestion) text += ` Nota del cliente:  ${suggestion}  `;
          });
          return text;
        }
        return "";
      },
      playNotifications(notifications, index = 0) {
        const { table = {}, dishes = {}, id = "" } = notifications[index];
        const { name = "" } = table;
        let text = "";
        const type = this.pendingNotifications[id];
        const isNew = type === "NEW";
        if (isNew) {
          text = `Nuevo pedido para la mesa ${name} ${this.resumeDishesList(dishes)}`;
        }
        const isUpdate = type === "UPDATE";
        if (isUpdate) {
          text = `El pedido de la mesa ${name} fue actualizado ${this.resumeDishesList(dishes)}`;
        }
        speech(text, () => {
          const hasPending = index < notifications.length - 1;
          delete this.pendingNotifications[id];
          if (hasPending) return this.playNotifications(notifications, ++index);
        });
      },
      async populateOrders(batch = [], type) {
        const { result = [] } = await surePromise(populateOrders({ batch }));
        // this.clearAllOrder(batch);
        // this.orders[type] = result;
        this.syncOrders(result, type);
        const notifications = this.getPendingNotifications();
        const hasNotification = notifications && notifications.length;
        if (hasNotification) this.playNotifications(notifications, 0);
      },
      async populateDeliveryOrders(result = [], type) {
        // this.orders[type] = result;
        this.syncOrders(result, type);
        const notifications = this.getPendingNotifications();
        const hasNotification = notifications && notifications.length;
        if (hasNotification) this.playNotifications(notifications, 0);
      },
      syncOrders(result, type) {
        if (this.orders[type].length === 0) {
          this.orders[type] = result;
        } else {
          for (const index in result) {
            const order = result[index];
            let found = false;
            for (const i in this.orders[type]) {
              if (this.orders[type][i].id === order.id) {
                found = true;
                this.orders[type][i].status = order.status;
                this.orders[type][i].dishes = order.dishes;
              }
            }

            if (!found) {
              this.orders[type].push(order);
            }
          }
        }
      },
      async handleHeadquaterSelectedChange(event) {
        const { details: headquater } = event;
        this.headquaterSelected = headquater;
        this.cancelSuscriptions();
        this.orders.PENDING = {};
        this.orders.COMPLETE = {};
        this.orders.PENDING = {};
        this.suscriptions = await this.orderSuscription(this.headquaterSelected, this.onOrderChange);
        this.suscriptions_delivery = await this.deliverySuscription(this.headquaterSelected, this.onDeliveryChange);
      },
      async updateDishStatus({ dish, order }) {
        if (order.order_type === "domicilio") {
          const status = dish.status === "DONE" ? "PENDING" : "DONE";
          const { ok } = await surePromise(
            requests.updateDeliveryDishStatus(order.id, dish.id, {
              status
            })
          );
          if (ok) {
            this.success = true;
            return;
          }
          this.error = true;
        } else { // Reservation
          const status = dish.status === "DONE" ? "PENDING" : "DONE";
          const { ok } = await surePromise(
            requests.updateDishStatus(order.id, dish.id, {
              status
            })
          );
          if (ok) {
            this.success = true;
            return;
          }
          this.error = true;
        }
      },
      resolveSnapshot(querySnapshot, callback, type) {
        const result = [];
        const hasData = !querySnapshot.empty;
        if (hasData) {
          querySnapshot.forEach(doc => {
            result.push(doc.data());
          });
          callback(result, type);
        }
      },
      resolveSnapshotDelivery(querySnapshot, callback, type) {
        const result = [];
        const hasData = !querySnapshot.empty;
        if (hasData) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            data.type = "delivery";
            result.push(data);
          });
          callback(result, type);
        }
      },
      getIsoDate(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}-${date.getDate()}`;
      },
      orderSuscription(headquater, callback) {
        const { id = "" } = headquater;
        const today = this.getIsoDate(new Date());
        const db = window.firebase.firestore();
        const OrdersRef = db.collection("Orders");

        const pending = OrdersRef.where("belongsTo", "==", id)
          .where("status", "==", "PENDING")
          .where("createAt", ">=", new Date(today))
          .orderBy("createAt", "desc")
          .onSnapshot(querySnapshot => this.resolveSnapshot(querySnapshot, callback, "PENDING"));

        const requested = OrdersRef.where("belongsTo", "==", id)
          .where("status", "==", "REQUESTED")
          .where("createAt", ">=", new Date(today))
          .orderBy("createAt", "desc")
          .onSnapshot(querySnapshot => this.resolveSnapshot(querySnapshot, callback, "REQUESTED"));

        const completed = OrdersRef.where("belongsTo", "==", id)
          .where("status", "==", "COMPLETE")
          .where("createAt", ">=", new Date(today))
          .orderBy("createAt", "desc")
          .onSnapshot(querySnapshot => this.resolveSnapshot(querySnapshot, callback, "COMPLETE"));

        const subscriptions = [pending, requested, completed];
        return this.orQuery(subscriptions).then(() => subscriptions);
      },
      deliverySuscription(headquater, callback) {
        const { id = "" } = headquater;
        const DeliverysRef = window.db.collection("Deliverys");

        const yesterday = this.subtractDay(new Date(), 1);
        const tomorrow = this.addDay(new Date(), 1);

        const pending = DeliverysRef
          .where("headquater", "==", id)
          .where("status", "==", "REQUESTED")
          .where("date", ">", yesterday)
          .where("date", "<", tomorrow)
          .onSnapshot(querySnapshot => this.resolveSnapshotDelivery(querySnapshot, callback, "REQUESTED"));

        const requested = DeliverysRef
          .where("headquater", "==", id)
          .where("status", "==", "PENDING")
          .where("date", ">", yesterday)
          .where("date", "<", tomorrow)
          .onSnapshot(querySnapshot => this.resolveSnapshotDelivery(querySnapshot, callback, "PENDING"));

        const completed = DeliverysRef
          .where("headquater", "==", id)
          .where("status", "==", "COMPLETE")
          .where("date", ">", yesterday)
          .where("date", "<", tomorrow)
          .onSnapshot(querySnapshot => this.resolveSnapshotDelivery(querySnapshot, callback, "COMPLETE"));

        const subscriptions = [pending, requested, completed];
        return this.orQuery(subscriptions).then(() => subscriptions);
      },
      getOrder(id = "") {
        const request = this.orders.REQUESTED.find(order => order.id === id);
        if (request) return request;
        const complete = this.orders.COMPLETE.find(order => order.id === id);
        if (complete) return complete;
        const pending = this.orders.PENDING.find(order => order.id === id);
        if (pending) return pending;
      },
      getOrderSpot(id = "") {
        const request = this.orders.REQUESTED.findIndex(order => order.id === id);
        if (request > -1) return { status: "REQUESTED", index: request };
        const complete = this.orders.COMPLETE.find(order => order.id === id);
        if (complete > -1) return { status: "COMPLETE", index: complete };
        const pending = this.orders.PENDING.find(order => order.id === id);
        if (pending > -1) return { status: "PENDING", index: pending };
        return {};
      },
      handleUpdateNotification(e) {
        const { detail: id } = e;
        try {
          this.$refs.notificationSound.play();
          if (typeof id === "string") {
            this.pendingNotifications[id] = "UPDATE";
          }
        } catch (error) {
          console.log({ error });
        }
      },
      handleNewNotification(e) {
        const { detail: id } = e;
        try {
          this.$refs.notificationSound.play();
          if (typeof id === "string") {
            this.pendingNotifications[id.payload] = "NEW";
          }
        } catch (error) {
          console.log({ error });
        }
      },
      async firebaseLogout() {
        this.logout();
      }
    }
  };
</script>
