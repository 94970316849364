<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import OrderGrid from "@components/generals/OrderGrid";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { createOrder, addDishesToOrder } = requests;

export default {
  name: "OrderModal",
  components: { OrderGrid },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    },
    headquater: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    display(val) {
      this.open = val;
      this.dishes = this.getDishes();
    }
  },
  data() {
    return {
      open: false,
      out: false,
      domicile: false,
      error: false,
      success: false,
      loading: false,
      dishes: []
    };
  },
  created() {
    this.open = this.display;
    this.dishes = this.getDishes();
  },
  computed: {
    hasDiscount() {
      return Boolean(Number(this.order.discount || "0"));
    },
    hasIva() {
      return this.order.hasOwnProperty("iva") && this.order.iva.active;
    },
    hasService() {
      return this.order.withService && this.order.service;
    }
  },
  methods: {
    mapDishesShape(dishes) {
      const result = {};
      for (const key of Object.keys(dishes)) {
        const dish = dishes[key];
        const { id = "" } = dish;
        result[id] = dish;
      }
      return result;
    },
    hasTable() {
      const { query = {} } = window.router.currentRoute;
      return query.hasOwnProperty("table");
    },
    async addOrder() {
      const { domicile = false, out = false, dishes = {}, order = {}, headquater = {} } = this;
      const { uid: user, restaurants = [] } = window.user;
      const [restaurant] = restaurants;
      const payload = {
        dishes: this.mapDishesShape(dishes),
        restaurant,
        belongsTo: headquater.id,
        headquater: headquater.id,
        dispatchedBy: user,
        domicile,
        user: "ANONYMUS",
        out,
        price: order.price,
        iva: order.iva
      };
      const hasService = order.hasOwnProperty("service") && order.withService === true;
      const hasDiscount = order.hasOwnProperty("discount");
      if (hasService) payload.service = Number(order.service);
      if (hasDiscount) payload.discount = Number(order.discount);
      if (this.hasTable()) payload.table = window.router.currentRoute.query.table;
      this.loading = true;
      let operation = null;
      const alreadyHasOrder = this.order.hasOwnProperty("id");
      if (alreadyHasOrder) {
        operation = addDishesToOrder(this.order.id, payload);
      } else {
        operation = createOrder(payload);
      }
      const { ok, error } = await surePromise(operation);
      this.loading = false;
      this.$emit("close");
      if (ok) {
        this.$emit("success", true);
        const { table = "" } = window.router.currentRoute.query;
        const query = {};
        if (table) {
          query.table = table;
          window.router.replace({ path: "/dashboard/tables", query });
        } else {
          window.router.replace({ path: "/dashboard/delivery", query: window.router.currentRoute.query });
        }
      } else {
        this.$emit("error", error);
      }
    },
    getDishes() {
      const { order = {} } = this;
      const { dishes = {} } = order;
      return Object.values(dishes);
    }
  }
};
</script>
