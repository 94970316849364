const firebaseConfig = {
  apiKey: 'AIzaSyAl87918dq4HBclHo_w8OVazGONNcWkFuk',
  authDomain: 'geochef-dev.firebaseapp.com',
  databaseURL: 'https://geochef-dev.firebaseio.com',
  projectId: 'geochef-dev',
  storageBucket: 'geochef-dev.appspot.com',
  messagingSenderId: '297956310618',
  appId: '1:297956310618:web:9077e88193da456d',
  fileStoreURL: 'https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o'
};

export default firebaseConfig;
