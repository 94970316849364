import calcPromotion from "@/utils/calcPromotion.js";

export default function calculatePrice(dishes = {}, order) {
  const items = Object.keys(dishes).map(id => {
    const { name, price, promotion = "", categories } = dishes[id];
    const { amount } = dishes[id];
    let discount = 0;
    if (promotion) discount = calcPromotion(promotion, amount);
    return {
      name,
      price,
      id,
      promotion,
      categories,
      amount,
      discount
    };
  });
  // debugger;
  let total = 0;
  items.forEach(item => {
    if (item.promotion) {
      total += (item.discount ? item.discount : 1) * item.price;
    } else {
      total += item.amount * item.price;
    }
  });
  if (order && order.iva && order.iva.active) {
    total += order.iva.value;
  }
  return total;
}
