export default {
  name: "UrlDataPersist",
  beforeRouteLeave(to, _from, next) {
    const { query = {}, hash = "", params = {} } = to;
    const hasQuery = Object.keys(query).length !== 0;
    const hasParams = Object.keys(params).length !== 0;
    const hasHash = hash !== "";
    if (!hasHash && !hasParams && !hasQuery) window.router.clear = true;
    next();
  }
};
