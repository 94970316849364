<style scoped lang="scss">
.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__caption {
    color: white;
    font-family: Futura;
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 8px;
  }
}
</style>

<template>
  <div class="switch-container">
    <p class="switch-container__caption">{{ caption }}</p>
    <div class="switch-container__switch align-switcher v-input--switch--inset">
      <v-switch class="v-input--switch--inset" inset color="red" v-model="switcher"></v-switch>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      switcher: this.active
    };
  }
};
</script>
