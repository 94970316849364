<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import uuid from "@utils/uuid";

export default {
  name: "TableOptionsMenu",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    },
    occupy: {
      type: Boolean,
      default: false
    },
    vacate: {
      type: Boolean,
      default: false
    },
    waiter: {
      type: Boolean,
      default: false
    },
    qr: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.options = this.open;
    this.id = `identifier-${uuid()}`;
  },
  computed: {
    hasSomeOption() {
      return this.qr || this.vacate || this.occupy || this.remove || this.edit;
    }
  },
  watch: {
    open(val) {
      this.options = val;
    }
  }
};
</script>
