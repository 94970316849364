<template>
  <div class="slidecontainer">
    <input
      type="range"
      min="1"
      max="100"
      :value="value"
      class="slider"
      @change="$emit('change', $event)"
    >
  </div>
</template>
<style scoped lang="scss">
.slidecontainer {
  height: 100%;
  transform: rotate(-90deg);
  z-index: 30000;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  border-radius: 20px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ED2236;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ED2236;
  cursor: pointer;
}
</style>
<script>
import theme from '@/theme.js';

export default {
  name: 'VerticalSlider',
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  data() {
    const { primary, secondary } = theme;
    return { primary, secondary };
  }
};
</script>
