<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>

  export default {
    name: "EraseConfirmDialog",
    props: {
      title: {
        type: String,
        default: ""
      },
      subtitle: {
        type: String,
        default: ""
      },
      confirm: {
        type: String,
        default: ""
      },
      showDialog: {
        type: Boolean,
        default: false
      },
      closeDialog: {
        type: Function,
        default: () => {
        }
      },
      confirmDialog: {
        type: Function,
        default: () => {
        }
      }
    },
    data() {
      return {
        show: false
      };
    },
    created() {
      this.show = this.showDialog;
    },
    watch: {
      showDialog(newValue) {
        this.show = newValue;
      },
      show(newValue) {
        if (!newValue) {
          this.closeDialog();
        }
      }
    },
    methods: {
      close() {
        this.show = false;
        this.closeDialog();
      }
    }
  };
</script>