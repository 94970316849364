<template>
  <div class="wrap-content-items-time">
    <div v-show="isFirstShedule === ''" class="time">
      <span>
        <img width="12px" src="~@assets/icons/time.svg" />
      </span>
      <span class="wrap-content-items-time-text">Hoy no podemos atenderte</span>
    </div>

    <div
      v-if="schedule[dayweek] && schedule[dayweek].evening"
      v-show="isFirstShedule === 'evening'"
      class="time"
    >
      <span>
        <img width="12px" src="~@assets/icons/time.svg" />
      </span>
      <span
        class="wrap-content-items-time-text"
      >{{ schedule[dayweek].evening.from }} - {{ schedule[dayweek].evening.to }}</span>
      <span class="btn-more" @click="openModal = true">...mas</span>
    </div>

    <div
      v-if="schedule[dayweek] && schedule[dayweek].afternoon"
      v-show="isFirstShedule === 'afternoon'"
      class="time"
    >
      <span>
        <img width="12px" src="~@assets/icons/time.svg" />
      </span>
      <span
        class="wrap-content-items-time-text"
      >{{ schedule[dayweek].afternoon.from }} - {{ schedule[dayweek].afternoon.to }}</span>
      <span class="btn-more" @click="openModal = true">...mas</span>
    </div>

    <div
      v-if="schedule[dayweek] && schedule[dayweek].morning"
      v-show="isFirstShedule === 'morning'"
      class="time"
    >
      <span>
        <img width="12px" src="~@assets/icons/time.svg" />
      </span>
      <span
        class="wrap-content-items-time-text"
      >{{ schedule[dayweek].morning.from }} - {{ schedule[dayweek].morning.to }}</span>
      <span class="btn-more" @click="openModal = true">...mas</span>
    </div>

    <v-dialog v-model="openModal" persistent max-width="500">
      <div class="schedule-dialog__content">
        <p class="schedule-dialog__content-title">Horarios de atención</p>
        <v-btn
          class="schedule-dialog__content-btn-close"
          color="#ED2236"
          flat
          icon
          @click="openModal = false"
        ></v-btn>
        <div v-if="schedule[dayweek] && schedule[dayweek].evening" class="time">
          <span>
            <img width="12px" src="~@assets/icons/time.svg" />
          </span>
          <span
            class="wrap-content-items-time-text"
          >{{ schedule[dayweek].evening.from }} - {{ schedule[dayweek].evening.to }}</span>
        </div>

        <div v-if="schedule[dayweek] && schedule[dayweek].afternoon" class="time">
          <span>
            <img width="12px" src="~@assets/icons/time.svg" />
          </span>
          <span
            class="wrap-content-items-time-text"
          >{{ schedule[dayweek].afternoon.from }} - {{ schedule[dayweek].afternoon.to }}</span>
        </div>

        <div v-if="schedule[dayweek] && schedule[dayweek].morning" class="time">
          <span>
            <img width="12px" src="~@assets/icons/time.svg" />
          </span>
          <span
            class="wrap-content-items-time-text"
          >{{ schedule[dayweek].morning.from }} - {{ schedule[dayweek].morning.to }}</span>
        </div>
        <v-btn
          class="schedule-dialog__content-btn-next"
          color="#ED2236"
          @click="openModal = false"
        >Continuar</v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ScheduleList",
  props: {
    dayweek: {
      type: String,
      default: ""
    },
    schedule: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { firstSchedule: "", openModal: false };
  },
  computed: {
    isFirstShedule() {
      const keys = Object.keys(this.schedule || {});
      let firstSchedule = "";
      if (keys.length) {
        const day = this.schedule[this.dayweek];
        if (day) {
          firstSchedule = this.checkFirstSchedule(day);
          return firstSchedule;
        }
        return firstSchedule;
      }
      return firstSchedule;
    }
  },
  methods: {
    checkFirstSchedule(day) {
      let firstSchedule = "";
      if (day.morning) firstSchedule = "morning";
      if (firstSchedule !== "" && day.afternoon) firstSchedule = "afternoon";
      if (firstSchedule !== "" && day.evening) firstSchedule = "evening";
      return firstSchedule;
    }
  }
};
</script>

<style scoped lang="scss">
.wrap-content-items-time {
  color: white;
  overflow: hidden;
  height: 23px;
}
.time {
  margin-bottom: 5px;
}
.wrap-content-items-time-text {
  margin-left: 10px;
}
.schedule-dialog__content {
  background: white;
  height: 250px;
  border-radius: 10px;
  padding: 5px;
  padding-top: 30px;
  position: relative;
}
.schedule-dialog__content-title {
  color: #16202f;
  font-size: 18px;
  font-family: Gibson-Bold;
  margin-left: 17px;
}
.schedule-dialog__content-btn-next {
  padding: 10px;
  color: white;
  border-radius: 12px;
  width: calc(100% - 15px);
  height: 50px;
  margin-top: 20px;
  font-size: 13px;
  font-family: Gibson-Bold;
}
.schedule-dialog__content-btn-close {
  background-image: url("~@assets/icons/close-red.svg");
  background-position: center center;
  position: absolute;
  top: 10px;
  right: 5px;
}
.btn-more {
  cursor: pointer;
}
</style>
