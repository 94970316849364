import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

const actions = {
  async setDataAlbumsAction({ commit, dispatch }, payload) {
    console.log('setDataAlbumsAction');
    const albumPictures = [];
    const { ok, result } = await surePromise(requests.getAllAlbums({ belongsTo: payload, limit: 100 }));

    if (ok && result && result.length > 0) {
      result.forEach(async (gallery) => {
        if (gallery.files.length > 0) {
          gallery.picture = await dispatch('getPictures', gallery.id);
          albumPictures.push(gallery);
        } else {
          await this.deleteAlbumAction(gallery.id, payload);
          if (result.length === 1) {
            commit('setDataPanoramicsMutation', albumPictures);
          }
        }
      });
      commit('setDataAlbumsMutation', albumPictures);
    } else {
      commit('setDataAlbumsMutation', albumPictures);
      commit('setDataPanoramicsMutation', albumPictures);
    }
  },

  async getPictures({ commit }, albumId) {
    console.log(commit);
    const { result } = await surePromise(requests.getAlbumPicture({ belongsTo: albumId, limit: 1 }));
    console.log('getPictures', result);
    return result[0].url;
  },

  async deleteAlbumAction(albumId, headquaterId) {
    const { ok } = await surePromise(requests.deleteAlbum(albumId, headquaterId));
    return ok;
  },

  setDataPanoramicsAction({ commit }, payload) {
    commit('setDataPanoramicsMutation', payload);
  },

  renderPanoramicsAction({ commit }, payload) {
    commit('renderPanoramicsMutation', payload);
  },

  managerShowFileUpdateAction({ commit }, payload) {
    commit('managerShowFileUpdateMutation', payload);
  },

  setDataFileUpdateAction({ commit }, payload) {
    commit('setDataFileUpdateMutation', payload);
  }
};

export default actions;
