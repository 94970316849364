<style scoped lang="scss">
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import TableFooter from "@components/tables/TableFooter";

const { fetchClients } = requests;

export default {
  name: "ClientTables",
  components: { TableFooter },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      page: 1,
      batch: 10,
      error: false,
      success: false,
      pagination: {
        sortBy: "name"
      },
      selected: [],
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name"
        },
        { text: "Numero de telefono", value: "phone", align: "left" },
        { text: "Correo electronico", value: "email", align: "left" },
        { text: "Ultima visita", value: "lastVisit", align: "left" }
      ],
      body: []
    };
  },
  filters: {
    noneField(val) {
      if (!val) {
        return "No hay dato";
      }
      return val;
    }
  },
  async mounted() {
    this.loading = true;
    this.getClients();
  },
  methods: {
    loadMore(direction) {
      if (direction === "next") {
        this.page += 1;
      }
      if (direction === "back") {
        this.page -= 1;
      }
      this.loading = true;
      this.getClients();
    },
    async getClients() {
      const { id = "" } = this.headquater;
      const { page = 1, batch = 10 } = this;
      const { ok, result = {} } = await surePromise(fetchClients(id, { page, batch }));
      this.loading = false;
      if (ok) {
        const { clients = [] } = result;
        this.body = clients;
        return;
      }
      this.error = true;
    },
    filterRepeats(client) {
      const { id = "" } = client;
      const isNotIn = this.body.some(item => item.id === id) === false;
      return isNotIn;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.body.slice();
    }
  }
};
</script>
