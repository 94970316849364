import { render, staticRenderFns } from "./RatingRestaurant.html?vue&type=template&id=cfbd12fa&scoped=true&"
import script from "./RatingRestaurant.js?vue&type=script&lang=js&"
export * from "./RatingRestaurant.js?vue&type=script&lang=js&"
import style0 from "./RatingRestaurant.scss?vue&type=style&index=0&id=cfbd12fa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfbd12fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VFlex,VLayout,VProgressCircular,VSnackbar,VTextarea})
