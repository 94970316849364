<template>
  <article class="wrap-content-admin-dashboard__reservs">
    <div class="free-table-container__caption-and-cta">
      <h1 class="free-table-container__caption">Reservas</h1>
      <ReservationMenu :headquater="headquater"></ReservationMenu>
    </div>
    <Reservations
      class="padding-reservations"
      :headquater="headquater"
      status="PENDING"
      mode="admin"
      @openReservation="$emit('openReservation', $event)"
    ></Reservations>
  </article>
</template>

<script>
import Reservations from "@components/generals/Reservations";
import ReservationMenu from "@components/menus/ReservationMenu";

export default {
  name: "ReservationsComboTables",
  components: { Reservations, ReservationMenu },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: ""
    },
    data: {
      type: Array,
      default: () => []
    },
    tables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showMenu: false,
      displayQrModal: false,
      tableQrUrl: "",
      tableName: ""
    };
  }
};
</script>

<style scoped lang="scss">
.padding-reservations {
  padding-bottom: 0;
}

.free-table-container__btn-add {
  position: absolute;
  top: -5px;
}

::v-deep .v-tabs__item--active {
  background: #f7f7f7 !important;
  border-bottom: 3px solid #ed2236;
  color: #16202f;
}
.free-table-container {
  padding: 0px !important;
  margin-top: 30px;
}

.free-table-container__caption {
  font-size: 30px;
  color: #161f2f;
  font-family: Futura;
  position: relative;
  margin-bottom: 15px;
}

.free-table-container__caption-and-cta {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.free-table-container ::v-deep .table-list-container__wrap-list--list {
  width: 150px !important;
  height: 150px !important;
  margin-bottom: 8px;
  box-shadow: 2px 3px 4px #00000030 !important;
  transition: all 0.35s ease-in;
  &:hover {
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.45) !important;
  }
}

.free-table-container__btn-reserv {
  height: 75px;
  color: white;
  float: right;
  position: relative;
  border-radius: 9px;
  box-shadow: inherit !important;

  .v-btn__content {
    p {
      font-size: 13px;
      font-family: Gibson-Bold;
      margin: 0px;
    }
    img {
      width: 30px;
      margin-bottom: 8px;
    }
  }
}

container-busy-tables ::v-deep .table-list-container__wrap-list--list {
  margin-bottom: 20px;
  width: calc(100% - 20px);
  box-shadow: 2px 3px 4px #00000063;
  padding-top: 20px;
}

.container-tables .empty {
  text-align: center !important;
  width: 100%;
  display: block;
}

.free-table-container .empty {
  color: #161f2f !important;
  margin-top: 100px;
}

.wrap-content-admin-dashboard__reservs {
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: scroll;
  height: 100vh;
  border-radius: 0px;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    height: 20px;
    background-color: rgb(237, 34, 54);
  }
}
</style>
