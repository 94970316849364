 <style scoped lang="scss">
@import "~@/styles/_colors.scss";
::v-deep .confirm-menu {
  background: white !important;
  height: 131px;
  border-radius: 12px !important;
}
::v-deep .v-list__tile__title {
  font-family: Gibson-Bold;
  font-size: 20px;
  position: relative;
  top: -10px;
  left: -9px;
}

.item-pin {
  margin-left: 0px;
  margin-top: 4px;
  position: relative;
  top: -4px;
  left: 8px;
}

.item-edit {
  margin-left: 3px;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 20px;
  background-image: url("~@assets/icons/edit.svg");
  background-repeat: no-repeat;
}

.container-list {
  padding-top: 10px;
  padding-left: 0px;
  padding-bottom: 10px;
  color: $secondary;
}
.container-list__item {
  list-style: none;
  display: block;
  cursor: pointer;
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 4px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.25s ease;
}
.container-list__item--selected {
  box-shadow: 0px 2px 4px #d4d4d4;
}
.container-list__item__title {
  padding-top: 8px;
  padding-left: 25px;
}
</style>

<template>
  <ul class="container-list">
    <li @click="handleSelectAll" v-if="categories.length" class="container-list__item">
      <v-layout row>
        <v-flex sm1 xs1 v-if="Boolean(caption)">
          <img width="34px" height="34px" :src="geochef" />
        </v-flex>
        <v-flex sm10 class="container-list__item__title">
          <span>{{ caption }}</span>
        </v-flex>
        <v-flex sm1 xs1>
          <img v-if="selectAll" width="30px" height="34px" :src="pin" />
        </v-flex>
      </v-layout>
    </li>

    <li
      v-for="({name, logo, id, selected}, index) in filterDishes"
      :key="id"
      class="container-list__item"
      :class="{ 'container-list__item--selected': !!selected }"
      @click="() => handlerSelect(index)"
    >
      <v-layout row>
        <v-flex sm1 xs1>
          <img width="34px" height="34px" :src="logo" />
        </v-flex>
        <v-flex sm10 class="container-list__item__title">
          <span>{{ name }}</span>
        </v-flex>
        <v-flex sm1 xs1>
          <img v-if="selected" class="item-pin" width="30px" height="34px" :src="pin" />
        </v-flex>
      </v-layout>
    </li>
  </ul>
</template>

<script>
import pin from "@assets/icons/marker-pin-red.svg";
import edit from "@assets/icons/edit.svg";
import close from "@assets/icons/close-red.svg";
import defaultLogo from "@assets/icons/marker-avatar.svg";
import geochef from "@assets/icons/geochef.svg";
import requests from "@/helpers/requests";

export default {
  name: "CategoriesList",
  props: {
    mode: {
      type: String,
      default: "show"
    },
    caption: {
      type: String,
      default: () => ""
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pin,
      close,
      edit,
      geochef,
      defaultLogo,
      selectAll: false,
      busy: false,
      deleting: false,
      loading: false
    };
  },
  computed: {
    filterDishes() {
      return this.categories.filter(item => {
        const { id } = item;
        if (this.mode === "show") {
          const isAllow =
            id !== "2cd158eb-5fca-424e-acd3-f9accb9a9955" &&
            id !== "32ceb392-f5ae-4811-ba97-e43539a69a7a" &&
            id !== "9c4e239e-6e8e-4447-937e-53a234d038e8";
          return isAllow;
        }
        return true;
      });
    }
  },
  methods: {
    deleteCategory(id = "") {
      this.busy = true;
      this.deleting = true;
      requests
        .deleteCategory(id)
        .then(response => this.handleDeleteCategoryResponse(id, response))
        .catch(this.handleDeleteCategoryFail);
    },
    handleDeleteCategoryResponse(id = "", response) {
      this.busy = false;
      this.deleting = false;
      if (response.ok) {
        this.success = true;
        const index = this.categories.map(item => item.id).indexOf(id);
        if (index !== -1) {
          this.categories.splice(index, 1);
        }
        this.$emit("deleted");
      } else {
        this.error = true;
      }
    },
    handleDeleteCategoryFail(error) {
      console.log("error ", error);
      this.busy = false;
      this.deleting = false;
      this.error = true;
    },
    handleSelectAll() {
      // this.selectAll = !this.selectAll;
      this.categories.forEach(item => {
        item.selected = false;
      });
      this.$emit('selectAll');
      // this.$emit("select", this.categories);
    },
    getSelectes() {
      return this.categories.filter(item => item.selected);
    },
    handlerSelect(index) {
      this.selectAll = false;
      const alreadySelected = this.categories[index].selected;
      if (this.mode === "edit") {
        this.categories[index].selected = !this.categories[index].selected;
        this.$emit("select", this.categories, index);
      } else {
        this.categories.forEach(item => {
          item.selected = false;
        });
        if (alreadySelected) return this.$emit("unselect");
        this.categories[index].selected = !this.categories[index].selected;
        this.$emit("select", this.categories, index);
      }
    }
  }
};
</script>
