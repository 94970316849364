import Vue from 'vue';
import ConfirmModal from "@components/modals/ConfirmModal";
import requests from '@/helpers/requests';
import STORE from '@/store/types';
const { updateProfile } = requests;

export default Vue.extend({
  name: 'UpdateAvatar',
  props: {
    position: {
      type: Object,
      default: () => ({
        bottom: 'auto',
        right: 'auto',
        left: 'auto',
        top: 'auto'
      })
    }
  },
  components: {
    ConfirmModal
  },
  data: () => ({
    showConfirmBox: false,
    avatar: [],
    loading: false
  }),
  methods: {
    async updateAvatar(files) {
      this.loading = true;
      const file = files[0];
      if (file.size > 4e+6) {
        console.log('es muy pesada', file);
        return;
      }
      const blob = file.slice(0, file.size, 'image/*');
      const newFile = new File([blob], window.user.uid, { type: file.type });
      const BASE = 'https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o/images%2FUsers';
      const avatar = `${BASE}%2F${newFile.name}?alt=media`;
      const storageRef = window.firebase.storage().ref(`images/Users/${newFile.name}`);
      await storageRef.put(newFile).then(snapshotAvatar => {
        console.log(snapshotAvatar);
      }).catch(e => {
        console.log(e);
      });
      const { ok } = await updateProfile({ photoURL: avatar });
      if (ok) {
        this.loading = false;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.$store.commit(STORE.MUTATIONS.PROFILE.SET_PROFILE, { photoURL: event.target.result });
        };
        reader.readAsDataURL(file);
        window.user.photoURL = avatar;
        this.$emit('successUpdateAvatar', { response: true });
        return;
      }
      this.loading = false;
      this.$emit('successUpdateAvatar', { response: false });
    }
  }
});
