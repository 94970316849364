const actions = {
  add_to_cart({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      if (state.headquater === payload.headquater || state.headquater === '') {
        commit('add_to_cart', payload);
        resolve();
      } else {
        reject();
      }
    });
  },
  update_cart({ commit }, payload) {
    commit('update_cart', payload);
  },
  remove_cart_item({ commit }, payload) {
    commit('remove_cart_item', payload);
  },
  remove_cart_items({ commit }, payload) {
    return new Promise((resolve) => {
      commit('remove_cart_items', payload);
        resolve();
    });
  },
  open_cart({ commit }) {
    commit('open_cart');
  },
  close_cart({ commit }) {
    commit('close_cart');
  },
  current_order({ commit }, payload) {
    commit('current_order', payload);
  },
  close_order({ commit }) {
    commit('close_order');
  },
  open_menu({ commit }) {
    commit('open_menu');
  },
  close_menu({ commit }) {
    commit('close_menu');
  },
  show_success_order({ commit }) {
    commit('show_success_order');
  },
  close_success_order({ commit }) {
    commit('close_success_order');
  }
};

export default actions;
