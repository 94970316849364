import buildUrlQuery from "@utils/buildUrlQuery.js";
import { BASE } from "./index";

const getComments = (headquater = "", query = {}) => {
  const token = localStorage.getItem("token") || "";
  const params = buildUrlQuery({ headquater, user: true, limit: 10, ...query });
  const url = `${BASE}/comments?${params}`;
  return fetch(url, {
    method: "GET",
    headers: { token }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default getComments;
