<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import ItemsLists from "@components/lists/ItemsLists";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import MenuForm from "@components/forms/MenuForm";
import ArticleFrame from "@components/generals/ArticleFrame";
import CreateDishModal from "@components/modals/CreateDishModal";

export default {
  name: "AdminArticles",
  components: {
    Checkbox,
    ArticleFrame,
    Loading,
    ItemsLists,
    UserBasicInfo,
    HeadquatersSelect,
    MenuForm,
    CreateDishModal,
    ReservationsComboTables
  },
  data() {
    return {
      headquater: {},
      tables: []
    };
  },
  created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    handleChooseTable() {
      console.log("choose table");
    },
    handleOpenReservation() {
      console.log("open reservation");
    }
  }
};
</script>
