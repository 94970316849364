<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import BasicInput from "@inputs/BasicInput";
import Loading from "@components/generals/Loading";
import InvitationHeader from "@components/Headers/LogoRed";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

export default {
  name: "Invitation",
  components: { BasicInput, Loading, InvitationHeader },
  computed: {
    submitBtnIsDisabled() {
      const { loading, checkValidity } = this;
      const result = loading === true || checkValidity() === false;
      return result;
    }
  },
  data() {
    return {
      reset: false,
      values: {
        code: ""
      },
      validity: {
        code: false
      },
      loading: false
    };
  },
  methods: {
    checkValidity() {
      const { validity } = this;
      return Object.values(validity).every(item => item === true);
    },
    async submitForm() {
      const { values = {} } = this;
      const { code = "" } = values;
      this.loading = true;
      const { error, ok } = await surePromise(requests.validateInvitation(code));
      this.loading = false;
      if (ok) {
        window.router.push("dashboard");
      } else {
        this.validity.code = false;
        console.log({ error });
      }
    },
    handleInputChange(value) {
      const [content, indicative] = value.split("-");
      const isValid = Boolean(content) && Boolean(indicative);
      this.values.code = value;
      this.validity.code = isValid;
    }
  }
};
</script>
