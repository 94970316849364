/* eslint-disable */
/**
 * @function validWord
 * @param  {type} data {description}
 * @return {type} {description}
 */
const word = (data = "") => {
  const result = /[A-Za-záéëËíóúÁÉÍÓÚñÑñÑ0-9_]{1,100}/i.exec(data);
  if (result) return result[0].length === data.length;
  return false;
};

/**
 * @function validPassword
 * @param  {type} data {description}
 * @return {type} {description}
 */
const validPassword = (data = "") => {
  const result = /[A-Za-záéëËíóúÁÉÍÓÚñÑñÑ0-9_]{6,20}/i.exec(data);
  const noSpace = !/(\W)/g.exec(data);
  if (result) return result[0].length === data.length && noSpace;
  return false;
};

/**
 * @function allSpace
 * @param  {type} data {description}
 * @return {type} {description}
 */
const allSpace = (data = "") => {
  let spaces = 0;
  for (let i = 0; i < data.length; i++) {
    const result = /\s/g.test(data[i]);
    if (result) spaces++;
  }
  const result = spaces === data.length;
  return result;
};

/**
 * @function validText
 * @param  {type} data {description}
 * @return {type} {description}
 */
const validText = (data = "") => {
  const result = /[(\r\n|\r|\n)A-Za-záéëËíóúÁÉÍÓÚñÑ0-9_:#$&/*¡;%?=()"\]\, /[/.-]{2,500}/gi.exec(data);
  const inRange = data.length >= 2 && data.length <= 500;
  if (result) return result[0].length === data.length && inRange && !allSpace(data);
  return false;
};

const validName = (data = "") => {
  const result = /[A-Za-záéëËíóúÁÉÍÓÚñÑ0-9_ .]{3,50}/i.exec(data);
  if (result) return result[0].length === data.length && !allSpace(data);
  return false;
};

/**
 * @function validAddress
 * @param  {type} data {description}
 * @return {type} {description}
 */
const validAddress = (data = "") => {
  const result = /[A-Za-záéíëËóúÁÉÍÓÚñÑ#/.,0-9-_ ]{1,500}/i.exec(data);
  if (result) return result[0].length === data.length;
  return false;
};

/**
 * @function validNumber
 * @param  {type} value {description}
 * @return {type} {description}
 */
const validNumber = (value = "") => {
  const result = /[0-9]{1,100}/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

/**
 * @function validPhone
 * @param  {type} value {description}
 * @return {type} {description}
 */
const validPhone = (value = "") => {
  const result = /[0-9()+]{1,100}/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

/**
 * @function validCellPhone
 * @param  {type} value {description}
 * @return {type} {description}
 */
const validCellPhone = (value = "") => {
  const result = /([3]{1})([0-2]{1})([0-9]{1})[0-9]{7}/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

/**
 * @function validEmail
 * @param  {type} value {description}
 * @return {type} {description}
 */
const validEmail = (value = "") => {
  const result = /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){3}\.[a-z]{2,3}$/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

/**
 * @function enumeration
 * @param  {type} value {description}
 * @return {type} {description}
 */
const enumeration = (list = [], value) => {
  const result = list.some(item => item === value);
  if (result === false) {
    // console.log('enumeration: ', list, ' value: ', value);
  }
  return result;
};
const validFilesize = value => {
  const MAXSIZE = 10000000; // 10.000.000 Bytes => 10MB
  const isValid = validNumber(value);
  if (isValid) {
    value = Number(value);
    // console.log("FILESIZE: ", value);
    return value <= MAXSIZE;
  }
  return false;
};

const validCC = value => {
  const result = /([0-9]{10})|([0-9]{9})|([0-9]{8})|([0-9]{7})|([0-9]{6})/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

const validNITFormat = (value = "") => {
  value = value.replace(/([.-])/g, "");
  const secuencia = [41, 37, 29, 23, 19, 17, 13, 7, 3];
  let count = 0;
  secuencia.forEach((current, index) => {
    count += Number(value[index]) * current;
  });
  count %= 11;
  if (count >= 2) count = 11 - count;
  return count === Number(value[9]);
};

const validNIT = (value = "") => {
  // if(value.length !== 10) return false;
  let isValid = false;
  const points = /(\d{3}[.])(\d{3}[.])(\d{3}[-])(\d{1})/g.exec(value);
  const dash = /(\d{1,9}[-])(\d{1})/g.exec(value);
  const numbers = /(\d){10}/g.exec(value);

  if (points) {
    isValid = points[0].length === value.length;
    if (isValid) return validNITFormat(value);
    return false;
  }

  if (dash) {
    isValid = dash[0].length === value.length;
    if (isValid) return validNITFormat(value);
    return false;
  }

  if (numbers) {
    isValid = numbers[0].length === value.length;
    if (isValid) return validNITFormat(value);
    return false;
  }
};

const validCcNIT = (value = "") => {
  const nit = validNIT(value);
  const cc = validCC(value);
  return nit || cc;
};

const validUrl = value => {
  const result = /https?:\/{2}(?:[\/-\w.]|(?:%[\da-fA-F]{2}))+/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

const validString = value => {
  const result = /([a-zA-Z0-9])\w+/g.exec(value);
  if (result) return result[0].length === value.length;
  return false;
};

export default {
  enumeration,
  validString,
  validName,
  validCcNIT,
  validWord: word,
  validCellPhone,
  validUrl,
  validPassword,
  validAddress,
  validNumber,
  validEmail,
  validPhone,
  validText,
  allSpace,
  validFilesize
};
