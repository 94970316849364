<template>
  <div>
    <v-dialog v-model="open" max-width="500" persistent>
      <v-container class="confirm-modal__container">
        <div class="confirm-modal__container__header">
          <span class="confirm-modal__container__header__caption">{{ title }}</span>
          <v-btn
            icon
            :disabled="loading === true"
            class="confirm-modal__container__header__btn-close"
            @click="open = false"
          ></v-btn>
        </div>

        <div class="confirm-modal__container__footer">
          <v-container v-if="loading" class="text-xs-center">
            <v-progress-circular
              indeterminate
              color="primary"
              class="confirm-modal__container__footer__progress"
            ></v-progress-circular>
          </v-container>

          <v-btn
            v-if="loading === false"
            class="confirm-modal__container__footer__btn-cancel"
            color="secondary"
            @click="open = false"
          >{{ btnTextCancel }}</v-btn>

          <v-btn
            v-if="loading === false"
            class="confirm-modal__container__footer__btn-confirm"
            color="primary"
            @click="handleConfirm"
          >{{ btnTextAgree }}</v-btn>
        </div>
      </v-container>
    </v-dialog>

    <v-snackbar
      v-model="error"
      color="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >Ocurrio un error intenta mas tarde.</v-snackbar>

    <v-snackbar
      v-model="success"
      color="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >Operacion completada con exito.</v-snackbar>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    title: {
      type: String,
      default: "Confirmación"
    },
    description: {
      type: String,
      default: ""
    },
    display: {
      type: Boolean,
      default: false
    },
    btnTextCancel: {
      type: String,
      default: "Cancelar"
    },
    btnTextAgree: {
      type: String,
      default: "Aceptar"
    }
  },
  data() {
    return {
      open: this.display,
      success: false,
      error: false,
      loading: false
    };
  },
  watch: {
    open(val) {
      if (val === false) this.$emit("close");
    },
    display(val) {
      this.open = val;
    }
  },
  methods: {
    handleConfirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-modal__container__footer {
  margin-top: 10px;
  text-align: left;
  button {
    border-radius: 8px;
    height: 40px;
    font-family: Gibson-Bold;
    font-size: 16px;
    text-transform: capitalize;
    width: 150px;
  }
}

::v-deep .v-dialog--active {
  border-radius: 12px;
}
.confirm-modal__container {
  background: white;
}
.confirm-modal__container__header {
  font-size: 25px;
  font-family: Gibson-Bold;
  color: #161f2f;
  padding-left: 15px;
}
.confirm-modal__container__header__btn-close {
  background-image: url("~@assets/icons/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  float: right;
  position: relative;
  top: -14px;
  right: -14px;
}
</style>
