import { BASE } from "./index";
const updateDish = (id = "", data = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/dish/update/${id}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default updateDish;
