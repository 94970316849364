const mutations = {
  setDataAlbumsMutation(state, payload) {
    state.albums = payload;
  },

  setDataPanoramicsMutation(state, payload) {
    state.panoramics = payload;
  },

  renderPanoramicsMutation(state, payload) {
    state.renderPanoramic = payload;
  },

  statusMediaManagerPanoramicsMutation(state, payload, newStateShow) {
    state.panoramics[payload].show = newStateShow;
  },

  managerShowFileUpdateMutation(state, payload) {
    state.modalFileUpdate.show = payload;
  },

  setDataFileUpdateMutation(state, payload) {
    state.modalFileUpdate.dataUpdate = payload;
  }
};

export default mutations;
