import { BASE } from "./index";

const deletePanoramic = (pictureId = '') => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/file/delete/${pictureId}`;

  return fetch(url, {
    method: "DELETE",
    headers: {
      token,
      "content-type": "application/json"
    }
  })
  .then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  })
  .catch(error => {
    console.error('ERROR: ', error);
  });
};

export default deletePanoramic;
