<style scoped lang="scss">
.wrap__reaseon-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.wrap__reaseon-modal__btn-close  {
   top: 10px;
  right: 14px;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  background-image: url('~@assets/icons/close-red.png');
  background-repeat: no-repeat;
  background-position: center;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="open"
      max-width="500"
      persistent
    >
      <div class="wrap__reaseon-modal">
        <div
          class="wrap__reaseon-modal__btn-close "
          @click="$emit('close')"
        ></div>
        <p>
          {{ text }}
        </p>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ReaseonModal',
  props: {
    display: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      open: this.display
    };
  },
  watch: {
    display(val) {
      this.open = val;
    }
  }
};
</script>
