<style scoped lang="scss">
@import "~@/styles/_colors.scss";

::v-deep .v-input__icon--append {
  overflow: hidden;
}

.select ::v-deep .v-input__slot {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2) !important;
}

::v-deep .v-input__icon--append i {
  color: transparent !important;
  background-image: url("~@assets/icons/arrow-down.png");
  background-position: center;
  background-size: 25px;
}

::v-deep .v-icon--disabled {
  color: transparent !important;
}
</style>

<template>
  <div>
    <v-select
      @input="handleChange"
      v-model="selected"
      class="select"
      :disabled="disable"
      :items="data"
      item-text="name"
      item-value="id"
      :label="label"
      solo
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "BasicSelect",
  props: {
    label: {
      type: String,
      default: () => ""
    },
    data: {
      type: Array,
      default: () => []
    },
    disable: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    defaultSelected: {
      type: String,
      default: ""
    }
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
    },
    reset() {
      this.selected = "";
    }
  },
  data() {
    const { defaultSelected = "" } = this;
    return {
      selected: defaultSelected
    };
  },
  methods: {
    handleChange() {
      this.$emit("change", this.selected);
    }
  }
};
</script>

