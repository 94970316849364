<style scoped lang="scss">
.categories__language-modal__btn-continue {
  width: calc(100% - 10px);
  border-radius: 12px;
  cursor: pointer;
  height: 42px;
  font-family: Gibson-Bold;
  position: relative;
  left: -2px;
  top: -6px;
}

::v-deep .v-input__slot {
 box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
}
.categories-modal__close {
  background-position: center;
  background-image: url('~@assets/icons/close.svg');
  background-size: 25px;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;
  top: 5px;
  left: calc(100% - 20px);
}

::v-deep .v-dialog--active {
    background: white;
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
  .categories-modal__title {
     color: #ED2236;
     font-size: 19px;
     font-family: Gibson-Bold;
     text-transform: uppercase;
     margin-bottom: 0px;
     margin-top: 20px;
  }
}
</style>

<template>
  <div>
    <v-dialog
      v-model="display"
      max-width="500"
      class="categories-modal"
      persistent
    >
      <div
        class="categories-modal__close"
        @click="handleCloseModal"
      ></div>

      <CategoriesTabsManager
        ref="categoriesTabsManager"
        :data="categories"
        @requestSuccess="success = true"
      ></CategoriesTabsManager>
    </v-dialog>

    <v-snackbar
      v-model="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
      color="success"
    >
      Operación completada con exito.
    </v-snackbar>

    <v-snackbar
      v-model="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
      color="error"
    >
      Ocurrio un error intenta mas tarde.
    </v-snackbar>
  </div>
</template>

<script>
import CategoriesTabsManager from '@components/generals/CategoriesTabsManager';

export default {
  name: 'CategoriesModal',
  components: { CategoriesTabsManager },
  props: {
    categories: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: this.open, ready: false, error: false, success: false
    };
  },
  watch: {
    open(val) {
      this.display = val;
    }
  },
  methods: {
    handleCloseModal() {
      const { categoriesTabsManager } = this.$refs;
      const categories = categoriesTabsManager.getSelectedCategories();
      this.$emit('close', categories);
    },
    handleUpdateSuccess(response) {
      console.log(response);
      if (response.ok) {
        this.$emit('close');
      } else {
        this.error = true;
      }
    },
    handleUpdateError(error = {}) {
      console.log('error ', error);
      this.error = true;
    }
  }
};
</script>
