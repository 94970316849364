import Vue from 'vue';
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import mixin from "@/mixins";
import BoxFlow from "@views/Admin/Boxflow";
// const allMonths = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const { Accounting } = mixin;

export default Vue.extend({
  name: 'HomeAdmin',
  mixins: [Accounting],
  components: {
    BoxFlow
  },
  data: () => ({
    tables: [],
    reservations: {
      total: 0,
      month: 0
    },
    dishes: {
      categories: 0,
      products: 0
    },
    delivery: [],
    commits: 0,
    bills: {
      today: 0,
      month: 0
    },
    accounting: {
      today: 0,
      month: 0
    },
    params: ''
  }),
  computed: {
    cards() {
      return [
        // {
        //   image: require('@/assets/icons/clients.svg'),
        //   title: 'Clientes',
        //   subTitle: {
        //     text: 'De este mes',
        //     quantity: this.$store.getters['clients/getTotalByMont']
        //   },
        //   total: {
        //     text: 'Total de clientes',
        //     quantity: this.$store.getters['clients/getClients'].length
        //   },
        //   route: '/dashboard/business'
        // },
        {
          image: require('@/assets/icons/sales.svg'),
          title: 'Ventas',
          subTitle: {
            text: 'De hoy',
            quantity: this.$store.getters['clients/getTotalsOrders'].day
          },
          total: {
            text: 'De este mes',
            quantity: this.$store.getters['clients/getTotalsOrders'].month
          },
          route: '/dashboard/boxflow'
        },
        {
          image: require('@/assets/icons/reservation.svg'),
          title: 'Reservas',
          subTitle: {
            text: 'De hoy',
            quantity: this.reservations.total
          },
          total: {
            text: 'De este mes',
            quantity: this.reservations.month
          },
          route: '/dashboard/headquaters'
        },
        // {
        //   image: require('@/assets/icons/reservation.svg'),
        //   title: 'Gastos',
        //   subTitle: {
        //     text: 'De hoy',
        //     quantity: this.accounting.today
        //   },
        //   total: {
        //     text: 'De este mes',
        //     quantity: this.accounting.month
        //   },
        //   route: '/dashboard/boxflow'
        // },
        {
          image: require('@/assets/icons/tables.svg'),
          title: 'Mesas',
          subTitle: {
            text: 'Disponibles',
            quantity: this.isBusy
          },
          total: {
            text: 'En uso',
            quantity: this.isFree
          },
          route: '/dashboard/tables'
        },
        // {
        //   image: require('@/assets/icons/pos.svg'),
        //   title: 'Pos',
        //   subTitle: {
        //     text: 'De hoy',
        //     quantity: 30
        //   },
        //   total: {
        //     text: 'N. de pedidos',
        //     quantity: 50
        //   }
        // },
        {
          image: require('@/assets/icons/product.svg'),
          title: 'Productos',
          subTitle: {
            text: 'N. De categoría',
            quantity: this.dishes.categories
          },
          total: {
            text: 'N. De productos',
            quantity: this.dishes.products
          },
          route: '/dashboard/menus'
        },
        {
          image: require('@/assets/icons/orders.svg'),
          title: 'Pedidos',
          subTitle: {
            text: 'Pendientes',
            quantity: this.deliberyPending
          },
          total: {
            text: 'En proceso',
            quantity: this.deliberyProcessing
          },
          route: '/dashboard/delivery'
        },
        {
          image: require('@/assets/icons/deliveries.svg'),
          title: 'Entregas',
          subTitle: {
            text: 'Pendientes',
            quantity: 45
          },
          total: {
            text: 'En proceso',
            quantity: 40
          }
        },
        {
          image: require('@/assets/icons/chat.svg'),
          title: 'Comentarios',
          subTitle: {
            text: 'Total',
            quantity: this.commits
          },
          total: {
            text: 'Notificaciones',
            quantity: 40
          },
          route: '/dashboard/comments'
        }
      ];
    },
    isBusy() {
      return this.tables.filter((table) => (table.status === 'BUSY')).length;
    },
    isFree() {
      return this.tables.filter((table) => (table.status === 'FREE')).length;
    },
    deliberyPending() {
      return this.delivery.filter((delivery) => (delivery.order_status === 'REQUESTED')).length;
    },
    deliberyProcessing() {
      return this.delivery.filter((delivery) => (delivery.order_status === 'PENDING')).length;
    }
  },
  async mounted() {
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    await this.initData();
  },
  beforeDestroy() {
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
  },
  methods: {
    async handleSelectHeadquater() {
      await this.initData();
    },
    async initData() {
      this.$store.commit('menu/setStateLoading', true);
      const { id } = this.$route.query;
      const { headquater } = this.$route.query;
      this.params = id || headquater;
      await this.$store.dispatch('clients/getClients', this.params);
      await this.$store.dispatch('clients/getOrders', this.params);
      await this.getTables();
      await this.getReservation();
      await this.getDishes();
      await this.deliverys();
      await this.comments();
      await this.getAccounting();
      this.$store.commit('menu/setStateLoading', false);
    },
    async getAccounting() {
      console.log("getAccounting");
      const month = (`0${new Date().getMonth() + 1}`).slice(-2);
      const year = new Date().getFullYear();
      const currentDay = new Date().getDate();
      const startAt = `${year}/${month}/01`;
      const endAt = `${year}/${month}/${currentDay}`;
      const belongsTo = this.params;
      const { ok, result = [] } = await surePromise(requests.getAccountings({ startAt, endAt, belongsTo, populate: true }));
      if (ok) {
        const egress = [];
        // eslint-disable-next-line array-callback-return
        result.filter(accounting => accounting.payrolls.filter(egrress => {
          if (egrress.type === "EGRESS") {
            egress.push(egrress);
          }
        }));
        egress.map(value => {
          this.accounting.month += value.value;
          return value;
        });
        const egressToday = [];
        // eslint-disable-next-line array-callback-return
        const egressToDay = result.filter(value => {
          if (new Date(value.createAt._seconds * 1000).getDate() === currentDay) {
            return value;
          }
        });
        // eslint-disable-next-line array-callback-return
        egressToDay.filter(value => {
          if (value.type === "EGRESS") {
            egressToday.push(value);
          }
        });
        egressToday.map(value => {
          this.accounting.today += value.value;
          return value;
        });
      }
    },
    async comments() {
      const { ok, result } = await surePromise(requests.getComments(this.params));
      if (ok) this.commits = result.length;
    },
    async deliverys() {
      const status = 'Processing,Pending';
      const { ok, result } = await surePromise(requests.fetchDelivery(this.params, status));
      if (ok) {
        this.delivery = result;
      }
    },
    routerGo(path) {
      this.$router.push({
        path,
        query: {
          id: this.params
        }
      });
    },
    getTables() {
      const db = window.firebase.firestore();
      const TablesRef = db.collection("Tables");
      TablesRef.where("belongsTo", "==", this.params).onSnapshot(response => {
        response.forEach(doc => this.tables.push(doc.data()));
      });
    },
    async getDishes() {
      let categories = [];
      const categoriesFilter = [];
      const objet = [];
      const { ok, result } = await surePromise(requests.fetchDishes({
        availableAt: this.params
      }));
      if (ok) {
        this.dishes.products = result.length;
      }
      categories = result.map(dish => Object.entries(dish.categories));
      categories.map(value => categoriesFilter.push(...value));
      // eslint-disable-next-line array-callback-return
      categoriesFilter.map(value => {
        objet.push(value[0]);
      });
      this.dishes.categories = new Set(objet).size;
    },
    getReservation() {
      const reservations = [];
      const reservationsBD = window.firebase.firestore().collection('Reservations');
      const year = new Date().getFullYear();
      const starDate = new Date(`${year}-01-01`);
      const endDate = new Date(`${year}-12-31`);
      const today = new Date().getDate();
      const month = new Date().getMonth() + 1;
      reservationsBD
        .where('belongsTo', "==", this.params)
        .where("status", "in", ["CONFIRM", "PENDING"])
        .where('createAt', '>', starDate)
        .where('createAt', '<', endDate)
        .onSnapshot(response => {
          response.forEach(doc => {
            reservations.push(doc.data());
          });
          // eslint-disable-next-line array-callback-return
          this.reservations.total = reservations.filter(value => {
            if (new Date(value.createAt.seconds * 1000).getDate() === today) {
              return value;
            }
          }).length;

          // eslint-disable-next-line array-callback-return
          this.reservations.month = reservations.filter(value => {
            if (new Date(value.createAt.seconds * 1000).getMonth() === month) {
              return value;
            }
          }).length;
      });
    }
  }
});
