import Vue from "vue";
import STORE from '@/store/types';

export default Vue.extend({
  name: "ReservationDetail",
  props: {
    reservation: {
      type: Object,
      default: () => ({
        name: "",
        hour: "",
        persons: "",
        status: "",
        address: "",
        date: "",
        dateTimeStamp: "",
        description: "",
        logo: "",
        id: "",
        user: {}
      })
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAdmin() {
      return localStorage.getItem("selectedRol") === "ADMIN";
    },
    isUser() {
      return localStorage.getItem("selectedRol") === "USER";
    }
  },
  methods: {
    updateReservation() {
      const reservationDetail = {
        detail: this.reservation,
        isActive: true,
        mode: "updateReservation"
      };
      this.$store.commit(STORE.MUTATIONS.RESERVATION_DETAIL.SET_RESERVATION, reservationDetail);
      this.$emit("closeReservationDetail");
    }
  }
});
