const mutations = {
  open_your_order(state) {
    state.show_your_order = true;
  },
  close_your_order(state) {
    state.show_your_order = false;
  }
};

export default mutations;
