 <style scoped lang="scss" src="./style.scss">

</style>

<template src="./template.html"></template>

<script>
  import logo from '@assets/images/logo_blanco.svg';

  export default {
    props: {
      subtitle: {
        type: String,
        default: ''
      }
  },
    data() {
      return { logo };
    }
  };
</script>
