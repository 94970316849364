function getProviderForProviderId({
 method, config, auth, firebase
}) {
  const isCustom = method === 'password';
  const isFB = method === 'facebook';
  const isGoogle = method === 'google.com';
  if (isCustom) return auth.signInWithEmailAndPassword(config.email, config.password);
  if (isGoogle) return new firebase.firebase_.auth.GoogleAuthProvider();
  if (isFB) {
    const fbProvider = new firebase.firebase_.auth.FacebookAuthProvider();
    fbProvider.setCustomParameters({ display: 'popup' });
    return fbProvider;
  }
  return null;
}

function trySignIn({
  provider,
  auth,
  showProfile,
  firebase,
  loginFallback
}) {
  auth
    .signInWithPopup(provider)
    .then((result) => {
      showProfile(result);
    })
    .catch(async (error) => {
      const errorCode = error.code;
      const { email } = error;
      const { credential } = error;
      const signWithOtherMethod = errorCode === 'auth/account-exists-with-different-credential';
      if (signWithOtherMethod) {
        const methods = await auth.fetchSignInMethodsForEmail(email);
        const isEmail = methods[0] === 'password';
        if (isEmail) {
          loginFallback({ email, credential });
        } else {
          const AlterinativeProvider = getProviderForProviderId({
            method: methods[0],
            showProfile,
            firebase,
            auth
          });
          auth.signInWithPopup(AlterinativeProvider).then((response) => {
            response.user.linkWithCredential(credential).then(() => showProfile());
          });
        }
      }
    });
}

export default {
  trySignIn,
  getProviderForProviderId
};
