import requests from "@/helpers/requests";
import redirectRoutes from "@/utils/redirectRoutes";
import { saveUser } from "@/utils/saveUser";
import { notifyEvent } from "@/utils/notifyEvent";

export const onUserSnap = (userRef, user, payload) => {
  const unsubscribe = userRef.where("id", "==", user.uid).onSnapshot(
    async snapshot => {
      const hasData = snapshot && snapshot.empty === false;
      if (hasData) {
        const [doc] = snapshot.docs;
        const { exists } = doc;
        if (exists) {
          const profile = doc.data();
          unsubscribe();
          saveUser(user, { ...profile, ...payload });
          notifyEvent({ name: "ready", detail: { ok: true } });
          redirectRoutes();
          requests.updateUser(user.uid, payload).catch(error => console.log({ error }));
        }
      }
    },
    error => {
      console.log("error ", error);
    }
  );
};
