<style scoped lang="scss">
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
export default {
  name: "OrderCard",
  props: {
    caption: {
      type: String,
      default: () => ""
    },
    color: {
      type: String,
      default: () => "primary"
    },
    type: {
      type: String,
      default: () => ""
    },
    waiter: {
      type: String,
      default: () => ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleSelectOption(type = "") {
      this.$emit("moveTo", type);
    }
  }
};
</script>