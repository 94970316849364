<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import EditOrderForm from "@components/forms/EditOrderForm";

export default {
  name: "EditOrderModal",
  components: { EditOrderForm },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    defaultTable: {
      type: Object,
      default: () => ({})
    },
    tables: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      error: false,
      success: false
    };
  },
  methods: {
    handleSwitching(state, table) {
      this.$emit("switching", state, table);
    },
    handleSuccessSwitchTable() {
      this.success = true;
      this.$emit("close");
    }
  }
};
</script>
