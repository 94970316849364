const getter = {
  loadingStatusGetter(state) {
    return state.loadingStatus;
  },

  toastStatusGetter(state) {
    return state.toastStatus;
  }
};

export default getter;
