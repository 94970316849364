<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import CategoriesList from "@components/lists/CategoriesList";
import anonymousUser from "@/assets/icons/waiter-icon.svg";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import timeout from "@/helpers/timeout";
import uuid from "@utils/uuid";
import STORE from '@/store/types';
const { assignWaiter } = requests;

export default {
  name: "AssignWaiterModal",
  components: { CategoriesList },
  props: {
    waiters: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    table: {
      type: String,
      default: ""
    },
    headquater: {
      type: String,
      default: ""
    },
    defaultWaiter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isInvalid: false,
      loading: false,
      error: false,
      success: false,
      display: this.open,
      categotyKey: uuid()
    };
  },
  watch: {
    open(val) {
      this.display = val;
      if (!val) this.unSelectAllWaiters();
      this.categotyKey = uuid();
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    checkValidity() {
      return this.waiters.some(waiter => waiter.selected);
    },
    async handleAssign() {
      this.loading = true;
      const { table = "", headquater = "" } = this;
      const { id: waiter = "", name = '' } = this.getSelected();
      const { ok } = await surePromise(assignWaiter({ waiter, table, headquater }));
      this.loading = false;
      this.display = false;
      if (ok) {
        await requests.waiterNotification(waiter, {
          body: 'Se le ha asignado una mesa',
          table,
          sender: window.user.uid
        });
        const tables = this.$store.getters[STORE.GETTERS.TABLE.GET_TABLES];
        // eslint-disable-next-line array-callback-return
        const filterTable = tables.filter(value => {
          if (value.id === table) return value;
        });
        if (filterTable[0].currentClient) {
          await requests.userNotification(filterTable[0].currentClient.id, {
            body: 'Se le ha asignado un mesero',
            table,
            sender: window.user.uid,
            type: 'ASSIGN_WAITER',
            title: 'Mesero asignado',
            waiter: name
          })
          .then(response => {
            console.log(response);
          });
        }
        this.success = true;
        await timeout(3000);
        return this.$emit("close");
      }
      this.error = true;
      await timeout(3000);
      return this.$emit("close");
    },
    getSelected() {
      return this.waiters.find(waiter => waiter.selected);
    },
    mapAllWaiters() {
      this.waiters.forEach(waiter => {
        waiter.logo = waiter.photoURL ? waiter.photoURL : anonymousUser;
        waiter.selected = waiter.id === this.defaultWaiter;
      });
      this.categotyKey = uuid();
    },
    unSelectAllWaiters() {
      this.waiters.forEach(waiter => {
        waiter.logo = waiter.photoURL ? waiter.photoURL : anonymousUser;
        waiter.selected = false;
      });
      this.isInvalid = !this.checkValidity();
    },
    initialize() {
      this.mapAllWaiters();
      this.isInvalid = !this.checkValidity();
    },
    handleSelectWaiter(_selectes, index) {
      this.waiters.forEach((waiter, i) => {
        waiter.selected = i === index;
      });
      this.isInvalid = !this.checkValidity();
      this.categotyKey = uuid();
    }
  }
};
</script>
