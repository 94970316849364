<style scoped lang="scss">
@import '~@/styles/_colors.scss';

.language-caption {
  color: $primary;
  text-transform: uppercase;
  font-family: Gibson;
  font-size: 13px;
  margin-bottom: 13px;
  margin-left: 5px;
}
::v-deep .v-input__slot {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2) !important;
}
</style>
<template>
  <div>
    <p class="language-caption">
      Idiomas
    </p>
    <LanguageSelect
      ref="languageSelect"
      :disabled="disabled"
      @loading="loading = $event"
      @select="handleSelectLang"
    ></LanguageSelect>

    <transition name="appear">
      <v-textarea
        v-if="loading === false"
        ref="descriptionField"
        :disabled="disabled"
        solo
        name="description"
        label="Descripción"
        :value="displayDescriptionByLang"
        @input="handleDescriptionChange"
      ></v-textarea>
    </transition>
  </div>
</template>

<script>
import LanguageSelect from '@components/selects/LanguageSelect';

export default {
  name: 'DescriptionByLanguage',
  components: { LanguageSelect },
  props: {
    mode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultDescription: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const descriptions = this.defaultDescription;
    return { loading: true, descriptions };
  },
  computed: {
    displayDescriptionByLang() {
      const { languageSelect } = this.$refs;
      const lang = languageSelect.getLanguageSelected();
      return this.descriptions[lang.code] || '';
    }
  },
  watch: {
    defaultDescription(val) {
      if (this.mode === 'edit') {
        this.$nextTick(() => {
          this.descriptions = val;
        });
      }
    }
  },
  methods: {
    isValid() {
      return Object
             .keys(this.descriptions)
             .some(description => this.descriptions[description] !== '');
    },
    getDifferencesBetweenDefaultAndActual() {
      const result = {};
      const keys = Object.keys(this.descriptions);
      for (const key in keys) {
        const hasKey = this.defaultDescription.hasOwnProperty(key);
        if (hasKey) {
          const isDifferent = this.descriptions[key] !== this.defaultDescription[key];
          if (isDifferent) result[key] = this.descriptions[key];
        }
      }
      return result;
    },
    getDifferences() {
      const descriptionDifference = this.getDifferencesBetweenDefaultAndActual();
      return { ...descriptionDifference, ...this.descriptions };
    },
    reset() {
      this.descriptions = {};
      const { descriptionField } = this.$refs;
      if (descriptionField) descriptionField.lazyValue = '';
      // console.log('reset called');
    },
    handleSelectLang() {
      const { descriptionField } = this.$refs;
      if (descriptionField) descriptionField.lazyValue = '';
    },
    handleDescriptionChange(value) {
      const { languageSelect } = this.$refs;
      const { code = 'ES' } = languageSelect.getLanguageSelected();
      this.descriptions[code] = value;
      this.$emit('input-change', value);
    }
  }
};
</script>
