<style scoped lang="scss">
@import '~@/styles/_colors.scss';

.time-schedule__title {
  color: $primary;
  text-transform: uppercase;
  font-family: Gibson;
  font-size: 13px;
}
::v-deep .v-input__icon--append {
  overflow: hidden;
}

.schedule-select ::v-deep .v-input__slot {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2) !important;
}

::v-deep .v-input__icon--append i {
  color: transparent !important;
  background-image: url('~@assets/icons/arrow-down.png');
  background-position: center;
  background-size: 25px;
}

::v-deep .v-icon--disabled {
  color: transparent !important;
}
</style>

<template>
  <div>
    <p class="time-schedule__title">
      Horarios de atención
    </p>

    <v-select
      v-model="selected"
      class="schedule-select"
      :disabled="disable"
      :items="weekdays"
      item-text="name"
      item-value="id"
      label="Dias de la semana"
      solo
    ></v-select>
    <TimeSchedule
      v-for="day in weekdays"
      v-show="day.id === selected"
      :key="`${day.id}-${renderId}`"
      :ref="`timeSchedule-${day.id}`"
      :disable="disable"
      :day="selected"
      :data="data"
    ></TimeSchedule>
  </div>
</template>

<script>
import TimeSchedule from '@components/inputs/TimeSchedule';
import uuid from '@utils/uuid';

export default {
  name: 'ScheduleTime',
  components: { TimeSchedule },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const weekdays = [
      {
        name: 'Lunes',
        id: 'monday'
      },
      {
        name: 'Martes',
        id: 'tuesday'
      },
      {
        name: 'Miercoles',
        id: 'wednesday'
      },
      {
        name: 'Jueves',
        id: 'thursday'
      },
      {
        name: 'Viernes',
        id: 'friday'
      },
      {
        name: 'Sabado',
        id: 'saturday'
      },
      {
        name: 'Domingo',
        id: 'sunday'
      }
    ];
    return { weekdays, selected: 'monday', renderId: uuid() };
  },
  watch: {
    data() {
      this.renderId = uuid();
    }
  },
  methods: {
    getAllSchedules(accumulator, item) {
      const result = {};
      const data = item.getData();
      console.log("getAllSchedules");
      console.log(data);
      if (data.afternoon) {
        if (data.afternoon.from && data.afternoon.to) result.afternoon = data.afternoon;
        else result.afternoon = data.afternoon;
      }
      if (data.morning) {
        if (data.morning.from && data.morning.to) result.morning = data.morning;
      }
      if (data.evening) {
        if (data.evening.from && data.evening.to) result.evening = data.evening;
      }
      return result;
    },
    getData() {
      const { weekdays = [] } = this;
      return weekdays.reduce((acc, day) => {
        acc[day.id] = this
                      .$refs[`timeSchedule-${day.id}`]
                      .reduce(this.getAllSchedules, {});
        return acc;
      }, {});
    }
  }
};
</script>
