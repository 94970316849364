<style scoped lang="scss">
    @import "style";
</style>

<template src="./template.html"></template>

<script>

  export default {
    name: "ShareAccount",
    components: { },
    props: {
      title: {
        type: String,
        default: 'COMPARTIR CUENTA'
      }
    },
    filters: {

    },
    computed: {

    },
    data() {
      return {

      };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
    watch: {

    }
  };
</script>
