<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import UserBasicInfo from "@components/generals/UserBasicInfo";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

const { fetchUser } = requests;

export default {
  name: "OrderDetailsModal",
  components: {
    UserBasicInfo
  },
  filters: {
    stringToDate(value) {
      return new Date(value);
    },
    formatDate(value) {
      return value.toLocaleString("es-CO", { timeZone: "America/Bogota" });
    }
  },
  props: {
    caption: {
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    async open(val) {
      this.display = val;
      if (this.display) {
        await this.getWaiter();
      } else {
        this.hasDispatcher = false;
      }
    }
  },
  data() {
    return {
      hasDispatcher: false,
      display: false,
      error: false,
      success: false,
      loading: false,
      dispatcher: {}
    };
  },
  created() {
    this.display = this.open;
  },
  computed: {
    hasOrder() {
      return Object.keys(this.order || {}).length > 0;
    },
    getDispatcher() {
      const { dispatchedBy: waiter = "" } = this.order;
      return this.dispatcher[waiter];
    },
    createAt() {
      if (this.isTimestamp(this.order.createAt)) return this.timestampToDate(this.order.createAt);
      return new Date(this.order.createAt);
    }
  },
  methods: {
    isTimestamp(date) {
      return date && date.hasOwnProperty("_seconds");
    },
    timestampToDate(date) {
      return new Date(date._seconds * 1000);
    },
    async getWaiter() {
      const { order = {} } = this;
      this.loading = true;
      const waiter = order.dispatchedBy;
      if (!waiter) return;
      const inCache = this.dispatcher.hasOwnProperty(waiter);
      if (inCache) {
        this.loading = false;
        this.hasDispatcher = true;
        return;
      }
      const { error, result } = await surePromise(fetchUser(waiter));
      if (error) {
        this.error = true;
        return;
      }
      this.dispatcher[waiter] = { ...result };
      delete this.dispatcher[waiter].point;
      delete this.dispatcher[waiter].email;
      delete this.dispatcher[waiter].phone;
      this.dispatcher[waiter].waiter = true;
      this.hasDispatcher = true;
      await this.$nextTick();
      this.loading = false;
    }
  }
};
</script>
