<template src="./template.html"></template>

<style scoped lang="scss">
  @import "style";
</style>

<script>
  import DeliveryCard from "@components/generals/DeliveryBoard/DeliveryCard";
  import DeliveryDataTable from "@components/generals/DeliveryBoard/DeliveryDataTable";
  import BackButton from "@components/generals/BackButton";
  import mixins from "@/mixins";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";
  import BillingAdmin from "@components/generals/BillingAdmin";

  const { updateDelivery, updateOrder, cancelOrder } = requests;
  const { DateManager } = mixins;

  export default {
    name: "DeliveryBoard",
    props: {},
    mixins: [DateManager],
    filters: {},
    data() {
      return {
        currentOrder: {},
        toggle: true,
        loading: false,
        currentStatus: "",
        cards: [
          { id: "INITIAL", name: "Pendiente", color: "grey lighten-3", total: 0 },
          { id: "REQUESTED", name: "Procesando", color: "orange", total: 0 },
          { id: "PENDING", name: "Despachado", color: "warning", total: 0 },
          { id: "COMPLETE", name: "Completado", color: "success", total: 0 },
          { id: "CANCEL", name: "Cancelado", color: "error", total: 0 },
          { id: "FINISHED", name: "Terminado", color: "success", total: 0 }
        ],
        orderHeaders: [
          { text: "Código del pedido", value: "id" },
          { text: "Cliente", value: "name" },
          { text: "Hora de ingreso Del pedido", value: "date" },
          { text: "Detalle y Valor Del Pedido", value: "cost" },
          { text: "Nº de Productos", value: "quantity" },
          { text: "Estado del pedido", value: "status" }
        ],
        orders: [],
        ordersBackup: [],
        headquater: this.getHeadquaterSelectedFromUserProfile()
      };
    },
    created() {
      this.currentStatus = this.$route.params.delivery_status ? this.$route.params.delivery_status : "";
      this.filterTable();
      this.watchOrders();
    },
    mounted() {
      window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    },
    beforeDestroy() {
      window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    },
    methods: {
      async handleSelectHeadquater(event) {
        const { detail: headquater } = event;
        this.headquater = headquater;
        this.setSubscription();
      },
      watchOrders() {
        const self = this;

        const { id = "" } = this.headquater;
        const yesterday = this.subtractDay(new Date(), 1);
        const tomorrow = this.addDay(new Date(), 1);

        window.db.collection("Deliverys")
          .where("headquater", "==", id)
          .where("date", ">", yesterday)
          .where("date", "<", tomorrow)
          .onSnapshot(snapshots => {
            snapshots.docChanges().forEach((change) => {
              const data = change.doc.data();
              data.date = data.date.toDate();
              data.createAt = data.createAt.toDate();
              const dishes = {};
              for (const i in data.dishes) {
                const dish = data.dishes[i];
                const hasPromotion = dish.promotion && dish.promotion.available === true;
                if (hasPromotion) {
                  dish.promotion = dish.promotion.type;
                } else {
                  delete dish.promotion;
                }
                dishes[dish.id] = dish;
              }

              let found = false;
              for (const i in self.ordersBackup) {
                console.log("cart");
                console.log(self.ordersBackup[i]);
                if (self.ordersBackup[i].id === data.id) {
                  found = true;
                  self.ordersBackup[i].status = data.status;
                  self.ordersBackup[i].dishes = dishes;
                  if (data.payAt) {
                    self.ordersBackup[i].payAt = data.payAt;
                  }

                  if (data.status === "FINISHED") {
                    self.ordersBackups.splice(i, 1);
                  }
                }
              }

              if (!found && !["FINISHED", "CANCEL", "PAYMENT_PENDING"].includes(data.status)) {
                self.ordersBackup.push(data);
              }
            });
            this.filterOrders();
          });

        window.db.collection("Orders")
          .where("belongsTo", "==", id)
          .where("createAt", ">", yesterday)
          .where("createAt", "<", tomorrow)
          .onSnapshot(snapshots => {
            snapshots.docChanges().forEach((change) => {
              const data = change.doc.data();
              data.date = data.createAt.toDate();
              data.createAt = data.createAt.toDate();

              let found = false;
              for (const i in self.ordersBackup) {
                console.log("order");
                console.log(self.ordersBackup[i]);
                if (self.ordersBackup[i].id === data.id) {
                  found = true;
                  self.ordersBackup[i].status = data.status;
                  self.ordersBackup[i].dishes = data.dishes;
                  if (data.payAt) {
                    self.ordersBackup[i].payAt = data.payAt;
                  }

                  if (data.status === "FINISHED") {
                    self.ordersBackups.splice(i, 1);
                  }
                }
              }

              if (!found && !["FINISHED", "CANCEL", "PAYMENT_PENDING"].includes(data.status)) {
                self.ordersBackup.push(data);
              }
            });
            this.filterOrders();
          });
      },
      onClickBack() {
        this.currentStatus = "";
        this.filterTable();
        this.$router.push({
          path: `/dashboard/delivery`,
          query: {
            id: this.headquater.id
          }
        });
      },
      onChangeCard(card) {
        this.currentStatus = card.id;
        this.$router.push({
          path: `/dashboard/delivery/${card.id}`,
          query: {
            id: this.headquater.id
          }
        });
        this.filterTable();
      },
      async filterTable() {
        this.orders = [];
        this.filterOrders();
      },
      filterOrders() {
        if (this.currentStatus) {
          this.orders = this.ordersBackup.filter(order => order.status === this.currentStatus);
        } else {
          this.orders = this.ordersBackup;
        }
        this.getTotalByStatus();
      },
      getTotalByStatus() {
        for (const i in this.cards) {
          this.cards[i].total = this.ordersBackup.filter(order => order.status === this.cards[i].id).length;
        }
      },
      async processOrder(order, estimatedTime) {
        const newStatus = "REQUESTED";
        if (order.domicile) {
          const { ok } = await surePromise(updateDelivery(order.id, {
            status: newStatus,
            estimated_time: estimatedTime
          }));
          if (ok) {
            order.status = newStatus;
            this.getTotalByStatus();
          }
        } else {
          const { ok } = await surePromise(updateOrder(order.id, { status: newStatus }));
          if (ok) {
            order.status = newStatus;
            this.getTotalByStatus();
          }
        }
      },
      async cancelOrder(data) {
        const { order } = data;
        const newStatus = "CANCEL";
        if (order.domicile) {
          const { ok } = await surePromise(updateDelivery(order.id, {
            status: newStatus,
            reason: data.message,
            headquater: this.headquater.id
          }));
          if (ok) {
            order.status = newStatus;
            this.getTotalByStatus();
          }
        } else {
          const { ok } = await surePromise(cancelOrder(order.id, {
            reason: data.message,
            headquater: this.headquater.id
          }));
          if (ok) {
            order.status = newStatus;
            this.getTotalByStatus();
          }
        }
      },
      onSelectedOrder(order) {
        this.toggle = false;
        this.currentOrder = order;
      },
      handlePaymentSuccess() {
        //
      }
    },
    computed: {},
    components: {
      DeliveryCard,
      DeliveryDataTable,
      BackButton,
      BillingAdmin
    }
  };
</script>
