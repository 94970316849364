import Vue from "vue";
import requests from "@/helpers/requests";
import kilometersToMiles from "@utils/kilometersToMiles.js";
import mapStyle from "@/utils/mapStyle.json";
import getDistanceFromLatLonInKm from "@/utils/getDistanceFromLatLonInKm.js";
import milesToKilometers from "@utils/milesToKilimeters.js";
import { mapState } from "vuex";
import STORE from "@/store/types";
import MapStatus from "@/components/Cart/MapStatus";

import { notifyEvent } from "@utils/notifyEvent";

const center = { lng: 0, lat: 0 };

export default Vue.extend({
  name: "Home",
  computed: {
    ...mapState({
      cart: state => state.cart.cart
    })
  },
  props: {
    rol: {
      type: String,
      default: ""
    }
  },
  components: {
    SearchComponent: () => import("@inputs/SearchRestaurants"),
    Logo: () => import("@components/Headers/LogoRed"),
    RestaurantList: () => import("@lists/RestaurantsList"),
    QuaternsCarousel: () => import("@/components/carousel/Quaterns/Quaterns.vue"),
    Kitchens: () => import("@/components/carousel/Kitchens/Kitchens.vue"),
    Offerts: () => import("@/components/carousel/Offerts/Offerts.vue"),
    EmptySection: () => import("@/components/generals/EmptySection"),
    ReadQR: () => import("@/components/generals/ReadQR"),
    MapStatus
  },
  data: () => ({
    notFoundRestaurants: false,
    restaurants: [],
    favoriteRestaurants: [],
    point: 0,
    categories: [],
    loading: true,
    description: "",
    internalError: false,
    animation: 2,
    userType: "",
    showOptions: true,
    fab: false,
    fling: false,
    hover: false,
    center,
    fetchOptions: { limit: 10, populate: "belongsTo" },
    openRestaurantList: false,
    requestGPS: false,
    openQR: false,
    localizing: false,
    radius: kilometersToMiles(5),
    restaurantSelected: {
      lat: center.lat,
      lng: center.lng
    },
    userPhoto: null,
    mapReady: false,
    showSlider: false,
    currentPlace: null,
    mapOptions: {
      disableDefaultUI: true,
      styles: mapStyle
    },
    offers: {}
  }),
  mounted() {
    const [$wrapper] = document.getElementsByClassName("application--wrap");
    $wrapper.style.background = "white";
    this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_CART);
  },
  destroyed() {
    const [$wrapper] = document.getElementsByClassName("application--wrap");
    $wrapper.style.background = "#16202f";
    let userType = "USER";
    const hasWaiterRol = window.user.roles.some(rol => rol === "WAITER");
    const hasAdminRol = window.user.roles.some(rol => rol === "ADMIN");
    if (hasWaiterRol) userType = "WAITER";
    if (hasAdminRol) userType = "ADMIN";
    this.userType = userType;
    const hasPhotoURL = window.user && window.user.photoURL;
    if (hasPhotoURL) {
      this.userPhoto = {
        url: window.user.photoURL,
        size: {
          width: 50,
          height: 50,
          f: "px",
          b: "px",
          borderRadius: 12
        },
        scaledSize: {
          width: 23,
          height: 23,
          f: "px",
          b: "px"
        }
      };
    }
  },
  async created() {
    // this.handleDecodeQr('Uq6L7JEXMmI6xNLx1TLV/leHL7YaEJcW4qC94euMq?table=022fb0f9-b0d4-4332-9a19-0ca6969ad830');
    this.initialize();
    await this.findFavoriteRestaurant();
    await this.findRestaurantOffers();
    const container = document.getElementById("container");
    container.style.backgroundColor = "white";
  },
  methods: {
    handleDecodeQr(data = "") {
      if (data) {
        const path = `/restaurant/${data}`;
        window.router.push({ path });
      }
    },
    findRestaurants() {
      this.restaurants.forEach(restaurant => {
        restaurant.selected = false;
      });
      this.restaurantSelected = {};
      const payload = {
        lat: this.center.lat,
        lon: this.center.lng
      };
      if (this.categories) {
        payload.categories = this.categories
          .filter(item => item.selected)
          .map(item => item.id)
          .join(",");
      }
      if (this.point) payload.stars = this.point;
      if (this.description) payload.description = this.description;
      return requests.fetchHeadquatersAt(payload).then(restaurants => {
        this.restaurants = restaurants.map(restaurant => {
          const { name, id, l, belongsTo, schedule = {} } = restaurant;
          const { _latitude, _longitude } = l;
          const distance = getDistanceFromLatLonInKm(this.center.lat, this.center.lng, Number(_latitude), Number(_longitude));
          restaurant.distance = distance;
          const distanceStars = `${milesToKilometers(restaurant.distance).toFixed(1)} km, ${restaurant.stars.toFixed(1)} Estrellas`;
          const coordinates = { lat: Number(_latitude), lng: Number(_longitude) };
          // const selected = this.restaurantSelected.id === id;
          return {
            distance_stars: distanceStars,
            logo: restaurant.logo,
            schedule,
            name,
            distance,
            id,
            coordinates,
            selected: false,
            belongsTo
          };
        });
        if (!this.restaurants.length && this.directionsDisplay) {
          this.directionsDisplay.setMap(null);
        }
      });
    },
    handleDoneTyping(value) {
      this.loading = true;
      this.description = value;
      this.findRestaurants().then(() => {
        this.$refs.searchComponent.hideFilter();
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
        // this.$refs.searchComponent.notfound = Boolean(this.restaurants.length);
        this.$refs.searchComponent.$el.querySelector("input").blur();
        this.loading = false;
      });
    },
    handleFilterChange(open) {
      this.showOptions = !open;
    },
    handleUnSelectCategorie() {
      this.categories.forEach(item => {
        item.select = false;
      });
      this.loading = true;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleNoPoints() {
      this.point = null;
      this.loading = true;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleSelectCategorie() {
      this.loading = true;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleSelectStar(payload) {
      this.loading = true;
      this.point = payload;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    getAllCategories() {
      this.$refs.searchComponent.hideFilter();
      this.initialize();
    },
    initialize() {
      this.loading = true;
      const { success, error, options } = this.geolocate();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        // this.loading = false;
        this.localizing = false;
        this.requestGPS = true;
      }
      // this.positionId = navigator.geolocation.watchPosition(success, error, options);
      requests
        .fetchCategories()
        .then(response => {
          this.categories = response.map(item => ({
            id: item.id,
            name: item.name,
            selected: false,
            logo: item.logo
          }));
          // this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.internalError = true;
        });
    },
    geolocate() {
      const options = {
        enableHighAccuracy: true
        // timeout: 10000,
        // maximumAge: 12220
      };
      const success = position => {
        this.loading = true;
        this.requestGPS = false;
        this.localizing = false;
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
        this.mapReady = true;
        this.findRestaurants().then(() => {
          this.loading = false;
        });
      };
      const error = () => {
        this.requestGPS = true;
        this.localizing = false;
      };
      return { error, success, options };
    },
    async findFavoriteRestaurant() {
      const payload = {
        favorite: true
      };
      this.favoriteRestaurants = await requests.fetchHeadquaters({ ...payload, ...this.fetchOptions });
    },
    async findRestaurantOffers() {
      const offers = {};
      const dishes = await window.firebase.firestore().collection("Dishes")
        .where("promotion.available", "==", true)
        .get();

      const headquatersIds = [];
      dishes.forEach(dish => {
        if (dish.data().belongsTo) {
          offers[dish.data().belongsTo] = dish.data();
          const heads = Object.keys(dish.data().availableAt);
          if (heads.length > 0) {
            headquatersIds.push(heads[0]);
          }

        }
      });

      const headquatersArray = {};
      console.log(headquatersArray);
      const headquaters = await window.firebase.firestore().collection("Headquaters")
        .where("id", "in", headquatersIds)
        .get();

      headquaters.forEach(head => {
        if (head.data().id) {
          headquatersArray[head.data().id] = head.data();
        }
      });

      for (const offer of Object.values(offers)) {
        const heads = Object.keys(offer.availableAt);
        let restaurant = "";
        if (heads.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          restaurant = heads[0];
        }
        offer.headquater = headquatersArray[restaurant];
      }

      this.offers = offers;
    },
    detailRestaurant(event) {
      const link = `/${event.belongsTo}/${event.id}`;
      const [id, headquater] = link.split("/").slice(1, 3);
      window.router.push({
        name: "restaurant",
        params: { id, headquater }
      });
    }
  }
});
