import { BASE } from "./index";

const startBoxflow = payload => {
  const { id = "" } = payload;
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/headquater/startflow/${id}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      token,
      "content-type": "application/json"
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default startBoxflow;
