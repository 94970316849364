import Vue from 'vue';

export default Vue.extend({
  name: 'AdminMenu',
  data: () => ({
    drawer: true,
    items: [
      { title: 'Tablero', icon: 'dashboard', id: 'board', route: '/dashboard/board' },
      // { title: 'Mi negocio', icon: 'business', id: 'business', route: '/dashboard/business' },
      // { title: 'Clientes', icon: 'business', id: 'clients', route: '/dashboard/clients' },
      { title: 'Mesas', icon: 'deck', id: 'available', route: '/dashboard/tables' },
      { title: 'Menú', icon: 'restaurant_menu', id: 'menu', route: '/dashboard/menus' },
      { title: 'Reservas', icon: 'book_online', id: 'booking', route: '/dashboard/booking' },
      { title: 'POS', icon: 'point_of_sale', id: 'order', route: '/dashboard/order' },
      { title: 'Flujo de caja', icon: 'monetization_on', id: 'boxFlow', route: '/dashboard/boxflow' },
      { title: 'Pedidos', icon: 'local_shipping', id: 'delivery', route: '/dashboard/delivery' },
      { title: 'Colaboradores', icon: 'emoji_people', id: 'employee', route: '/dashboard/employees' },
      { title: 'Sedes', icon: 'store_mall_directory', id: 'headquaters', route: '/dashboard/headquaters' },
      // { title: 'Cotización', icon: 'request_quote', id: 'quotation', route: '/dashboard/quotation' },
      { title: 'Comentarios', icon: 'feedback', id: 'comments', route: '/dashboard/comments' },
      { title: 'Galeria', icon: 'collections', id: 'gallery', route: '/dashboard/gallery' }
      // { title: 'Comprobantes de pago', icon: 'payments', id: 'payments', route: '/dashboard/payments' },
    ],
    mini: true,
    right: true
  }),
  computed: {
    isMini: {
      get() {
        return this.$store.getters['menu/getStateMini'];
      },
      set(oldValue) {
        this.$store.commit('menu/setStateMini', oldValue);
      }
    },
    showMenu() {
      return this.$store.getters['menu/getStateMenu'];
    },
    currentRoute() {
      return this.$route.name;
    },
    avatar() {
      return this.$store.getters['profile/getProfile'].profile.photoURL;
    }
  },
  methods: {
    handleChooseItem(item) {
      // this.showMenu = false;
      // this.$emit("display-menu", this.showMenu);
      this.$emit("choose-item", item.route);
    }
  }
});
