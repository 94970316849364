import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import STORE from "@/store/types";
import store from "@/store";

Vue.use(Router);
window.prevRoute = null;

const router = new Router({
  mode: "history",
  routes
});

router.beforeResolve((to, from, next) => {
  if (to.meta.showCart === true) {
    store.dispatch(STORE.ACTIONS.CART.CLOSE_CART);
  } else {
    store.dispatch(STORE.ACTIONS.CART.OPEN_CART);
  }


  if (to.meta.showOrder === true) {
    store.dispatch(STORE.ACTIONS.CART.OPEN_MENU);
  } else {
    store.dispatch(STORE.ACTIONS.CART.CLOSE_MENU);
  }

  const { query = {}, hash = "", params = {} } = to;
  window.prevRoute = from.fullPath === "/" ? "/dashboard" : from.fullPath;
  const hasQuery = Object.keys(query).length !== 0;
  const hasParams = Object.keys(params).length !== 0;
  const hasHash = hash !== "";
  const urlOptions = {};
  if (hasQuery) urlOptions.query = query;
  if (hasParams) urlOptions.params = params;
  if (hasHash) urlOptions.hash = hash;
  const hasOptions = Object.keys(urlOptions).length !== 0;
  if (hasOptions) router.urlOptions = urlOptions;
  if (router.clear) router.urlOptions = {};
  next();
});
router.afterEach(() => {
  router.replace({ ...router.urlOptions });
  if (router.clear) delete router.clear;
});

export default router;
