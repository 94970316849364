<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import CategoriesList from "@components/lists/CategoriesList";

export default {
  name: "HeadquerListModal",
  components: { CategoriesList },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    headquaters: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    open(value) {
      this.display = value;
    }
  },
  data() {
    return {
      display: this.open,
      fullscreen: false
    };
  },
  mounted() {
    this.fullscreen = window.matchMedia("(max-width: 500px)").matches;
  },
  methods: {
    handleSelectHeadquater(event) {
      this.$emit("select", event);
      this.$emit("close");
    }
  }
};
</script>
