<style scoped lang="scss">
.loader {
  width: 320px;
  height: 320px;
  margin-top: -30px;
  margin-left: -150px;
  left: 50%;
  top: 30%;
  position: absolute;
}
</style>
<template>
  <video class="loader" autoplay loop muted playsinline>
    <source src="@/assets/loading-geo.webm" type="video/mp4">
  </video>
</template>
<script>
export default {
  name: "Loading"
};
</script>
