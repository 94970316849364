<style scoped lang="scss">
  @import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
  import TableModal from "@/components/modals/TableModal";
  import TableCard from "@/components/cards/TableCard";
  import ConfirmModal from "@/components/modals/ConfirmModal";
  import AssignWaiterModal from "@/components/modals/AssignWaiterModal";
  import AssignPromoModal from "@/components/modals/AssignPromoModal";
  import EmptySection from "@/components/generals/EmptySection";
  import surePromise from "@utils/surePromise";
  import uuid from "@utils/uuid";
  import requests from "@/helpers/requests";
  import STORE from "@/store/types";
  import { mapState } from "vuex";

  const { deleteTable, updateTable } = requests;

  export default {
    name: "TableList",
    components: { TableModal, ConfirmModal, AssignWaiterModal, TableCard, EmptySection, AssignPromoModal },
    props: {
      options: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        default: "ALL"
      },
      headquater: {
        type: String,
        default: ""
      },
      mode: {
        type: String,
        default: ""
      },
      data: {
        type: Array,
        default: () => []
      },
      waiters: {
        type: Array,
        default: () => []
      },
      showAll: {
        type: Boolean
      }
    },
    data() {
      return {
        tables: [],
        success: false,
        error: false,
        displayMenu: false,
        editModal: false,
        openConfirmModal: false,
        displayAssignModal: false,
        displayPromoModal: false,
        assignWaiterKey: uuid(),
        activeWaiter: "",
        tableSelected: {},
        currentTable: {},
        locations: {}
      };
    },
    watch: {
      // modalAssignWaiter(value) {
      //   if (value) {
      //     const table = this.$store.getters[STORE.GETTERS.TABLE.GET_TABLE_ORDER];
      //     this.handleAssignWaiter(table);
      //     this.handleOccupy(table);
      //     this.$store.commit(STORE.MUTATIONS.TABLE.SET_STATE_MODAL_WAITER, false);
      //   }
      // },
      data(val) {
        this.tables = this.filterByStatus([...val], this.status);
        this.locations = this.groupByLocation(this.tables);
      },
      status(val) {
        this.tables = this.filterByStatus(this.getTables(), val);
        this.locations = this.groupByLocation(this.tables);
      },
      tables(val) {
        this.$store.dispatch(STORE.ACTIONS.TABLE.TABLES, val);
      }
    },
    mounted() {
      this.updateList();
    },
    computed: {
      // ...mapState({
      //   modalAssignWaiter: state => state.table.modalAssignWaiter
      // }),
      hasWaiters() {
        return this.waiters.length > 0;
      },
      hasTableSelected() {
        return Object.keys(this.tableSelected).length > 0;
      },
      isEmpty() {
        return Object.keys(this.locations).length === 0;
      },
      selectedStatus() {
        const isBusy = this.status === "BUSY";
        const isFree = this.status === "FREE";
        const isAll = this.status === "ALL";
        if (isBusy) return "ocupada";
        if (isFree) return "libre";
        if (isAll) return "todas";
        return "";
      },
      getCurrentOrder() {
        return this.currentTable && this.currentTable.currentOrder ? this.currentTable.currentOrder.id : "";
      }
    },
    methods: {
      updateList() {
        this.tables = this.filterByStatus(this.getTables(), this.status);
        this.locations = this.groupByLocation(this.tables);
      },
      getActiveWaiter(actives) {
        const keys = Object.keys(actives);
        const waiter = keys.find(key => Boolean(actives[key]));
        if (waiter) {
          const { id = "" } = actives[waiter];
          return id;
        }
        return "";
      },
      handleAssignWaiter(table = {}) {
        const { activesWaiters = {} } = table;
        this.activeWaiter = this.getActiveWaiter(activesWaiters);
        this.tableSelected = table;
        this.displayAssignModal = true;
        this.assignWaiterKey = uuid();
      },
      handleAssignCloseModal() {
        this.displayAssignModal = false;
        this.activeWaiter = "";
        this.assignWaiterKey = uuid();
      },
      handlePromoCloseModal() {
        this.displayPromoModal = false;
      },
      filterByStatus(list, status) {
        const isAll = status === "ALL";
        if (isAll) return list;
        return list.filter(item => item.status === status);
      },
      filterAllLinked(tables = []) {
        return tables.filter(table => {
          const hasLinks = table.hasOwnProperty("links") && Object.keys(table.links).length > 0;
          if (hasLinks) {
            const isMain = table.links[table.id] === true;
            if (isMain) return true;
            return false;
          }
          return true;
        });
      },
      groupByLocation(tables) {
        const result = tables.reduce((accumulator, current, index) => {
          const { location = index } = current;
          const key = location.toLowerCase();
          const hasKey = accumulator.hasOwnProperty(key);
          if (hasKey) {
            accumulator[key] = {
              items: [...accumulator[key].items, current],
              expand: accumulator[key].expand
            };
          } else {
            accumulator[key] = { items: [current], expand: true };
          }
          return accumulator;
        }, {});
        return result;
      },
      getTables() {
        return this.data.map(table => table);
      },
      getIndexOfTableSelected(data, tableSelected) {
        return data.map(item => item.id).indexOf(tableSelected.id);
      },
      handleQrClick(item) {
        this.$emit("downloadQr", item);
      },
      async handleOccupy(table = {}) {
        const { id = "" } = table;
        const status = "BUSY";
        const activesWaiters = { ...table.activesWaiters };
        const isWaiter = window.localStorage.getItem("selectedRol") === "WAITER";
        if (isWaiter) activesWaiters[window.user.uid] = true;
        const update = { status, activesWaiters };
        table.loading = true;
        this.tables = [...this.tables];
        this.locations = this.groupByLocation(this.tables);
        const { ok, error } = await surePromise(updateTable(id, update));
        table.loading = false;
        if (ok) {
          this.success = true;
          table.status = status;
          this.tables = this.filterByStatus(this.tables, this.status);
          this.locations = this.groupByLocation(this.tables);
          this.$emit("occupy", table);
          return Promise.resolve(table);
        }
        this.error = true;
        return Promise.reject(error);
      },
      async handleVacate(table = {}) {
        const { id = "" } = table;
        const status = "FREE";
        const activesWaiters = { ...table.activesWaiters };
        activesWaiters[window.user.uid] = false;
        const update = { status, activesWaiters, currentClient: "", currentOrder: "" };
        table.loading = true;
        this.tables = [...this.tables];
        this.locations = this.groupByLocation(this.tables);
        const { ok } = await surePromise(updateTable(id, update));
        table.loading = false;
        if (ok) {
          this.success = true;
          table.status = status;
          this.tables = this.filterByStatus(this.tables, this.status);
          this.locations = this.groupByLocation(this.tables);
          return this.$emit("vacate", table);
        }
        this.error = true;
      },
      async deleteTable() {
        const { tableSelected = {}, data = [] } = this;
        const { id = "" } = tableSelected;
        const { confirmModal } = this.$refs;
        confirmModal.loading = true;
        const { ok } = await surePromise(deleteTable(id));
        confirmModal.loading = false;
        this.openConfirmModal = false;
        if (ok) {
          const index = this.getIndexOfTableSelected(data, id);
          const hasIndex = index !== -1;
          if (hasIndex) this.data.splice(index, 1);
          confirmModal.success = true;
        } else {
          confirmModal.error = true;
        }
        this.tables = [...this.tables];
      },
      handleSelectCard(event, table) {
        const { target = {} } = event;
        const isEllipse = target.classList.contains("ellipse");
        if (!isEllipse) this.handleClick(table);
      },
      selectedTable(item, table) {
        item.selected = table.id === item.id;
        return item;
      },
      handleDeleteClick(table) {
        this.openConfirmModal = true;
        this.tableSelected = table;
      },
      handleEditClick(table) {
        this.editModal = true;
        this.tableSelected = table;
      },
      handleCloseTableModal() {
        this.editModal = false;
        this.tableSelected = {};
      },
      handleMenuChange(open) {
        this.displayMenu = open;
      },
      handleClick(item) {
        this.tableSelected = item;
        this.tableSelected.chairs = String(item.chairs);
        const noOrder = item.hasOwnProperty("currentOrder") && item.currentOrder === "";
        const noUser = item.hasOwnProperty("currentClient") && item.currentClient === "";
        if (noOrder) item.currentOrder = {};
        if (noUser) item.currentClient = {};
        this.$emit("choose", item);
      },
      getStatus(item) {
        if (item.status === "FREE") return "Libre";
        if (item.status === "BUSY") return "Ocupada";
        return false;
      },
      handlePromo(table = {}) {
        this.displayPromoModal = true;
        this.currentTable = table;
      }
    }
  };
</script>
