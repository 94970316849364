<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import Comments from "@components/generals/Comments/Comments.vue";
import SaveAndDeleteCombo from "@components/generals/SaveAndDeleteCombo";
import EmptySection from "@/components/generals/EmptySection";
import AccountingBoard from "@/components/generals/AccountingBoard";
import BasicSwitcher from "@components/switchers/BasicSwitcher.vue";
import { notifyEvent } from "@/utils/notifyEvent";
import mixin from '@/mixins';

const { MenuMixin } = mixin;

export default {
  name: "AdminBoxflow",
  components: {
    Checkbox,
    Comments,
    Loading,
    AccountingBoard,
    HeadquatersSelect,
    UserBasicInfo,
    BasicSwitcher,
    EmptySection,
    SaveAndDeleteCombo,
    ReservationsComboTables
  },
  mixins: [MenuMixin],
  data() {
    return {
      headquaterSelected: {},
      showDetails: false,
      headquaters: {},
      headquater: {},
      showOverlay: false,
      fetchingComments: true,
      success: false,
      error: false,
      loading: true
    };
  },
  created() {
    const { headquaters = {} } = window.user;
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
    this.headquaterSelected = this.headquater;
    this.headquaters = headquaters;
  },
  computed: {
    getHeadquaterSelected() {
      const headquater = Object.keys(this.headquaters).find(key => this.headquaters[key].selected === true);
      return this.headquaters[headquater];
    }
  },
  watch: {
    showOverlay(val) {
      return notifyEvent({ name: "overlay", detail: val });
    }
  },
  async mounted() {
    this.loading = false;
    window.addEventListener("notification", this.handleNewNotification);
    window.addEventListener("headquaterSelected", this.refreshData);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
  },
  beforeDestroy() {
    window.removeEventListener("headquaterSelected", this.refreshData);
    window.removeEventListener("notification", this.handleNewNotification);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  methods: {
    refreshData() {
      this.headquaterSelected = this.getHeadquaterSelected;
      this.headquater = this.headquaterSelected;
      this.$refs.accountingBoard.refreshData(this.headquaterSelected);
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    }
  }
};
</script>
