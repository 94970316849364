<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import surePromise from "@utils/surePromise";
import validators from "@utils/validators";
import requests from "@/helpers/requests";

const { sendInvoice } = requests;
const { validEmail } = validators;

export default {
  name: "SendEmailForm",
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    user: {
      type: String,
      default: () => ""
    },
    order: {
      type: String,
      default: () => ""
    },
    service: {
      type: Number,
      default: 0
    },
    iva: {
      type: Number,
      default: 0
    },
    discount: {
      type: Number,
      default: 0
    },
    withService: {
      type: Boolean,
      default: false
    },
    withIva: {
      type: Boolean,
      default: false
    },
    domicile: {
      type: Number,
      default: 0
    },
    douceur: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    activator: null,
    attach: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    index: -1,
    items: [{ header: "Selecciona un email o agregalo." }],
    loading: false,
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0
  }),
  computed: {
    isValid() {
      return this.model.map(item => item.text).some(email => validEmail(email));
    },
    isEmpty() {
      return this.model.length === 0;
    }
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map(v => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1]
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    }
  },

  methods: {
    reset() {
      this.activator = null;
      this.attach = null;
      this.colors = ["green", "purple", "indigo", "cyan", "teal", "orange"];
      this.editing = null;
      this.index = -1;
      this.items = [{ header: "Selecciona un email o agregalo." }];
      this.loading = false;
      this.nonce = 1;
      this.menu = false;
      this.model = [];
      this.x = 0;
      this.search = null;
      this.y = 0;
    },
    async sendInvoice() {
      const { model, discount, service, withService, withIva, iva, domicile, douceur, order = "", user = "" } = this;
      const cc = model
        .map(item => item.text)
        .filter(item => validEmail(item))
        .filter(item => item !== user)
        .join();
      const payload = { cc };
      payload.iva = Number(iva);
      payload.active = withIva;
      payload.discount = Number(discount);
      payload.domicile = Number(domicile);
      payload.douceur = Number(douceur);
      payload.service = withService ? Number(service) : 0;
      this.loading = true;
      const { ok, error, result } = await surePromise(sendInvoice(order, payload));
      this.loading = false;
      if (ok) {
        this.$emit("success");
      } else {
        console.log({ error });
        this.$emit("fail");
      }
      console.log({ ok, error, result });
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.index = index;
      } else {
        this.editing = null;
        this.index = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    }
  }
};
</script>
