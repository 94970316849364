
<template>
  <v-dialog
    v-model="open"
    max-width="700"
    max-height="500px"
    persistent
    content-class="payment-dialog"
  >
    <v-container class="payment-modal scroll-bar">
      <section v-if="!displayBilling">
        <div @click="$emit('closePaymentModal')" class="payment-modal__header">
          <h2>Formas de pago</h2>
          <v-btn fab icon dark small class="payment-modal__header__btn-close" @click="$emit('close')" />
        </div>
        <h2 class="payment-modal__title--center">Monto total</h2>
        <p class="payment-modal__amount-text">${{ totalAmount | filterMoney }}</p>
        <div class="payment-modal__card-list">
          <div class="payment-modal__card">
            <div
              @click="selectPaymentMethod('cash')"
              class="payment-modal__card-box"
              :class="{'is-active': isCash}"
            >
              <img src="@/assets/icons/cash-pay.svg" />
            </div>
            <p class="payment-modal__card-title">Efectivo</p>
          </div>
          <div class="payment-modal__card">
            <div
              @click="selectPaymentMethod('card')"
              class="payment-modal__card-box"
              :class="{'is-active': isCard}"
            >
              <img src="@/assets/icons/cards-pay.svg" />
            </div>
            <p class="payment-modal__card-title">Débito o Crédito</p>
          </div>
          <div class="payment-modal__card">
            <div
              @click="selectPaymentMethod('transfer')"
              class="payment-modal__card-box"
              :class="{'is-active': isTransfer}"
            >
              <img src="@/assets/icons/transfer-pay.svg" />
            </div>
            <p class="payment-modal__card-title">Transacción</p>
          </div>
        </div>
        <div class="payment-modal__footer-content">
          <div class="payment-modal__field-content">
            <p class="payment-modal__text--center" v-show="isCash">¿Con cuánto va a pagar?</p>
            <v-text-field
              v-if="isCash"
              class="payment-modal__field"
              v-model.lazy="valueToPay"
              prepend-icon="monetization_on"
              label="¿Con cuánto va a pagar?"
              v-money="money"
              solo
            ></v-text-field>
            <BasicSelect
              v-else
              :data="filterListOfPaymentMethods.items"
              :label="filterListOfPaymentMethods.label"
              @change="onSelectedPaymentMethod"
            ></BasicSelect>
          </div>
          <div v-show="valueToPay.length > 2 && isCash">
            <h2 class="payment-modal__title--center">{{ calcMoneyReturned.type }}</h2>
            <p class="payment-modal__amount-text">${{ calcMoneyReturned.value | filterMoney }}</p>
          </div>
          <v-btn
            class="payment-modal__btn-action"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click.stop="payOrder"
          >Realizar pago</v-btn>
        </div>
      </section>
      <section v-else>
        <img class="payment-modal__check" src="@/assets/icons/check.svg" />
        <h2 class="payment-modal__title--center">Monto cancelado</h2>
        <p class="payment-modal__amount-text">${{ totalAmount | filterMoney }}</p>
        <p class="payment-modal__text--center">¿Qué quieres hacer con tu factura?</p>
        <div class="payment-modal__invoice-edit" @click="showInvoiceDetail = true">
          <v-btn flat icon class="btn-edit payment-modal__invoice-edit__button"></v-btn>
          <p class="payment-modal__invoice-edit__text">Editar</p>
        </div>
        <div class="payment-modal__card-list">
          <div class="payment-modal__card">
            <div
              @click="selectSendInvoiceMethod('email')"
              class="payment-modal__card-box"
              :class="{'is-active': isSendToEmail}"
            >
              <img src="@/assets/icons/email.svg" />
            </div>
            <p class="payment-modal__card-title">Enviar por email</p>
          </div>
          <div class="payment-modal__card">
            <div
              @click="selectSendInvoiceMethod('printer')"
              class="payment-modal__card-box"
              :class="{'is-active': isSendToPrint}"
            >
              <img src="@/assets/icons/printer.svg" />
            </div>
            <p class="payment-modal__card-title">Imprimir</p>
          </div>
        </div>
        <div class="payment-modal__footer-content">
          <SendEmailForm
            :user="currentOrder.user"
            :order="currentOrder.id"
            :service="currentOrder.service"
            :iva="currentOrder.iva.value"
            :discount="currentOrder.discount"
            :with-service="Boolean(currentOrder.service)"
            :with-iva="currentOrder.iva.active"
            @success="onSuccessEmail"
            :show-button="true"
            ref="sendEmail" v-if="isSendToEmail"
          />
          <v-snackbar :bottom="true" :color="snackBar.color" :right="true" :timeout="snackBar.timeOut" v-model="snackBar.showSnackbar">
            {{snackBar.text}}
          </v-snackbar>
          <v-btn v-if="!isSendToEmail" @click="finish" class="payment-modal__btn-action" color="primary">Finalizar</v-btn>
        </div>
      </section>
      <InvoiceDetail
        @closeInvoiceDetail="showInvoiceDetail = false"
        :headquater="headquater"
        :current-order="currentOrder"
        :show="showInvoiceDetail"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import BasicSelect from "@/components/selects/BasicSelect";
import SendEmailForm from "@/components/forms/SendEmailForm";
import InvoiceDetail from "@/components/generals/InvoiceDetail/InvoiceDetail.vue";
import { VMoney } from "v-money";

export default {
  name: "PaymentModal",
  components: {
    BasicSelect,
    InvoiceDetail,
    SendEmailForm
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    totalAmount: {
      type: Number,
      default: 0
    },
    makePaymentFn: {
      type: Function,
      default: () => ({})
    },
    closeFn: {
      type: Function,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    printInvoice: {
      type: Function,
      default: () => ({})
    },
    currentOrder: {
      type: Object,
      default: () => ({
        paidMethod: '',
        dispatchedBy: {
          name: ''
        },
        createAt: {
          _seconds: 0
        },
        iva: {
          computedValue: 0
        },
        table: {},
        basePrice: 0
      })
    },
    headquater: {
      type: Object,
      default: () => ({
        name: ''
      })
    },
    selectedPaymenPethod: {
      type: Function,
      default: () => {}
    }
  },
  directives: {
    money: VMoney
  },
  created() {
    this.chosenPayment = "cash";
    this.selectedPaymenPethod("CASH");
  },
  data: () => ({
    snackBar: {
      showSnackbar: false,
      timeOut: 25000,
      color: "error",
      text: "Tenemos un error en este momento"
    },
    chosenPayment: "cash",
    typeOfInvoiceDelivery: "",
    valueToPay: "",
    money: {
      precision: "",
      thousands: ",",
      prefix: "",
      suffixUnit: "",
      masked: false
    },
    cardPayments: ["Débito", "Mastercard", "Visa"],
    transferPayments: ["Territorio QR", "Rappi Pay", "Nequi", "Payu", "Paypal", "Otros"],
    displayBilling: false,
    customerEmail: "",
    showInvoiceDetail: false,
    mapPaidMethods: {
      cash: "CASH",
      Débito: "DEBIT_CARD",
      Mastercard: "CREDIT_CARD",
      Visa: "CREDIT_CARD",
      "Territorio QR": "QR",
      "Rappi Pay": "RAPI",
      Nequi: "NEQUI",
      Paypal: "PAYPAL",
      Payu: "PAYU"
    }
  }),
  computed: {
    calcMoneyReturned() {
      const subtraction = +this.valueToPay.replace(/,/g, "") - this.totalAmount;
      return {
        value: subtraction,
        type: subtraction > 0 ? "Cambio" : "Debe"
      };
    },
    isCash() {
      return this.chosenPayment === "cash";
    },
    isCard() {
      return this.chosenPayment === "card";
    },
    isTransfer() {
      return this.chosenPayment === "transfer";
    },
    isSendToEmail() {
      return this.typeOfInvoiceDelivery === "email";
    },
    isSendToPrint() {
      return this.typeOfInvoiceDelivery === "printer";
    },
    filterListOfPaymentMethods() {
      const paymentType = this.chosenPayment;
      if (paymentType === "card") {
        return {
          label: "Selecciones la franquicia",
          items: this.cardPayments
        };
      }
      if (paymentType === "transfer") {
        return {
          label: "Selecciona la pasarela de pago",
          items: this.transferPayments
        };
      }
      return false;
    }
  },
  methods: {
    onSelectedPaymentMethod(method) {
      this.selectedPaymenPethod(this.mapPaidMethods[method]);
    },
    onSuccessEmail() {
      console.log('envió');
      this.snackBar.showSnackbar = true;
      this.snackBar.color = "success";
      this.snackBar.text = "Se ha enviado el e-mail correctamente";
      this.typeOfInvoiceDelivery = '';
    },
    resetState() {
      Object.assign(this.$data, this.$options.data());
    },
    selectPaymentMethod(option) {
      this.chosenPayment = option;
    },
    selectSendInvoiceMethod(option) {
      this.typeOfInvoiceDelivery = option;
      if (option === "printer") return this.$htmlToPaper("invoice-detail");
      return false;
    },
    showBillingMeans() {
      this.displayBilling = true;
    },
    async payOrder() {
      (await this.makePaymentFn()) && this.showBillingMeans();
      return false;
    },
    finish() {
      console.log(this.typeOfInvoiceDelivery);
      if (this.typeOfInvoiceDelivery === 'email') {
        console.log(this.$refs.sendEmail);
      }
      this.closeFn();
      this.resetState();
      this.$emit("paymentSuccess");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_colors.scss";
::v-deep .payment-dialog {
  background: white;
  border-radius: 10px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

::v-deep .form-title {
  font-size: 25px;
}

.payment-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  max-height: 500px;
  overflow-y: auto;
  &__title {
    font-size: 40px;
  }

  &__text {
    font-size: 15px;
  }

  &__text--center {
    font-size: 19px;
    text-align: center;
  }

  &__invoice-edit {
    text-align: center;
    cursor: pointer;
    width: 10%;
    margin-left: auto;
    margin-right: auto;

    &__button {
      background-image: url("~@/assets/icons/edit.svg");
      background-position: center;
      background-size: 20px;
      background-repeat: no-repeat;
      position: relative;
    }
    &__text {
      position: relative;
      font-size: 14px;
      color: $primary;
    }
  }

  &__title--center {
    margin-top: 15px;
    font-size: 40px;
    text-align: center;
  }

  &__amount-text {
    font-size: 31px;
    text-align: center;
    color: $primary;
    font-weight: 700;
  }

  &__check {
    margin: 0 auto;
    display: block;
    margin-bottom: -15px;
  }

  &__card-list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__header {
    top: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
    &__btn-close {
      background-position: center;
      background-size: 25px;
      background-repeat: no-repeat;
      background-image: url("~@/assets/icons/close-red.svg");
      float: right;
      position: absolute;
      right: 2%;
      top: 0;
    }
  }

  &__card {
    width: 158px;
    height: auto;
    margin: 21px;
  }

  &__card-box {
    width: 158px;
    height: 119px;
    display: flex;
    justify-content: center;
    background: $grey-one 0% 0% no-repeat padding-box;
    border-radius: 8px;
    transition: 0.5s;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 3px 6px #00000065;
    }

    &.is-active {
      box-shadow: 0px 3px 6px #00000065;
    }

    img {
      width: 50%;
    }
  }

  &__card-title {
    margin-top: 8px;
    font-size: 18px;
    text-align: center;
  }

  &__footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__field {
    max-width: 335px;
  }

  &__field-content {
    margin: 0 auto;
    display: block;
    width: 383px;
  }

  &__field-label {
    font-size: 19px;
    margin-bottom: 15px;
  }

  &__btn-action {
    min-width: 327px;
    margin-left: 0px;
    padding-right: 0px;
    margin-top: 16px;
    border-radius: 12px;
    font-family: Gibson;
    height: 45px;
    box-shadow: none !important;
  }
}
</style>
