<style scoped lang="scss">
    @import "style";
</style>

<template src="./template.html"></template>

<script>
  import { mapState } from "vuex";
  import MenuCard from "@components/cards/MenuCard";
  import defaultPlate from "@assets/images/default-plate.png";
  import onPWA from "@/utils/onPWA.js";
  import _numeral from "numeral";
  import STORE from "@/store/types";

  try {
    _numeral.register('locale', 'co', {
      delimiters: {
        thousands: '.',
        decimal: '.'
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal(number) {
        return number === 1 ? 'er' : 'nd';
      },
      currency: {
        symbol: '$'
      }
    });
    _numeral.locale('co');
  } catch (error) {
    //
  }

  export default {
    name: "PromoDetail",
    props: {
      headquater: {
        type: String,
        default: ''
      },
      promotion: {
        type: Object,
        default: () => ({})
      },
      image: {
        type: String,
        default: defaultPlate
      },
      price: {
        type: Number,
        default: 0
      },
      description: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
        default: ""
      },
      lang: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: () => ({})
      },
      initialSuggestion: {
        type: String,
        default: ""
      }
    },
    components: { MenuCard },
    computed: {
      ...mapState({
        openPromo: state => state.cart.show_promo
      })
    },
    data() {
      return {
        showPromo: false,
        onPWA: onPWA(),
        amount: 0,
        added_to_cart: false,
        suggestion: ''
      };
    },
    created() {
      this.showPromo = false;
    },
    mounted() {
      this.loading = false;
    },
    filters: {
      numeral(value) {
        return _numeral(value).format('$0,0');
      }
    },
    methods: {
      handleChangeSuggestions(val) {
        this.$emit("suggestion", val, this.id);
      },
      handleAmountChange(name) {
        if (name === "add") this.amount++;
        if (name === "minus" && this.amount > 0) this.amount--;
        if (this.amount === 0) this.suggestion = "";
      },
      displayLangAvailable(payload = {}) {
        const { lang = "" } = window.user;
        const isAvailable = payload.hasOwnProperty(lang);
        if (isAvailable) return payload[lang];
        const keys = Object.keys(payload);
        if (keys.length !== 0) {
          const [available] = keys;
          return payload[available];
        }
        return payload.ES;
      },
      closeCart() {
        this.$router.push("/home");
      },
      goToYourOrder() {
        this.$router.push("/your-order");
      },
      addToCart() {
        const self = this;
        if (this.amount > 0) {
          const item = Object.assign({}, this.item);
          // eslint-disable-next-line
          item["amount"] = this.amount;
          // eslint-disable-next-line
          item["price"] = this.price;
          // eslint-disable-next-line
          item["suggestion"] = this.suggestion;

          this.$store.dispatch(STORE.ACTIONS.CART.ADD_TO_CART, {
            item,
            headquater: this.headquater,
            restaurant: this.$route.params.id
          }).then(() => {
            self.amount = 0;
            self.open = false;
            self.added_to_cart = true;
            self.showPromo = false;
          });
        }
      },
      goToCart() {
        this.$router.push('/cart');
        this.showPromo = false;
      }
    },
    watch: {
      openPromo(newVal) {
        this.showPromo = newVal;
      }
    }
  };
</script>
