import { BASE } from "./index";
const setLike = ({ id, state }) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/headquater/update/${id}`;
  return fetch(url, {
    method: "PUT",
    headers: { token, "content-type": "application/json" },
    body: JSON.stringify({ ...state })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default setLike;
