import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import uuid from "@utils/uuid";

export default class DispatchReservationClock {
  constructor() {
    this.intervals = [];
  }

  dispatchDoneEvent = detail => {
    window.dispatchEvent(new CustomEvent("DispatchReservationSuccess", { detail }));
  };

  dispatchFailEvent = detail => {
    window.dispatchEvent(new CustomEvent("DispatchReservationFail", { detail }));
  };

  async tick(id, reservation) {
    const { ok, error, result } = await surePromise(requests.dispatchReservationExpired({ reservations: [reservation.id] }));
    this.pull(id);
    if (ok) this.dispatchDoneEvent(result);
    if (error) this.dispatchFailEvent(error);
  }

  push(data) {
    const now = new Date();
    const { date } = data;
    let sec = 0;
    if (date.hasOwnProperty("_seconds")) sec = date._seconds;
    if (date.hasOwnProperty("seconds")) sec = date.seconds;
    if (date.hasOwnProperty("toMillis")) sec = date.toMillis();
    const time = sec * 1000 - now.getTime();
    const id = uuid();
    const interval = setTimeout(() => {
      this.tick(id, data);
    }, time);
    this.intervals.push({ interval, time, id });
    window.intervals = this.intervals;
  }

  clear() {
    for (const { interval } of this.intervals) window.clearInterval(interval);
    this.intervals = [];
  }

  pull(id) {
    const index = this.intervals.map(data => data.id).indexOf(id);
    const { interval } = this.intervals[index];
    window.clearInterval(interval);
    this.intervals.splice(index, 1);
  }
}
