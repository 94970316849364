import Login from "@views/Login";
import Home from "@/views/User/Home/index.vue";
import Cart from "@/views/User/Cart";
import YourOrder from "@/views/User/YourOrder";
import DashBoard from "@views/Dashboard";
import Board from "@views/Admin/Home/index.vue";
import Comments from "@views/Admin/Comments";
import Tables from "@views/Admin/Tables";
import Business from "@views/Admin/Business";
import Delivery from "@views/Admin/Delivery";
import Employees from "@views/Admin/Employees";
import Menus from "@views/Admin/Menus";
import Order from "@views/Admin/Order";
import BoxFlow from "@views/Admin/Boxflow";
import HeadQuaters from "@views/Admin/Headquaters";
import Payments from "@views/Admin/Payments";
import Articles from "@views/Admin/Articles";
import Quotation from "@views/Admin/Quotation";
import Booking from "@views/Admin/Booking";
import Clients from "@views/Admin/Clients";
import NewQuotation from "@views/NewQuotation";
import Gallery from "@views/Admin/Gallery";
import Restaurants from "@views/UserDashboard/index.vue";
import Profile from "@views/Shared/Profile";
import Reset from "@views/Reset";
import Recovery from "@views/Recovery";
import Invitation from "@views/Invitation";
import Register from "@views/Register";
import Restaurant from "@views/Shared/Restaurant";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      showCart: true,
      showOrder: true
    }
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart
  },
  {
    path: "/your-order",
    name: "your-order",
    component: YourOrder
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashBoard,
    children: [
      {
        path: 'board',
        name: 'board',
        component: Board,
        meta: {
          showCart: true
        }
      },
      {
        path: "comments",
        name: "comments",
        component: Comments,
        props: true
      },
      {
        path: "tables",
        name: "available",
        component: Tables,
        props: true
      },
      {
        path: "business",
        name: "business",
        component: Business,
        props: true
      },
      {
        path: "delivery",
        name: "delivery",
        component: Delivery,
        props: true,
        children: [
          {
            path: ":delivery_status",
            component: Delivery,
            props: true
          }
        ]
      },
      {
        path: "employees",
        name: "employee",
        component: Employees,
        props: true
      },
      {
        path: "menus",
        name: "menu",
        component: Menus,
        props: true
      },
      {
        path: "order",
        name: "order",
        component: Order,
        props: true,
        meta: {
          ma: true
        }
      },
      {
        path: "boxflow",
        name: "boxFlow",
        component: BoxFlow,
        props: true
      },
      {
        path: "headquaters",
        name: "headquaters",
        component: HeadQuaters,
        props: true
      },
      {
        path: "payments",
        name: "payments",
        component: Payments,
        props: true
      },
      {
        path: "articles/:id",
        name: "articles",
        component: Articles,
        props: true
      },
      {
        path: "quotation",
        name: "quotation",
        component: Quotation,
        props: true
      },
      {
        path: "quotation/new",
        name: "new",
        component: NewQuotation
      },
      {
        path: "gallery",
        name: "gallery",
        component: Gallery,
        props: true
      },
      {
        path: "booking",
        name: "booking",
        component: Booking,
        props: true
      },
      {
        path: "clients",
        name: "clients",
        component: Clients,
        props: true
      }
    ]
  },
  {
    path: "/restaurants",
    name: "dashboardState",
    component: Restaurants,
    meta: {
      showCart: true
    }
  },
  {
    path: "/dashboard/:state",
    name: "dashboardState",
    component: DashBoard
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile
  },
  {
    path: "/reset",
    name: "reset",
    component: Reset
  },
  {
    path: "/recovery",
    name: "recovery",
    component: Recovery
  },
  {
    path: "/invitation",
    name: "invitation",
    component: Invitation
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/restaurant/:id/:headquater",
    name: "restaurant",
    component: Restaurant,
    meta: {
      showCart: true
    }
  }
];

export default routes;
