<style scoped lang="scss">
    @import "style";
</style>

<template src="./template.html"></template>

<script>
  import { mapState } from "vuex";
  import _numeral from "numeral";

  try {
    _numeral.register('locale', 'co', {
      delimiters: {
        thousands: '.',
        decimal: '.'
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal(number) {
        return number === 1 ? 'er' : 'nd';
      },
      currency: {
        symbol: '$'
      }
    });
    _numeral.locale('co');
  } catch (error) {
    //
  }

  export default {
    name: "ResumeCost",
    components: { },
    props: {
      title: {
        type: String,
        default: ''
      },
      setOrderAmount: {
        type: Function,
        default: () => {}
      },
      order: {
        type: Object,
        default: () => {}
      },
      headquater: {
        type: Object,
        default: () => {}
      }
    },
    filters: {
      numeral(value) {
        return _numeral(value).format('$ 0,0');
      }
    },
    computed: {
      ...mapState({
        openYourOrder: state => state.yourOrder.show_your_order,
        cart_items: state => state.cart.cart
      }),
      subtotal() {
        let total = 0;
        for (const i in this.cart_items) {
          if (this.cart_items[i].amount > 0) {
            total += this.cart_items[i].price * this.cart_items[i].amount;
          }
        }
        this.setOrderAmount(total);
        return total;
      },
      cost() {
        if (this.order.order_type === "domicilio") {
          return this.headquater.domicile ? parseFloat(this.headquater.domicile) : 0;
        }
        return 0;
      }
    },
    data() {
      return {

      };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
    watch: {

    }
  };
</script>
