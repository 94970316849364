import Vue from 'vue';
import STORE from '@/store/types';

export default Vue.extend({
  name: 'LoadingStore',

  computed: {
    getLoadingStore() {
      return this.$store.getters[STORE.GETTERS.UTILS_INTERFACE.GET_LOADING_STATUS];
    }
  }
});
