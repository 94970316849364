<template>
  <div :class="{ 'gap': hasIcon }" class="item-container" @click="handleClick">
    <span :class="`icon is-${icon}`" class="item-container__icon"></span>
    <span class="item-container__text">{{ text }}</span>
    <span v-if="hasArrow" class="item-container__arrow"></span>
  </div>
</template>

<script>
export default {
  name: "QuestionItem",
  props: {
    icon: {
      type: String,
      default: () => ""
    },
    text: {
      type: String,
      default: () => ""
    },
    hasArrow: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasIcon() {
      return this.icon !== "";
    }
  },
  methods: {
    handleClick(event) {
      this.$emit("select", event);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-container {
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  min-height: 52px;
  display: grid;
  grid-template-columns: 20px 1fr 15px;
  width: calc(100% - 10px);
  font-family: Gibson-Bold;
  font-size: 15px;
  margin: 0px auto;
  margin-bottom: 20px;
  transition: all 0.25s;
  &:hover {
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.2);
  }
  &__text {
    grid-area: 1/2;
    padding-top: 6px;
    color: #5a5a5a;
    line-height: 1.2;
  }
  &__arrow {
    width: 12px;
    height: 8px;
    background-image: url("~@assets/icons/arrow-next-gray.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    padding-top: 30px;
  }
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;

  &.is-setting {
    background-image: url("~@assets/icons/setting.svg");
  }

  &.is-headquarters {
    background-image: url("~@assets/icons/headquarters.svg");
  }

  &.is-pay {
    background-image: url("~@assets/icons/pay.svg");
  }

  &.is-security {
    background-image: url("~@assets/icons/security.svg");
    background-size: 14px;
  }
}

.gap {
  grid-gap: 8px;
}
</style>
