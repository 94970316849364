<style scoped lang="scss">
  .needs-section__title {
    color: white;
    font-size: 18px;
    max-width: 500px;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .needs-section__options {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    max-width: 500px;
    margin: 0px auto;
  }

  .needs-section__wrap-pay-btn {
    padding-top: 30px;
    text-align: center;

    .v-btn {
      border-radius: 12px;
      max-width: 500px;
      font-family: Gibson-Bold;
      font-size: 12px;
      height: 45px;
      width: 100%;
    }
  }

  .needs-section__options__btn {
    margin: 0px auto;
    border-radius: 7px;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 15px;
    text-transform: none;
    min-height: 140px;
  }

  .needs-section__options__btn--icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    width: 45px;
    height: 45px;
    display: block;
    position: relative;
    top: -14px;
  }

  .waiter-icon {
    background-image: url("~@assets/icons/waiter.svg");
  }

  .dishe-icon {
    background-image: url("~@assets/icons/dishe.svg");
  }

  .reset-icon {
    background-image: url("~@assets/icons/reset.svg");
  }

  .message-icon {
    background-image: url("~@assets/icons/message.svg");
  }

  .cutlery-icon {
    background-image: url("~@assets/icons/cutlery.svg");
  }

  .menu-icon {
    background-image: url("~@assets/icons/menu.svg");
  }

  .needs-section__options__btn--label {
    font-size: 14px;
    display: block;
    position: absolute;
    bottom: -35px;
  }

  .container-send-message {
    background: white !important;
    border-radius: 12px;
    position: relative;
    padding: 20px;
    height: 300px;
  }

  .v-card__title {
    color: black;
  }

  .v-card__actions {
    bottom: 17px;
    position: absolute;
    right: 10px;
  }

  .btn-send {
    border-radius: 12px;
  }

  .wrap-loading {
    align-content: center;
    height: 100%;
    display: grid;
    justify-content: center;
  }

  @media (max-width: 520px) {
    .needs-section__options {
      grid-template-columns: 1fr 1fr;
    }
  }
</style>

<template>
  <v-container class="needs-section">
    <div id="messageDialog"></div>
    <p class="needs-section__title">¿Qué necesitas?</p>
    <div class="needs-section__options">
      <v-btn
        color="#16202f"
        class="needs-section__options__btn"
        @click="() => requestNotification('Mesero por favor venga lo necesito')"
      >
        <div class="needs-section__options__btn--icon waiter-icon"></div>
        <p class="needs-section__options__btn--label">Mesero</p>
      </v-btn>
      <v-btn
        color="#16202f"
        class="needs-section__options__btn"
        @click="() => requestNotification('Mesero por favor, limpiar mesa')"
      >
        <div class="needs-section__options__btn--icon dishe-icon"></div>
        <p class="needs-section__options__btn--label">Limpiar Mesa</p>
      </v-btn>
      <v-btn
        color="#16202f"
        class="needs-section__options__btn"
        @click="() => requestNotification('Mesero por favor traigame la ultima orden')"
      >
        <div class="needs-section__options__btn--icon reset-icon"></div>
        <p class="needs-section__options__btn--label">Ultima orden</p>
      </v-btn>
      <div>
        <v-dialog v-model="dialog" color="white" persistent max-width="400">
          <template v-slot:activator="{ on }">
            <v-btn color="#16202f" class="needs-section__options__btn" v-on="on">
              <div class="needs-section__options__btn--icon message-icon"></div>
              <p class="needs-section__options__btn--label">Enviar mensaje</p>
            </v-btn>
          </template>
          <div color="white" class="container-send-message">
            <v-textarea
              v-if="loading === false"
              v-model="message"
              outline
              height="200"
              label="Escribe un mesaje para el mesero"
              :rules="[validateMessage]"
            ></v-textarea>
            <div v-if="loading" class="wrap-loading text-xs-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <v-card-actions v-if="loading === false">
              <v-spacer></v-spacer>
              <v-btn color="primary" flat="flat" @click="handleCancelMessage">Cancelar</v-btn>

              <v-btn
                color="primary"
                class="btn-send"
                :disabled="!messageValid"
                @click="handleSendMessage"
              >Enviar
              </v-btn>
            </v-card-actions>
          </div>
        </v-dialog>
      </div>
      <v-btn
        color="#16202f"
        class="needs-section__options__btn"
        @click="() => requestNotification('Mesero por favor traigame los cubiertos')"
      >
        <div class="needs-section__options__btn--icon cutlery-icon"></div>
        <p class="needs-section__options__btn--label">Cubiertos</p>
      </v-btn>
      <v-btn color="#16202f" class="needs-section__options__btn" @click="$emit('showMenu')">
        <div class="needs-section__options__btn--icon menu-icon"></div>
        <p class="needs-section__options__btn--label">Menú</p>
      </v-btn>
    </div>

    <div class="needs-section__wrap-pay-btn">
      <v-btn color="primary" :disabled="!canPay" @click="showBilling">Pagar cuenta</v-btn>
    </div>

    <v-snackbar
      v-model="success"
      :bottom="true"
      :left="true"
      :timeout="4000"
      color="success"
    >{{ successMessage }}
    </v-snackbar>

    <v-snackbar
      v-model="error"
      :bottom="true"
      :left="true"
      color="error"
      :timeout="4000"
    >{{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import requests from "@/helpers/requests";
  import validators from "@/utils/validators.js";

  export default {
    name: "OrderOptions",
    props: {
      table: {
        type: String,
        default: ""
      },
      waiter: {
        type: String,
        default: ""
      },
      canPay: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        messageValid: false,
        dialog: false,
        message: "",
        loading: false,
        error: false,
        success: false,
        errorMessage: "Ocurrio un error intenta mas tarde",
        successMessage: "Operacion completada con exito"
      };
    },
    mounted() {
      window.addEventListener("waiterMessage", this.handleWaiterMessage);
      window.addEventListener("assignedWaiter", this.assignedWaiter);
    },
    beforeDestroy() {
      window.removeEventListener("waiterMessage", this.handleWaiterMessage);
      window.removeEventListener("assignedWaiter", this.assignedWaiter);
    },
    methods: {
      assignedWaiter(e) {
        this.success = true;
        this.successMessage = `Se le asignó el mesero: ${e.detail.waiter}`;
      },
      handleWaiterMessage() {
        this.success = true;
        this.successMessage = "Listo ya recibí tu mensaje";
      },
      handleWaiterNotificationSuccess(response) {
        if (response.ok) {
          console.log("notification sended");
          this.successMessage = "Peticion enviada.";
          this.success = true;
        } else {
          this.error = true;
          this.errorMessage = "Ocurrio un error, por favor intenta mas tarde.";
        }
      },
      handleWaiterNotificationError(error) {
        console.log("error ", error);
      },
      async requestNotification(body) {
        await requests.adminNotification(this.table, {
          body,
          table: this.table,
          sender: window.user.uid
        })
          .then(this.handleWaiterNotificationSuccess)
          .catch(this.handleWaiterNotificationError);

        return requests
          .waiterNotification(this.waiter, {
            body,
            table: this.table,
            sender: window.user.uid
          })
          .then(this.handleWaiterNotificationSuccess)
          .catch(this.handleWaiterNotificationError);
      },
      validateMessage() {
        const isValid = validators.validText(this.message);
        this.messageValid = isValid;
        if (isValid === false) return "Escribe un mensaje valido";
        return "";
      },
      handleCancelMessage() {
        this.dialog = false;
        this.message = "";
      },
      handleSendMessage() {
        console.log(this.message);
        if (this.message) {
          this.loading = true;
          requests
            .waiterNotification(this.waiter, {
              body: this.message,
              table: this.table,
              sender: window.user.uid
            })
            .then(() => {
              this.loading = false;
              this.message = "";
              this.messageValid = false;
              this.successMessage = "Operacion completada con exito";
              this.success = true;
              this.error = false;
              this.dialog = false;
            })
            .catch(this.handleWaiterNotificationError);
        }
      },

      showBilling() {
        this.$emit("showBilling");
        this.requestNotification("La cuenta por favor");
      }
    }
  };
</script>
