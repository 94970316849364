import Vue from 'vue';
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

export default Vue.extend({
  name: "SocialShare",
  data: () => ({
    dialShare: false,
    pageUrl: "https://geochef.firebaseapp.com",
    restaurant: '',
    id: ''
  }),
  mounted() {
    const { id, headquater } = this.$route.params;
    this.id = id;
    this.restaurant = headquater;
  }
});
