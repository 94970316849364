const convertTime12to24 = (time) => {
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.toString().replace('am', '').replace('pm', '').split(':');
  if (hours === '12') hours = '00';
  if (time.includes('pm')) hours = parseInt(hours, 10) + 12;
  return `${hours}:${minutes}`;
};

const convert24To12 = (value) => {
  if (!value) return '';
  // eslint-disable-next-line prefer-const
  let [hour, minute] = value.split(':');
  hour = Number(hour);
  if (hour > 12) hour -= 12;
  return `${hour}:${minute}`;
};

export default {
  convertTime12to24,
  convert24To12
};
