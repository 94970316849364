<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import MenuCard from "@components/cards/MenuCard";
  import { mapState } from "vuex";
  import onPWA from "@/utils/onPWA.js";
  import SocialMediaList from "@lists/SocialMediaList";
  import LocationMap from "@components/maps/LocationMap";
  import OrderStatus from "@components/Cart/OrderStatus";
  import STORE from "@/store/types";

  export default {
    name: "OrderSuccess",
    props: {
      isModal: {
        type: Boolean,
        default: true
      }
    },
    components: { MenuCard, SocialMediaList, LocationMap, OrderStatus },
    computed: {
      ...mapState({
      })
    },
    data() {
      return {
        onPWA: onPWA(),
        currentStep: 3,
        mode: ''
      };
    },
    created() {
      this.showCart = this.openCart;
    },
    mounted() {
      this.loading = false;
    },
    methods: {
      closeCart() {
        this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_CART);
        this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_SUCCESS_ORDER);
        this.$router.push("/home");
      },
      goToYourOrder() {
        this.$router.push("/your-order");
      }
    },
    watch: {
      openCart(newVal) {
        this.showCart = newVal;
      }
    }
  };
</script>