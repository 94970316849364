<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import MenuCard from "@components/cards/MenuCard";
  import defaultPlate from "@assets/images/default-plate.png";
  import onPWA from "@/utils/onPWA.js";

  export default {
    name: "PromoDetailModal",
    props: {
      headquater: {
        type: String,
        default: ""
      },
      promotion: {
        type: Object,
        default: () => ({})
      },
      image: {
        type: String,
        default: defaultPlate
      },
      price: {
        type: Number,
        default: 0
      },
      quantity: {
        type: Number,
        default: 0
      },
      description: {
        type: String,
        default: ""
      },
      title: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: () => ({})
      },
      updateOrder: {
        type: Function,
        default: () => ({})
      },
      changeAmount: {
        type: Function,
        default: () => ({})
      },
      closePromo: {
        type: Function,
        default: () => ({})
      },
      updatePromoQuantity: {
        type: Function,
        default: () => ({})
      },
      openPromo: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      changeSuggestion: {
        type: Function,
        default: () => ({})
      }
    },
    components: { MenuCard },
    data() {
      return {
        showPromo: false,
        onPWA: onPWA(),
        amount: 0,
        added_to_cart: false,
        suggestion: ""
      };
    },
    created() {
    },
    mounted() {
      this.loading = false;
    },
    methods: {
      handleAmountChange(name) {
        if (name === "add") this.amount++;
        if (name === "minus" && this.amount > 0) this.amount--;
        if (this.amount === 0) this.suggestion = "";
        this.changeAmount(this.amount);
      },
      closeModal() {
        this.closePromo();
      }

    },
    watch: {
      openPromo(newVal) {
        this.showPromo = newVal;
        if (newVal) {
          this.amount = 0;
          this.suggestion = "";
        }
      },
      suggestion(newVal) {
        this.$emit('changeSuggestion', newVal);
      }
    }
  };
</script>
