<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import QuestionItem from "@components/generals/QuestionItem";
import BasicInput from "@inputs/BasicInput";
import SearchInput from "@inputs/SearchInput";
import validators from "@utils/validators";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
const { validName, validText } = validators;

export default {
  name: "QuestionMenu",
  components: { BasicInput, SearchInput, QuestionItem },
  data() {
    return {
      loading: false,
      success: false,
      search: "",
      notfound: false,
      error: false,
      displayMenu: false,
      isValid: false,
      showBtnSearch: false,
      questions: []
    };
  },
  computed: {
    isPopulated() {
      return this.questions.length !== 0;
    }
  },
  mounted() {
    window.addEventListener("overlay", this.handleOverlayChange);
    document.addEventListener("keypress", this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener("overlay", this.handleOverlayChange);
  },
  methods: {
    navigateTo(id) {
      console.log({ id });
      const path = `/dashboard/articles/${id}`;
      return window.router.push({ path });
    },
    handleDescriptionChange(val) {
      this.isValid = validText(val);
      const isEmpty = val === "";
      if (isEmpty) {
        this.showBtnSearch = false;
      } else {
        this.showBtnSearch = true;
      }
    },
    handleKeyPress(e) {
      const { keyCode } = e;
      const isEnter = keyCode === 13;
      if (isEnter && this.isValid) {
        this.searchQuestion();
      }
    },
    openQuestion(question) {
      const { id = "" } = question;
      this.displayMenu = false;
      this.reset();
      window.router.push({ name: "articles", params: { id } });
    },
    async searchQuestion() {
      const { descriptionInput } = this.$refs;
      const { value = "" } = descriptionInput;
      this.loading = true;
      const { ok, error, result } = await surePromise(
        requests.fetchArticles({
          description: value
        })
      );
      this.loading = false;
      if (ok) {
        this.questions = [];
        const notfound = result && result.length === 0;
        if (notfound) {
          descriptionInput.notfound = true;
        } else {
          descriptionInput.notfound = false;
          this.questions = this.questions.concat(result);
        }
      } else {
        this.error = true;
        console.log({ error });
      }
    },
    handleClearField() {},
    handleClickBtnSearch() {},
    handleOverlayChange() {
      this.displayMenu = false;
    },
    reset() {
      this.loading = false;
      this.success = false;
      this.search = "";
      this.notfound = false;
      this.error = false;
      this.displayMenu = false;
      this.questions = [];
      const { descriptionInput } = this.$refs;
      descriptionInput.value = "";
    },
    handleFieldChange(name, value) {
      const isName = name === "name";
      if (isName) {
        this.validity.name = validName(value);
        this.values.name = value;
      }
    }
  }
};
</script>