<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import MenuForm from "@components/forms/MenuForm";
import CreateDishModal from "@components/modals/CreateDishModal";
import ItemsLists from "@components/lists/ItemsLists";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import { notifyEvent } from "@/utils/notifyEvent";
import { filterMoney } from "@/utils";
import mixins from '@/mixins';

const { MenuMixin } = mixins;

export default {
  name: "AdminMenus",
  components: {
    Checkbox,
    HeadquatersSelect,
    UserBasicInfo,
    MenuForm,
    ItemsLists,
    CreateDishModal,
    ReservationsComboTables
  },
  mixins: [MenuMixin],
  data() {
    const { headquaters = {} } = window.user;
    const headquater = this.getHeadquaterSelectedFromUserProfile();
    return {
      tables: [],
      dish: {},
      headquatersSelected: headquater,
      dishes: [],
      showDetails: false,
      dishBusy: false,
      showCreateDishModal: false,
      headquaters,
      headquater,
      showOverlay: false,
      fetchingDishes: true,
      success: false,
      error: false,
      loading: true
    };
  },
  computed: {
    getHeadquaterSelected() {
      const headquater = Object.keys(this.headquaters).find(key => this.headquaters[key].selected === true);
      return this.headquaters[headquater];
    },
    headquaterFocus() {
      return Object.values(this.headquaters).map(this.mapHeadquaterSelected);
    },
    availableHeadquatersForDish() {
      return Object.values(this.headquaters).map(headquater => {
        headquater.selected = this.dish.availableAt.hasOwnProperty(headquater.id);
        return headquater;
      });
    },
    hasDishSelected() {
      const result = Object.keys(this.dish).length !== 0;
      this.showDetails = result;
      return result;
    },
    headquatersValues() {
      return Object.values(this.headquaters);
    },
    headquaterList() {
      return Object.values(window.user.headquaters).map(item => item.name);
    },
    isPopulated(val) {
      return Object.keys(val).length !== 0;
    }
  },
  watch: {
    showOverlay(val) {
      return notifyEvent({ name: "overlay", detail: val });
    }
  },
  async mounted() {
    this.loading = false;
    this.setSubscription();
    this.dishes = [];
    this.dish = {};
    this.fetchingEmployees = true;
    await this.fetchMenu();
    window.addEventListener("notification", this.handleNewNotification);
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
  },
  beforeDestroy() {
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.removeEventListener("notification", this.handleNewNotification);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  methods: {
    async fetchMenu() {
      const { ok, result: dishes } = await surePromise(this.getDishesByHeadquater(this.headquater));
      if (ok) {
        this.fetchingDishes = false;
        if (dishes.length) this.dishes = dishes;
        return;
      }
      this.fetchingDishes = false;
      this.error = true;
    },
    mapHeadquaterSelected(headquater) {
      if (this.showCreateDishModal) {
        headquater.selected = false;
      } else {
        headquater.selected = headquater.id === this.headquatersSelected.id;
      }
      return headquater;
    },
    handleDeleteDish(id = "") {
      const index = this.dishes.map(item => item.id).indexOf(id);
      this.dishes.splice(index, 1);
      this.success = true;
      this.dish = {};
    },
    handleEditDishSuccess(changes = {}) {
      const index = this.dishes.map(item => item.id).indexOf(changes.id);
      this.dishes[index] = { ...this.dishes[index], ...changes };
      this.dish = this.dishes[index];
      const isAvailableAtHeadquaterSelected = this.dish.availableAt.hasOwnProperty(this.headquatersSelected.id);
      if (!isAvailableAtHeadquaterSelected) {
        this.dishes.splice(index, 1);
        this.dish = {};
      }
      this.success = true;
    },
    handleAddDishSuccess(dish) {
      this.dishes.push(dish);
      this.success = true;
    },
    mapItemData(data) {
      return data.map(item => {
        item.title = item.name;
        item.subtitle = `$${filterMoney(item.price)}`;
        item.available = item.status === "available";
        return item;
      });
    },
    resolveChange() {
      const operations = [];
      const activeChange = this.active !== this.dish.workingAt[this.headquatersSelected.id];
      const detailSelected = this.$refs.detailsSelectHeadquater.selected.id;
      const changeHeadquater = detailSelected !== this.headquatersSelected.id;
      if (activeChange) {
        if (this.active === true) operations.push({ type: "setActive" });
        if (this.active === false) operations.push({ type: "setUnactive" });
      }
      if (changeHeadquater) operations.push({ type: "addHeadquater", headquater: detailSelected });
      if (this.suspend === true) operations.push({ type: "suspend" });
      return operations;
    },
    mapOpeationsToRequests(operations) {
      const { headquatersSelected, dish } = this;
      return operations
        .map(operation => {
          operation.employee = dish.id;
          if (operation.type !== "addHeadquater") operation.headquater = headquatersSelected.id;
          return operation;
        })
        .map(operation => {
          const request = requests.updateEmployeeFromHeadquater(operation);
          request.then(response => this.handleResponseOperation(operation, response)).catch(error => this.handleFailOperation(operation, error));
          return request;
        });
    },
    handleResponseOperation(operation, response) {
      const { type = "", employee, headquater } = operation;
      if (response.ok === true) {
        const currentEmployee = this.employees.find(item => item.id === employee);
        if (type === "setActive") {
          currentEmployee.workingAt[headquater] = true;
        }
        if (type === "setUnactive") {
          currentEmployee.workingAt[headquater] = false;
        }
        if (type === "suspend") {
          currentEmployee.suspend = true;
        }
        return;
      }
      this.error = true;
    },
    handleFailOperation() {
      this.error = true;
    },
    async saveEmployeeChanges() {
      let operations = this.resolveChange();
      if (operations.length) {
        operations = this.mapOpeationsToRequests(operations);
        try {
          const results = await Promise.all(operations);
          const allOperationSuccess = results.every(result => result.ok === true);
          this.$refs.saveAndDeleteCombo.saving = false;
          if (allOperationSuccess) {
            this.success = true;
          } else {
            this.error = true;
          }
        } catch {
          this.error = true;
        }
      } else {
        this.$refs.saveAndDeleteCombo.saving = false;
      }
    },
    async deleteEmployeeFromHeadquaterSelected() {
      const { headquatersSelected, dish } = this;
      const { ok } = await surePromise(
        requests.updateEmployeeFromHeadquater({
          employee: dish.id,
          headquater: headquatersSelected.id,
          type: "leaveHeadquater"
        })
      );
      if (ok) {
        this.$refs.saveAndDeleteCombo.deleting = false;
        this.success = true;
        this.dishes = this.employees.filter(employee => employee.id !== dish.id);
        this.dish = {};
        return;
      }
      this.$refs.saveAndDeleteCombo.deleting = false;
      this.error = true;
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    async getDishesByHeadquater(headquater) {
      if (headquater) {
        /* eslint-disable prefer-const */
        let { ok, result, error } = await surePromise(
          requests.fetchDishes({
            availableAt: headquater.id
          })
        );
        if (ok) {
          result = result.map(item => {
            if (item.image) item.image = item.image;
            return item;
          });
          return Promise.resolve(result);
        }
        return Promise.reject(error);
      }
      return [];
    },
    setHeadquaterSelected(headquater) {
      this.headquaters[this.headquatersSelected.id].selected = false;
      this.headquatersSelected = headquater;
      this.headquaters[headquater.id].selected = true;
    },
    async handleSelectHeadquater(event) {
      this.loading = true;
      const headquater = event.detail;
      const { headquaterSelect } = this.$refs;
      this.setHeadquaterSelected(headquater);
      this.dishes = [];
      this.dish = {};
      headquaterSelect.selected = headquater;
      headquaterSelect.selectedByDefault = headquater;
      this.fetchingDishes = true;
      this.headquater = headquater;
      await this.fetchMenu();
      this.currentOrder = {};
      this.currentClient = {};
      this.setSubscription();
    },
    handleSelectedItem(item) {
      this.dish = { ...item };
    }
  }
};
</script>
