import { BASE } from "./index";

const fetchUserByBatch = (payload = {}) => {
  const token = window.localStorage.getItem("token");
  const url = `${BASE}/users/batch`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ ...payload }),
    headers: {
      "content-type": "application/json",
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default fetchUserByBatch;
