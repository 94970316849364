import Vue from 'vue';
import STORE from '@/store/types';

export default Vue.extend({
  name: 'UserPanoramicsModel',

  components: {
    PanoramicVideo: () => import('@components/generals/MediaDescription/PanoramicVideo')
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    getRenderPanoramicStore() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_RENDER_PANRAMICS];
    },

    getPanoramicsStore() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_PANORAMICS];
    }
  },

  methods: {
    closeModal() {
      this.$emit('closeModalPanoramics');
    }
  }
});
