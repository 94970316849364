import { BASE } from "./index";
const updateProfile = data => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/profile/update`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res.json());
    return res.json();
  });
};
export default updateProfile;
