<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import theme from "@/theme.js";

export default {
  name: "SearchInput",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: ""
    },
    valid: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    change: {
      type: Function,
      default() {}
    },
    onEnter: {
      type: Function,
      default() {}
    }
  },
  data() {
    const result = {
      value: "",
      primary: theme.primary,
      notfound: false,
      showFilter: false,
      shouldHideFilter: true,
      typingTimer: null,
      doneTypingInterval: 2000
    };
    if (this.defaultValue) result.value = this.defaultValue;
    return result;
  },
  watch: {
    value(val) {
      this.notfound = false;
      this.$emit("change", val);
    },
    defaultValue(val) {
      this.value = val;
    }
  },
  mounted() {
    /*
      let user = {};
      if(window.localStorage.getItem('user')) JSON.parse(window.localStorage.getItem('user'));
      if (user && user.photoURL) {
        const avatarIcon = this.$el.querySelector('.v-input__icon--prepend-inner > .v-icon');
        avatarIcon.style['background-image'] = filterIcon;
      }
      */
  },
  methods: {
    handleDisplayFilter() {
      console.log("click display filter");
      this.showFilter = !this.showFilter;
    },
    hideFilter() {
      if (this.showFilter) this.showFilter = false;
    },
    doneTyping() {
      this.$emit("doneTyping", this.value);
    },
    clearTypingInterval() {
      window.clearTimeout(this.typingTimer);
    },
    startCountDown() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.doneTyping, this.doneTypingInterval);
    },
    handleChange(payload) {
      this.$emit("input-change", payload);
    },
    onClearInput() {
      console.log("clear");
      this.value = "";
      // this.showFilter = false;
      this.$emit("doneTyping", this.value);
    }
  }
};
</script>
