<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import DeliveryBoard from "@/components/generals/DeliveryBoard";
import { notifyEvent } from "@/utils/notifyEvent";
import mixins from "@/mixins";
const { DateManager, MenuMixin } = mixins;

export default {
  name: "AdminBusiness",
  components: {
    Loading,
    ReservationsComboTables,
    DeliveryBoard
  },
  mixins: [DateManager, MenuMixin],
  data() {
    const headquater = this.getHeadquaterSelectedFromUserProfile();
    return {
      loading: false,
      showOverlay: false,
      headquater,
      headquaterSelected: {}
    };
  },
  async mounted() {
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
    this.loading = true;
    await this.initialize();
    await this.$nextTick();
    this.loading = false;
  },
  created() {
  },
  beforeDestroy() {
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  computed: {
  },
  methods: {
    async initialize() {
      this.headquater = this.getHeadquaterSelectedFromUserProfile();
      this.headquaterSelected = this.headquater;
    },
    async handleSelectHeadquater() {
      this.loading = true;
      await this.initialize();
      await this.$nextTick();
      this.loading = false;
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
      notifyEvent({ name: "overlay", detail: this.showOverlay });
    }
  }
};
</script>
