<template>
  <div :class="[{ 'gap': hasIcon }, origin]" class="item-container" @click="handleClick">
    <span :class="`icon is-${icon}`" class="item-container__icon"></span>
    <span class="item-container__title">{{ title }}</span>
    <span class="item-container__subtitle">{{ subtitle }}</span>
    <span class="item-container__table" v-if="table">Mesa: {{ table }}</span>
    <span class="item-container__date">{{ date | formatDate }}</span>
    <v-btn @click="assignWaiterFromNotification(id)" v-if="!hasWaiter" class="item-container__button" flat
           color="primary"
    >
      Asignar un mesero
    </v-btn>
    <span v-if="hasArrow" class="item-container__arrow"></span>
  </div>
</template>

<script>
  import moment from "moment";

  moment.locale("es");

  export default {
    name: "NotificationItem",
    props: {
      origin: {
        type: String,
        default: ""
      },
      id: {
        type: String,
        default: ""
      },
      hasWaiter: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: () => ""
      },
      title: {
        type: String,
        default: () => ""
      },
      subtitle: {
        type: String,
        default: () => ""
      },
      date: {
        type: Date,
        default: () => ""
      },
      table: {
        type: String,
        default: () => ""
      },
      idTable: {
        type: String,
        default: () => ""
      },
      hasArrow: {
        type: Boolean,
        default: true
      },
      assignWaiter: {
        type: Function,
        default: () => {
        }
      }
    },
    filters: {
      formatDate(date) {
        if (date) {
          return moment(date).fromNow();
        }
        return "";
      }
    },
    computed: {
      hasIcon() {
        return this.icon !== "";
      }
    },
    methods: {
      assignWaiterFromNotification(id) {
        this.assignWaiter(id, this.idTable);
      },
      handleClick(event) {
        this.$emit("select", event);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .item-container {
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    min-height: 52px;
    display: grid;
    grid-template-columns: 20px 1fr 15px;
    width: calc(100% - 10px);
    font-family: Gibson-Bold;
    font-size: 15px;
    margin: 0px auto;
    margin-bottom: 20px;
    transition: all 0.25s;

    &:hover {
      box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.2);
    }

    &__title {
      grid-area: 1/2;
      padding-top: 6px;
      color: #5a5a5a;
      line-height: 1.2;
    }

    &__subtitle {
      grid-area: 2/2;
      padding-top: 6px;
      color: #5a5a5a;
      line-height: 1.2;
      font-size: 12px;
      font-weight: normal;
    }

    &__date {
      grid-area: 3/2;
      padding-top: 6px;
      color: gray;
      line-height: 1.2;
      font-size: 12px;
      font-weight: 400 !important;
    }

    &__table {
      padding-top: 6px;
      grid-area: 4/2;
      line-height: 1.2;
      font-size: 12px;
    }

    &__button {
      padding-top: 6px;
      grid-area: 5/2;
      line-height: 1.2;
      font-size: 12px;
    }

    &__arrow {
      width: 12px;
      height: 8px;
      background-image: url("~@assets/icons/arrow-next-gray.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      padding-top: 30px;
    }
  }

  .icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;

    &.is-setting {
      background-image: url("~@assets/icons/setting.svg");
    }

    &.is-headquarters {
      background-image: url("~@assets/icons/headquarters.svg");
    }

    &.is-pay {
      background-image: url("~@assets/icons/pay.svg");
    }

    &.is-security {
      background-image: url("~@assets/icons/security.svg");
      background-size: 14px;
    }
  }

  .gap {
    grid-gap: 8px;
  }

  .mesa {
    background: #ed2236;
    color: white;

    span {
      color: white;
    }

    button {
      background: white !important;
      height: 30px;
      width: 150px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .qrReader {
    background: #ff9800;
    color: white;

    span {
      color: white;
    }

    //#ff9800
  }

</style>
