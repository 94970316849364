import Vue from 'vue';

import moment from 'moment';
import PersonsAndTimeForm from "@components/forms/PersonsAndTimeForm.vue";
import BasicInput from "@inputs/BasicInput";
import validators from "@utils/validators";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import STORE from '@/store/types';

const { validName, validText, validCellPhone } = validators;
const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export default Vue.extend({
  name: "ReservationMenu",
  components: {
    BasicInput,
    PersonsAndTimeForm
  },
  updated() {
    if (this.$refs.datePicker) {
      this.dateTitle = this.$refs.datePicker.genPickerTitle().data.props.date;
    }
  },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: 'newReservation'
    }
  },
  data() {
    return {
      success: false,
      error: false,
      startFromNow: false,
      values: {
        today: "",
        name: "",
        phone: "",
        hour: "",
        date: "",
        persons: "",
        note: ""
      },
      validity: {
        name: false,
        phone: false,
        note: false,
        hour: false,
        persons: false
      },
      loading: false,
      menu: false,
      dateTitle: "",
      isToday: false,
      dayweek: weekDays[new Date().getDay() - 1]
    };
  },
  computed: {
    isValidName() {
      return validName(
        this.values.name ||
          this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.name
          );
    },
    isValidPhone() {
      return validCellPhone(
        this.values.phone ||
        this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.user.phone
        );
    },
    valuesReservation: {
      get() {
        return {
          name: this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.name ?
            this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.name :
            this.values.name,
          phone: this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.user.phone ?
            this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.user.phone :
            this.values.phone
        };
      }
    },
    isValid() {
      return !!(this.isValidName && this.isValidPhone && this.values.persons && this.values.hour);
    },
    handleLabelSubmit() {
      return (this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].mode === 'updateReservation') ? 'Actualizar reserva' : 'Añadir reserva';
    },
    isActiveModal: {
      get() {
        return (this.menu || this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].isActive);
      },
      set(newValue) {
        this.menu = newValue;
        this.$store.commit(STORE.MUTATIONS.RESERVATION_DETAIL.SET_RESERVATION, {
          detail: {
            user: {}
          },
          isActive: false
        });
      }
    },
    calculateDate: {
      get() {
        return {
          date: this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.dateTimeStamp ?
            moment(this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.dateTimeStamp.seconds * 1000).format('YYYY-MM-DD') :
            this.values.date,
          today: this.values.today
        };
      },
      set(newVal) {
        this.values.date = !newVal ?
          moment(this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.dateTimeStamp.seconds * 1000).format('YYYY-MM-DD') :
          newVal;
      }
    },
    detailReservation() {
      return this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail;
    }
  },
  watch: {
    detailReservation(val) {
      if (val.hour) {
        this.$refs.personsAndTime.displayTime = val.hour;
        this.$refs.personsAndTime.persons = val.persons;
        this.values.persons = val.persons;
        this.values.hour = val.hour;
        this.values.phone = val.phone;
        this.values.name = val.name;
      }
    }
  },
  mounted() {
    this.calculateHour();
    this.calculateDates();
    window.addEventListener("overlay", this.handleOverlayChange);
  },
  beforeDestroy() {
    window.removeEventListener("overlay", this.handleOverlayChange);
  },
  methods: {
    calculateDates() {
      this.values.date = new Date();
      this.values.date = this.toLocalDateString(this.values.date);
      this.values.today = new Date();
      this.values.today = this.toLocalDateString(this.values.today);
    },
    calculateHour() {
      const daytime = this.getDayTime();
      const { schedule = {} } = this.headquater;
      const hasDayweek = schedule.hasOwnProperty(this.dayweek);
      if (this.isToday) {
        if (hasDayweek) {
          const { from = "" } = schedule[this.dayweek][daytime];
          const scheduleHour = this.convert12To24(from).join(":");
          const now = new Date();
          const currentHour = `${now.getHours()}:${this.formatTwoDigitsMinutes(now.getMinutes())}`;
          this.startFromNow = this.timeAGreatteThanTimeB(currentHour, scheduleHour);
        }
      } else {
        this.startFromNow = false;
      }
    },
    formatTwoDigitsMinutes(minutes) {
      const min = Number(minutes);
      if (min < 10) return `0${min}`;
      return min;
    },
    convert12To24(time = "") {
      const plainTime = time
        .replace("pm", "")
        .replace("am", "")
        .split(":");
      const pm = time.includes("pm");
      if (pm) {
        plainTime[0] = `${Number(plainTime[0]) + 12}`;
      }
      if (pm === false) {
        plainTime[0] = `0${plainTime[0]}`;
      }
      return plainTime;
    },
    formatHourAnMinutes(time) {
      let result = "";
      const plainTime = this.convert12To24(time);
      result = `${plainTime[0]}:${plainTime[1]}`;
      return result;
    },
    handleOverlayChange() {
      this.menu = false;
    },
    timeAGreatteThanTimeB(a, b) {
      const dateA = new Date();
      const dateB = new Date();
      dateA.setHours(a.split(":")[0]);
      dateA.setMinutes(a.split(":")[1]);
      dateB.setHours(b.split(":")[0]);
      dateB.setMinutes(b.split(":")[1]);
      return dateA > dateB;
    },
    getDayTime() {
      const today = new Date();
      const curHr = today.getHours();
      if (curHr < 12) return "morning";
      if (curHr < 18) return "afternoon";
      return "evening";
    },
    convertStringTotDate(fulldate, time) {
      const day = fulldate.split("-")[2];
      const [hour, minute] = this.convert12To24(time);
      const date = new Date(fulldate);
      date.setDate(day);
      date.setHours(hour);
      date.setMinutes(minute);
      return date;
    },
    async submitForm() {
      this.loading = true;
      const { hour, persons, date, note, name, phone } = this.values;
      const data = {
        user: { name, phone },
        hour,
        persons,
        date: this.convertStringTotDate(date, hour)
      };
      if (note) data.user.note = note;
      data.belongsTo = this.headquater.id;
      data.name = this.headquater.name;
      data.dateDisplay = this.dateTitle;

      const { ok, error, result } = (this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].mode === 'updateReservation') ?
        await surePromise(requests.updateReservation(
          this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].detail.id, data)) :
        await surePromise(requests.createReservation(data));
      this.loading = false;
      if (ok) {
        console.log({ result });
        this.reset();
        this.success = true;
      } else {
        console.log({ error });
        this.error = true;
      }
    },
    reset() {
      let date = new Date();
      date = this.toLocalDateString(date);
      let today = new Date();
      today = this.toLocalDateString(today);
      this.success = false;
      this.error = false;
      this.values = {
        today,
        name: "",
        phone: "",
        date,
        persons: "",
        note: ""
      };
      this.validity = {
        name: false,
        phone: false,
        note: false,
        hour: false,
        persons: false
      };
      this.loading = false;
      this.menu = false;
      this.dateTitle = "";
      this.isToday = false;
      this.startFromNow = false;
      this.dayweek = weekDays[new Date().getDay() - 1];
      if (this.$refs.personsAndTime) this.$refs.personsAndTime.reset();
      this.isActiveModal = false;
    },
    handleFieldChange(name, value) {
      const isName = name === "name";
      const isNote = name === "note";
      const isPhone = name === "phone";
      if (isName) {
        this.validity.name = validName(value);
        this.values.name = value;
        this.$store.commit(STORE.MUTATIONS.RESERVATION_DETAIL.SET_DETAIL_NAME, value);
      }
      if (isNote) {
        this.validity.note = validText(value);
        this.$store.commit(STORE.MUTATIONS.RESERVATION_DETAIL.SET_DETAIL_NOTE, value);
        this.values.note = value;
      }
      if (isPhone) {
        this.validity.phone = validCellPhone(value);
        this.$store.commit(STORE.MUTATIONS.RESERVATION_DETAIL.SET_DETAIL_PHONE, value);
        this.values.phone = value;
      }
    },
    sameDate(a, b) {
      const plainA = a.split("-");
      const plainB = b.split("-");
      const sameYear = plainA[0] === plainB[0];
      const sameMonth = plainA[1] === plainB[1];
      const sameDay = plainA[2] === plainB[2];
      return sameYear && sameMonth && sameDay;
    },
    allowedDates(val) {
      const daysAllowed = this.headquater.schedule || {};
      const currentDate = new Date(val);
      const allow = daysAllowed.hasOwnProperty(weekDays[currentDate.getDay()]);
      return allow;
    },
    toLocalDateString(date) {
      const currentMonth = date.getMonth();
      const month = String(currentMonth).length === 1 && currentMonth < 9 ? `0${currentMonth + 1}` : currentMonth + 1;
      const day = String(date.getDate()).length === 1 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}-${month}-${day}`;
    },
    handleChangePersons(val) {
      this.values.persons = String(val);
      this.validity.persons = true;
    },
    handleChangeHour(val) {
      this.values.hour = String(val);
      this.validity.hour = true;
    },
    handleSelectReservDate(event) {
      this.values.date = event;
      if (this.$store.getters[STORE.GETTERS.RESERVATION_DETAIL.GET_RESERVATION].mode === 'updateReservation') {
        this.$store.commit(STORE.MUTATIONS.RESERVATION_DETAIL.SET_DETAIL_TIMESTAMP, Number(moment(event).format('X')));
      }
      const { date } = this.values;
      this.isToday = this.sameDate(this.toLocalDateString(new Date()), date);
      this.values.dayweek = weekDays[new Date(date).getDay()];
      this.dayweek = this.values.dayweek;
      this.$refs.personsAndTime.dayweek = this.values.dayweek;
      this.$refs.personsAndTime.updateVariables(this.headquater.schedule);
      this.calculateHour();
    }
  }
});
