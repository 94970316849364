<template>
  <div>
    <v-dialog v-model="open" width="500" persistent>
      <section class="edit-modal">
        <div class="edit-modal__header">
          <span>{{ caption }}</span>
          <v-btn icon class="edit-modal__header-close" :disabled="loading" @click="handleClose"></v-btn>
        </div>
        <TableForm
          :id="data.id"
          ref="tableForm"
          :mode="mode"
          :tables="tables"
          :display="open"
          :name="data.name || ''"
          :belongs-to="belongsTo"
          :location="String(data.location || '')"
          :chairs="String(data.chairs || '')"
          @loading="loading = $event"
          @success="handleOperationSuccess"
          @error="error = $event"
          :btn-text-agree="btnTextAgree"
        ></TableForm>
      </section>
    </v-dialog>

    <v-snackbar v-model="error" color="error" :bottom="true" :left="true" :timeout="2500">Ocurrio un error intenta mas tarde.</v-snackbar>

    <v-snackbar v-model="success" color="success" :bottom="true" :left="true" :timeout="2500">Operacion completada con exito.</v-snackbar>
  </div>
</template>

<script>
import TableForm from "@components/forms/TableForm";

export default {
  name: "TableModal",
  components: { TableForm },
  props: {
    belongsTo: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: "create"
    },
    display: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    tables: {
      type: Array,
      default: () => []
    },
    btnTextAgree: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: this.display,
      loading: false,
      error: false,
      success: false
    };
  },
  watch: {
    display(val) {
      this.open = val;
    },
    open(val) {
      const isClose = val === false;
      if (isClose) this.$emit("close");
    }
  },
  methods: {
    handleClose() {
      this.open = false;
      if (this.$refs.tableForm) this.$refs.tableForm.resetForm();
    },
    handleOperationSuccess(event) {
      this.success = event;
      if (this.$refs.tableForm) this.$refs.tableForm.resetForm();
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog--active {
  border-radius: 12px !important;
}
.edit-modal {
  background: white;
  padding: 30px;
}
.edit-modal__header {
  font-size: 25px;
  font-family: Futura;
  color: #161f2f;
  padding-left: 15px;
}
.edit-modal__header-close {
  background-image: url("~@assets/icons/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  float: right;
  position: relative;
  top: -24px;
  right: -24px;
}
</style>
