<style scoped lang="scss">
  @import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
  import VClamp from "vue-clamp";
  import TableOptionsMenu from "@/components/menus/TableOptionsMenu";
  import ClickOutside from "vue-click-outside";

  export default {
    name: "TableCard",
    components: { VClamp, TableOptionsMenu },
    directives: {
      ClickOutside
    },
    props: {
      dark: {
        type: Boolean,
        default: false
      },
      table: {
        type: Object,
        default: () => ({})
      },
      headquater: {
        type: String,
        default: ""
      },
      status: {
        type: String,
        default: ""
      },
      mode: {
        type: String,
        default: ""
      },
      locations: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
      filters: {
      getActiveWaiter(actives) {
        const keys = Object.keys(actives);
        const waiter = keys.find(key => Boolean(actives[key]));
        const { name = "" } = actives[waiter];
        return name;
      },
      translateStatus(status) {
        const isBusy = status === "BUSY";
        const isFree = status === "FREE";
        const isReserved = status === "RESERVED";
        if (isBusy) return "Ocupada";
        if (isFree) return "Libre";
        if (isReserved) return "Reservada";
        return "";
      }
    },
    watch: {
      loading(val) {
        this.spinner = val;
      }
    },
    data() {
      return {
        currentOrder: {},
        spinner: false,
        displayOptions: false
      };
    },
    created() {
      const { loading = false } = this;
      this.spinner = loading;
    },
    computed: {
      isLinked() {
        return this.table.links.hasOwnProperty(this.table.id) && this.table.links[this.table.id] === true;
      },
      calculateTotalValue() {
        return this.table.currentOrder.price ? this.table.currentOrder.price : 0;
      },
      getLikesNames() {
        return Object.keys(this.table.links)
          .map(link => this.populateLink(link))
          .map(link => (link ? link.name : ""))
          .join();
      },
      getNames() {
        const isLinked = this.table.links.hasOwnProperty(this.table.id) && Object.keys(this.table.links).length !== 0;
        if (isLinked) {
          const { locations = {} } = this;
          const allLinkedTables = Object.keys(this.table.links).filter(link => this.filterLinkWithCurrentTable(link, this.table));
          const links = [this.table.id, ...allLinkedTables];
          const tables = Object.values(locations)
            .map(location => [...location.items])
            .flat();
          const first = tables.find(table => table.id === links[0]);
          const last = tables.find(table => table.id === links[links.length - 1]);
          if (first && last) return `${first.name}/${last.name}`;
          if (first) return `${first.name}`;
          if (last) return `${last.name}`;
        }
        return this.table.name;
      },
      isFree() {
        return this.table.status === "FREE";
      },
      hasPrice() {
        return this.table.currentOrder && this.table.currentOrder.hasOwnProperty("price");
      },
      hasWaiter() {
        return Object.keys(this.table.activesWaiters).some(key => Boolean(this.table.activesWaiters[key]));
      },
      hasClient() {
        if (this.hasWaiter && typeof this.table.currentClient !== "string") {
          return this.table.currentClient.name;
        }
        return "";
      },
      hasPendingOrder() {
        return this.hasPrice && this.table.currentOrder.hasOwnProperty("dishes");
      },
      isBusy() {
        return this.table.status === "BUSY";
      },
      busyWithoutOrder() {
        return !this.table.currentOrder && this.table.status === "BUSY";
      }
    },
    methods: {
      filterLinkWithCurrentTable(link, table) {
        return table.id !== link;
      },
      populateLink(id = "") {
        const { locations = {} } = this;
        const tables = Object.values(locations)
          .map(location => [...location.items])
          .flat();
        return tables.find(table => table.id === id);
      }
    }
  };
</script>
