const state = {
  albums: [],
  panoramics: [],
  renderPanoramic: true,
  modalFileUpdate: {
    show: false,
    dataUpdate: {
      id: '',
      urlImage: '',
      title: '',
      description: '',
      typeUpdate: ''
    }
  }
};

export default state;
