<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import Order from "@components/steppers/Order";
import DishesGrid from "@components/generals/DishesGrid";
import DishCategories from "@components/Headers/DishCategories";
import DishGridFooter from "@components/footers/DishGridFooter";
import calculatePrice from "@/utils/calculatePrice";
import uuid from "@utils/uuid";

export default {
  name: "OrderForm",
  components: { Order, DishesGrid, DishCategories, DishGridFooter },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: () => ({
        iva: {},
        dishes: {},
        price: 0
      })
    }
  },
  data() {
    return {
      step: 1,
      footerKey: uuid(),
      drinks: "9c4e239e-6e8e-4447-937e-53a234d038e8",
      dessert: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
      main: "32ceb392-f5ae-4811-ba97-e43539a69a7a",
      category: "",
      lang: "es",
      allowToContinue: false,
      loading: false,
      error: false
    };
  },
  computed: {
    backText() {
      const step = Number(this.step);
      const back = step === 2 || step === 3;
      const cancel = step === 1;
      if (cancel) return "Cancelar";
      if (back) return "Atras";
      return "";
    },
    nextText() {
      const step = Number(this.step);
      const end = step === 3;
      const next = step === 1 || step === 2;
      if (next) return "Continuar";
      if (end) return "Finalizar pedido";
      return "";
    }
  },
  watch: {
    step() {
      this.resolveDishStep(this.step);
    }
  },
  mounted() {
    this.catergorie = this.main;
    this.resolveDishStep(this.step);
  },
  methods: {
    handleDishChange(event) {
      const { id = "" } = event;
      const { dishes = {} } = this.order;
      this.order.dishes[id] = { ...dishes[id], ...event };
      if (dishes[id] && dishes[id].amount === 0) {
        delete this.order.dishes[id];
      }
      this.order.price = calculatePrice(this.order.dishes, this.order);
      this.$emit("order", this.order);
      const shouldResetBilling = Object.keys(dishes).length === 0;
      if (shouldResetBilling) {
        this.$emit("resetBilling");
      }
      this.footerKey = uuid();
    },
    resolveDishStep(val) {
      let result = "";
      const isMain = Number(val) === 1;
      const isDrink = Number(val) === 2;
      const isDesset = Number(val) === 3;
      if (isMain) result = "Platos fuertes";
      if (isDrink) result = "Bebidas";
      if (isDesset) result = "Postres";
      this.$emit("step", result);
    },
    filterByCategory(list, category) {
      return list.filter(item => item.categories[category]);
    },
    handleNext() {
      const step = Number(this.step);
      if (step === 3) {
        this.$emit("modal");
      }
      const isAllow = step < 3;
      if (isAllow) this.step = step + 1;
    },
    handleBack() {
      const step = Number(this.step);
      const isAllow = step > 1;
      if (isAllow) this.step = step - 1;
    }
  }
};
</script>
