import Vue from 'vue';

export default Vue.extend({
  name: 'Quationstable',
  data: () => ({
    body: [{
      isChecked: false,
      name: 'Alexander',
      date: 'Enero 22/2020',
      phone: '3172935583'
    }],
    headers: [
      {
        text: "Nobres de archivo",
        align: "left",
        sortable: false,
        value: "name"
      },
      { text: "Fecha", value: "phone", align: "left" },
      { text: "Número de contacto", value: "email", align: "left" },
      { text: "Administracions", value: "lastVisit", align: "left" }
    ]
  }),
  methods: {
    goNewQuotation() {
      this.$router.push({
        name: 'new'
      });
    }
  }
});
