const MenuMixin = {
  computed: {
    isMiniMenu() {
      return this.$store.getters['menu/getStateMini'];
    },
    isActiveMenu() {
      return this.$store.getters['menu/getStateMenu'];
    }
  }
};

export default MenuMixin;
