import buildUrlQuery from "@utils/buildUrlQuery.js";
import { BASE } from "./index";
const fetchHeadquater = (id, query = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const params = buildUrlQuery(query);
  const url = `${BASE}/headquater/${id}?${params}`;
  return fetch(url, {
    method: "GET",
    headers: { token }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default fetchHeadquater;
