<style scoped lang="scss">
  @import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
  import BasicInput from "@inputs/BasicInput";
  import FileUploadInput from "@inputs/FileUploadInput";
  import validators from "@utils/validators";
  import DescriptionByLanguage from "@components/inputs/DescriptionByLanguage";
  import CategoriesModal from "@components/modals/CategoriesModal";
  import SaveAndDeleteCombo from "@components/generals/SaveAndDeleteCombo";
  import HeadquatersModal from "@components/modals/HeadquatersModal";
  import LocationMapModal from "@components/modals/LocationMapModal";
  import ScheduleTime from "@components/generals/ScheduleTime";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";

  export default {
    name: "HeadquaterForm",
    components: {
      BasicInput,
      SaveAndDeleteCombo,
      LocationMapModal,
      ScheduleTime,
      DescriptionByLanguage,
      FileUploadInput,
      CategoriesModal,
      HeadquatersModal
    },
    props: {
      headquaters: {
        type: Array,
        default: () => []
      },
      mode: {
        type: String,
        default: "create"
      },
      data: {
        type: Object,
        default: () => ({
          paypal: { clientId: "" },
          payu: {
            accountId: "",
            merchantId: "",
            apiKey: ""
          }
        })
      }
    },
    data() {
      return {
        location: {},
        displayLocationModal: false,
        fetchingCategories: false,
        loading: false,
        showHeadquatersModal: false,
        showCategories: false,
        covidFree: false,
        paypal: {
          clientId: ""
        },
        domicile: 0,
        payu: {
          accountId: "",
          merchantId: "",
          apiKey: ""
        },
        validity: {
          name: this.mode === "edit",
          whatsapp: this.mode === "edit",
          phone: this.mode === "edit",
          address: this.mode === "edit",
          facebook: this.mode === "edit",
          website: this.mode === "edit",
          instagram: this.mode === "edit",
          description: false,
          image: false
        },
        changes: {
          name: this.data.name || "",
          whatsapp: this.data.whatsapp || "",
          address: this.data.address || "",
          facebook: this.data.facebook || "",
          website: this.data.website || "",
          instagram: this.data.instagram || "",
          phone: this.data.phone || "",
          categories: this.data.categories || []
        },
        nameInvalidMsg: "Escribe un nombre valido"
      };
    },
    computed: {
      shortCoordinates() {
        const { _latitude, _longitude } = this.data.l || {};
        return { lat: _latitude, lng: _longitude };
      },
      allFieldsValids() {
        const optionals = ["website", "instagram", "facebook", "whatsapp"];
        return Object.keys(this.validity)
          .filter(item => !optionals.includes(item))
          .every(field => this.validity[field] === true);
      }
    },
    watch: {
      data(val) {
        const fields = Object.keys(val);
        fields.forEach(field => {
          const inValidation = this.validity.hasOwnProperty(field);
          const inChanges = this.changes.hasOwnProperty(field);
          if (inValidation) this.validity[field] = true;
          if (inChanges) this.changes[field] = val[field];
        });
        const { descriptionField, headquaterLogo, headquaterBanner } = this.$refs;
        descriptionField.reset();
        headquaterLogo.reset();
        headquaterBanner.reset();
      }
    },
    methods: {
      handleConfirmLocation(location) {
        const { lat, lng } = location;
        this.location = { lat, lng };
      },
      handleDisplayLocation() {
        this.displayLocationModal = true;
      },
      deleteHeadquater() {
        console.log("delete headquater");
      },
      handleFileUploadChange(file) {
        this.validity.image = Boolean(file);
      },
      handleSave() {
        const createMode = this.mode === "create";
        if (createMode) return this.createHeadquater();
        this.saveChanges();
      },
      async createHeadquater() {
        this.loading = true;
        const logoFiles = this.$refs.headquaterLogo.getFiles();
        const bannerFiles = this.$refs.headquaterBanner.getFiles();
        const schedule = this.$refs.scheduleTime.getData();
        const hasLogoToUpload = logoFiles.length !== 0;
        const hasBannerToUpload = bannerFiles.length !== 0;
        const [restaurant] = window.user.restaurants || [];
        const { id: belongsTo = "" } = restaurant;
        const owner = window.user.id;
        const { location = {} } = this;
        const payload = { schedule, belongsTo, owner };
        if (hasLogoToUpload) payload.logo = await this.uploadImage({ file: logoFiles[0] });
        if (hasBannerToUpload) payload.banner = await this.uploadImage({ file: bannerFiles[0] });
        const locationChange = Object.keys(location).length !== 0;
        if (locationChange) {
          payload.l = {
            _latitude: Number(location.lat),
            _longitude: Number(location.lng)
          };
        }
        payload.name = this.changes.name;
        if (this.changes.address) payload.address = this.changes.address;
        if (this.changes.phone) payload.phone = this.changes.phone;
        if (this.changes.whatsapp) payload.whatsapp = this.changes.whatsapp;
        if (this.changes.instagram) payload.instagram = this.changes.instagram;
        if (this.changes.website) payload.website = this.changes.website;
        if (this.changes.facebook) payload.facebook = this.changes.facebook;
        payload.description = this.$refs.descriptionField.descriptions;
        payload.covidFree = this.data.covidFree;
        payload.paypal = this.data.paypal;
        payload.domicile = this.data.domicile;
        payload.payu = this.data.payu;
        payload.categories = this.changes.categories.map(category => category.id);
        payload.categories = payload.categories.reduce((accumulator, current) => {
          accumulator[current] = true;
          return accumulator;
        }, {});
        payload.profile = true;
        const { ok, result } = await surePromise(requests.saveHeadquater(payload));
        this.loading = false;
        this.$refs.saveAndDeleteCombo.saving = false;
        if (ok) {
          const newHeadquater = { ...payload, id: result.id };
          this.$emit("success", newHeadquater);
          this.$emit("close");
          window.dispatchEvent(new window.CustomEvent("newHeadquater", { detail: newHeadquater }));
        } else {
          this.$emit("error");
        }
      },
      handleDisplayCategory() {
        this.showCategories = true;
      },
      handleCloseCategoriesModal(categories) {
        this.showCategories = false;
        this.changes.categories = categories;
      },
      handleFieldChange(field, value) {
        this.changes[field] = value;
        if (value === "") this.validity[field] = false;
        if (field === "name") this.validity[field] = validators.validName(value);
        if (field === "price") this.validity[field] = validators.validNumber(value) && value !== "0";
        if (field === "address") this.validity[field] = validators.validAddress(value);
        if (field === "description") {
          const isValid = this.$refs.descriptionField.isValid();
          this.validity[field] = isValid;
        }
        if (field === "phone") this.validity[field] = validators.validPhone(value);
        if (field === "whatsapp") this.validity[field] = validators.validCellPhone(value);
        if (field === "website") this.validity[field] = validators.validUrl(value);
        if (field === "instagram") this.validity[field] = validators.validUrl(value);
        if (field === "facebook") this.validity[field] = validators.validUrl(value);
      },
      async handleDeleteHeadquater() {
        const { id = "" } = this.data;
        const { ok, result, error } = await surePromise(requests.deleteHeadquater(id));
        this.loading = false;
        this.$refs.saveAndDeleteCombo.deleting = false;
        const success = ok && result.ok === true;
        if (success) return this.$emit("deleted", id);
        const isBusy = error && error.status === 406;
        if (isBusy) return this.$emit("busy");
        this.$emit("error");
      },
      getDifferences() {
        const result = {};
        const { name = "", address = "", phone = "", whatsapp = "", instagram = "", facebook = "", website = "" } = this.data;
        const { location = {} } = this;
        if (name !== this.changes.name) result.name = this.changes.name;
        if (address !== this.changes.address) result.address = this.changes.address;
        if (phone !== this.changes.phone) result.phone = this.changes.phone;
        if (whatsapp !== this.changes.whatsapp) result.whatsapp = this.changes.whatsapp;
        if (instagram !== this.changes.instagram) result.instagram = this.changes.instagram;
        if (facebook !== this.changes.facebook) result.facebook = this.changes.facebook;
        if (website !== this.changes.website) result.website = this.changes.website;
        if (this.changes.categories.length) {
          result.categories = this.changes.categories.map(category => category.id);
          result.categories = result.categories.reduce((accumulator, current) => {
            accumulator[current] = true;
            return accumulator;
          }, {});
        }
        const locationChange = Object.keys(location).length !== 0;
        if (locationChange) {
          result.l = {
            _latitude: location.lat,
            _longitude: location.lng
          };
        }
        const description = this.$refs.descriptionField.getDifferences();
        if (Object.keys(description).length) result.description = description;
        return result;
      },
      async uploadImage(payload) {
        const { file } = payload;
        const storageRef = window.firebase.storage().ref(`images/Headquater/${file.name}`);
        await storageRef.put(file);
        const BASE = `${window.fileStoreURL}/images%2FHeadquater`;
        const filename = encodeURIComponent(file.name.trim());
        return Promise.resolve(`${BASE}%2F${filename}?alt=media`);
      },
      async saveChanges() {
        this.loading = true;
        const schedule = this.$refs.scheduleTime.getData();
        const logoFiles = this.$refs.headquaterLogo.getFiles();
        const bannerFiles = this.$refs.headquaterBanner.getFiles();
        const hasLogoToUpload = logoFiles.length !== 0;
        const hasBannerToUpload = bannerFiles.length !== 0;
        let payload = { schedule };

        if (hasLogoToUpload) payload.logo = await this.uploadImage({ file: logoFiles[0] });
        if (hasBannerToUpload) payload.banner = await this.uploadImage({ file: bannerFiles[0] });

        const differences = this.getDifferences();
        payload = {
          ...differences,
          ...payload,
          covidFree: this.data.covidFree,
          paypal: this.data.paypal,
          domicile: this.data.domicile,
          payu: this.data.payu
        };
        const { ok, result, error } = await surePromise(requests.updateHeadquater(this.data.id, payload));
        this.loading = false;
        this.$refs.saveAndDeleteCombo.saving = false;
        const success = ok && result.ok === true;
        if (success) {
          this.$emit("success", { ...payload, id: this.data.id });
        } else if (error.status === 406) {
          this.$emit("busy");
        } else {
          this.$emit("error");
        }
      }
    }
  };
</script>
