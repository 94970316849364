<style scoped lang="scss">
::v-deep .theme--light.v-icon.v-icon--disabled {
  color: transparent !important;
}
::v-deep .v-messages__message {
  color: #ef4f34;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-style: italic;
  font-weight: bold;
}

::v-deep .v-input__icon--append {
  i {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('~@assets/icons/arrow-down.png');
    color: transparent !important;
    width: 20px;
    height: 20px;
  }
}

</style>
<template>
  <div>
    <v-container
      v-if="loading === true"
      class="text-xs-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-container>

    <v-select
      v-if="loading === false"
      v-model="selected"
      :disabled="disabled || error === true"
      :hint="errorMessage"
      :persistent-hint="error === true"
      :items="items"
      item-text="name"
      item-value="code"
      label="Selecciona un idioma"
      solo
    ></v-select>
  </div>
</template>

<script>
import requests from '@/helpers/requests';

export default {
  name: 'LanguageSelect',
  props: {
   disabled: {
     type: Boolean,
     default: false
   }
  },
  data() {
    const { lang = 'ES' } = window.user;
    return {
      loading: true,
      success: false,
      error: false,
      selected: lang
    };
  },
  computed: {
   errorMessage() {
      if (this.error === true) return 'Ocurrio un error en la carga de los idiomas';
      return '';
    }
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
    selected(val) {
      const lang = this.items.find(item => item.code === val);
      this.$emit('select', lang);
    }
  },
  created() {
    requests
      .fetchLanguages()
      .then(this.handleLanguageResponse)
      .catch(this.handleFetchLanguageFail);
  },
  methods: {
    getLanguageSelected() {
      return this.items.find(item => item.code === this.selected);
    },
    handleLanguageResponse(response) {
      this.loading = false;
      this.success = true;
      this.items = response;
    },
    handleFetchLanguageFail(error) {
      console.log('error ', error);
      this.loading = false;
      this.error = true;
    }
  }
};
</script>
