<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
export default {
  name: "PickerDate",
  props: {
    date: {
      type: String,
      default: () => {
        const date = new Date();
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) dt = `0${dt}`;
        if (month < 10) month = `0${month}`;
        return `${year}-${month}-${dt}`;
      }
    },
    confirmMessage: {
      type: String,
      default: "Ok"
    },
    cancelMessage: {
      type: String,
      default: "Cancelar"
    },
    disabledConfirmlBtn: {
      type: Boolean,
      default: false
    },
    disabledCancelBtn: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: String,
      default: ""
    },
    maxDate: {
      type: String,
      default: ""
    }
  },
  data() {
    return { value: null };
  },
  created() {
    this.value = this.date;
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>
