<style scoped lang="scss">
::v-deep .theme--light.v-icon.v-icon--disabled {
  color: transparent !important;
}

::v-deep .v-input__slot {
   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2) !important;
}

::v-deep .v-messages__message {
  color: #ef4f34;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-style: italic;
  font-weight: bold;
}

::v-deep .v-input__icon--append {
  i {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('~@assets/icons/arrow-down.png');
    color: transparent !important;
    width: 20px;
    height: 20px;
  }
}

</style>
<template>
  <v-select
    v-model="selected"
    :disabled="disabled"
    :items="items"
    item-text="name"
    item-value="type"
    label="Selecciona una promoción"
    solo
  ></v-select>
</template>

<script>
export default {
  name: 'PromotionSelect',
  props: {
   disabled: {
     type: Boolean,
     default: false
   },
   default: {
     type: Object,
     default: () => ({})
   }
  },
  data() {
    return {
      selected: this.default.type || 'NONE',
      items: [
        {
          name: 'Ninguna',
          type: 'NONE'
        },
        {
          name: 'X2',
          type: 'X2'
        },
        {
          name: 'X3',
          type: 'X3'
        }
      ]
    };
  },
  watch: {
    default(val) {
      this.selected = val.type || 'NONE';
    }
  },
  methods: {
    reset() {
      this.selected = 'NONE';
      this.items = [
        {
          name: 'Ninguna',
          type: 'NONE'
        },
        {
          name: 'X2',
          type: 'X2'
        },
        {
          name: 'X3',
          type: 'X3'
        }
      ];
    },
    getSelectedPromotion() {
      return this.selected;
    }
  }
};
</script>
