<style scoped lang="scss">
 .fit-container {
  padding-left: 25px;
  padding-right: 25px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%), url('~@assets/images/splash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: grid;
  align-items: center;
  min-height: 700px;
}
 @media (orientation: landscape) {
  .fit-container {
     height: auto !important;
  }
}
@media (min-width: 784px) {
  .fit-container {
     height: 100% !important;
  }
}

@media screen and (min-height: 572px) {
  .fit-container {
    align-items: center;
    height: 100% !important;
  }
}

</style>

<template>
  <div class="fit-container">
    <RegisterForm></RegisterForm>
  </div>
</template>

<script>
import RegisterForm from '@forms/RegisterForm';

export default {
  name: 'Register',
  components: { RegisterForm }
};
</script>
