<style scoped lang="scss">
.modal {
  position: relative !important;
}
.modal__content {
  border-radius: 12px;
  background: white;
  display: grid;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;

  &__btn-close {
    background-image: url("~@assets/icons/close-red.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
    z-index: 1;
    float: right;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>

<template>
  <div>
    <v-dialog v-model="open" max-width="500" persistent>
      <div class="modal">
        <v-btn @click="closeModal" icon flat class="modal__content__btn-close"></v-btn>
        <div class="modal__content">
          <SendEmailForm
            :order="order"
            :discount="discount"
            :service="service"
            :with-service="withService"
            :with-iva="withIva"
            :iva="iva"
            :user="user"
            :domicile="domicile"
            :douceur="douceur"
            ref="sendEmailForm"
            @success="handleSuccess"
            @fail="handleFail"
          ></SendEmailForm>
        </div>
      </div>
    </v-dialog>

    <v-snackbar
      v-model="error"
      color="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >Ocurrio un error, intenta mas tarde.</v-snackbar>

    <v-snackbar
      v-model="success"
      color="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >Operacion completada con exito.</v-snackbar>
  </div>
</template>

<script>
import SendEmailForm from "@forms/SendEmailForm";

export default {
  name: "SendInvoiceModal",
  components: { SendEmailForm },
  props: {
    order: {
      type: String,
      default: () => ""
    },
    user: {
      type: String,
      default: () => ""
    },
    service: {
      type: Number,
      default: 0
    },
    discount: {
      type: Number,
      default: 0
    },
    iva: {
      type: Number,
      default: 0
    },
    display: {
      type: Boolean,
      default: false
    },
    withService: {
      type: Boolean,
      default: false
    },
    withIva: {
      type: Boolean,
      default: false
    },
    headquater: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: String,
      default: ""
    },
    domicile: {
      type: Number,
      default: 0
    },
    douceur: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      open: this.display,
      success: false,
      error: false,
      resetForm: false
    };
  },
  watch: {
    display(val) {
      this.open = val;
    }
  },
  methods: {
    handleSuccess() {
      this.success = true;
      this.closeModal();
    },
    handleFail() {
      this.error = true;
    },
    closeModal() {
      this.open = false;
      if (this.$refs.sendEmailForm) this.$refs.sendEmailForm.reset();
      this.$emit("close");
      this.resetForm = true;
    }
  }
};
</script>

