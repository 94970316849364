import { BASE } from "./index";
const createDelivery = payload => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/deliverys/create`;
  return fetch(url, {
    method: "POST",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify({ ...payload })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default createDelivery;
