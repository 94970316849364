import Vue from 'vue';
import Loading from "@components/generals/Loading";
import Checkbox from "@components/generals/Checkbox";
import BasicInput from "@inputs/BasicInput";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";

export default Vue.extend({
  name: 'NewQuotation',
  components: {
    Loading,
    Checkbox,
    BasicInput,
    ReservationsComboTables
  },
  data: () => ({
    headquater: {}
  }),
  created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
    console.log('headquater', this.headquater);
  },
  methods: {
    goQuotationsList() {
      this.$router.push({
        name: 'quotation'
      });
    }
  }
});
