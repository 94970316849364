const mutations = {
  setStateMini(state, payload) {
    state.isMini = payload;
  },
  setStateMenu(state, payload) {
    state.showMenu = payload;
  },
  setStateLoading(state, status) {
    state.showLoading = status;
  }
};

export default mutations;
