import firebase from "firebase/app";
import { notifyEvent } from "@/utils/notifyEvent";
import { resolveUserProfile } from "@/utils/resolveUserProfile";
import { updateUserWhenCreate } from "@/utils/updateUserWhenCreate";
import surePromise from "@utils/surePromise";

export const onStageChange = async user => {
  if (user) {
    const { ok: success, error, result: token } = await surePromise(firebase.auth().currentUser.getIdToken(true));
    if (success) {
      if (token) {
        window.token = token;
        window.localStorage.setItem("token", token);
        if (window.justRegister) {
          return updateUserWhenCreate(user, window.justRegister);
        }
        const { ok, error: getProfileException } = await surePromise(resolveUserProfile(user));
        if (ok) {
          return notifyEvent({ name: "ready", detail: { ok: true } });
        }
        notifyEvent({ name: "getProfileException", details: getProfileException });
      } else {
        notifyEvent({ name: "getProfileException", details: error });
      }
    } else {
      window.user = null;
      window.localStorage.clear();
      notifyEvent({ name: "logout" });
    }
  }
};
