import { BASE } from "./index";
const fetchWaitersFromHeadquater = id => {
  const token = window.localStorage.getItem("token");
  const url = `${BASE}/headquater/waiters/${id}`;
  return fetch(url, {
    method: "GET",
    headers: { token }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default fetchWaitersFromHeadquater;
