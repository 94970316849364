<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import MenuCard from "@components/cards/MenuCard";
  import { mapState } from "vuex";
  import onPWA from "@/utils/onPWA.js";
  import UserMap from "@components/maps/UserMap";
  import RouteRestaurantMap from "@components/maps/RouteRestaurantMap";
  import ResumeCost from "@components/YourOrder/ResumeCost";
  import ShareAccount from "@components/YourOrder/ShareAccount";
  import OrderType from "@components/YourOrder/OrderType";
  import OrderDouceur from "@components/YourOrder/OrderDouceur";
  import OrderPaymentOptions from "@components/YourOrder/OrderPaymentOptions";
  import PersonsAndTimeForm from "@components/forms/PersonsAndTimeForm";
  import requests from "@/helpers/requests";
  import surePromise from "@/utils/surePromise";
  import moment from "moment";
  import validators from "@utils/validators";
  import BasicInput from "@inputs/BasicInput";
  import STORE from "@/store/types";
  import OrderPayment from "@/components/Cart/OrderPayment";
  import GoogleMapsApiLoader from 'google-maps-api-loader'

  const { createDelivery, updateDelivery } = requests;
  const { validName, validCellPhone } = validators;
  const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  export default {
    name: "YourOrder",
    components: {
      MenuCard,
      UserMap,
      ResumeCost,
      ShareAccount,
      OrderType,
      OrderDouceur,
      OrderPaymentOptions,
      BasicInput,
      PersonsAndTimeForm,
      RouteRestaurantMap,
      OrderPayment
    },
    computed: {
      ...mapState({
        openYourOrder: state => state.yourOrder.show_your_order,
        cart_items: state => state.cart.cart,
        headquater: state => state.cart.headquater
      }),
      subtotal() {
        let total = 0;
        for (const i in this.cart_items) {
          if (this.cart_items[i].amount > 0) {
            total += this.cart_items[i].price * this.cart_items[i].amount;
          }
        }
        return total;
      },
      cost() {
        if (this.order.order_type === "domicilio") {
          return this.restaurant.domicile ? parseFloat(this.restaurant.domicile) : 0;
        }
        return 0;
      },
      total() {
        return this.subtotal + this.subtotal * 0.08 + this.cost + this.order.order_douceur;
      },
      totalForIva() {
        return this.subtotal;
      }
    },
    data() {
      const minDate = this.toLocalDateString(moment().startOf("isoWeek").toDate());
      const maxDate = this.toLocalDateString(moment().endOf("isoWeek").toDate());

      let date = new Date();
      date = this.toLocalDateString(date);

      let today = new Date();
      today = this.toLocalDateString(today);

      console.log("date");
      console.log(date);

      return {
        paymentMode: "select",
        locationErrorMsg: "",
        selectedDate: "",
        addressFromMap: "",
        validity: {
          phone: false,
          name: false
        },
        nameMsg: "Escribe minimo 3 caracteres maximo 50",
        phoneMsg: "Escribe tu numero de celular",
        onPWA: onPWA(),
        showYourOrder: this.openYourOrder,
        displaySuccessSnackbar: false,
        displayErrorSnackbar: false,
        loading: false,
        center: {
          lat: 0,
          lng: 0
        },
        createdOrder: {
          id: ""
        },
        order: {
          belongsTo: "",
          headquater: "",
          dishes: [],
          quantity: "",
          checkout_total: "",
          order_douceur: 0,
          order_status: "",
          order_type: "",
          cash_value: "", // payment type
          user_id: "",
          name: "",
          email: "",
          phone: "",
          country: "",
          city: "",
          note: "",
          date,
          duration: 60,
          created_date: new Date(),
          created_month: "",
          created_year: "",
          timestamp: "",
          address: "",
          drivingDuration: 0,
          location: {
            latitude: 0,
            longitude: 0
          },
          domicile: true,
          hour: "",
          persons: 1,
          iva: { active: true, value: 8, computedValue: 0 }
        },
        restaurant: {},
        isToday: false,
        dateTitle: "",
        dayweek: weekDays[new Date().getDay() - 1],
        today,
        minDate,
        maxDate
      };
    },
    async created() {
      const self = this;
      this.showYourOrder = this.openYourOrder;
      this.order.headquater = this.headquater;

      const dishes = {};
      for (const i in this.cart_items) {
        const dish = this.cart_items[i];
        // const hasPromotion = dish.promotion && dish.promotion.available === true;
        // if (hasPromotion) {
        //   dish.promotion = dish.promotion.type;
        // } else {
        //   delete dish.promotion;
        // }
        dishes[dish.id] = dish;
      }

      this.order.dishes = dishes;

      this.order.quantity = this.cart_items.length;
      this.order.order_status = "INITIAL";
      this.order.user_id = window.user.id;
      this.order.email = window.user.email;
      window.addEventListener("userProfile", user => {
        console.log("userProfile");
        self.order.user_id = user.id;
      });

      const options = {
        enableHighAccuracy: true,
        // timeout: 5000,
        maximumAge: 0
      };

      this.restaurant = await window.db.collection("Headquaters").where("id", "==", this.headquater).limit(1).get();
      this.restaurant = this.restaurant.docs[0].data();
      this.restaurant.selected = true;
      this.order.user_id = window.user.id;
      this.order.belongsTo = this.restaurant.id;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.successGetPosition, this.failGetPosition, options);
      }
      this.handleFieldChange("phone", window.user.phone);
      this.handleFieldChange("name", window.user.name);
    },
    async mounted() {
      this.loading = false;
    },
    watch: {
      openYourOrder(newVal) {
        this.showYourOrder = newVal;
      }
    },
    methods: {
      async updatedLocation(lat, lng) {

        // const google = await GoogleMapsApiLoader({
        //   apiKey: "AIzaSyCV2B0aoGdSE5GcWKbglVeUqklCAAyY1O4"
        // });

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { location: { lat, lng } },
          (results, status) => {
            if (status === "OK") {
              this.addressFromMap = results[0].formatted_address;
              console.log(this.addressFromMap);
            } else {
              // window.alert(`Geocoder failed due to: ${status}`);
            }
          }
        );
      },
      setDrivingDuration(minutes) {
        this.handleFieldChange("drivingDuration", minutes);
      },
      async successGetPosition(position) {
        console.log("successGetPosition");
        console.log(position);

        this.locationErrorMsg = "";
        this.center.lat = this.restaurant.l.latitude || this.restaurant.l._latitude;
        this.center.lng = this.restaurant.l.longitude || this.restaurant.l._longitude;

        this.order.location.latitude = position.coords.latitude;
        this.order.location.longitude = position.coords.longitude;

        console.log(this.center.lat);
        console.log(this.order.location.latitude);

        setTimeout(() => {
          this.$refs.howToArriveMap.getRoute();
        }, 100);

        this.updatedLocation(position.coords.latitude, position.coords.longitude);
      },
      failGetPosition() {
        this.error = true;
        this.loading = false;
      },
      allowedDates(val) {
        const daysAllowed = this.restaurant.schedule;
        const currentDate = new Date(val);
        const allow = daysAllowed.hasOwnProperty(weekDays[currentDate.getDay()]);
        return allow;
      },
      handleChangePersons(val) {
        // eslint-disable-next-line radix
        this.order.persons = parseInt(val);
      },
      toLocalDateString(date) {
        const currentMonth = date.getMonth();
        const month = String(currentMonth).length === 1 && currentMonth < 9 ? `0${currentMonth + 1}` : currentMonth + 1;
        const day = String(date.getDate()).length === 1 ? `0${date.getDate()}` : date.getDate();
        return `${date.getFullYear()}-${month}-${day}`;
      },
      sameDate(a, b) {
        const plainA = a.split("-");
        const plainB = b.split("-");
        const sameYear = plainA[0] === plainB[0];
        const sameMonth = plainA[1] === plainB[1];
        const sameDay = plainA[2] === plainB[2];
        return sameYear && sameMonth && sameDay;
      },
      handleFieldChange(field, value) {
        if (field === "name") {
          this.validity.name = validName(value);
          this.order.name = value;
        }

        if (field === "phone") {
          this.validity.phone = validCellPhone(value);
          this.order.phone = value;
        }

        if (field === "note") {
          this.order.note = value;
        }

        if (field === "address") {
          this.order.address = value;
        }

        if (field === "drivingDuration") {
          // eslint-disable-next-line radix
          this.order.drivingDuration = isNaN(parseInt(value)) ? 0 : parseInt(value);
        }

        const { name, phone } = this.validity;
        this.validity.invalidForm = !(
          name === true &&
          phone === true && this.order.drivingDuration > 0
        );
      },
      closeOrder() {
        this.$router.push("/cart");
      },
      setOrderAmount(value) {
        this.order.checkout_total = value;
      },
      setOrderType(value) {
        this.order.order_type = value;
        if (this.order.order_type === "domicilio") {
          //
        } else if (["recoger", "comer"].includes(this.order.order_type)) {
          this.order.order_douceur = 0;
        }

        this.selectedDate = this.toLocalDateString(new Date());
        this.order.date = this.toLocalDateString(new Date());
        this.order.created_date = new Date();
      },
      setOrderDouceur(value) {
        this.order.order_douceur = value;
      },
      setOrderPaymentOption(value) {
        this.order.cash_value = value;
      },
      async submit() {
        if (this.order.location.latitude === 0 && this.order.location.longitude === 0) {
          this.validity.invalidForm = true;
          this.locationErrorMsg = "Ubicacion es requerida";
        } else {
          this.locationErrorMsg = "";
        }

        const isValid = this.validity.invalidForm === false;
        if (isValid && this.order.drivingDuration > 0) {
          this.loading = true;

          this.order.checkout_total = this.total;
          this.order.phone = this.order.phone.replace(/\s/g, "") || null;
          this.order.time = moment().format("HH-mm");
          this.order.timestamp = Date.now();
          this.order.date = this.order.created_date;
          this.order.iva.computedValue = this.totalForIva * 0.08;

          const { ok, result, error } = await surePromise(createDelivery(this.order));
          if (ok) return this.handleDeliverySuccess(result);
          this.handleDeliveryError(error);
        }
      },
      handleDeliverySuccess(response) {
        const self = this;
        this.loading = false;
        if (response.ok) {
          if (this.order.cash_value === "en_linea") {
            this.createdOrder = {
              id: response.id
            };
            this.paymentMode = "payment";
          } else {
            this.$store.dispatch(STORE.ACTIONS.CART.REMOVE_CART_ITEMS).then(() => {
              self.$store.dispatch(STORE.ACTIONS.CART.OPEN_SUCCESS_ORDER);
              self.$router.push("/cart");
            });
          }
        }
      },
      async handlePaymentSuccess(response) {
        const self = this;
        const { ok } = await surePromise(updateDelivery(this.createdOrder.id, {
          status: "INITIAL",
          paymentID: response.paymentID,
          payAt: new Date()
        }));
        if (ok) {
          this.$store.dispatch(STORE.ACTIONS.CART.REMOVE_CART_ITEMS).then(() => {
            self.$store.dispatch(STORE.ACTIONS.CART.OPEN_SUCCESS_ORDER);
            self.$router.push("/cart");
          });
        }
      },
      handleDeliveryError() {
        this.loading = false;

        this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, {
          show: true,
          status: "error",
          message: "Ocurrio un error interno, por favor intenta mas tarde."
        });
      }
    }
  };
</script>
