<template src="./template.html"></template>
<script>
  import WaiterDashboard from "@/views/Waiter/Dashboard";
  import AdminDashboard from "@/views/Admin/Dashboard";
  import UserDashboard from "@/views/UserDashboard";
  import ChefDashboard from "@/views/Chef/Dashboard";
  import Comments from "@/components/generals/Comments/Comments.vue";
  import redirectRoutes from "@/utils/redirectRoutes";

  export default {
    name: "Dashboard",
    components: {
      SelectProfile: () => import("@/components/generals/SelectProfile/SelectProfile.vue"),
      HeadquerListModal: () => import("@/components/modals/HeadquaterListModal"),
      Home: () => import("@/views/User/Home/index.vue"),
      UserDashboard,
      WaiterDashboard,
      ChefDashboard,
      AdminDashboard,
      Comments
    },
    data: () => ({
      roles: [],
      selectedUserType: {
        CHEF: false,
        ADMIN: false,
        WAITER: false,
        USER: false
      },
      selectProfile: true,
      userType: "",
      headQuaters: [],
      showQuaterList: false,
      selectedRol: ""
    }),
    mounted() {
      this.roles = window.user.roles;
      this.getSelectedRol();
    },
    methods: {
      handleProfile() {
        this.selectProfile = true;
        this.headQuaters = [];
        this.selectedUserType = {
          CHEF: false,
          ADMIN: false,
          WAITER: false,
          USER: false
        };
      },
      getSelectedRol() {
        const selectedRol = localStorage.getItem("selectedRol");
        if (selectedRol) {
          this.selectProfile = false;
          this.selectedUserType[selectedRol] = true;
          this.userType = selectedRol;
          return redirectRoutes(selectedRol);
        }
        this.userTypes();
        return redirectRoutes(this.userType);
      },
      userTypes() {
        const isWaiterOrAdmin = window.user.roles.includes("WAITER") || window.user.roles.includes("ADMIN");
        const isChef = window.user.roles.includes("CHEF");
        if (isWaiterOrAdmin) {
          this.userType = "WAITER";
        } else if (isChef) {
          this.userType = "CHEF";
        } else {
          this.userType = "USER";
        }
        const isUser = this.userType === "USER";
        if (isUser) {
          this.selectProfile = false;
          this.selectedUserType.USER = true;
          localStorage.setItem("selectedRol", "USER");
        }
      },
      selectedUser(rol) {
        this.selectProfile = false;
        this.selectedUserType[rol] = true;
        this.userType = rol;
        return redirectRoutes(rol);
      },
      filterHeadQuaters(rol) {
        this.selectedRol = rol;
        const { workingAt, headquaters } = window.user;
        if (rol === "USER") {
          return this.selectedUser(rol);
        }
        if (rol === 'ADMIN') {
          this.headQuaters = Object.values(headquaters);
        } else this.headQuaters = Object.values(workingAt);
        if (this.headQuaters.length > 1) {
          this.showQuaterList = true;
          return;
        }
        this.selectedUser(rol);
      },
      onCloseHeadquaterList() {
        this.showQuaterList = false;
        this.selectedUser(this.selectedRol);
      }
    }
  };
</script>
