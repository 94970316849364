const mutations = {
  setTableOrder(state, table) {
    state.tableOrder = table;
  },
  setNewOrder(state, order) {
    state.newOrder = order;
  },
  setStateModalAssignWaiter(state, payload) {
    state.modalAssignWaiter = payload;
  },
  setTables(state, tables) {
    state.tables = tables;
  }
};

export default mutations;
