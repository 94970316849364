import Artyom from "artyom.js";
const artyom = new Artyom();

function speech(message = "", callback) {
  artyom.say(message, {
    lang: "es-ES",
    onEnd: () => {
      if (callback) return callback();
    }
  });
}

window.speech = speech;

export default speech;
