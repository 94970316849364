<style lang="sass" scoped>
@import './style.scss'
</style>
<template src="./template.html"></template>

<script>
import RestaurantsList from "@lists/RestaurantsList";
import Carrousel from "@lists/Carrousel";
import SearchRestaurants from "@inputs/SearchRestaurants";
import Loading from "@components/generals/Loading";
import milesToKilometers from "@utils/milesToKilimeters.js";
import kilometersToMiles from "@utils/kilometersToMiles.js";
import mapStyle from "@/utils/mapStyle.json";
import VerticalSlider from "@/components/generals/VerticalSlider";
import OptionMenu from "@/components/menus/OptionMenu";
import ReadQR from "@/components/generals/ReadQR";
import WaiterDashboard from "@/views/Waiter/Dashboard";
import AdminDashboard from "@/views/Admin/Dashboard";
import requests from "@/helpers/requests";
import getDistanceFromLatLonInKm from "@/utils/getDistanceFromLatLonInKm.js";

const center = { lng: 0, lat: 0 };
export default {
  name: "UserDashboard",
  components: {
    Carrousel,
    SearchRestaurants,
    RestaurantsList,
    VerticalSlider,
    OptionMenu,
    ReadQR,
    WaiterDashboard,
    Loading,
    AdminDashboard
  },
  props: {
    rol: {
      type: String,
      default: ""
    }
  },
  filters: {
    parseToKm(value) {
      return milesToKilometers(value).toFixed(1);
    }
  },
  data() {
    return {
      animation: 2,
      userType: "",
      notFoundRestaurants: false,
      showOptions: true,
      fab: false,
      fling: false,
      hover: false,
      center,
      openRestaurantList: false,
      point: 0,
      loading: false,
      requestGPS: false,
      openQR: false,
      localizing: false,
      radius: kilometersToMiles(5),
      categories: [],
      description: "",
      restaurantSelected: {
        lat: center.lat,
        lng: center.lng
      },
      userPhoto: null,
      restaurants: [],
      mapReady: false,
      showSlider: false,
      currentPlace: null,
      internalError: false,
      mapOptions: {
        disableDefaultUI: true,
        styles: mapStyle
      }
    };
  },
  created() {
    let userType = "USER";
    const hasWaiterRol = window.user.roles.some(rol => rol === "WAITER");
    const hasAdminRol = window.user.roles.some(rol => rol === "ADMIN");
    if (hasWaiterRol) userType = "WAITER";
    if (hasAdminRol) userType = "ADMIN";
    this.userType = userType;
    const hasPhotoURL = window.user && window.user.photoURL;
    if (hasPhotoURL) {
      this.userPhoto = {
        url: window.user.photoURL,
        size: {
          width: 50,
          height: 50,
          f: "px",
          b: "px",
          borderRadius: 12
        },
        scaledSize: {
          width: 23,
          height: 23,
          f: "px",
          b: "px"
        }
      };
    }
  },
  mounted() {
    const userType = localStorage.getItem("selectedRol");
    if (userType === "USER") {
      window.scrollTo(0, 0);
      this.initialize();
    }
    window.handleDecodeQr = this.handleDecodeQr;
  },
  updated() {
    if (this.$refs.optionMenu) {
      const openMenu = window.router.currentRoute.name === "dashboardState";
      this.$refs.optionMenu.openMenu = openMenu;
    }
  },
  methods: {
    failRequestPermissions() {},
    handleDecodeQr(data = "") {
      if (data) {
        const path = `/restaurant/${data}`;
        window.router.push({ path });
      }
    },
    reloadPage() {
      window.location.reload();
    },
    handleFilterChange(open) {
      this.showOptions = !open;
    },
    handleGoProfile() {
      window.router.push({ name: "profile" });
    },
    openFilter() {
      this.showSlider = !this.showSlider;
    },
    initialize() {
      this.loading = true;
      const { success, error, options } = this.geolocate();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        this.loading = false;
        this.localizing = false;
        this.requestGPS = true;
      }
      // this.positionId = navigator.geolocation.watchPosition(success, error, options);
      requests
        .fetchCategories()
        .then(response => {
          this.categories = response.map(item => ({
            id: item.id,
            name: item.name,
            selected: false,
            logo: item.logo
          }));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.internalError = true;
        });
    },
    reloadOperation() {
      this.localizing = true;
      this.initialize();
    },
    readQR() {
      this.openQR = true;
    },
    getRoute() {
      this.directionsService = new window.google.maps.DirectionsService();
      if (!this.directionsDisplay) {
        this.directionsDisplay = new window.google.maps.DirectionsRenderer();
      }
      this.directionsDisplay.setMap(this.$refs.map.$mapObject);
      const vm = this;
      const origin = { ...this.center };
      const destination = { ...this.restaurantSelected };
      vm.directionsService.route(
        {
          origin,
          destination,
          travelMode: "DRIVING"
        },
        (response, status) => {
          if (status === "OK") {
            vm.directionsDisplay.setDirections(response);
          }
        }
      );
    },
    handleMarkerClick() {
      // window.location.href = `${window.location.origin}/restaurant/${this.restaurantSelected.belongsTo}/${this.restaurantSelected.id}`;
      const path = `/restaurant/${this.restaurantSelected.belongsTo}/${this.restaurantSelected.id}`;
      window.router.push({ path });
    },
    handleChangeRadius(e) {
      this.radius = Number(e.target.value);
    },
    findRestaurants() {
      this.restaurants.forEach(restaurant => {
        restaurant.selected = false;
      });
      this.restaurantSelected = {};
      const payload = {
        lat: this.center.lat,
        lon: this.center.lng
      };
      if (this.categories) {
        payload.categories = this.categories
          .filter(item => item.selected)
          .map(item => item.id)
          .join(",");
      }
      if (this.point) payload.stars = this.point;
      if (this.description) payload.description = this.description;
      return requests.fetchHeadquatersAt(payload).then(restaurants => {
        this.restaurants = restaurants.map(restaurant => {
          const { name, id, l, belongsTo, schedule = {} } = restaurant;
          const { _latitude, _longitude } = l;
          const distance = getDistanceFromLatLonInKm(this.center.lat, this.center.lng, Number(_latitude), Number(_longitude));
          restaurant.distance = distance;
          const distanceStars = `${milesToKilometers(restaurant.distance).toFixed(1)} km, ${restaurant.stars.toFixed(1)} Estrellas`;
          const coordinates = { lat: Number(_latitude), lng: Number(_longitude) };
          // const selected = this.restaurantSelected.id === id;
          return {
            distance_stars: distanceStars,
            logo: restaurant.logo,
            schedule,
            name,
            distance,
            id,
            coordinates,
            selected: false,
            belongsTo
          };
        });
        if (!this.restaurants.length && this.directionsDisplay) {
          this.directionsDisplay.setMap(null);
        }
      });
    },
    geolocate() {
      const options = {
        enableHighAccuracy: true
        // timeout: 10000,
        // maximumAge: 12220
      };
      const success = position => {
        this.loading = true;
        this.requestGPS = false;
        this.localizing = false;
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
        this.mapReady = true;
        this.findRestaurants().then(() => {
          this.loading = false;
        });
      };
      const error = () => {
        this.requestGPS = true;
        this.localizing = false;
      };
      return { error, success, options };
    },
    handleUnSelectCategorie() {
      this.categories.forEach(item => {
        item.select = false;
      });
      this.loading = true;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleSelectCategorie() {
      this.loading = true;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleDoneTyping(value) {
      this.loading = true;
      this.description = value;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
        // this.$refs.searchComponent.notfound = Boolean(this.restaurants.length);
        this.$refs.searchComponent.$el.querySelector("input").blur();
      });
    },
    handleNoPoints() {
      this.point = null;
      this.loading = true;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleSelectStar(payload) {
      this.loading = true;
      this.point = payload;
      this.findRestaurants().then(() => {
        this.loading = false;
        this.$refs.searchComponent.hideFilter();
        const { value = "" } = this.$refs.searchComponent;
        if (value) {
          this.notFoundRestaurants = value && this.restaurants.length === 0;
        } else {
          this.notFoundRestaurants = false;
        }
      });
    },
    handleSelectRestaurant(item) {
      const { coordinates, id, belongsTo } = item;
      const { lat, lng } = coordinates;
      this.restaurantSelected.lat = lat;
      this.restaurantSelected.lng = lng;
      this.restaurantSelected.id = id;
      this.restaurantSelected.belongsTo = belongsTo;
      this.restaurants.forEach(el => {
        el.selected = false;
      });
      item.selected = !item.selected;
      this.panTo(this.restaurantSelected);
      this.getRoute();
    },
    updateCenter(coords) {
      this.center = {
        lat: coords.lat(),
        lng: coords.lng()
      };
    },
    panTo(location) {
      this.animation = 1;
      setTimeout(() => {
        this.animation = 2;
        this.$refs.map.panTo(location);
      }, 50);
    }
  }
};
</script>
