<style scoped lang="scss">
  @import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
  import ItemsLists from "@components/lists/ItemsLists";
  import anonymousUser from "@/assets/icons/waiter-icon.svg";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";
  import timeout from "@/helpers/timeout";
  import uuid from "@utils/uuid";
  import STORE from "@/store/types";

  const { fetchDishes } = requests;

  export default {
    name: "AssignPromoModal",
    components: { ItemsLists },
    props: {
      waiters: {
        type: Array,
        default: () => []
      },
      open: {
        type: Boolean,
        default: false
      },
      table: {
        type: String,
        default: ""
      },
      order: {
        type: String,
        default: ""
      },
      headquater: {
        type: String,
        default: ""
      },
      defaultWaiter: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        isInvalid: false,
        loading: false,
        error: false,
        success: false,
        display: this.open,
        dishes: [],
        dessertCategory: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
        selectedDish: "",
        message: "",
        maxChars: 100
      };
    },
    watch: {
      async open(val) {
        this.display = val;
        this.categotyKey = uuid();

        if (val) {
          this.selectedDish = "";
          this.message = "";
          this.loading = true;
          const { ok, result } = await surePromise(requests.fetchDishes({
            availableAt: this.headquater,
            type: "food"
          }));
          this.loading = false;
          if (ok) {
            this.dishes = result.filter(item => item.categories[this.dessertCategory]);
            for (const dish of this.dishes) {
              dish.selected = false;
            }
            console.log(result);
          }
        }
      }
    },
    mounted() {
      this.initialize();
    },
    methods: {
      checkValidity() {
        return this.selectedDish !== "" && this.message.length <= this.maxChars;
      },
      async handlePromo() {
        this.loading = true;
        const { table = "" } = this;
        const tables = this.$store.getters[STORE.GETTERS.TABLE.GET_TABLES];
        // eslint-disable-next-line array-callback-return
        const filterTable = tables.filter(value => {
          if (value.id === table) return value;
        });
        if (filterTable[0].currentClient) {
          const { ok } = await surePromise(
            requests.notifyDishPromo(this.order, this.selectedDish.id, {
              content: this.message
            })
          );
          this.loading = false;
          if (ok) {
            this.$emit('close');
            this.success = true;
            return;
          }
        }
        this.success = true;
        await timeout(3000);
        return this.$emit("close");
      },
      async initialize() {
        this.isInvalid = !this.checkValidity();
      },
      handleSelectWaiter(_selectes, index) {
        this.waiters.forEach((waiter, i) => {
          waiter.selected = i === index;
        });
        this.isInvalid = !this.checkValidity();
        this.categotyKey = uuid();
      },
      onSelectItem(item) {
        this.selectedDish = item;
        this.isInvalid = !this.checkValidity();
      }
    }
  };
</script>
