export default {
  primary: '#ED2236',
  secondary: '#16202f',
  secondary_lite: '#16202f',
  accent: '#16202f',
  error: '#ED2236',
  warning: '#ffeb3b',
  info: '#2196f3',
  success: '#4caf50'
};
