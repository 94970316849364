<style scoped lang="scss">
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import CardBusiness from "@components/cards/CardBusiness";

export default {
  name: "CardBusinessList",
  components: { CardBusiness },
  props: {
    noWaiters: {
      type: Boolean,
      default: false
    },
    noAccounting: {
      type: Boolean,
      default: false
    },
    noOrders: {
      type: Boolean,
      default: false
    },
    noTables: {
      type: Boolean,
      default: false
    },
    noSells: {
      type: Boolean,
      default: false
    },
    headquater: {
      type: Object,
      default: () => ({}) 
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    orders() {
      return { ...this.data };
    },
    tables() {
      return { ...this.data };
    },
    sells() {
      return { ...this.data };
    },
    waiters() {
      return { ...this.data };
    }
  },
  data() {
    return { accounting: "" };
  },
  methods: {
    handleDate(event, day) {
      this.$emit("date", event, day);
    }
  }
};
</script>