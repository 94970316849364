<style scoped lang="scss">
  @import './style.scss';
</style>
<template src="./template.html"></template>

<script>
import BasicInput from '@inputs/BasicInput';
import EmailBox from '@components/boxes/EmailBox';
import validators from '@utils/validators';
import Header from '@components/Headers/LogoRed';

const { validEmail } = validators;
  export default {
    name: 'ResetForm',
    components: { BasicInput, Header, EmailBox },
    data() {
      return {
        validity: { email: false, invalidForm: true },
        email: '',
        emailMsg: 'Escribe una direccion de correo electrónico valida',
        loading: false,
        emailSended: false
      };
    },
    methods: {
      handleFieldChange(field, value) {
        if (field === 'email') {
          this.validity.email = validEmail(value);
          this.email = value;
          this.emailMsg = 'Escribe una direccion de correo electrónico valida';
        }
        const { email } = this.validity;
        this.validity.invalidForm = email === false;
      },
      handleSubmit(e) {
        if (e) e.preventDefault();
        this.loading = true;
        const actionCodeSettings = {
             url: window.location.origin,
             handleCodeInApp: false
          };
          window.auth.sendPasswordResetEmail(this.email, actionCodeSettings).then(() => {
            this.loading = false;
            this.emailSended = true;
          }).catch((error) => {
             this.loading = false;
             console.log('error ', error);
          });
        }
    }
  };
</script>
