<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import VClamp from "vue-clamp";
import MemberMenu from "@/components/menus/MemberMenu";
import QuestionMenu from "@/components/menus/QuestionMenu";

export default {
  name: "ChefHeader",
  components: { VClamp, MemberMenu, QuestionMenu },
  props: {
    headquaters: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    photo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      displayProfile: false,
      showMenu: false,
      search: "",
      loading: false,
      notfound: false,
      defaultPhoto: this.photo
    };
  },
  methods: {
    handleSelectHeadquater(headquater) {
      const event = new CustomEvent("headquaterSelected", {
        detail: headquater
      });
      window.dispatchEvent(event);
    },
    handleClickLogout() {
      this.displayProfile = false;
      this.$emit("signout");
    },
    handleChooseItem(item) {
      console.log(item);
      this.showMenu = false;
      this.$emit("display-menu", this.showMenu);
      this.$emit("choose-item", item);
    },
    handleShowMenu(event) {
      console.log({ event });
    },
    handleDisplayProfile() {
      this.displayProfile = !this.displayProfile;
      this.showMenu = false;
      if (this.displayProfile) {
        this.$emit("displayMenu");
      }
    }
  }
};
</script>

