<style scoped lang="scss">
 .fit-container {
  padding-left: 0px;
  padding-right: 0px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%), url('~@assets/images/splash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: grid;
  align-items: start;
  justify-content: center;
}
.v-form {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (orientation: landscape) {
  .fit-container {
     height: 100%;
  }
}
@media (min-width: 784px) {
  .fit-container {
     height: 100% !important;
     padding-left: 10px;
     padding-right: 10px;
  }
}
@media screen and (min-height: 572px) {
  .fit-container {
    align-items: center;
    height: 100% !important;
  }
}
</style>

<template>
  <div class="fit-container">
    <RecoveryForm></RecoveryForm>
  </div>
</template>

<script>
import RecoveryForm from '@forms/RecoveryForm';

export default {
  name: 'Recovery',
  components: { RecoveryForm }
};
</script>
