<template>
  <v-container
    class="wrap-restaurants__content__wrap-list"
    :class="{'wrap-restaurants__content__wrap-list-pull-up': noPadding, 'fit-onPWA': onPWA}"
  >
    <div
      v-if="data.length && mode !== 'admin'"
      class="wrap-restaurants__content__wrap-list__title subheading"
    >{{ title }}</div>

    <ul class="wrap-restaurants__content__wrap-list-ul">
      <li v-for="(item, index) in data" :key="index">
        <RestaurantCard
          :id="mode === 'reservations' || mode === 'waiter' || mode === 'admin' ? (item.reservations ? item.reservations.id : ''): ''"
          ref="restaurantCard"
          :lang="lang"
          :show-open-state="showOpenState"
          :date-time-stamp="item.date"
          :cancel-reaseon="mode === 'reservations' || mode == 'waiter' || mode === 'admin' ? (item.reservations ? item.reservations.cancelReaseon : '') : ''"
          :date="mode === 'reservations' || mode == 'waiter' || mode === 'admin' ? (item.reservations ? item.reservations.dateDisplay : '') : ''"
          :address="mode === 'reservations' || mode == 'waiter' || mode === 'admin' ? item.address : ''"
          :hour="mode === 'reservations' || mode == 'waiter' || mode === 'admin' ? (item.reservations ? item.reservations.hour: '') : ''"
          :persons="mode === 'reservations' || mode == 'waiter' || mode === 'admin' ? (item.reservations ? item.reservations.persons : '') : ''"
          :status="mode === 'reservations' || mode == 'waiter' || mode === 'admin' ? (item.reservations ? item.reservations.status : '') : ''"
          :mode="mode"
          :like="item.like"
          :name="item.name"
          :banner="item.banner"
          :logo="item.logo"
          :description="item.description"
          :schedule="item.schedule"
          :disable-cancel="busy"
          :link="`/${item.belongsTo}/${item.id}`"
          :point="item.stars"
          :user="item.user"
          @cancelReservation="(reaseon) => handleCancelReservation(item.reservations.id, item.id, reaseon)"
          @like="$emit('like', item.id)"
          @unlike="$emit('unlike', item.id)"
          @openReservation="$emit('openReservation', $event)"
        ></RestaurantCard>
      </li>
    </ul>
    <v-snackbar
      v-model="successMessage"
      :bottom="true"
      color="success"
      :timeout="8000"
    >Operación completada con exito.</v-snackbar>
    <v-snackbar
      v-model="failMessage"
      :bottom="true"
      color="error"
      :timeout="8000"
    >Ocurrio un error en la operación , intenta mas tarde.</v-snackbar>
  </v-container>
</template>
<script>
import RestaurantCard from "@components/cards/RestaurantCard";
import theme from "@/theme.js";
import onPWA from "@/utils/onPWA.js";
import requests from "@/helpers/requests";

export default {
  name: "RestaurantsList",
  components: { RestaurantCard },
  props: {
    lang: {
      type: String,
      default: "ES"
    },
    showOpenState: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    },
    hour: {
      type: String,
      default: ""
    },
    persons: {
      type: String,
      default: ""
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const { secondary, primary } = theme;
    return {
      onPWA: onPWA(),
      successMessage: false,
      failMessage: false,
      openRestaurantList: false,
      title: this.caption,
      busy: false,
      secondary,
      primary,
      point: 0,
      loading: false
    };
  },
  watch: {
    caption(val) {
      this.title = val;
    }
  },
  methods: {
    handleUpdateReservationError(error, card) {
      this.failMessage = true;
      this.successMessage = false;
      this.$refs.restaurantCard.loading = false;
      this.busy = false;
      card.loading = false;
    },
    handleUpdateReservationSuccess(response, card, headquater) {
      card.loading = false;
      this.busy = false;
      if (response.ok) {
        this.successMessage = true;
        const selectedReserv = this.data.find(item => item.id === headquater);
        selectedReserv.reservations.status = "CANCEL";
        this.failMessage = false;
      } else {
        this.failMessage = true;
        this.successMessage = false;
      }
    },
    handleCancelReservation(id, headquater, reaseon = "") {
      const card = this.$refs.restaurantCard.find(item => item.id === id);
      card.loading = true;
      this.busy = true;
      requests
        .updateReservation(id, { status: "CANCEL", cancelReaseon: reaseon })
        .then(response => this.handleUpdateReservationSuccess(response, card, headquater))
        .catch(error => this.handleUpdateReservationError(error, card, headquater));
    }
  }
};
</script>

<style scoped lang="scss">
.fit-onPWA {
  margin-top: 0px;
}
.wrap-restaurants__content__title {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  font-size: 30px;
  color: white;
}
.wrap-restaurants__content__wrap-list {
  padding-left: 0px;
  padding-right: 0px;
  padding-left: 3px;
  padding-right: 3px;
}
.wrap-restaurants__content__wrap-list-ul {
  padding: 0px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 34px;
  justify-content: center;
  margin-top: 5px;
}

@media only screen and (max-width: 450px) {
  .wrap-restaurants__content__wrap-list-ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  /* .wrap-restaurants__content__wrap-list-ul li {
    margin-bottom: 30px;
  } */
}

.wrap-restaurants__content__wrap-list__title {
  color: white;
  font-family: Gibson;
  font-size: 20px !important;
  margin-top: 20px;
}
.wrap-restaurants__content__wrap-list-pull-up {
  padding-top: 10px !important;
}
</style>
