import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import modules from './modules';

Vue.use(Vuex);

const disableModules = ['cart', 'yourOrder'];
const enabledModules = [];
for (const moduleName in modules) {
  if (!disableModules.includes(moduleName)) {
    enabledModules.push(modules[moduleName]);
  }
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // eslint-disable-next-line array-callback-return
  modules: enabledModules
});


const store = new Vuex.Store({
  devtools: true,
  modules,
  plugins: [vuexLocal.plugin]
});

export default store;
