<style scoped lang="scss">
  @import "@/styles/_colors.scss";

  .menu-card {
    background: white;
    color: black;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    padding-bottom: 18px;
    width: 100%;

    &:hover {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.35);
    }
  }

  .menu-card-container {
    padding: 18px;
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    transition: all 0.5s ease-in;
    position: relative;
  }

  .menu-card-container__img {
    width: 130px;
    height: 130px;
    border-radius: 14px;
    background-color: whitesmoke;
    margin: 0 0 0 -36px;
  }

  .menu-card-container__content {
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 78%
    // width: 68%
  }

  .menu-card-container__title {
    font-size: 16px;
    font-weight: bold;
    color: #3f455f;
    word-break: break-word;
  }

  .menu-card-container__btn-toggle {
    /*z-index: 1;*/
    float: right;
    position: absolute;
    top: 0px;
    right: 2px;

    &::v-deep i {
      font-size: 25px;
    }
  }

  .menu-card-container__promotion {
    background: $primary;
    padding: 5px;
    color: white;
    border-radius: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .menu-card-container__description {
    text-align: justify;
    overflow: visible;
    position: relative;
    top: 4px;
    min-height: 70px;
  }

  .collapse {
    min-height: 76px;
  }

  .menu-card-container__price {
    color: $primary;
    padding-bottom: 5px;
  }

  .menu-card-container__description {
    color: #505776;
  }

  .menu-card-container__title,
  .menu-card-container__price,
  .menu-card-container__description {
    margin-left: 15px;
    display: block;
    margin: 2px 0 0 0;
  }

  .wrap-btn-plate {
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }

  .btn-plate {
    width: 40px;
    height: 40px;
    margin: 0 !important;
    background: $primary;

    &::v-deep i {
      font-size: 25px;
    }
  }

  .amount-label {
    font-size: 25px;
    font-weight: bold;
    margin: 0 12px;
  }

  .wrap-suggestion {
    /*padding-top: 10px;*/
    margin-left: 23px;
    margin-right: 23px;
  }

  .v-input__slot {
    border-color: #ef4f35 !important;
  }

  .menu-card-container {
    .suggestion {
      .theme--light.v-label {
        color: $primary !important;
      }

      .v-label {
        color: $primary;
      }
    }
  }

  .add-to-cart-section {
    width: 100%;
    text-align: center;
  }

  .add-to-cart {
    border: solid 1px red;
    border-radius: 10px;
    height: 52px;
    padding-left: 32px;
    padding-right: 32px;
    width: 272px;

    span {
      font-family: "Gibson-Bold", Futura !important;
    }
  }

  @media(max-width: 1024px) {
    .menu-card-container__content {
      width: 68%;
    }
  }

  @media(max-width: 424px) {
    .menu-card-container__title {
      max-width: 300px;
    }

    .menu-card-container__content {
      width: 65%;
    }
  }

  @media(max-width: 380px) {
    .menu-card-container__title {
      max-width: 205px;
    }
  }

  @media(max-width: 320px) {
    .menu-card-container__content {
      width: 60%;
    }
  }
</style>

<template>
  <section class="menu-card">
    <div class="menu-card-container">
      <img :src="image" class="menu-card-container__img" />
      <div class="menu-card-container__content">
        <h1 class="menu-card-container__title">
          {{ title }}
          <v-btn
            v-if="showOptions && showSelectOption"
            icon
            flat
            color="primary"
            class="menu-card-container__btn-toggle"
            @click="toggleHeight"
          >
            <v-icon v-if="open">remove</v-icon>
            <v-icon v-else>add</v-icon>
          </v-btn>
        </h1>

        <span class="menu-card-container__price">${{ price | filterMoney }}</span>

        <span
          v-if="promotion.type"
          class="menu-card-container__promotion"
        >
          Promoción: {{ promotion.type }}
        </span>

        <p
          v-if="showDescription"
          :key="lang"
          class="menu-card-container__description"
          :class="{ 'collapse': !open && expandDescription == false }"
        >
          {{ displayLangAvailable(description) }}
        </p>
      </div>
    </div>
    <div v-if="open || editable" class="wrap-btn-plate">
      <v-btn
        icon
        flat
        class="btn-plate"
        color="white"
        @click="handleAmountChange('add')"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <span class="amount-label">{{ amount }}</span>

      <v-btn
        icon
        flat
        class="btn-plate btn-minus-plate"
        color="white"
        :disabled="lockMinus"
        @click="handleAmountChange('minus')"
      >
        <v-icon>remove</v-icon>
      </v-btn>
    </div>

    <div class="add-to-cart-section">
      <div v-if="editable" class="wrap-suggestion">
        <v-textarea
          auto-grow
          elevation="0"
          v-model="suggestion"
          density="0"
          rows="1"
          :color="'rgb(239, 79, 53)'"
          name="suggestion"
          class="suggestion"
          placeholder="Sugerencia"
          @input="handleChangeSuggestions"
          :outline="false"
        ></v-textarea>
      </div>
    </div>

    <div v-if="amount > 0 && (open && !editable)" class="add-to-cart-section">

      <div v-if="amount > 0 && (open || edit)" class="wrap-suggestion">
        <v-textarea
          auto-grow
          elevation="0"
          v-model="suggestion"
          density="0"
          rows="1"
          :color="'rgb(239, 79, 53)'"
          name="suggestion"
          class="suggestion"
          placeholder="Sugerencia"
          @input="handleChangeSuggestions"
          :outline="false"
        ></v-textarea>
      </div>

      <v-btn
        v-if="!showOrder"
        class="add-to-cart"
        outlined
        color="primary"
        flat
        @click="addToCart"
      >
        <span>AGREGAR AL CARRITO</span>
      </v-btn>
    </div>

    <v-snackbar
      v-model="added_to_cart"
      :timeout="3000"
      :top="true"
      class="snapbar-top"
      :light="true"
      :multi-line="true"
      :rounded="true"
      :max-width="200"
      @click="goToCart"
    >
      <template v-slot:default>
        <img class="icon" :src="require('@/assets/icons/cart.svg')" alt="">
        <div class="content">
          <div class="title"><b>Se agrego un producto al carrito</b></div>
          <div class="subtitle">{{ item.name }}</div>
        </div>
      </template>
    </v-snackbar>

    <EraseConfirmDialog
      title="Sólo puedes agregar productos de un restaurante cada vez"
      subtitle="¿Deseas vaciar el carrito y agregar este producto?"
      confirm="Vaciar carrito y agregar"
      :show-dialog="showEraseConfirmDialog"
      :confirm-dialog="eraseConfirmDialog"
      :close-dialog="closeEraseConfirmDialog"
    />
  </section>
</template>


<script>
  import defaultPlate from "@assets/images/default-plate.png";
  import STORE from "@/store/types";
  import EraseConfirmDialog from "@components/Cart/EraseConfirmDialog";

  export default {
    name: "MenuCard",
    props: {
      headquater: {
        type: String,
        default: ""
      },
      lockMinus: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        default: ""
      },
      showSelectOption: {
        type: Boolean,
        default: true
      },
      showOptions: {
        type: Boolean,
        default: false
      },
      showDescription: {
        type: Boolean,
        default: true
      },
      promotion: {
        type: Object,
        default: () => ({})
      },
      image: {
        type: String,
        default: defaultPlate
      },
      price: {
        type: Number,
        default: 0
      },
      description: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
        default: ""
      },
      lang: {
        type: String,
        default: ""
      },
      expandDescription: {
        type: Boolean,
        default: false
      },
      displaySuggestions: {
        type: Boolean,
        default: false
      },
      initialAmount: {
        type: Number,
        default: 1
      },
      initialSuggestion: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: () => ({})
      },
      editable: {
        type: Boolean,
        default: false
      },
      showOrder: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        open: this.displaySuggestions,
        edit: this.editable,
        amount: this.initialAmount,
        suggestion: this.initialSuggestion,
        added_to_cart: false,
        showEraseConfirmDialog: false
      };
    },
    components: { EraseConfirmDialog },
    created() {
      if (this.editable) {
        this.suggestion = this.initialSuggestion;
      }
    },
    mounted() {
      window.addEventListener("resetMenuCard", this.handleResetCard);
    },
    beforeDestroy() {
      window.removeEventListener("resetMenuCard", this.handleResetCard);
    },
    methods: {
      handleResetCard(event) {
        const { detail: id = "" } = event;
        const hasId = this.id === id;
        if (hasId) return this.resetState();
      },
      resetState() {
        this.amount = this.initialAmount;
        this.suggestion = "";
      },
      handleChangeSuggestions(val) {
        this.$emit("suggestion", val, this.id);
      },
      generateAmountFromDishes(dishes) {
        let result = 0;
        Object.keys(dishes).forEach(key => {
          result += dishes[key].amount;
        });
        return result;
      },
      toggleHeight() {
        if (this.editable) {
          this.$store.dispatch(STORE.ACTIONS.CART.REMOVE_CART_ITEM, {
            index: this.index
          });
        } else {
          this.open = !this.open;
        }
      },
      handleAmountChange(name) {
        if (name === "add") this.amount++;
        if (name === "minus" && this.amount > 0) this.amount--;
        this.$emit("changeAmount", this.id, this.amount);

        if (this.editable) {
          this.$store.dispatch(STORE.ACTIONS.CART.UPDATE_CART, {
            index: this.index,
            amount: this.amount
          });
        } else {
          if (this.amount === 0) this.suggestion = "";
        }
      },
      addToCart() {
        const self = this;
        const item = Object.assign({}, this.item);
        // eslint-disable-next-line
        item["amount"] = this.amount;
        // eslint-disable-next-line
        item["price"] = this.price;
        // eslint-disable-next-line
        item["suggestion"] = this.suggestion;

        this.$store.dispatch(STORE.ACTIONS.CART.ADD_TO_CART, {
          item,
          headquater: this.headquater,
          restaurant: this.$route.params.id
        }).then(() => {
          self.amount = self.initialAmount;
          self.open = false;
          self.added_to_cart = true;
        })
          .catch(() => {
            self.showEraseConfirmDialog = true;
          });
      },
      goToCart() {
        this.$router.push("/cart");
      },
      closeEraseConfirmDialog() {
        this.showEraseConfirmDialog = false;
      },
      eraseConfirmDialog() {
        const self = this;

        this.$store.dispatch(STORE.ACTIONS.CART.REMOVE_CART_ITEMS).then(() => {
          self.addToCart();
          self.showEraseConfirmDialog = false;
        });
      }
    },
    watch: {
      initialAmount(newVal) {
        if (this.editable) {
          this.amount = newVal;
        }
      }
    }
  };
</script>
