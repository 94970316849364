<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import UpdatePaymentForm from "@/components/forms/UpdatePaymentForm";

export default {
  name: "PayuModal",
  components: { UpdatePaymentForm },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    display: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    display(val) {
      this.open = val;
    }
  },
  data() {
    return {
      open: false,
      error: false,
      success: false,
      loading: false
    };
  },
  created() {
    this.open = this.display;
  },
  methods: {
    closeModal() {
      this.$refs.payuForm.resetState();
      this.$emit("close");
    }
  }
};
</script>
