<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
export default {
  name: "DishCategories"
};
</script>