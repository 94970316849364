<template src="./template.html"></template>

<style scoped lang="scss">
  @import "style";
</style>

<script>
  export default {
    name: "DeliveryCard",
    props: {
      card: {
        type: Object,
        default: () => {}
      },
      onChangeCard: {
        type: Function,
        default: () => {}
      }
    },
    components: {},
    created() {

    },
    methods: {
      onClickCard() {
        this.onChangeCard(this.card);
      }
    },
    data() {
      return {
      };
    }
  };
</script>