import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import App from "./App/index.vue";
import config from "./config.js";
import router from "./router";
import "@/styles/fonts.scss";
import "@/styles/app.scss";
import "@components/icons/glyphter-font/css/Glyphter.css";
import "./registerServiceWorker";

// CSS

import '@assets/css/fonts.css';
import '@assets/scss/styles.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';

// Store
import store from "./store";

// Mixins
import mixins from "@/mixins";

// Filters
import "@/filters";

// Plugins

import "@/plugins";

import Vuetify from "vuetify";

import i18n from "./lang";
import { disableZoomingEvents } from "@/utils/disableZoomingEvents";
import { onStageChange } from "@/utils/onStageChange";

const { DataProvider, UrlDataPersist } = mixins;


window.firebase = firebase.initializeApp(config);
window.fileStoreURL = config.fileStoreURL;
window.db = firebase.firestore();
window.auth = firebase.auth();
window.auth.languageCode = "es";
window.router = router;

disableZoomingEvents();

window.auth.onAuthStateChanged(onStageChange);

Vue.config.productionTip = false;

Vue.mixin(DataProvider);
Vue.mixin(UrlDataPersist);

new Vue({
  i18n,
  router,
  store,
  Vuetify,
  render: h => h(App)
}).$mount("#app");
