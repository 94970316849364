<style scoped lang="scss">
</style>

<template>
  <div>
    <gmap-map
      ref="map"
      :zoom="5"
      :options="mapOptions"
      :center="position"
      class="resturant-map"
    >
      <gmap-marker
        :animation="2"
        :icon="require('@/assets/icons/marker-now.svg')"
        :position="position"
        :clickable="true"
        :draggable="true"
        @dragend="handleClickOnMap"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import mapStyle from '@/utils/mapStyle.json';

export default {
 name: 'LocationMap',
 props: {
   location: {
     type: Object,
     default: () => (
      {
        lat: 4.0000000,
        lng: -72.0000000
      }
     )
   },
   mode: {
     type: String,
     default: 'create'
   }
 },
 data() {
    const mapOptions = {
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
      scaleControl: true,
      styles: mapStyle
    };
    return {
      mapOptions, position: this.location
    };
  },
 watch: {
   location(val) {
    this.position = val;
   }
 },
  methods: {
    handleClickOnMap(event) {
      const { latLng } = event;
      const lat = latLng.lat();
      const lng = latLng.lng();
      this.position.lat = lat;
      this.position.lng = lng;
    }
  }
};
</script>
