<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import MenuCard from "@components/cards/MenuCard.vue";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import EmptySection from "@/components/generals/EmptySection";

const { fetchMenu } = requests;

export default {
  name: "DishesGrid",
  components: { MenuCard, EmptySection },
  props: {
    step: {
      type: Number,
      default: 1
    },
    lang: {
      type: String,
      default: "es"
    },
    focus: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: ""
    },
    headquater: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      isEmpty: false,
      dishes: []
    };
  },
  watch: {
    focus(isFocus) {
      const { dishes = [] } = this;
      const shouldRequest = isFocus && dishes.length === 0;
      if (shouldRequest) {
        const { headquater = "", category = "" } = this;
        this.fetchData({ headquater, category });
      }
    }
  },
  methods: {
    matchOldDishes(dishes) {
      dishes.forEach(dish => {
        const match = this.order.dishes.hasOwnProperty(dish.id);
        if (match) {
          dish.amount = this.order.dishes[dish.id].amount || 0;
          dish.suggestion = this.order.dishes[dish.id].suggestion || "";
          this.order.dishes[dish.id].defaults = true;
        }
      });
      return dishes;
    },
    async fetchData(payload = {}) {
      const { headquater = "", category = "" } = payload;
      this.loading = true;
      this.isEmpty = false;
      this.$emit("fetching", this.loading);
      const { ok, result: dishes = [] } = await surePromise(fetchMenu(headquater, { categories: category }));
      this.loading = false;
      this.$emit("fetching", this.loading);
      if (ok) {
        this.isEmpty = dishes.length === 0;
        this.dishes = this.matchOldDishes(dishes);
        return;
      }
      this.error = true;
    },
    handleChangeSuggestion(suggestion, id) {
      const dish = Object.assign(
        {},
        this.dishes.find(item => item.id === id)
      );
      dish.price = Number(dish.price);
      const hasPromotion = dish.promotion && dish.promotion.available === true;
      if (hasPromotion) {
        dish.promotion = dish.promotion.type;
      } else {
        delete dish.promotion;
      }
      this.$emit("change", { ...dish, suggestion });
    },
    handleChangeAmount(id, amount) {
      const dish = Object.assign(
        {},
        this.dishes.find(item => item.id === id)
      );
      dish.price = Number(dish.price);
      const hasPromotion = dish.promotion && dish.promotion.available === true;
      if (hasPromotion) {
        dish.promotion = dish.promotion.type;
      } else {
        delete dish.promotion;
      }
      this.$emit("change", { ...dish, amount });
    }
  },
  mounted() {
    const { headquater = "", category = "" } = this;
    if (this.focus) this.fetchData({ headquater, category });
  }
};
</script>