<style scoped lang="scss">
</style>

<template>
  <div>
    <gmap-map
      ref="map"
      :zoom="5"
      :options="mapOptions"
      :center="position"
      class="resturant-map"
    >
      <gmap-marker
        :animation="2"
        :icon="require('@/assets/icons/avatar.svg')"
        :position="position"
        :clickable="true"
        :draggable="true"
        @dragend="handleClickOnMap"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
  import mapStyle from "@/utils/mapStyle.json";

  export default {
    name: "UserMap",
    props: {
      location: {
        type: Object,
        default: () => (
          {
            lat: 4.0000000,
            lng: -72.0000000
          }
        )
      },
      mode: {
        type: String,
        default: "create"
      },
      updatedLocation: {
        type: Function,
        default: () => {
        }
      }
    },
    data() {
      const mapOptions = {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        scaleControl: true,
        styles: mapStyle
      };
      return {
        mapOptions, position: this.location
      };
    },
    created() {
      this.updatedLocation(this.location.lat, this.location.lng);
    },
    watch: {
      location(val) {
        this.position = val;
        this.updatedLocation(this.position.lat, this.position.lng);
      }
    },
    methods: {
      handleClickOnMap(event) {
        const { latLng } = event;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.position.lat = lat;
        this.position.lng = lng;

        this.updatedLocation(lat, lng);
      }
    }
  };
</script>
