<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import MenuCard from "@components/cards/MenuCard";
  import { mapState } from "vuex";
  import onPWA from "@/utils/onPWA.js";
  import SocialMediaList from "@lists/SocialMediaList";
  import LocationMap from "@components/maps/LocationMap";
  import OrderStatus from "@components/Cart/OrderStatus";
  import STORE from "@/store/types";
  import RouteRestaurantMap from "@components/maps/RouteRestaurantMap";

  export default {
    name: "OrderStatus",
    props: {
      isModal: {
        type: Boolean,
        default: true
      },
      currentStep: {
        type: Number,
        default: 1
      },
      order: {
        type: Object,
        default: () => {
        }
      },
      center: {
        type: Object,
        default: () => ({
          lat: 0,
          lng: 0
        })
      },
      location: {
        type: Object,
        default: () => ({
            lat: 0,
            lng: 0
          })
      },
      closeOrder: {
        type: Function,
        default: () => {}
      }
    },
    components: { MenuCard, SocialMediaList, LocationMap, OrderStatus, RouteRestaurantMap },
    computed: {
      ...mapState({}),
      getOrderDoneText() {
        if (this.order.order_type === 'domicilio') {
          return "Tu orden esta en camino";
        } else if (this.order.order_type === 'recoger') {
          return "Tu orden esta lista para llevar";
        } else if (this.order.order_type === 'comer') {
          return "Tu mesa esta servida";
        }
      }
    },
    data() {
      return {
        onPWA: onPWA(),
        mode: "",
        headquater: {},
        minutes: "",
        distance: ""
      };
    },
    created() {
      const self = this;
      this.showCart = this.openCart;
      setTimeout(() => { self.$refs.howToArriveMap.getRoute(); }, 100);
    },
    mounted() {
      this.loading = false;
    },
    methods: {
      setDrivingDuration(minutes, distance) {
        this.minutes = minutes;
        this.distance = distance;
      },
      getOrderId(order) {
        console.log(order);
        const date = order.createAt.toDate();
        const h = date.getHours().toString();
        const m = date.getMinutes().toString();
        const s = date.getSeconds().toString();
        return h + m + s;
      },
      closeCart() {
        this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_CART);
        this.$store.dispatch(STORE.ACTIONS.CART.CLOSE_SUCCESS_ORDER);
        this.$router.push("/home");
      },
      goToYourOrder() {
        this.$router.push("/your-order");
      }
    },
    watch: {
      openCart(newVal) {
        this.showCart = newVal;
      },
      order: {
        deep: true,
        handler(newVal) {
          console.log(newVal);
        }
      }
    }
  };
</script>