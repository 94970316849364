<style scoped lang="scss">
.v-item--active {
  height: 300px !important;
}
.tab-item-container__loader {
  position: relative;
  top: 25px;
  text-align: center;
}
.tab-item-container__wrap-search {
  display: grid;
  grid-template-columns: 378px 35px;
  height: 45px;
  margin-top: 20px;
  padding-left: 10px;
}

.tab-item-container__btn--search {
  background-position: center;
  background-image: url("~@assets/icons/search.png");
  background-repeat: no-repeat;
  background-size: 18px;
}

::v-deep .basic-input {
  position: relative;
  top: 0px;
}
.tab-item-container {
  max-height: 420px;
  min-height: 420px;
  overflow-y: auto;
}
.wrap-categories-loader {
  text-align: center;
  padding: 10px;
  &__btn-loadmore {
    border-radius: 12px;
    width: 100%;
    height: 45px;
    margin-left: 0px;
    font-size: 13px;
    font-family: Gibson-Bold;
  }
}

::v-deep .container-list {
  padding-top: 20px;
}
::v-deep .tab-title {
  margin-bottom: 5px;
  a {
    color: #16202f !important;
    font-size: 15px;
    font-family: Gibson-Bold;
    text-transform: uppercase;
  }
}
::v-deep .tab-title-active {
  background: white !important;
  border-bottom: 3px solid #ed2236;
  color: #16202f;
  font-size: 15px;
  font-family: Gibson-Bold;
  text-transform: uppercase;
}
</style>

<template>
  <v-tabs v-model="focus" color="#fff" hide-slider :show-arrows="false" :grow="true" :max="200">
    <v-tab
      class="tab-title"
      active-class="tab-title-active"
    >{{ $t('lang.CategoryTabs.categories') }}</v-tab>

    <v-tab class="tab-title" active-class="tab-title-active">{{ $t('lang.CategoryTabs.addBtn') }}</v-tab>

    <v-tab-item class="tab-item-container scroll-bar">
      <div v-show="searching === false" class="tab-item-container__wrap-search">
        <BasicInput
          :label="$t('lang.CategoryTabs.searchCategoryLabel')"
          :valid="true"
          styl
          :dark="true"
          :msg="$t('lang.CategoryTabs.searchCategoryLabelValid')"
          :disabled="loading"
          @input-change="handleSearchInputChange"
        ></BasicInput>
        <v-btn
          flat
          icon
          class="tab-item-container__btn--search"
          :disabled="loading"
          @click="searchCategory"
        ></v-btn>
      </div>

      <v-container v-if="search !== '' && categories.length === 0 && searching === false">
        <p>
          {{ $t('lang.CategoryTabs.noResultFor') }}
          <b>{{ search }}</b>
        </p>
      </v-container>

      <v-container v-if="searching === true" class="tab-item-container__loader">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-container>

      <CategoriesList
        mode="edit"
        :caption="$t('lang.CategoryTabs.selectCategoryField')"
        :categories="categories"
        @select="(payload) => $emit('select', payload)"
        @unselect="$emit('unselect')"
        @deleted="$emit('requestSuccess')"
      ></CategoriesList>
      <div v-if="complete === false" class="wrap-categories-loader">
        <v-btn
          v-if="categories.length && (loading === false || searching === true)"
          color="primary"
          class="wrap-categories-loader__btn-loadmore"
          @click="loadMore"
        >{{ $t('lang.CategoryTabs.showMoreCTA') }}</v-btn>
        <v-progress-circular v-if="loading === true" indeterminate color="primary"></v-progress-circular>
      </div>
    </v-tab-item>

    <v-tab-item>
      <CategoriesForm
        ref="categoryForm"
        mode="create"
        @success="handleFormSubmitSuccess"
        @error="$emit('error', $event) "
      ></CategoriesForm>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import CategoriesList from "@components/lists/CategoriesList";
import BasicInput from "@components/inputs/BasicInput";
import CategoriesForm from "@components/forms/CategoriesForm";
import requests from "@/helpers/requests";

export default {
  name: "CategoriesTabsManager",
  components: { CategoriesList, BasicInput, CategoriesForm },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      search: "",
      complete: false,
      searching: false,
      loading: true,
      ready: false,
      error: false,
      success: false,
      categories: [],
      focus: 0,
      queryOptions: { limit: 10 }
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    getSelectedCategories() {
      return this.categories.filter(category => category.selected);
    },
    handleFormSubmitSuccess(event) {
      this.$emit("requestSuccess", event);
      this.$refs.categoryForm.reset();
      this.focus = 0;
      this.categories = [];
      this.queryOptions.limit = 10;
      delete this.queryOptions.startAt;
      this.getCategories();
    },
    handleSearchInputChange(val = "") {
      this.search = val;
      this.complete = val !== "";
    },
    searchCategory() {
      delete this.queryOptions.limit;
      delete this.queryOptions.startAt;
      this.queryOptions.name = this.search;
      this.categories = [];
      this.searching = true;
      this.queryOptions.limit = 10;
      this.getCategories().then(() => {
        this.searching = false;
      });
    },
    getCategories() {
      return requests
        .fetchCategories({ ...this.queryOptions })
        .then(this.handleGetCategories)
        .catch(this.handleGetCategoriesFail);
    },
    loadMore() {
      this.loading = true;
      this.getCategories().then(() => {
        this.loading = false;
      });
    },
    handleGetCategories(response = []) {
      this.complete = response.length === 0;
      response.forEach(item => {
        const isSelected = this.data.hasOwnProperty(item.id);
        item.selected = isSelected;
      });
      this.categories = this.categories.concat(response);
      this.loading = false;
      if (this.categories.length) {
        this.queryOptions.startAt = this.categories[
          this.categories.length - 1
        ].id;
      }
      this.queryOptions.limit = 10;
      delete this.queryOptions.name;
    },
    handleGetCategoriesFail(error) {
      console.log("error ", error);
      this.loading = false;
      this.error = true;
      this.$emit("requestFail");
    }
  }
};
</script>
