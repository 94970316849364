<style scoped lang="scss">
::v-deep .basic-input ::v-deep .v-input__slot {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25) !important;
}
::v-deep .field-msg {
  color: red !important;
}

.edit-form__btn-submit {
  width: 100%;
  height: 45px;
  border-radius: 12px;
  font-family: Gibson-Bold;
  margin: 0px;
}
</style>
<template>
  <v-form ref="tableForm">
    <v-container>
      <p>Nombre</p>
      <BasicInput
        :default-value="name"
        :disabled="loading"
        type="text"
        label="Nombre"
        :valid="validity.name"
        styl
        :msg="msgName"
        :reset-input="reset"
        :overlay="false"
        @input-change="handleFieldChange('name', $event)"
      ></BasicInput>

      <p>Ubicación</p>
      <BasicInput
        :default-value="location"
        :disabled="loading"
        type="text"
        label="Ubicación"
        :valid="validity.location"
        styl
        :reset-input="reset"
        :msg="'Escribe una ubicacion valida'"
        :overlay="false"
        @input-change="handleFieldChange('location', $event)"
      ></BasicInput>

      <p>Puestos</p>
      <BasicInput
        :default-value="chairs"
        :disabled="loading"
        type="number"
        label="Puestos"
        :valid="validity.chairs || chairs === ''"
        styl
        :reset-input="reset"
        :msg="'Escribe el numero de puestos'"
        :overlay="false"
        :min="0"
        @input-change="handleFieldChange('chairs', $event)"
      ></BasicInput>

      <v-btn
        v-if="loading === false"
        class="edit-form__btn-submit"
        color="primary"
        :disabled="disabled"
        @click="handleSubmit"
      >{{ btnTextAgree }}</v-btn>

      <transition name="appear">
        <div v-if="loading" class="text-xs-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </transition>
    </v-container>
  </v-form>
</template>

<script>
import BasicInput from "@inputs/BasicInput";
import validators from "@utils/validators";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { createTable, updateTable } = requests;

export default {
  name: "TableForm",
  components: {
    BasicInput
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    tables: {
      type: Array,
      default: () => []
    },
    location: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: ""
    },
    chairs: {
      type: String,
      default: "0"
    },
    display: {
      type: Boolean,
      default: false
    },
    belongsTo: {
      type: String,
      default: ""
    },
    btnTextAgree: {
      type: String,
      default: "Crear"
    }
  },
  data() {
    return {
      loading: false,
      disabled: true,
      emptyField: false,
      reset: false,
      repeats: {
        name: false
      },
      validity: {
        name: false,
        location: false,
        chairs: false
      },
      changes: {
        name: "",
        location: "",
        chairs: ""
      }
    };
  },
  mounted() {
    const { name = "", location = "", chairs = "" } = this;
    this.changes = {
      name,
      location,
      chairs
    };
    this.validity = {
      name: validators.validWord(name),
      location: validators.validText(location),
      chairs: validators.validNumber(chairs)
    };
  },
  computed: {
    msgName() {
      let result = "";
      if (this.isRepeat("name")) {
        result = "Este nombre ya fue utilizado";
      } else {
        result = "Escribe un nombre valido";
      }
      return result;
    }
  },
  watch: {
    display(val) {
      const { name = "", location = "", chairs = "" } = this;
      if (val) {
        this.validity.name = validators.validWord(name);
        this.validity.chairs = validators.validNumber(chairs) && chairs !== "0";
        this.validity.location = validators.validText(location);
        this.changes = { name, location, chairs };
      } else {
        this.changes = { name: "", location: "", chairs: "" };
        this.disabled = true;
      }
    },
    loading(val) {
      this.$emit("loading", val);
    }
  },
  methods: {
    resetState() {
      Object.assign(this.$data, this.$options.data());
    },
    resetForm() {
      this.resetState();
    },
    isRepeat(field) {
      return this.tables.filter(table => table[field] !== this[field]).some(table => table[field] === this.changes[field]);
    },
    shouldBeDisabled() {
      let disabled = true;
      const editMode = this.mode === "edit";
      if (editMode) {
        const isEqual = this.dataIsEqual();
        const notEqual = isEqual === false;
        if (notEqual) {
          const changes = this.getFieldChanges();
          const changesAreValids = Object.keys(changes).every(field => this.validity[field] === true);
          if (changesAreValids) disabled = false;
        }
      } else {
        const allFieldsAreValids = Object.keys(this.validity).every(field => this.validity[field] === true);
        if (allFieldsAreValids) disabled = false;
      }
      return disabled;
    },
    dataIsEqual() {
      const changes = this.getFieldChanges();
      const isEqual = Object.keys(changes).every(key => changes[key] === this[key]);
      return isEqual;
    },
    getFieldChanges() {
      const result = {};
      Object.keys(this.changes).forEach(field => {
        const hasChange = this.changes[field] !== this[field];
        if (hasChange) result[field] = this.changes[field];
      });
      return result;
    },
    async editTable() {
      const changes = this.getFieldChanges();
      const { ok, result, error } = await surePromise(updateTable(this.id, changes));
      this.loading = false;
      if (ok) {
        this.$emit("success", true, result);
        return;
      }
      this.$emit("success", false, result);
      this.$emit("error", true, error);
    },
    async addTable() {
      const { name = "", location = "" } = this.changes;
      const { ok, error } = await surePromise(
        createTable({
          belongsTo: this.belongsTo,
          location,
          chairs: Number(this.changes.chairs),
          name
        })
      );
      this.loading = false;
      if (ok) {
        this.$emit("success", true);
        this.changes = { name: "", location: "", chairs: "" };
        this.disabled = true;
        this.validity = { name: false, location: false, chairs: false };
        this.reset = true;
        return;
      }
      this.$emit("success", false);
      this.$emit("error", true, error);
    },
    handleSubmit() {
      this.loading = true;
      const editMode = this.mode === "edit";
      const createMode = this.mode === "create";
      if (editMode) return this.editTable();
      if (createMode) return this.addTable();
    },
    handleFieldChange(field, value) {
      this.changes[field] = value;
      const isName = field === "name";
      const isChair = field === "chairs";
      const isLocation = field === "location";
      if (isName) {
        this.validity.name = validators.validWord(value) && this.isRepeat("name") === false;
      }
      if (isChair) this.validity.chairs = validators.validNumber(value) && value !== "0";
      if (isLocation) this.validity.location = validators.validText(value);
      this.disabled = this.shouldBeDisabled();
    }
  }
};
</script>
