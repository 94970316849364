<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import DishCard from "@components/cards/DishCard";

export default {
  name: "OrderGrid",
  components: { DishCard },
  props: {
    dishes: {
      type: Array,
      default: () => []
    }
  }
};
</script>