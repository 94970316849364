/* eslint-disable */
import orders from "./orders";
import headquater from "./headquater";
import afterwareRequest from "@/helpers/afterwareRequest";
import assignWaiter from "./assignWaiter";
import requestWebcheckout from "./requestWebcheckout";
import confirmUserRequest from "./confirmUserRequest";
import dispatchOrder from "./dispatchOrder";
import waiterNotification from "./waiterNotification";
import adminNotification from "./adminNotification";
import checkPhoneNumber from "./checkPhoneNumber";
import deleteTable from "./deleteTable";
import createCategory from "./createCategory";
import dispatchReservationExpired from "./dispatchReservationExpired";
import createTable from "./createTable";
import createReservation from "./createReservation";
import showMenu from "./showMenu";
import showInvoiceScreen from "./showInvoiceScreen";
import createOrder from "./createOrder";
import addDishesToOrder from "./addDishesToOrder";
import updateUser from "./updateUser";
import updateComment from "./updateComment";
import updateOrder from "./updateOrder";
import updateProfile from "./updateProfile";
import updateDish from "./updateDish";
import notifyDishPromo from "./notifyDishPromo";
import confirmDishPromo from "./confirmDishPromo";
import updateTable from "./updateTable";
import saveDish from "./saveDish";
import sendInvoice from "./sendInvoice";
import saveHeadquater from "./saveHeadquater";
import updateHeadquater from "./updateHeadquater";
import deleteDish from "./deleteDish";
import deleteHeadquater from "./deleteHeadquater";
import updateReservation from "./updateReservation";
import switchTable from "./switchTable";
import fetchCategories from "./fetchCategories";
import fetchOrders from "./fetchOrders";
import fetchOrder from "./fetchOrder";
import fetchUser from "./fetchUser";
import fetchUserByBatch from "./fetchUserByBatch";
import fetchTable from "./fetchTable";
import fetchRestaurant from "./fetchRestaurant";
import fetchReservations from "./fetchReservations";
import fetchHeadquatersAt from "./fetchHeadquatersAt";
import fetchHeadquaters from "./fetchHeadquaters";
import fetchEmployeesByHeadquater from "./fetchEmployeesByHeadquater";
import fetchReservationsByHeadquater from "./fetchReservationsByHeadquater";
import fetchLanguages from "./fetchLanguages";
import fetchDishes from "./fetchDishes";
import fetchMenu from "./fetchMenu";
import fetchArticles from "./fetchArticles";
import cancelOrder from "./cancelOrder";
import fetchArticle from "./fetchArticle";
import fetchArticleContent from "./fetchArticleContent";
import fetchDish from "./fetchDish";
import fetchListOfCategories from "./fetchListOfCategories";
import fetchHeadquater from "./fetchHeadquater";
import fetchProfile from "./fetchProfile";
import updateEmployeeFromHeadquater from "./updateEmployeeFromHeadquater";
import validateInvitation from "./validateInvitation";
import deleteCategory from "./deleteCategory";
import getTables from "./getTables";
import setLike from "./setLike";
import deleteComment from "./deleteComment";
import linkTables from "./linkTables";
import fetchClients from "./fetchClients";
import getOrdersByDate from "./getOrdersByDate";
import fetchWaitersFromHeadquater from "./fetchWaitersFromHeadquater";
import logout from "./logout";
import invitate from "./invitate";
import updatePayuInfo from "./updatePayuInfo";
import updateDishStatus from "./updateDishStatus";
import updateDeliveryDishStatus from "./updateDeliveryDishStatus";
import removeDishFromOrder from "./removeDishFromOrder";
import getComments from "./getComments";
import populateOrders from "./populateOrders";
import createAlbum from "./createAlbum";
import uploadMedia from "./uploadMedia";
import getAllAlbums from "./getAllAlbums";
import getAlbumPicture from "./getAlbumPicture";
import getAccounting from "./getAccounting";
import getAccountings from "./getAccountings";
import startBoxflow from "./startBoxflow";
import finishBoxFlow from "./finishBoxFlow";
import aggregateOrdersByDateRange from "./aggregateOrdersByDateRange";
import createAccounting from "./createAccounting";
import closeAccounting from "./closeAccounting";
import createPayroll from "./createPayroll";
import deletePanoramic from "./deletePanoramic";
import deleteAlbum from "./deleteAlbum";
import updateFile from "./updateFile";
import updateAlbum from "./updateAlbum";
import createDelivery from "./createDelivery";
import updateDelivery from "./updateDelivery";
import getDeliverys from "./getDeliverys";
import fetchDelivery from "./fetchDelivery";
import userNotification from "./userNotification";

export const BASE = "https://us-central1-geochef-dev.cloudfunctions.net/api";
 // export const BASE = "http://localhost:5000/geochef-dev/us-central1/api";

const requests = {
  userNotification,
  fetchDelivery,
  assignWaiter,
  invitate,
  updateUser,
  updateComment,
  startBoxflow,
  createAccounting,
  createPayroll,
  closeAccounting,
  linkTables,
  deleteComment,
  deleteTable,
  aggregateOrdersByDateRange,
  fetchRestaurant,
  fetchClients,
  fetchHeadquaters,
  fetchHeadquater,
  fetchCategories,
  fetchWaitersFromHeadquater,
  fetchUserByBatch,
  fetchArticleContent,
  fetchReservationsByHeadquater,
  fetchOrders,
  fetchOrder,
  populateOrders,
  getOrdersByDate,
  getAccountings,
  getAccounting,
  updateDishStatus,
  updateDeliveryDishStatus,
  updatePayuInfo,
  logout,
  setLike,
  fetchMenu,
  fetchTable,
  fetchUser,
  fetchDishes,
  fetchDish,
  fetchLanguages,
  fetchListOfCategories,
  fetchArticles,
  fetchArticle,
  createReservation,
  createOrder,
  switchTable,
  createCategory,
  createTable,
  cancelOrder,
  deleteHeadquater,
  fetchReservations,
  updateReservation,
  updateProfile,
  updateTable,
  updateHeadquater,
  updateDish,
  notifyDishPromo,
  confirmDishPromo,
  updateOrder,
  saveDish,
  sendInvoice,
  saveHeadquater,
  deleteDish,
  addDishesToOrder,
  requestWebcheckout,
  deleteCategory,
  fetchHeadquatersAt,
  fetchProfile,
  finishBoxFlow,
  checkPhoneNumber,
  showMenu,
  showInvoiceScreen,
  waiterNotification,
  adminNotification,
  validateInvitation,
  confirmUserRequest,
  fetchEmployeesByHeadquater,
  updateEmployeeFromHeadquater,
  removeDishFromOrder,
  dispatchReservationExpired,
  dispatchOrder,
  getTables,
  getComments,
  createAlbum,
  uploadMedia,
  getAllAlbums,
  getAlbumPicture,
  deletePanoramic,
  deleteAlbum,
  updateFile,
  updateAlbum,
  createDelivery,
  updateDelivery,
  getDeliverys
};

export default afterwareRequest(requests);
