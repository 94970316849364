<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import BoxFlowForm from "@/components/forms/BoxFlowForm";

export default {
  name: "BoxFlowModal",
  components: { BoxFlowForm },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    display: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ""
    },
    accounting: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    flowInitializated: {
      type: Boolean,
      default: false
    },
    onFlowInit: {
      type: Function,
      default: () => function () {}
    },
    onFlowEnd: {
      type: Function,
      default: () => function () {}
    }
  },
  watch: {
    display(val) {
      this.open = val;
      if (!val) {
        this.$refs.boxFlowForm.resetState();
      }
    }
  },
  data() {
    return {
      open: false,
      error: false,
      success: false,
      loading: false
    };
  },
  created() {
    this.open = this.display;
  },
  methods: {
    handleInitFlow(...values) {
      this.$emit("close");
      this.onFlowInit(...values);
    },
    handleEndFlow(...values) {
      this.$emit("close");
      this.onFlowEnd(...values);
    }
  }
};
</script>
