<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import moment from "moment";
import mixins from "@/mixins";
import AccountingHeader from "@/components/Headers/AccountingHeader";
import AccountingTabs from "@components/generals/AccountingTabs";
import AccountingTable from "@/components/tables/AccountingTable";
import BoxFlowModal from "@/components/modals/BoxFlowModal";
import AccountingModal from "@/components/modals/AccountingModal";
import uuid from "@utils/uuid";
const { Accounting } = mixins;
moment.locale("es");

export default {
  name: "AccountingBoard",
  mixins: [Accounting],
  components: {
    AccountingModal,
    AccountingHeader,
    AccountingTable,
    AccountingTabs,
    BoxFlowModal
  },
  props: {
    headquater: {
      type: Object,
      default: () => this.getHeadquaterSelectedFromUserProfile()
    }
  },
  data() {
    return {
      tableKey: uuid()
    };
  },
  computed: {
    caption() {
      if (this.flow.initialized) return "Cerrar caja";
      return "Abrir caja";
    }
  },
  methods: {
    aggregateByHour(payrolls) {
      return Object.entries(
        payrolls.reduce((accumulator, payroll) => {
          const { value, createAt, type = "" } = payroll;
          const isRevenue = type === "REVENUE";
          const isEgress = type === "EGRESS";
          const hour = createAt._seconds ? moment(createAt._seconds * 1000).format("h a") : moment(new Date(createAt)).format("h a");
          if (accumulator.hasOwnProperty(hour)) {
            if (isRevenue) accumulator[hour] += value;
            if (isEgress) accumulator[hour] -= value;
          } else {
            accumulator[hour] = value;
          }
          return accumulator;
        }, {})
      );
    },
    aggregateByDate(payrolls) {
      return Object.entries(
        payrolls.reduce((accumulator, payroll) => {
          const { value, createAt, type = "" } = payroll;
          const isRevenue = type === "REVENUE";
          const isEgress = type === "EGRESS";
          const date = createAt._seconds
            ? moment(createAt._seconds * 1000).format("YYYY/MM/DD - h:mm:ss a")
            : moment(new Date(createAt)).format("YYYY/MM/DD - h:mm:ss a");
          if (accumulator.hasOwnProperty(date)) {
            if (isRevenue) accumulator[date] += value;
            if (isEgress) accumulator[date] -= value;
          } else {
            accumulator[date] = value;
          }
          return accumulator;
        }, {})
      );
    },
    mapPayrollsToDataset(payrolls) {
      const result = [];
      payrolls.forEach(() => {});
      return result;
    },
    mapTimestampToDate(data = []) {
      return data.map(item => {
        if (item.createAt.seconds) item.createAt = new Date(item.createAt.seconds * 1000);
        if (item.createAt._seconds) item.createAt = new Date(item.createAt._seconds * 1000);
        return item;
      });
    },
    calculateFields(field) {
      const result = {};
      const isRevenue = field.type === "REVENUE";
      const isEgress = field.type === "EGRESS";
      if (isRevenue) result.revenue = Number(field.value);
      if (isEgress) result.egress = Number(field.value);
      result.id = field.id;
      result.createAt = field.createAt;
      return result;
    },
    groupBy(data = [], time) {
      return data.reduce((accumulator, currentValue) => {
        const isHour = time === "hour";
        const isDay = time === "day";
        if (isHour) {
          const hour = new Date(currentValue.createAt).getHours();
          if (accumulator.hasOwnProperty(hour)) {
            accumulator[hour].push(this.calculateFields(currentValue));
            return accumulator;
          }
          accumulator[hour] = [this.calculateFields(currentValue)];
          return accumulator;
        }
        if (isDay) {
          const day = currentValue.createAt.getDate();
          if (accumulator.hasOwnProperty(day)) {
            accumulator[day].push(this.calculateFields(currentValue));
            return accumulator;
          }
          accumulator[day] = [this.calculateFields(currentValue)];
        }
        return accumulator;
      }, {});
    },
    calculatePayrolls(data = []) {
      const result = data.reduce(
        (accumulator, current) => {
          accumulator.createAt = current.createAt;
          const isRevenue = current.hasOwnProperty("revenue");
          if (isRevenue) {
            accumulator.revenue += Number(current.revenue);
            return accumulator;
          }
          accumulator.egress += Number(current.egress);
          return accumulator;
        },
        { revenue: 0, egress: 0, value: 0 }
      );
      result.value = result.revenue - result.egress;
      return result;
    },
    mapGroups(groups) {
      Object.keys(groups).forEach(key => {
        const group = groups[key];
        groups[key] = [this.calculatePayrolls(group)];
      });
      return groups;
    },
    async handleSubmitAccountingForm(payroll) {
      this.displayAccountingModal = false;
      this.success = true;
      this.accounting.payrolls = [...this.accounting.payrolls, payroll];
      this.accounting = this.accounting;
      const isRevenue = payroll.type === "REVENUE";
      if (isRevenue) {
        this.currentValue += payroll.value;
      } else {
        this.currentValue -= payroll.value;
      }
      await this.$nextTick();
      this.tableKey = uuid();
    },
    handleAccounting() {
      this.displayAccountingModal = true;
    },
    handleOpenBox() {
      this.displayBoxFlowModal = true;
    },
    handleCloseBox() {
      this.displayBoxFlowModal = true;
    },
    handleFlowInit(value, accounting) {
      this.flow.initialized = true;
      this.flow.initialAmount = value;
      this.accounting = accounting;
      this.currentValue = this.accounting.currentValue;
      const { headquaters = {} } = window.user;
      const headquater = Object.keys(headquaters).filter(headquater => headquater.id === this.headquater.id);
      headquater.boxflow = false;
      this.headquater.boxflow = false;
    },
    handleFlowEnd() {
      const { headquaters = {} } = window.user;
      const headquater = Object.keys(headquaters).filter(headquater => headquater.id === this.headquater.id);
      console.log('restaurantes', headquater);
      headquater.boxflow = false;
      this.flow.complete = true;
      this.flow.initialized = false;
      this.headquater.boxflow = false;
      this.flow.endAmount = 0;
      this.payrolls = null;
      this.accounting = null;
      this.currentValue = 0;
      this.$refs.acountingHeader.selected = "1";
    }
  }
};
</script>
