<template>
  <div class="empty-section" :class="{'empty-section--light': light}">
    <div class="empty-table__img">
      <slot name="image"></slot>
    </div>
    <h4 class="empty-section__title">{{ title }}</h4>
    <p class="empty-section__text">{{ text }}</p>
    <v-btn
      v-show="showBtn"
      @click="eventHandle"
      class="empty-section__btn"
      color="primary"
    >{{ btnText}}</v-btn>
    <slot name="footer"></slot>
  </div>
</template>
<script>
export default {
  name: "EmptySection",
  props: {
    imgSrc: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Untitle"
    },
    text: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: "Untitle"
    },
    light: {
      type: Boolean,
      default: false
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    eventHandle: {
      type: Function,
      default: () => null
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_colors";
.empty-section--light {
  color: white !important;
}
.empty-section {
  color: #00000063;
  min-width: 265px;
  max-width: 400px;
  margin: 0 auto;
  display: block;

  &__img,
  img {
    margin: 0 auto;
    display: block;
    margin-top: 50px;
    margin-bottom: 20px;
    height: 120px;
    width: 120px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500px;
    text-align: center;
    line-height: 32px;
    color: $primary;
  }

  &__text {
    text-align: center;
    font-size: 16px;
  }

  &__btn {
    min-width: 250px;
    border-radius: 12px;
    height: 50px;
    padding: 0px;
    font-family: Gibson-Bold;
    margin: 0px;
  }
}
</style>