<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import Comments from "@components/generals/Comments/Comments.vue";
import SaveAndDeleteCombo from "@components/generals/SaveAndDeleteCombo";
import EmptySection from "@/components/generals/EmptySection";
import BasicSwitcher from "@components/switchers/BasicSwitcher.vue";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import moment from "moment";
import mixin from '@/mixins';
import { notifyEvent } from "@/utils/notifyEvent";

const { updateComment, deleteComment } = requests;
const { getComments } = requests;
const { MenuMixin } = mixin;

export default {
  name: "AdminComments",
  components: {
    Checkbox,
    Comments,
    Loading,
    HeadquatersSelect,
    UserBasicInfo,
    BasicSwitcher,
    EmptySection,
    SaveAndDeleteCombo,
    ReservationsComboTables
  },
  mixins: [MenuMixin],
  data() {
    return {
      headquaterSelected: {},
      comments: [],
      comment: {},
      showDetails: false,
      currentTab: 0,
      hideComment: false,
      headquaters: {},
      headquater: this.getHeadquaterSelectedFromUserProfile(),
      showOverlay: false,
      fetchingComments: true,
      success: false,
      error: false,
      loading: true
    };
  },
  created() {
    const { headquaters = {} } = window.user;
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
    this.headquaterSelected = this.headquater;
    this.headquaters = headquaters;
  },
  computed: {
    toggleSwitchText() {
      if (this.comment.enable) return "Ocultar";
      return "Mostrar";
    },
    emptyDisable() {
      return this.filterByEnable(this.comments, false).length === 0;
    },
    emptyEnable() {
      return this.filterByEnable(this.comments, true).length === 0;
    },
    getHeadquaterSelected() {
      const headquater = Object.keys(this.headquaters).find(key => this.headquaters[key].selected === true);
      return this.headquaters[headquater];
    },
    hasCommentSelected() {
      const result = Object.keys(this.comment).length !== 0;
      this.showDetails = result;
      return result;
    }
  },
  watch: {
    showOverlay(val) {
      return notifyEvent({ name: "overlay", detail: val });
    },
    async currentTab(val) {
      this.showDetails = false;
      this.comment = {};
      this.comments = [];
      const enable = val === 0;
      await this.fetchComments({ enable });
    }
  },
  async mounted() {
    this.loading = false;
    this.comments = [];
    const enable = this.currentTab === 0;
    await this.fetchComments({ enable });
    window.addEventListener("notification", this.handleNewNotification);
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
  },
  beforeDestroy() {
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.removeEventListener("notification", this.handleNewNotification);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  methods: {
    filterByEnable(comments, enable) {
      return comments.filter(comment => comment.enable === enable);
    },
    setComments({ id = "", comment, enable }) {
      const match = comment.id === id;
      if (match) comment.enable = enable;
      return comment;
    },
    async handleDeleteComment() {
      const { id = "" } = this.comment;
      const { ok } = await surePromise(deleteComment(id));
      this.$refs.saveComment.deleting = false;
      this.$refs.saveComment.saving = false;
      if (ok) {
        this.success = true;
        this.comments = this.comments.filter(comment => comment.id !== id);
        this.showDetails = false;
        return;
      }
      this.error = true;
    },
    async handleSaveUpdateComment() {
      const { enable, id = "" } = this.comment;
      const { ok } = await surePromise(updateComment(id, { enable }));
      this.$refs.saveComment.deleting = false;
      this.$refs.saveComment.saving = false;
      if (ok) {
        this.success = true;
        this.comments = this.comments.map(comment => this.setComments({ id, comment, enable }));
        this.showDetails = false;
        return;
      }
      this.error = true;
    },
    isPopulated(val) {
      return Object.keys(val).length !== 0;
    },
    handleSelectComment(comment) {
      this.comment = { ...comment };
    },
    async fetchComments(query) {
      this.fetchingComments = true;
      const { ok, result: comments = [] } = await surePromise(getComments(this.headquater.id, query));
      if (ok) {
        this.fetchingComments = false;
        if (comments.length) {
          this.comments = comments.map(comment => {
            comment.publishAt = moment(comment.createAt._seconds * 1000).format("MMMM Do YYYY, h:mm:ss a");
            return comment;
          });
        }
        return;
      }
      this.fetchingComments = false;
      this.error = true;
    },
    handleFailOperation() {
      this.error = true;
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    setHeadquaterSelected(headquater) {
      this.headquatersSelected = headquater;
      this.headquaters[this.headquatersSelected.id].selected = false;
      this.headquaters[headquater.id].selected = true;
    },
    async handleSelectHeadquater(event) {
      const headquater = event.detail;
      this.headquater = headquater;
      this.setHeadquaterSelected(headquater);
      this.comments = [];
      this.comment = {};
      const enable = this.currentTab === 0;
      await this.fetchComments({ enable });
    }
  }
};
</script>
