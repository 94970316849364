import buildUrlQuery from "@utils/buildUrlQuery.js";
import { BASE } from "./index";
const getTables = (query = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const params = buildUrlQuery(query);
  const url = `${BASE}/tables?${params}`;
  return fetch(url, { method: "GET", headers: { token } }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default getTables;
