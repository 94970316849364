function resolveDashboardRoute(rol = "") {
  const openMenu = window.router.history.current.name === "dashboardState";
  if (openMenu) {
    return window.router.push({
      name: "dashboardState",
      hash: window.router.currentRoute.hash
    });
  }
  const isAdmin = rol === "ADMIN"; // window.user.roles.includes("ADMIN");
  const { pathname } = window.location;
  if (isAdmin) {
    const comeFromRoot = pathname === "/";
    const comeFromLogin = pathname === "/login";
    const comeFromDashboard = pathname === "/dashboard";
    const shouldGoToDashboardBusiness = comeFromRoot || comeFromLogin || comeFromDashboard;
    if (shouldGoToDashboardBusiness) {
      const { id } = getHeadquaterSelectedFromUserProfile();
      const path = "/dashboard/board";
      return window.router.push({ path, query: { id } });
    }
    return window.router.push({ path: pathname });
  }
  return window.router.push({
    name: "dashboard",
    hash: window.router.currentRoute.hash
  });
}

function redirectRoutes(rol = "") {
  const shouldGoToQR = window.router.history.current.name === "qr";
  if (shouldGoToQR) return window.router.push({ name: "qr" });

  const hasContinue = window.router.currentRoute.hash.includes("#continueUrl");
  if (hasContinue) {
    // const { continueUrl = '' } = window.router.currentRoute.query || {};
    const continueUrl = window.router.currentRoute.hash.replace("#continueUrl=", "");
    return window.router.push(continueUrl);
  }

  const shouldGoToInvitation = window.router.history.current.name === "invitation";
  if (shouldGoToInvitation) return window.router.push({ name: "invitation" });

  const shouldGoToProfile = window.router.history.current.name === "profile";
  if (shouldGoToProfile) return window.router.push({ name: "profile" });

  const shouldGoToDashboard = window.router.history.current.name !== "restaurant";
  if (shouldGoToDashboard) return resolveDashboardRoute(rol);
}

function getHeadquaterSelectedFromUserProfile() {
  const { headquaters = {} } = window.user;
  const headquater = Object.keys(headquaters).find(key => headquaters[key].selected === true);
  const noneHeadquater = headquater === undefined;
  if (noneHeadquater) {
    Object.keys(headquaters).forEach(key => {
      headquaters[key].selected = false;
    });
    const [firstHeadquater = {}] = Object.keys(headquaters);
    const hasHeadquater = headquaters.hasOwnProperty(firstHeadquater);
    if (hasHeadquater) {
      headquaters[firstHeadquater].selected = true;
      return headquaters[firstHeadquater];
    }
  }
  return headquaters[headquater];
}

export default redirectRoutes;
