<style scoped lang="scss">
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import TableFooter from "@components/tables/TableFooter";
import OrderDetailsModal from "@components/modals/OrderDetailsModal";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import moment from "moment";

const { fetchOrder } = requests;

export default {
  name: "AccountingTable",
  components: { TableFooter, OrderDetailsModal },
  props: {
    body: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      page: 1,
      batch: 10,
      error: false,
      success: false,
      displayDetailsModal: false,
      displayPayrollModal: false,
      pagination: { page: 1 },
      orderSelected: {},
      orders: {},
      selected: [],
      headers: [
        { text: "Fecha", value: "createAt", align: "left" },
        { text: "Monto", value: "value", align: "left" },
        { text: "Tipo", value: "type", align: "left" },
        { text: "Medio de pago", value: "paidMethod", align: "left" },
        { text: "Descripcion", value: "description", align: "left" }
      ]
    };
  },
  filters: {
    formatType(val) {
      const isRevenue = val === "REVENUE";
      const isEgress = val === "EGRESS";
      if (isRevenue) return "Ingreso";
      if (isEgress) return "Egreso";
      return "";
    },
    formatPaidMethod(val) {
      const isCash = val === "CASH";
      const isCard = ["CREDIT_CARD", "DEBIT_CARD"].includes(val);
      const isTransfer = ["PSE", "BANK_REFERENCED", "SPEI", "ACH", "REFERENCED", "PAYPAL", "PAYU"].includes(val);
      if (isCash) return "Efectivo";
      if (isCard) return "Tarjeta";
      if (isTransfer) return "Transferencia";
      return "";
    },
    formatDate(val) {
      if (val._seconds) return moment(val._seconds * 1000).format("YYYY/MM/DD -  h:mm:ss a");
      return moment(new Date(val)).format("YYYY/MM/DD -  h:mm:ss a");
    },
    noneField(val) {
      if (!val && val !== 0) return "No hay dato";
      return val;
    }
  },
  computed: {
    noneDataToDisplay() {
      const amountItemsDisplyed = this.pagination.page * this.body.length;
      return !this.body[amountItemsDisplyed - 1];
    }
  },
  methods: {
    async openDetails(order) {
      const inCache = this.orders.hasOwnProperty(order);
      if (inCache) {
        this.orderSelected = { ...this.orders[order] };
        this.displayDetailsModal = true;
        await this.$nextTick();
        this.$refs.orderDetailsModal.loading = false;
        return;
      }
      this.loading = true;
      const { ok, result } = await surePromise(fetchOrder(order));
      this.loading = false;
      this.$refs.orderDetailsModal.loading = false;
      if (ok) {
        this.orders[order] = result;
        this.orderSelected = result;
        await this.$nextTick();
        this.displayDetailsModal = true;
        return;
      }
      this.error = true;
    },
    async handlePayrollDetails(item) {
      this.displayDetailsModal = true;
      const { value: price = "", description: note = "" } = item;
      this.orderSelected = { ...item, price, note };
      this.$refs.orderDetailsModal.loading = false;
      await this.$nextTick();
    }
  }
};
</script>
