<style scoped. lang="scss">
.input-file {
  width: 1px;
  height: 1px;
  z-index: -1;
}
.file-list__btn-remove {
  background-image: url('~@assets/icons/close-red.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
}
.file-list {
  max-height: 150px;
  padding-top: 5px;
  overflow-y: auto;
  list-style: none;
  color: #16202f !important;
  padding-left: 0px;
  .file-list__item {
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    .file-list__filename {
       display: inline-block;
       min-width: 205px;
    }
  }
}
.file-upload-btn {
  width: calc(100% - 15px);
  border-radius: 12px;
  height: 53px;
  margin-top: 0px;
  font-size: 13px;
  font-family: Gibson-Bold;
  color: #ef4f34 !important;
  border: 1px solid #ef4f34;
}

.file-upload-btn--dark {
  color: #16202f !important;
  background: transparent !important;
  border: 1px solid  #16202f !important;
}
</style>

<template>
  <div>
    <input
      :id="id"
      ref="filePicker"
      type="file"
      class="input-file"
      :accept="accept"
      :multiple="multiple"
      @change="handleChange"
    >

    <v-btn
      :for="id"
      :class="{'file-upload-btn--dark': dark}"
      class="file-upload-btn"
      :disabled="disabled"
      @click="handlePickFile"
    >
      {{ caption }}
    </v-btn>

    <ul class="file-list">
      <li
        v-for="(file, index) in files"
        :key="file.size"
        class="file-list__item"
      >
        <v-btn
          icon
          flat
          class="file-list__btn-remove"
          :disabled="disabled"
          @click="removeFile(index)"
        ></v-btn>
        <span
          class="file-list__filename text-truncate"
          :title="file.name"
        >
          {{ file.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'FileUploadInput',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: 'Cargar archivo'
    },
    id: {
      type: String,
      default: 'file-input'
    }
  },
  data() {
    return {
      inputFiles: [],
      files: []
    };
  },
  mounted() {
    window.addEventListener(`file[${this.id}]`, this.setFiles);
  },
  beforeDestroy() {
    window.removeEventListener(`file[${this.id}]`, this.setFiles);
  },
  methods: {
    handleClick() {
      const input = document.getElementById(this.id);
      input.value = '';
      this.inputFiles = [];
    },
    getFiles() {
      const result = [];
      const names = this.files.map(item => item.name);
      for (let i = 0; i < this.inputFiles.length; i++) {
        const file = this.inputFiles[i];
        const isAvailable = names.includes(file.name);
        if (isAvailable) result.push(file);
      }
      console.log('result ', result, ' ', this.inputFiles);
      return result;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    reset() {
      const input = document.getElementById(this.id);
      input.value = '';
      this.files = [];
      this.inputFiles = [];
    },
    handlePickFile() {
      this.$refs.filePicker.value = '';
      this.inputFiles = [];
      this.$refs.filePicker.click();
    },
    filterRepeats(item) {
      const isNewFile = this.files.map(file => file.name).includes(item.name) === false;
      return isNewFile;
    },
    copyFilesToArray(files) {
      const result = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        result.push(file);
      }
      return result;
    },
    setFiles(e) {
      this.inputFiles = e.detail.files;
      if (this.multiple) {
        this.files = this.files.concat(e.detail.newFiles);
      } else {
        this.files = e.detail.newFiles;
      }
    },
    handleChange() {
      const { files } = this.$refs.filePicker;
      const newFiles = this.copyFilesToArray(files).filter(this.filterRepeats);
      const event = new CustomEvent(`file[${this.id}]`, {
        detail: {
          newFiles,
          files
        }
      });
      window.dispatchEvent(event);
      this.$emit('change', this.files);
    }
  }
};
</script>
