const actions = {
  loadingStatusAction({ commit }, payload) {
    commit('loadingStatusMutation', payload);
  },

  toastStatusAction({ commit }, payload) {
    commit('toastStatusMutation', payload);
  }
};

export default actions;
