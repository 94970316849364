<style lang="scss" src="./style.scss"></style>
<style lang="scss" src="./style.scss"></style>

<template src="./template.html"></template>

<script>
  import Loading from "@components/generals/Loading";
  import LoadingStore from "@components/LoadingStore";
  import ToastStore from "@components/ToastStore";
  import CartButton from "@components/Cart/CartButton";
  import PromoDetailModal from "@/components/PromoDetailModal";
  import onPWA from "@/utils/onPWA.js";
  import requests from "@/helpers/requests";
  import surePromise from "@utils/surePromise";
  import { notifyEvent } from "@/utils/notifyEvent";
  import i18n from "@/lang";
  import { TOAST } from "@/constants";
  import STORE from "@/store/types";

  export default {
    name: "App",
    components: {
      Loading,
      LoadingStore,
      ToastStore,
      CartButton,
      PromoDetailModal
    },
    data() {
      return {
        notificationSubscribe: null,
        tableReady: null,
        openMenu: false,
        notSupported: false,
        notSupportSpeech: false,
        newUpdate: false,
        onPWA: onPWA(),
        invoiceReady: null,
        ready: false,
        addApp: false,
        deferredPrompt: null,
        backsnack: false,
        notificationText: "",
        notificationImage: "",
        notificationPrice: 0,
        orderDone: false,
        dishStatus: false,
        dishOffer: false,
        dishOfferOrder: "",
        dishOfferDish: "",
        notificationContent: "",
        notificationQuantity: 0,
        notificationSuggestion: "",
        notificationTable: "",
        profileError: false,
        backsnackTitile: "Geochef",
        addToOrder: false,
        loadingOffer: false
      };
    },
    watch: {
      $route(to, from) {
        this.changeTitle(to.name);
        const isNotLogin = window.localStorage.getItem("user") === null;
        if (isNotLogin) {
          const goToDashboard = to.name === "dashboard" || to.name === "dashboardState" || to.path === "/dashboard/tables";
          const goToRestaurant = to.name === "restaurant";
          const goToProfile = to.name === "profile";
          if (goToDashboard || goToRestaurant || goToProfile) {
            window.location.href = `${window.location.origin}/login`;
          }
        } else {
          const closeMenu = from.name === "dashboardState" && from.params.state === "menu" && to.name === "dashboard";
          const backDashboard = to.name === "dashboardState" && to.params.state === "menu" && from.name === "dashboard";
          if (to.name === "dashboardState" && window.router.currentRoute.hash) {
            const emitTab = new CustomEvent("menuStateChangeTab");
            window.dispatchEvent(emitTab);
          }
          if (closeMenu) {
            const event = new CustomEvent("menuStateChange", {
              detail: { open: false }
            });
            window.dispatchEvent(event);
          } else if (backDashboard) {
            const event = new CustomEvent("menuStateChange", {
              detail: { open: true }
            });
            window.dispatchEvent(event);
          } else {
            this.routerMiddleware();
          }
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener("ready", this.handleUserReady);
      window.removeEventListener("getProfileException", this.handleGetProfileException);
      window.removeEventListener("logout", this.unsubscribeNotifications);
    },
    mounted() {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", this.tryRegisterServiceWorker);
        window.addEventListener("blur", this.fetchNewSeviceWorkerUpdate);
      }
      window.addEventListener("ready", this.handleUserReady);
      window.addEventListener("getProfileException", this.handleGetProfileException);
      window.addEventListener("logout", this.unsubscribeNotifications);
      if (this.onPWA) window.addEventListener("backsnack", this.handleChangeBacksnack);
      window.addEventListener("beforeinstallprompt", this.handleBeforeInstall);
      this.notSupported = !this.messagingisSupported();
      this.notSupportSpeech = this.supportSpeech();
      this.routerMiddleware();
    },
    methods: {
      async addDishToOrder() {
        this.loadingOffer = true;
        let toastStatus = {};
        const { ok } = await surePromise(
          requests.confirmDishPromo(this.dishOfferOrder, this.dishOfferDish, {
            amount: this.notificationQuantity,
            suggestion: this.notificationSuggestion,
            table: this.notificationTable
          })
        );
        this.loadingOffer = false;
        if (ok) {
          this.dishOffer = false;
          toastStatus = { show: true, status: TOAST.SUCCESS, message: "Gracias, se añadio a su pedido" };
          this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
        } else {
          this.dishOffer = false;
          toastStatus = { show: true, status: TOAST.ERROR, message: "Lo sentimos, ocurrio un problema" };
          this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
        }
      },
      supportSpeech() {
        return !("speechSynthesis" in window);
      },
      handleGetProfileException() {
        this.profileError = true;
      },
      retryGetProfile() {
        window.location.reload();
      },
      unsubscribeNotifications() {
        if (this.notificationSubscribe) this.notificationSubscribe();
        return window.router.push({ name: "login" });
      },
      fetchNewSeviceWorkerUpdate() {
        if (window.serviceWorkerRegistration) {
          window.serviceWorkerRegistration.update();
        }
      },
      tryRegisterServiceWorker() {
        const SWRegister = navigator.serviceWorker.register("/service-worker.js");
        SWRegister.then(registration => {
          window.serviceWorkerRegistration = registration;
          registration.onupdatefound = this.showUserUpdateServiceWorkerSignal;
          this.fetchNewSeviceWorkerUpdate();
        });
      },
      failRequestPermissions(error) {
        console.log("fail notification permittions ", error);
      },
      dispatcNewNotification(notification) {
        const event = new CustomEvent("notification", {
          detail: { notification }
        });
        window.dispatchEvent(event);
      },
      dispatcWaiterMessage() {
        const event = new CustomEvent("waiterMessage");
        window.dispatchEvent(event);
      },
      dispatchWaiterInvoiceMessage() {
        const event = new CustomEvent("waiterInvoiceMessage");
        window.dispatchEvent(event);
      },
      dispatchWaiterPaymentCompleteMessage() {
        const event = new CustomEvent("waiterPaymentCompleteMessage");
        window.dispatchEvent(event);
      },
      handleInvoiceMenu() {
        window.location.href = `${window.location.origin}/${this.invoiceReady.link}`;
      },
      handleOpenMenu() {
        const onRestaurant = window.router.currentRoute.name === "restaurant";
        if (onRestaurant) window.location.href = `${window.location.origin}/${this.tableReady.link}`;
        window.router.push({ path: `/${this.tableReady.link}` });
        this.tableReady = null;
      },
      handleNewMessage(payload = {}) {
        const { data = {} } = payload;
        if (data.type === "SHOW_MENU") {
          this.tableReady = data.link;
          this.$store.commit("cart/current_menu", `restaurant/${data.link}`);
          if (navigator.vibrate) navigator.vibrate([300, 200, 300]);
        }
      },
      handleCancelUpdate(e) {
        e.preventDefault();
        this.addApp = false;
      },
      changeTitle(focus) {
        const focusReservation = focus === "reservations";
        const focusRestaurants = Boolean(focus === "restaurants" || focus === "restaurant" || focus === "dashboard" || focus === "dashboardState");
        const focusFavorites = focus === "favorites";
        const focusMaps = focus === "maps";
        const focusEvents = focus === "events";
        const focusProfile = focus === "profile";
        const focusRecovery = focus === "recovery";
        const focusReset = focus === "reset";
        const focusRegister = focus === "register";
        const focusDashboard = focus === "dashboard" || focus === "maps";
        let event = null;

        if (focusDashboard) {
          event = new CustomEvent("backsnack", {
            detail: { title: "Geochef", open: false }
          });
          window.dispatchEvent(event);
          window.scrollTo(0, 0);
        } else if (focusFavorites) {
          event = new CustomEvent("backsnack", {
            detail: { title: i18n.tc("lang.Titles.favorites"), open: true }
          });
        } else if (focusReservation) {
          event = new CustomEvent("backsnack", {
            detail: { title: "Reservaciones", open: true }
          });
        } else if (focusRestaurants) {
          if (focus === "restaurant") {
            event = new CustomEvent("backsnack", {
              detail: { title: "Geochef", open: true }
            });
          } else if (focus === "dashboard") {
            event = new CustomEvent("backsnack", {
              detail: { title: "Geochef", open: false }
            });
          } else {
            event = new CustomEvent("backsnack", {
              detail: { title: "Restaurantes", open: true }
            });
          }
        } else if (focusMaps) {
          event = new CustomEvent("backsnack", {
            detail: { title: "Geochef", open: false }
          });
        } else if (focusEvents) {
          event = new CustomEvent("backsnack", {
            detail: { title: "Eventos", open: true }
          });
        } else if (focusProfile) {
          event = new CustomEvent("backsnack", {
            detail: { title: "Perfil", open: true }
          });
        } else if (focusRecovery) {
          event = new CustomEvent("backsnack", {
            detail: { title: "Recuperar contraseña", open: true }
          });
        } else if (focusRegister) {
          event = new CustomEvent("backsnack", {
            detail: { title: "", open: false }
          });
        } else if (focusReset) {
          event = new CustomEvent("backsnack", {
            detail: { title: "", open: false }
          });
        } else {
          event = new CustomEvent("backsnack", {
            detail: { title: "Geochef", open: false }
          });
        }
        if (event) window.dispatchEvent(event);
        window.scrollTo(0, 0);
      },
      handleChangeBacksnack(e) {
        const { title = "Geochef", open = false } = e.detail;
        this.backsnackTitile = title;
        this.backsnack = open;
      },
      handleBackClick() {
        window.router.push({ name: "dashboard", hash: "" });
      },
      handleDowndloadUpdate() {
        window.location.reload();
      },
      showUserUpdateServiceWorkerSignal() {
        if (this.onPWA) {
          this.newUpdate = true;
          setTimeout(() => {
            this.newUpdate = false;
          }, 2000);
        }
      },
      handleBeforeInstall(e) {
        e.preventDefault();
        this.deferredPrompt = e;
        this.addApp = true;
      },
      handleInstallPWA(e) {
        e.preventDefault();
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
          this.addApp = false;
          if (choiceResult.outcome === "accepted") {
            window.localStorage.setItem("intalled", true);
          } else {
            window.localStorage.setItem("intalled", false);
          }
        });
      },
      routerMiddleware() {
        const alreadyLogin = window.localStorage.getItem("user");
        const onRegister = window.router.history.current.name === "register";
        const onRecovery = window.router.history.current.name === "recovery";
        const onReset = window.router.history.current.name === "reset";
        const onLogin = window.router.history.current.name === "login";
        const onProfile = window.router.history.current.name === "profile";
        this.changeTitle(window.router.history.current.name);
        if (alreadyLogin) {
          const hasContinue = window.location.href.includes("continueUrl");
          const hasInvitation = window.location.href.includes("invitation");
          if (onLogin && hasContinue && hasInvitation) {
            return window.router.push({ name: "invitation" });
          }
          if (onReset || onRegister || onLogin || onRecovery) {
            return window.router.push({ name: "dashboard" });
          }
        } else {
          this.ready = true;
          const letPass = onRegister || onRecovery || onReset || onProfile;
          if (letPass === false) {
            const hasContinue = window.location.href.includes("continueUrl");
            if (hasContinue) return;
            if (onLogin) return;
            const continueUrl = window.location.href.replace(window.location.origin, "");
            if (window.router.currentRoute.path === "/") {
              return window.router.push({ name: "login" });
            }
            if (continueUrl === "/index.html") return;
            return window.router.push({
              name: "login",
              hash: `#continueUrl=${continueUrl}`
            });
          }
        }
      },
      async setNotificationId(token) {
        const { ok, result } = await surePromise(requests.updateUser(window.user.uid, { notificationId: token }));
        if (ok) window.user.notificationId = result;
      },
      messagingisSupported() {
        try {
          window.firebase.messaging();
          return true;
        } catch (error) {
          console.log({ error });
          return false;
        }
      },
      registerNotification() {
        if (this.messagingisSupported()) {
          const messaging = window.firebase.messaging();
          if (!messaging) return;
          const hasId = window.user.hasOwnProperty("notificationId") && window.user.notificationId !== "";
          messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then(token => {
              const alreadyRegistered = hasId && token === window.user.notificationId;
              if (alreadyRegistered === false) {
                this.setNotificationId(token);
              }
            })
            .catch(this.failRequestPermissions);
          messaging.onMessage((payload = {}) => {
            console.log("messaging.onMessage");
            console.log(payload);
            console.log("messaging.onMessage");
          });
          messaging.onTokenRefresh(() => {
            messaging
              .getToken()
              .then(this.setNotificationId)
              .catch(err => {
                console.log("Unable to retrieve refreshed token ", err);
              });
          });
        } else {
          this.notSupported = true;
        }
      },
      getNewNotification(payload) {
        const notifications = Object.keys(payload.notifications || {});
        return notifications.find(key => !window.user.notifications.hasOwnProperty(key));
      },
      resolveNotification(payload) {
        const newNotification = this.getNewNotification(payload);
        if (newNotification) {
          window.user.notifications[newNotification] = payload.notifications[newNotification];
          console.log("resolveNotification");
          console.log(payload.notifications[newNotification]);
          console.log("resolveNotificationEnd");
          this.addToOrder = false;
          const { link, type } = payload.notifications[newNotification];
          const showMenu = type === "SHOW_MENU";
          const showInvoice = type === "SHOW_INVOICE";
          const paymentConfirmed = type === "PAYMENT_CONFIRMED";
          const waiterRequest = type === "WAITER_REQUEST";
          const adminRequest = type === "ADMIN_REQUEST";
          const confirmRequest = type === "CONFIRM_REQUEST";
          const readQR = type === "QR_SCANNER";
          const assignedWaiter = type === "ASSIGN_WAITER";
          const hasNewOrder = type === "NEW_ORDER";
          const isDishOffer = type === "DISH_OFFER";
          const hasOrderConfirmed = type === "PAYMENT_CONFIRMED";
          const hasInvoiceRequest = type === "INVOICE_REQUEST";
          const isOrderDone = type === "ORDER_DONE";
          const hasUpdateOrder = type === "UPDATE_ORDER";
          const isDish = type === "DISH_STATUS";
          const isOrderCanceled = type === "ORDER_CANCELED";
          if (showMenu) {
            this.tableReady = { link: `restaurant/${link}` };
            this.$store.commit("cart/current_menu", `restaurant/${link}`);
          }
          if (showInvoice) {
            this.invoiceReady = { link: `restaurant/${link}` };
            this.$store.commit("cart/current_menu", false);
            this.dispatchWaiterInvoiceMessage();
          }
          if (paymentConfirmed) {
            this.dispatchWaiterPaymentCompleteMessage();
          }
          if (adminRequest) {
            const data = payload.notifications[newNotification];
            data.id = newNotification;
            data.readed = false;
            console.log("admin notification", data);
            this.dispatcNewNotification(data);
          }
          if (waiterRequest) {
            const data = payload.notifications[newNotification];
            data.id = newNotification;
            data.readed = false;
            this.dispatcNewNotification(data);
          }
          if (isDishOffer) {
            const data = payload.notifications[newNotification];
            const { image = "", title = "", price = "", content = "" } = payload.notifications[newNotification];
            this.notificationText = title;
            this.notificationContent = content;
            this.notificationImage = image;
            this.notificationPrice = price;
            this.notificationQuantity = 0;
            this.notificationSuggestion = "";
            this.dishOfferOrder = data.order;
            this.dishOfferDish = data.dish;
            this.notificationTable = data.table;
            this.dishOffer = true;
            this.addToOrder = true;
            if (navigator.vibrate) navigator.vibrate([300, 100, 300]);
          }
          if (isOrderDone) {
            if (window.localStorage.getItem("selectedRol") === "USER") {
              const { image = "", body = "" } = payload.notifications[newNotification];
              this.notificationText = body;
              this.notificationImage = image;
              this.orderDone = true;
            } else if (window.localStorage.getItem("selectedRol") === "WAITER") {
              if (navigator.vibrate) navigator.vibrate([300, 100, 300]);
              notifyEvent({ name: "notification", detail: { notification: payload.notifications[newNotification] } });
            }
          }
          if (isDish) {
            const { image = "", body = "", price = "" } = payload.notifications[newNotification];
            this.notificationText = body;
            this.notificationImage = image;
            this.dishStatus = true;
            this.notificationPrice = price;
            if (navigator.vibrate) navigator.vibrate([300, 100, 300]);
          }
          if (isOrderCanceled) {
            console.log("isOrderCanceled");
            const { title = "" } = payload.notifications[newNotification];
            const toastStatus = { show: true, status: TOAST.ERROR, message: title };
            this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
          }
          if (readQR) notifyEvent({ name: "qrReader", detail: payload.notifications[newNotification] });
          if (assignedWaiter) notifyEvent({ name: "assignedWaiter", detail: payload.notifications[newNotification] });
          if (hasNewOrder) {
            let playsCount = 0;
            let self = this;
            const interval = setInterval(function() {
              playsCount++;
              if (playsCount === 3) {
                clearInterval(interval);
              } else {
                self.$refs.notificationPlay.play();
              }
            }, 3000);
            notifyEvent({ name: "newOrder", detail: payload.notifications[newNotification] });
          }
          if (hasOrderConfirmed) {
            notifyEvent({
              name: "orderConfirmed",
              detail: payload.notifications[newNotification]
            });
          }
          if (confirmRequest) this.dispatcWaiterMessage();
          if (hasInvoiceRequest) notifyEvent({ name: "newOrder", detail: payload.notifications[newNotification] });
          if (hasUpdateOrder) {
            notifyEvent({ name: "updateOrder", detail: payload.notifications[newNotification] });
          }

          if (navigator.vibrate) navigator.vibrate([300, 100, 300]);
        }
      },
      updatePromoQuantity(amount) {
        this.notificationQuantity = amount;
      },
      updatePromoSuggestion(value) {
        console.log(value);
        this.notificationSuggestion = value;
      },
      closePromo() {
        this.dishOffer = false;
      },
      setNotificationSubscriptions(id) {
        const db = window.firebase.firestore();
        this.notificationSubscribe = db
          .collection("Users")
          .doc(id)
          .onSnapshot(doc => {
            const payload = doc.data() || {};
            this.resolveNotification(payload);
          });
        window.notificationSubscribe = this.notificationSubscribe;
      },
      handleUserReady(e) {
        const { detail } = e;
        this.ready = detail.ok;
        this.setNotificationSubscriptions(window.user.uid);
        this.registerNotification();
        const comeFromLogin = window.router.currentRoute.name === "login";
        const { query = {}, hash = "" } = window.router.currentRoute;
        if (comeFromLogin) {
          window.router.push({ name: "dashboard", query, hash });
          window.router.replace({ query, hash });
          return;
        }
        const isEmptyPath = window.router.currentRoute.path === "/";
        if (isEmptyPath) {
          const isAdmin = window.localStorage.getItem("selectedRol") === "ADMIN";
          if (isAdmin) {
            window.router.push({ path: "/dashboard/business" });
            window.router.replace({ query, hash });
            return;
          }
          window.router.push({ name: "dashboard" });
          window.router.replace({ query, hash });
        }
      }
    },
    errorCaptured() {
      notifyEvent({ name: "getProfileException", details: "Ocurrio un problema, vuelva a recargar la pagina" });
    }
  };
</script>
