<style lang="scss" scoped>
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import BasicSelect from "@/components/selects/BasicSelect";
import BasicInput from "@inputs/BasicInput";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import validators from "@utils/validators";
const { validString } = validators;
const { updatePayuInfo } = requests;

export default {
  name: "UpdatePaymentForm",
  components: { BasicInput, BasicSelect },
  props: {
    apiKey: {
      type: String,
      default: () => ""
    },
    merchantId: {
      type: String,
      default: () => ""
    },
    accountId: {
      type: String,
      default: () => ""
    },
    mode: {
      type: String,
      default: () => "create"
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      success: false,
      reset: false,
      fields: {
        apiKey: "",
        accountId: "",
        merchantId: "",
        restaurant: ""
      },
      validity: {
        apiKey: false,
        accountId: false,
        merchantId: false,
        restaurant: false
      }
    };
  },
  computed: {
    isValid() {
      return Object.keys(this.validity).every(key => this.validity[key] === true);
    },
    restaurants() {
      return window.user.restaurants.map(restaurant => {
        const { name = "", id = "" } = restaurant;
        return { name, id };
      });
    }
  },
  created() {
    this.fields.accountId = "";
    this.fields.apiKey = "";
    this.fields.merchantId = "";
    this.fields.restaurant = "";
  },
  methods: {
    async handleCancel() {
      await this.resetState();
      this.$emit("cancel");
    },
    timeout(time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time);
      });
    },
    async handleSubmit() {
      const { fields } = this;
      this.loading = true;
      const { ok } = await surePromise(updatePayuInfo(fields.restaurant, fields));
      this.loading = false;
      if (ok) {
        await this.resetState();
        this.success = true;
        await this.timeout(2000);
        this.$emit("cancel");
        return;
      }
      this.error = true;
    },
    handleChangeRestaurant(option) {
      this.validity.restaurant = Boolean(option);
      this.fields.restaurant = option;
    },
    handleFieldChange(field, value) {
      this.validity[field] = validString(value);
      this.fields[field] = value;
    },
    async resetState() {
      Object.assign(this, this.$options.data());
      this.reset = true;
      await this.$nextTick();
      this.reset = false;
      return Promise.resolve(true);
    }
  }
};
</script>
