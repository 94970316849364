<style scoped lang="scss">

</style>

<template>
  <div>
    <v-switch
      v-model="selected"
      :label="label"
      :disabled="disable"
    ></v-switch>

    <div class="time-schedule-container__wrap-picker">
      <transition name="appear">
        <TimePickerInput
          v-if="selected"
          ref="openTimeField"
          :label="label"
          :min-time="openMinTime"
          :max-time="openMaxTime"
          :value="openValue"
          :disable="disable"
          hint="Hora de apertura"
          @change="handleOpenFieldChange"
        ></TimePickerInput>
      </transition>

      <transition name="appear">
        <TimePickerInput
          v-if="selected"
          ref="closeTimePicker"
          :label="label"
          hint="Hora de cierre"
          :max-time="closeMaxTime"
          :min-time="openValue"
          :value="closeValue"
          :disable="disable"
          @change="handleCloseFieldChange"
        ></TimePickerInput>
      </transition>
    </div>
  </div>
</template>

<script>
import TimePickerInput from '@components/inputs/TimePickerInput';

export default {
   name: 'PickerTimeForm',
   components: {
     TimePickerInput
   },
   props: {
     disable: {
       type: Boolean,
       default: false
     },
     label: {
       type: String,
       default: ''
     },
     openMinTime: {
       type: String,
       default: ''
     },
     openMaxTime: {
       type: String,
       default: ''
     },
     closeMaxTime: {
       type: String,
       default: ''
     },
     closeMinTime: {
       type: String,
       default: ''
     },
     openValue: {
       type: String,
       default: ''
     },
     closeValue: {
       type: String,
       default: ''
     },
     display: {
       type: Boolean,
       default: false
     }
   },
   data() {
      return {
        selected: this.display, openTimeFulfilled: this.display, openTime: '', closeTime: ''
      };
   },
   watch: {
      display(val) {
        this.selected = val;
        if (this.closeTime) {
          const shouldResetCloseField = this.oneTimeIsLateThatOther(
            this.openTime,
            this
              .closeTime
              .replace('am', '')
              .replace('pm', '')
          );
          if (shouldResetCloseField) this.$refs.closeTimePicker.reset();
        } else if (val) {
          // this.$emit('change-close', time);
        }
      }
   },
   methods: {
    getData() {
      const { openTimeField, closeTimePicker } = this.$refs;
      const result = {
        active: this.selected
      };
      if (openTimeField && closeTimePicker) {
        result.from = openTimeField.displayTimeInFormat12;
        result.to = closeTimePicker.displayTimeInFormat12;
      }
      return result;
    },
    createDateFromTime(time = '') {
      const date = new Date();
      const [hour, minute] = time.split(':');
      date.setHours(Number(hour), Number(minute));
      return date;
    },
    oneTimeIsLateThatOther(firstTime, secondTime) {
      const firstDate = this.createDateFromTime(firstTime);
      const secondtDate = this.createDateFromTime(secondTime);
      return firstDate > secondtDate;
    },
    handleCloseFieldChange(time) {
      this.$emit('change-close', time);
      this.closeTime = time;
    },
    handleOpenFieldChange(time) {
      this.$emit('change-open', time);
      this.openTime = time;
      this.openTimeFulfilled = true;
      if (this.closeTime) {
        const shouldResetCloseField = this.oneTimeIsLateThatOther(
          time,
          this
            .closeTime
            .replace('am', '')
            .replace('pm', '')
        );
        if (shouldResetCloseField) this.$refs.closeTimePicker.reset();
      }
    }
   }
};
</script>
