<style scoped lang="scss">
@import "~@/styles/_colors.scss";

.invalid {
  .v-input__slot {
    box-shadow: 0px 3px 1px -2px rgba(255, 0, 0, 0.2), 0px 2px 2px 0px rgba(255, 0, 0, 0.14), 0px 1px 5px 0px rgba(255, 0, 0, 0.12) !important;
  }
}

.input-caption {
  font-size: 13px;
  text-transform: uppercase;
  color: $primary;
  font-family: Gibson;
  margin-bottom: 10px;
  margin-left: 5px;
}

.input-caption--push {
  margin-left: 35px !important;
}

.field-msg {
  position: relative;
  bottom: 20px;
  left: 5px;
}
.basic-input {
  .v-input__control {
    padding-left: 9px !important;
    .v-input__slot {
      border-radius: 12px !important;
      padding: 10px 20px !important;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
      .v-label {
        font-weight: lighter;
        color: #8b8989;
      }
    }
  }
}
</style>

<template>
  <div :key="inputKey">
    <p
      v-if="caption"
      :style="{'color': captionColor }"
      class="input-caption"
      :class="{'input-caption--push': money}"
    >{{ caption }}</p>
    <v-text-field
      ref="textField"
      v-model="value"
      v-money="money ? moneyOptions: ''"
      :prepend-icon="money ? 'monetization_on' : ''"
      :label="label"
      :type="type"
      :hint="hint"
      :persistent-hint="showHint && displayErrorMessage === false"
      class="basic-input"
      solo
      :min="min"
      :disabled="disabled"
      :class="{ invalid: styl && valid === false && value !== '' }"
      @input="handleInputChange"
    ></v-text-field>
    <small
      v-if="displayErrorMessage"
      class="field-msg"
      :style="{ color: dark ? '#ED2236' : '#fff', 'margin-left': money ? '30px' : '0px' }"
    >{{ msg }}</small>
  </div>
</template>

<script>
import theme from "@/theme";
import { VMoney } from "v-money";
import uuid from "@utils/uuid";

export default {
  name: "BasicInput",
  props: {
    captionColor: {
      type: String,
      default: "#ED2236"
    },
    caption: {
      type: String,
      default: ""
    },
    resetInput: {
      type: Boolean,
      default: false
    },
    money: {
      type: Boolean,
      default: false
    },
    showHint: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    overlay: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    showMsg: {
      type: Boolean,
      default: false
    },
    styl: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    defaultValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    change: {
      type: Function,
      default() {}
    }
  },
  data() {
    const result = {
      inputKey: uuid(),
      value: "",
      theme,
      moneyOptions: {
        precision: "",
        thousands: ",",
        prefix: "",
        suffixUnit: "",
        masked: false
      }
    };
    if (this.defaultValue) result.value = this.defaultValue;
    return result;
  },
  computed: {
    displayErrorMessage() {
      return (this.valid === false && this.value !== "") || this.showMsg;
    }
  },
  directives: {
    money: VMoney
  },
  watch: {
    resetInput(val) {
      if (val) {
        this.value = "";
        this.inputKey = uuid();
      }
    },
    defaultValue(val) {
      this.value = val;
    },
    valid(val) {
      this.valid = val;
    }
  },
  methods: {
    reset() {
      if (this.$refs.textField && this.$refs.textField.$refs.input) {
        this.$refs.textField.clearableCallback("");
      }
    },
    handleInputChange(value) {
      if (typeof value === "string") {
        if (this.money) return this.$emit("input-change", value.replace(/[,.]/g, ""));
        return this.$emit("input-change", value);
      }
    }
  }
};
</script>
