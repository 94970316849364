<style scoped lang="scss">
  .container-cancel-description {
    background: white;
    padding: 20px;
    border-radius: 10px;
  }

  .btn-send {
    border-radius: 10px;
  }
</style>

<template>
  <v-dialog
    v-model="open"
    color="white"
    persistent
    max-width="400"
  >
    <div
      color="white"
      class="container-cancel-description"
    >
      <h3 align="center">¿Cuánto demora prepararlo?</h3>
      <div class="promo-cart-details">
        <div class="wrap-btn-plate">
          <v-btn
            icon
            flat
            class="btn-plate"
            color="white"
            @click="handleAmountChange('add')"
          >
            <v-icon>add</v-icon>
          </v-btn>

          <div class="amount-label">
            {{ amount }}
            <div class="amount-legend">minutos</div>
          </div>

          <v-btn
            icon
            flat
            class="btn-plate btn-minus-plate"
            color="white"
            @click="handleAmountChange('minus')"
          >
            <v-icon>remove</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="loading"
        class="wrap-loading text-xs-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-card-actions
        v-if="loading === false"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          flat="flat"
          @click="handleCancel"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          class="btn-send"
          :disabled="amount === 0"
          @click="handleSend"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </div>
  </v-dialog>
</template>

<script>
  export default {
    name: "EstimatedTimeModal",
    props: {
      display: {
        type: Boolean,
        default: false
      },
      update: {
        type: Function,
        default: () => {
        }
      }
    },
    data() {
      return {
        loading: false,
        open: this.display,
        amount: 5,
        isValid: false
      };
    },
    watch: {
      display(val) {
        this.open = val;
        if (val) {
          this.amount = 5;
          this.update(this.amount);
        }
      }
    },
    created() {
      this.update(this.amount);
    },
    methods: {
      handleAmountChange(name) {
        if (name === "add") this.amount += 5;
        if (name === "minus" && this.amount > 5) this.amount -= 5;
        if (this.amount === 5) this.suggestion = "";
        this.update(this.amount);
      },
      handleCancel() {
        this.$emit("cancel");
        this.message = "";
        this.isValid = false;
      },
      handleSend() {
        this.$emit("confirm", this.message);
        this.message = "";
        this.isValid = false;
      }
    }
  };
</script>
