import { BASE } from "./index";
const dispatchOrder = payload => {
  const { tableId, orderId, discount, service, iva = {} } = payload;
  const update = { iva };
  update.discount = Number(discount);
  update.service = Number(service);
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/order/dispatch/${orderId}/${tableId}`;
  return fetch(url, {
    method: "POST",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify({ update })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default dispatchOrder;
