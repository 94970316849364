import { BASE } from "./index";

const updateFile = (pictureId = "", headquaterId = "", data = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/file/update/${pictureId}/${headquaterId}`;

  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      token
    }
  })
  .then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  })
  .catch(error => {
    console.error('ERROR: ', error);
  });
};

export default updateFile;
