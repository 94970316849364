<style scoped lang="scss">
::v-deep .v-input__icon--append {
  width: 30px;
  height: 30px;
  overflow: hidden;
  i {
    color: transparent !important;
    background-image: url("~@/assets/icons/arrow-down.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.v-select {
  max-width: 300px;
  max-height: 52px;
  justify-self: end;
  margin-bottom: 24px;
}
::v-deep .v-input__slot {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}
</style>

<template>
  <v-select
    v-model="selectedByDefault"
    item-text="name"
    item-value="id"
    :items="items"
    :label="label"
    solo
    @change="handleChange"
  ></v-select>
</template>

<script>
export default {
  name: "HeadquatersSelect",
  props: {
    label: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    defaultItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedByDefault: this.defaultItem,
      selected: this.defaultItem
    };
  },
  methods: {
    handleChange(id) {
      const selected = this.items.find(item => item.id === id);
      this.selected = selected;
      this.$emit("change", selected);
    }
  }
};
</script>
