import { BASE } from "./index";
const fetchDish = (id = "") => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/dishe/${id}`;
  return fetch(url, {
    method: "GET",
    headers: { token }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default fetchDish;
