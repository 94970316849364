<style scoped lang="scss">
.wrap__language-modal {
  z-index: 1000;
}
.language-modal__btn-continue {
  width: calc(100% - 10px);
  border-radius: 12px;
  cursor: pointer;
  height: 42px;
  font-family: Gibson-Bold;
  position: relative;
  left: -2px;
  top: -6px;
}

::v-deep .v-input__slot {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
}
.language-modal__close {
  background-position: center;
  background-image: url("~@assets/icons/close.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: relative;
  top: -50px;
  left: calc(100% - 20px);
}

::v-deep .v-dialog--active {
  background: white;
  border-radius: 12px;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  .language-modal__title {
    color: #ed2236;
    font-size: 16px;
    font-family: Gibson-Bold;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 20px;
  }
}
</style>

<template>
  <div>
    <v-dialog v-model="display" max-width="500" class="language-modal" persistent>
      <div class="wrap__language-modal">
        <p class="language-modal__title">Languages/Idiomas</p>
        <div class="language-modal__close" @click="$emit('close')"></div>
        <LanguageSelect
          ref="languageSelect"
          class="language-modal__select"
          @loading="ready = !$event"
        ></LanguageSelect>
        <v-btn
          v-if="ready"
          color="primary"
          class="language-modal__btn-continue"
          @click="handleUpdateLanguage"
        >Continuar</v-btn>
      </div>
    </v-dialog>

    <v-snackbar
      v-model="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
      color="success"
    >Operación completada con exito.</v-snackbar>

    <v-snackbar
      v-model="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
      color="error"
    >Ocurrio un error intenta mas tarde.</v-snackbar>
  </div>
</template>

<script>
import LanguageSelect from "@components/selects/LanguageSelect";
import requests from "@/helpers/requests";

const { updateProfile } = requests;

export default {
  name: "LanguageModal",
  components: { LanguageSelect },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: this.open,
      ready: false,
      error: false,
      success: false
    };
  },
  watch: {
    open(val) {
      this.display = val;
    }
  },
  methods: {
    handleUpdateLanguage() {
      const { languageSelect } = this.$refs;
      const { code = "ES" } = languageSelect.getLanguageSelected();
      const difference = code !== window.user.lang;
      if (difference) {
        languageSelect.loading = true;
        updateProfile({ lang: code })
          .then(this.handleUpdateSuccess)
          .catch(this.handleUpdateError);
      } else {
        this.$emit("close");
      }
    },
    handleUpdateSuccess(response) {
      console.log(response);
      const { languageSelect } = this.$refs;
      languageSelect.loading = false;
      if (response.ok) {
        const { code = "ES" } = languageSelect.getLanguageSelected();
        window.user.lang = code;
        this.success = true;
        this.notifyLanguageChange(code);
        this.$emit("close");
      } else {
        this.error = true;
      }
    },
    handleUpdateError(error = {}) {
      console.log("error ", error);
      const { languageSelect } = this.$refs;
      languageSelect.loading = false;
      this.error = true;
    }
  }
};
</script>
