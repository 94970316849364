<style scoped lang="scss">
    @import "style";
</style>

<template src="./template.html"></template>

<script>
  import _numeral from "numeral";

  try {
    _numeral.register('locale', 'co', {
      delimiters: {
        thousands: '.',
        decimal: '.'
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal(number) {
        return number === 1 ? 'er' : 'nd';
      },
      currency: {
        symbol: '$'
      }
    });
    _numeral.locale('co');
  } catch (error) {
    //
  }

  export default {
    name: "ShareAccount",
    components: { },
    props: {
      title: {
        type: String,
        default: 'Propina'
      },
      setOrderDouceur: {
        type: Function,
        default: () => {}
      },
      defaultValue: {
        type: Number,
        default: 0
      }
    },
    filters: {
      numeral(value) {
        return _numeral(value).format('$0,0');
      }
    },
    computed: {

    },
    data() {
      return {
        douceurType: 0,
        douceurTypes: [
          { value: 0 },
          { value: 1000 },
          { value: 3000 },
          { value: 5000 },
          { value: 10000 }
        ]
      };
    },
    watch: {
      defaultValue(newVal) {
        this.douceurType = newVal;
        this.setOrderDouceur(this.douceurType);
      }
    },
    created() {
      this.setOrderDouceur(this.douceurType);
    },
    mounted() {

    },
    methods: {
      setDouceurType(value) {
        this.douceurType = value;
        this.setOrderDouceur(this.douceurType);
      }
    }
  };
</script>
