<style scoped lang="scss">
@import "@/styles/_colors.scss";
.footer-grid {
  height: 100px !important;
  background: rgba(255, 255, 255, 0.7) !important;
}
.btn-back,
.btn-next {
  border-radius: 10px;
  max-width: 357px;
  min-width: 100px;
  font-size: 15px;
  font-family: Gibson-Bold;
  height: 52px;
  width: calc(50% - 20px);
  display: inline-block;
}
.btn-back {
  ::v-deep .v-btn__content {
    color: $secondary;
  }
}
</style>

<template>
  <v-footer class="footer-grid">
    <v-layout row wrap>
      <v-flex>
        <div class="text-xs-center">
          <v-btn outline color="secondary" @click="$emit('back')" class="btn-back">{{btnBack}}</v-btn>
          <v-btn
            color="primary"
            @click="$emit('next')"
            :disabled="shouldDisable"
            class="btn-next"
          >{{btnNext}}</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: "DishGridFooter",
  props: {
    btnBack: {
      type: String,
      default: ""
    },
    btnNext: {
      type: String,
      default: ""
    },
    step: {
      type: Number,
      default: 0
    },
    order: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    shouldDisable() {
      const { order = {}, step } = this;
      const result = Object.keys(order.dishes || {}).length === 0 && step === 3;
      // console.log({ result });
      return result;
    }
  }
};
</script>

