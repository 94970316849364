<style scoped>
  @import "style.css";
</style>
<template src="./template.html"></template>

<script>
  import RestaurantHeader from "@components/Headers/RestaurantHeader";
  import getDistanceFromLatLonInKm from "@utils/getDistanceFromLatLonInKm";
  import calcPromotion from "@utils/calcPromotion";
  import RouteMap from "@components/maps/RouteMap";
  import MenuCard from "@components/cards/MenuCard";
  import SocialMediaList from "@lists/SocialMediaList";
  import PersonsAndTimeForm from "@components/forms/PersonsAndTimeForm";
  import ConfirmationReserv from "@components/generals/ConfirmationReserv";
  import Order from "@components/steppers/Order";
  import OrderOptions from "@components/generals/OrderOptions";
  import BillingOrder from "@components/generals/BillingOrder";
  import theme from "@/theme.js";
  import requests from "@/helpers/requests";
  import Comments from "@components/generals/Comments/Comments.vue";
  import surePromise from "@/utils/surePromise";
  import RatingRestaurant from "@/components/generals/RatingRestaurant/RatingRestaurant.vue";
  import UserPanoramicsModel from "@components/generals/MediaDescription/UserPanoramicsModel";
  import STORE from "@/store/types";
  import PromoDetail from "@/components/PromoDetail";
  import mixins from "@/mixins";

  const { DateManager } = mixins;
  const { fetchOrder, fetchTable, fetchRestaurant, fetchHeadquater, fetchMenu, createReservation, createOrder, addDishesToOrder } = requests;
  const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  export default {
    name: "Restaurant",
    components: {
      RestaurantHeader,
      RouteMap,
      MenuCard,
      SocialMediaList,
      PersonsAndTimeForm,
      ConfirmationReserv,
      Order,
      OrderOptions,
      BillingOrder,
      Comments,
      RatingRestaurant,
      UserPanoramicsModel,
      PromoDetail
    },
    mixins: [DateManager],
    filters: {
      filterByCategory(list, category) {
        return list.filter(item => item.categories[category]);
      },
      isPopulate(dishes) {
        return Object.keys(dishes).length === 0;
      }
    },
    data() {
      let date = new Date();
      date = this.toLocalDateString(date);
      let today = new Date();
      today = this.toLocalDateString(today);
      const { table = "" } = window.router.currentRoute.query;
      const { lang = "ES" } = window.user || {};
      return {
        key: 0,
        headquater: "",
        counter: 0,
        device: null,
        modalPanoramics: false,
        ...theme,
        table,
        tableName: "",
        creatingReservation: false,
        billing: false,
        displaySuccessSnackbar: false,
        displayErrorSnackbar: false,
        displayBoxFlowClosedSnackbar: false,
        dishes: {},
        drinks: "9c4e239e-6e8e-4447-937e-53a234d038e8",
        dessert: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
        main: "32ceb392-f5ae-4811-ba97-e43539a69a7a",
        accountId: 0,
        merchantId: 0,
        lang,
        iva: 8,
        apiKey: "",
        readyToOrder: false,
        fetchingDish: false,
        step: 0,
        headquaters: [],
        menus: [],
        active: 0,
        options: false,
        order: null,
        loading: true,
        lockDishes: false,
        loadingMenu: false,
        notFound: false,
        error: false,
        loadingPromotions: false,
        deniedLocation: false,
        restaurant: null,
        lat: null,
        lng: null,
        hour: "",
        persons: "",
        dateTitle: "",
        isToday: false,
        center: { lat: 0.0, lng: 0.0 },
        dayweek: weekDays[new Date().getDay() - 1],
        showOrder: false,
        showPhone: false,
        showWhatsapp: false,
        showTabs: true,
        showReserv: true,
        renderPath: false,
        menuMode: "user",
        tableBusy: false, // window.router.currentRoute.params.hasOwnProperty('table')
        today,
        date,
        readyToReserv: false,
        waiter: "",
        noneWaiter: false,
        tableData: {},
        comments: [
          {
            user: "",
            text: ""
          }
        ],
        showComments: false,
        rating: {
          totalPayment: 0,
          show: false
        },
        endOrder: false,
        hasPrevOrder: false,
        orderSuscription: false
      };
    },
    computed: {
      isDisableToOrder() {
        return false;
      },
      hasOrder() {
        return this.order && typeof this.order === "object" && Object.keys(this.order || {}).length !== 0;
      },
      hasComments() {
        return !(this.comments.length !== 0);
      },
      dishWithoutPromotions() {
        return this.menus.map(item => ({ ...item })).filter(dish => !dish.promotion || (dish.promotion && dish.promotion.available === false));
      },
      dishWithPromotions() {
        return this.menus.map(item => ({ ...item })).filter(dish => dish.promotion && dish.promotion.available === true);
      },
      firstComment() {
        const comment = this.comments.find(c => c.user);
        if (!comment) {
          return {
            user: { name: "Administrador" },
            text: "No hay comentarios para el restaurante"
          };
        }
        return comment;
      },
      getDataAlbums() {
        return this.$store.getters[STORE.GETTERS.ALBUMS.GET_ALBUM];
      }
    },
    watch: {
      hour() {
        this.validateReservation();
      },
      dateTitle() {
        this.validateReservation();
      },
      persons() {
        this.validateReservation();
      },
      order() {
        const self = this;
        if (this.orderSuscription) {
          this.orderSuscription();
        }
        const OrdersRef = window.db.collection("Orders");
        this.orderSuscription = OrdersRef.where("id", "==", self.order.id)
          .onSnapshot(async snapshots => {
            for (const change of snapshots.docChanges()) {
              if (change.type === "modified") {
                if (change.doc.id === self.order.id) {
                  console.log("orderChange");

                  if (change.doc.data().status === "CANCEL") {
                    this.showOrder = false;
                    this.options = false;
                    this.showTabs = true;
                    this.$store.commit("cart/current_menu", false);
                    this.showReserv = true;
                  } else {
                    self.order = change.doc.data();
                    self.dishes = {};
                    self.dishes = {
                      ...self.order.dishes
                    };
                    self.step = 0;
                    self.options = true;
                    self.showOrder = false;
                    self.showTabs = false;
                    self.menus = [];
                    await surePromise(self.findMenu(self.$route.params.headquater, {}));
                    self.handleOrderSuccess(self.order, { ok: true, id: self.order.id });
                    await self.loadTable(self.tableData.id, self.tableData);
                  }
                }
              }
            }
          });
      },
      restaurant(newValue) {
        console.log("changed restaurant");
        const table = window.router.currentRoute.query.hasOwnProperty("table");
        if (table) {
          newValue.admins.forEach(value => {
            this.notificationAdmin(value);
          });
        }
      },
      step(val) {
        let categories = "";
        const { headquater } = window.router.currentRoute.params;
        if (Number(val) === 1) categories = this.main;
        if (Number(val) === 2) categories = this.drinks;
        if (Number(val) === 3) categories = this.dessert;
        const alreadyHave = this.menus.some(item => item.categories[categories]);
        if (alreadyHave) return;
        this.fetchingDish = true;
        this.findMenu(headquater, { categories }, true).then(() => {
          this.fetchingDish = false;
        });
      }
    },
    async updated() {
      // console.log("updated");
      this.counter++;
      if (this.showTabs === false) {
        if (this.$refs.datePicker) {
          this.dateTitle = this.$refs.datePicker.genPickerTitle().data.props.date;
        }
        if (this.resturant === null) {
          this.headquaters.forEach(item => {
            item.selected = item.id === window.router.history.current.params.headquater;
          });
          this.restaurant = this.headquaters.find(item => item.selected);
        }
      }
      if (this.renderPath) {
        if (this.$refs.restaurantMap) this.$refs.restaurantMap.getRoute();
        if (this.$refs.howToArriveMap) this.$refs.howToArriveMap.getRoute();
        this.renderPath = false;
      }
      if (this.counter === 1) await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.$route.params.id);
    },
    async mounted() {
      this.dayweek = weekDays[new Date().getDay() - 1];
      this.handleComments();
      const table = window.router.currentRoute.query.hasOwnProperty("table");
      this.active = window.router.currentRoute.query.hasOwnProperty("promotions") ? 2 : 0;

      if (table) {
        const assignedTable = await window.db.collection("Tables").where("belongsTo", "==", this.$route.params.headquater)
          .where("currentClient", "==", window.user.uid)
          .where("status", "==", "BUSY")
          .get();
        if (assignedTable.docs.length > 0 && assignedTable.docs[0].exists && !window.router.currentRoute.query.hasOwnProperty("invoice")) {
          window.router.replace({ query: { table: assignedTable.id } });
        } else {
          console.log("this.setTable");
          await this.setTable();
        }
      }


      const ReservationsRef = window.db.collection("Tables");
      ReservationsRef.where("belongsTo", "==", this.$route.params.headquater)
        .where("currentClient", "==", window.user.uid)
        .where("status", "==", "BUSY")
        .onSnapshot(async snapshots => {
          let currentOrder;
          let table;
          for (const change of snapshots.docChanges()) {
            if (change.type === "added" || change.type === "modified") {
              // eslint-disable-next-line prefer-destructuring
              currentOrder = change.doc.data().currentOrder;
              const table2 = await window.db.collection("Tables")
                .doc(change.doc.id).get();
              if (table2.data().status !== "FREE") {
                table = table2;
                // break;
              }
            } else if (change.type === "removed") {
              if (!this.rating.show && !this.billing) {
                this.showOrder = false;
                this.options = false;
                this.showTabs = true;
                this.$store.commit("cart/current_menu", false);
                this.showReserv = true;

                // this.$router.push({
                //   force: true,
                //   path: `/restaurant/${this.$route.params.id}/${this.$route.params.headquater}`
                // });
              }
            }
          }

          if (table && (!this.rating.show && !this.billing && !window.router.currentRoute.query.hasOwnProperty("invoice"))) {
            const waiters = Object.keys(table.data().activesWaiters);
            this.waiter = waiters.length > 0 ? waiters[0] : "";

            if (this.waiter && table.data().status !== "FREE") {
              // console.log("this.waiter");
              // console.log(this.waiter);

              this.table = table.id;

              // console.log("this.table");
              // console.log(this.table);

              if (currentOrder) {
                currentOrder = await window.db.collection("Orders").doc(currentOrder).get();
              }
              if (currentOrder.exists) {
                this.order = currentOrder.data();
                this.hasPrevOrder = currentOrder.id;
                this.dishes = {
                  ...this.order.dishes
                };
                // console.log("currentOrder.exists");
                // console.log(this.order);
                await surePromise(this.findMenu(this.$route.params.headquater, {}));
                this.handleOrderSuccess(currentOrder.data(), { ok: true, id: currentOrder.id });
              }

              this.showReserv = false;
              this.showTabs = false;
              this.options = true;
              this.showOrder = false;
              this.menuMode = table.data().activesWaiters[window.user.uid] ? "waiter" : "user";
              this.tableData = table.data();
              await this.loadTable(table.id, table.data());
              this.$store.commit("cart/current_menu", `/restaurant/${this.$route.params.id}/${this.$route.params.headquater}`);
            } else {
              this.$store.commit("cart/current_menu", false);
            }
          } else {
            this.$store.commit("cart/current_menu", false);
          }
        });

      this.isToday = this.sameDate(this.toLocalDateString(new Date()), this.date);
      this.initialize();
      window.addEventListener("languageChange", this.handleChangeLanguage);
      window.addEventListener("orderConfirmed", this.handleOrderConfirmed);
    },
    created() {
      this.headquater = this.$route.params.headquater;
      this.validateDevice();
    },
    beforeDestroy() {
      window.removeEventListener("languageChange", this.handleChangeLanguage);
      window.removeEventListener("orderConfirmed", this.handleOrderConfirmed);
    },
    methods: {
      async notificationAdmin(admin) {
        const { table } = this.$route.query;
        await requests.adminNotification(admin, {
          body: "Mesa ocupada",
          table,
          sender: window.user.uid,
          type: "QR_SCANNER",
          title: "Se ha ocupado una mesa",
          origin: "qrReader"
        });
      },
      validateDevice() {
        if (screen.width > 600) {
          this.device = true;
        } else {
          this.device = false;
        }
      },
      async setDataPanoramics(albumId) {
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true);
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.RENDER_PANORAMICS, false);
        const { ok, result } = await surePromise(requests.getAlbumPicture({ belongsTo: albumId }));
        if (ok) await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_PANORAMIC, result);
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.RENDER_PANORAMICS, true);
        this.panoramicModelManager(true);
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
      },
      panoramicModelManager(status) {
        this.modalPanoramics = status;
      },
      handlePaymentsBilling(event) {
        this.rating.show = event.success;
        this.rating.totalPayment = event.total;
        this.billing = false;
      },
      handleOrderConfirmed() {
        //
      },
      async setTable() {
        const id = window.router.currentRoute.query.table;
        const { error, result } = await surePromise(fetchTable(id));
        if (result.status === "FREE") {
          this.$store.commit("cart/current_menu", false);
        }
        if (error) {
          this.displayErrorSnackbar = true;
          this.tableBusy = true;
          return;
        }
        if (result.status === "BUSY" && result.currentClient !== window.user.id) {
          this.tableBusy = true;
          return;
        }
        this.tableName = result.name;
        const waiters = Object.keys(result.activesWaiters);
        this.waiter = waiters.length > 0 ? waiters[0] : "";
        this.menuMode = result.activesWaiters[window.user.uid] ? "waiter" : "user";
        if (this.isAdmin()) {
          this.waiter = window.user.uid;
          this.menuMode = "admin";
        }
        if (this.waiter === undefined) this.noneWaiter = true;
        if (!this.tableBusy) {
          console.log("!this.tableBusy");
          const { error: fetchRestaurantException, result: restaurant } = await surePromise(
            fetchRestaurant(window.router.history.current.params.id, {})
          );
          console.log("restaurant****");
          console.log(result.currentOrder);
          console.log("restaurant", restaurant);
          if (fetchRestaurantException) return this.handleRestaurtansResponseError(fetchRestaurantException);
          const { accountId, merchantId, apiKey } = restaurant;
          this.accountId = accountId;
          this.merchantId = merchantId;
          this.apiKey = apiKey;
          const invoice = window.router.currentRoute.query.hasOwnProperty("invoice");
          // if (!result.currentOrder) {
          // if (invoice) {
            this.handleRequestOrder(invoice);
          // }
        }
      },
      async loadTable(id, result) {
        console.log("loadTable");
        console.log(result);
        this.tableName = result.name;
        // this.waiter = Object.keys(result.activesWaiters).find(key => result.activesWaiters[key]);
        let waiters = [];
        if (result.activesWaiters) {
          waiters = Object.keys(result.activesWaiters);
          this.waiter = waiters.length > 0 ? waiters[0] : "";
          this.menuMode = result.activesWaiters[window.user.uid] ? "waiter" : "user";
        }
        // if (this.menuMode === "waiter") this.readyToOrder = true;
        if (this.isAdmin()) {
          // this.readyToOrder = true;
          this.waiter = window.user.uid;
          this.menuMode = "admin";
        }
        if (this.waiter === undefined) this.noneWaiter = true;
        if (this.tableBusy) {
          const { error: fetchRestaurantException, result: restaurant } = await surePromise(
            fetchRestaurant(window.router.history.current.params.id, {})
          );
          if (fetchRestaurantException) return this.handleRestaurtansResponseError(fetchRestaurantException);
          const { accountId, merchantId, apiKey } = restaurant;
          this.accountId = accountId;
          this.merchantId = merchantId;
          this.apiKey = apiKey;
          const invoice = window.router.currentRoute.query.hasOwnProperty("invoice");
          this.handleRequestOrder(invoice);
        }
      },
      handleChangeLanguage(e) {
        const { detail } = e;
        this.lang = detail.lang;
      },
      isAdmin() {
        const { roles = [] } = window.user;
        return roles.includes("ADMIN");
      },
      updateOrder() {
        const payload = { dishes: { ...this.dishes } };
        try {
          this.$refs.dessertStep.ordering = true;
        } catch (error) {
          //
        }
        payload.newOrder = false;
        this.$store.dispatch(STORE.ACTIONS.TABLE.TABLE_ORDER, payload);
        addDishesToOrder(this.order.id, payload)
          .then(response => this.handleOrderSuccess(payload, response))
          .catch(error => this.handleOrderError(error));
      },
      generateOrderPayload() {
        Object.keys(this.dishes).forEach(key => {
          const { amount, promotion = "", price } = this.dishes[key];
          if (promotion) {
            this.dishes[key].computedPrice = price * calcPromotion(promotion, amount);
          }
        });
        const user = window.router.currentRoute.query.hasOwnProperty("user") ? window.router.currentRoute.query.user : window.user.uid;
        const belongsTo = window.router.currentRoute.params.headquater;
        const restaurant = window.router.currentRoute.params.id;
        let { table = "" } = window.router.currentRoute.query;
        if (!table) {
          // eslint-disable-next-line prefer-destructuring
          table = this.table;
        }
        const dispatchedBy = this.waiter;
        const payload = {
          dishes: { ...this.dishes },
          user,
          table,
          belongsTo,
          restaurant,
          dispatchedBy
        };
        return payload;
      },
      generateOrder() {
        const payload = this.generateOrderPayload();
        // console.log("crear orden", payload);
        payload.newOrder = true;
        this.$store.dispatch(STORE.ACTIONS.TABLE.TABLE_ORDER, payload);
        createOrder(payload)
          .then(response => this.handleOrderSuccess(payload, response))
          .catch(error => this.handleOrderError(error));
      },
      async handleOrderError(response) {
        const error = await response;
        try {
          this.$refs.dessertStep.ordering = false;
        } catch (error) {
          //
        }
        const boxFlowClosed = error && error.code === "boxFlowClosed";
        this.displayBoxFlowClosedSnackbar = boxFlowClosed;
        if (!boxFlowClosed) this.displayErrorSnackbar = true;
      },
      handleOrderSuccess(payload, response) {
        if (response.ok) {
          try {
            this.$refs.dessertStep.ordering = false;
          } catch (error) {
            //
          }
          this.options = true;
          this.showOrder = false;
          if (this.order === null) {
            this.order = { ...payload, id: response.id };
            if (this.iva) this.order.iva = { active: true, value: this.iva };
          }
          // console.log("handleOrderSuccess");
          // console.log(this.menus);
          this.menus = this.menus.map(item => {
            item.lock = this.dishes[item.id];
            return item;
          });
          if (this.menuMode === "waiter" || this.menuMode === "admin") {
            this.billing = true;
            this.options = false;
            this.showTabs = false;
          }
        } else {
          this.$refs.dessertStep.ordering = false;
          this.displayErrorSnackbar = true;
        }
      },
      handleShowBilling() {
        this.billing = true;
        this.options = false;
        this.showTabs = false;
      },
      handleShowMenu() {
        this.step = 1;
        this.options = false;
        this.showOrder = true;
        this.showTabs = false;
      },
      handleEndOrder() {
        this.$refs.dessertStep.ordering = true;
        if (this.order) {
          this.updateOrder();
        } else {
          this.generateOrder();
        }
      },
      handleChangeSuggestion(val, id) {
        this.dishes[id].suggestion = val;
      },
      generateAmountFromDishes(dishes) {
        let result = 0;
        Object.keys(dishes).forEach(key => {
          result += dishes[key].amount;
        });
        return result;
      },
      handleChangeAmount(id, amount) {
        // console.log(id);
        // console.log(amount);
        const { price, name, promotion = {}, categories, image = "" } = this.menus.find(item => item.id === id);
        const hasDishe = this.dishes.hasOwnProperty(id);
        if (hasDishe) {
          this.dishes[id].amount = amount;
          this.dishes[id].price = Number(price);
          this.dishes[id].name = name;
          this.dishes[id].categories = categories;
          this.dishes[id].image = image;
          if (promotion && promotion.available) this.dishes[id].promotion = promotion.type;
        } else {
          this.dishes[id] = {
            amount,
            name,
            price: Number(price),
            categories,
            image
          };
          if (promotion && promotion.available) this.dishes[id].promotion = promotion.type;
        }
        if (amount === 0) delete this.dishes[id];
        const isEmpty = Object.keys(this.dishes).length === 0;
        if (isEmpty) {
          this.$refs.orderHeader.orderAmount = 0;
          this.$refs.orderHeader.orderActive = false;
        } else {
          const count = this.generateAmountFromDishes(this.dishes);
          this.$refs.orderHeader.orderAmount = count;
          this.$refs.orderHeader.orderActive = true;
        }
        // this.readyToOrder = this.menuMode === "waiter" || this.menuMode === "admin" || Object.keys(this.dishes).length !== 0;
        this.readyToOrder = Object.keys(this.dishes).length !== 0;
      },
      handleRequestOrder(invoice) {
        this.showTabs = false;
        this.showReserv = false;
        this.showOrder = true;
        // confirm table is free
        const { headquater } = window.router.currentRoute.params;
        let categories = "";
        if (this.step === 1) categories = this.main;
        if (this.step === 2) categories = this.drinks;
        if (this.step === 3) categories = this.dessert;
        if (invoice) {
          this.findMenu(headquater, {}, true).then(() => {
            this.options = false;
            this.showOrder = false;
            this.billing = true;
            this.showTabs = false;
          });
        } else {
          this.findMenu(headquater, { categories }, true);
        }
      },
      handleOpenRervationDetails() {
        window.router.push({
          name: "dashboardState",
          hash: "#tab=reservations",
          params: { state: "menu" }
        });
      },
      handleChangePersons(val) {
        this.persons = String(val);
      },
      handleChangeHour(val) {
        this.hour = String(val);
      },
      toLocalDateString(date) {
        const currentMonth = date.getMonth();
        const month = String(currentMonth).length === 1 && currentMonth < 9 ? `0${currentMonth + 1}` : currentMonth + 1;
        const day = String(date.getDate()).length === 1 ? `0${date.getDate()}` : date.getDate();
        return `${date.getFullYear()}-${month}-${day}`;
      },
      validateReservation() {
        this.readyToReserv = Boolean(this.hour && this.persons && this.dateTitle) && this.$refs.personsAndTime.isClose === false;
      },
      getDayTime() {
        const today = new Date();
        const curHr = today.getHours();
        if (curHr < 12) return "morning";
        if (curHr < 18) return "afternoon";
        return "evening";
      },
      allowedDates(val) {
        const daysAllowed = this.restaurant.schedule;
        const currentDate = new Date(val);
        const allow = daysAllowed.hasOwnProperty(weekDays[currentDate.getDay()]);
        return allow;
      },
      handleSelectReservDate() {
        this.isToday = this.sameDate(this.toLocalDateString(new Date()), this.date);
        this.dayweek = weekDays[new Date(this.date).getDay() - 1];
        this.$refs.personsAndTime.dayweek = this.dayweek;
        this.$refs.personsAndTime.updateVariables(this.restaurant.schedule);
        this.validateReservation();
      },
      sameDate(a, b) {
        const plainA = a.split("-");
        const plainB = b.split("-");
        const sameYear = plainA[0] === plainB[0];
        const sameMonth = plainA[1] === plainB[1];
        const sameDay = plainA[2] === plainB[2];
        return sameYear && sameMonth && sameDay;
      },
      async selectTab(tab) {
        const { headquater } = window.router.currentRoute.params;
        const isMenu = tab === "menu";
        // const isInfo = tab === 'info';
        const isPromotion = tab === "promotions";
        if (isMenu) {
          this.loadingMenu = true;
          const { error } = await surePromise(this.findMenu(headquater, {}));
          this.loadingMenu = false;
          if (error) this.error = true;
        }
        if (isPromotion) {
          this.loadingPromotions = true;
          const { error } = await surePromise(this.findMenu(headquater, { promotion: true }));
          this.loadingPromotions = false;
          if (error) this.error = true;
        }
      },
      async findMenu(id, query, order = false) {
        const { error, result: menus } = await surePromise(fetchMenu(id, query));
        if (error) {
          this.displayErrorSnackbar = true;
          this.loadingMenu = false;
          this.loadingPromotions = false;
          return Promise.reject(error);
        }
        const hasPrevOrder = window.router.currentRoute.query.hasOwnProperty("order") || this.hasPrevOrder;
        // console.log("hasPrevOrder2");
        // console.log(hasPrevOrder);
        if (hasPrevOrder) {
          const { error: failFetchingOrder } = await surePromise(this.fetchPrevOrder(menus));
          if (failFetchingOrder) {
            this.displayErrorSnackbar = true;
          }
          this.loadingPromotions = false;
          this.loadingMenu = false;
        }
        if (order) {
          this.menus = this.menus.concat(menus);
          return Promise.resolve(this.menus);
        }
        this.menus = menus;
        return Promise.resolve(this.menus);
      },
      async fetchPrevOrder(menus) {
        // console.log("fetchPrevOrder");
        // console.log(menus);
        let { order: id = "" } = window.router.currentRoute.query;
        if (!id && this.hasPrevOrder) {
          id = this.hasPrevOrder;
        }
        const { result: order, error } = await surePromise(fetchOrder(id));
        if (order) {
          this.dishes = { ...order.dishes, ...this.dishes };
          this.order = { ...order };
          menus.forEach(dish => {
            const match = this.dishes.hasOwnProperty(dish.id);
            if (match) {
              dish.amount = this.dishes[dish.id].amount || 0;
              dish.suggestion = this.dishes[dish.id].suggestion || "";
              this.dishes[dish.id].defaults = true;
            }
          });
          return Promise.resolve(menus);
        }
        return Promise.reject(error);
      },
      reloadOperation() {
        this.loading = true;
        this.error = false;
        this.deniedLocation = false;
        this.notFound = false;
        this.initialize();
      },
      initialize() {
        const options = {
          enableHighAccuracy: true,
          // timeout: 5000,
          maximumAge: 0
        };
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.successGetPosition, this.failGetPosition, options);
        }
      },
      handleSelectContact(name) {
        const isPhone = name === "phone";
        const isWhatsapp = name === "whatsapp";
        if (isPhone) {
          this.showPhone = true;
          this.showWhatsapp = false;
        } else if (isWhatsapp) {
          this.showWhatsapp = true;
          this.showPhone = false;
        } else {
          window.open(this.restaurant[name], "_blank");
          this.showWhatsapp = false;
          this.showPhone = false;
        }
      },
      successGetPosition(position) {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.aggregateRestaurant();
      },
      failGetPosition(error) {
        this.deniedLocation = error.code === 1;
        this.error = true;
        this.loading = false;
      },
      async aggregateRestaurant() {
        const { id } = window.router.history.current.params;
        const { ok, error, result } = await surePromise(fetchRestaurant(id, {}));
        if (ok) return this.handleRestaurantsResponse(result);
        this.handleRestaurtansResponseError(error);
      },
      handleRestaurtansResponseError() {
        this.loading = false;
      },
      async handleRestaurantsResponse(response = {}) {
        const { notFound = null, headquaters = [] } = response;
        this.notFound = Boolean(notFound);
        if (notFound) {
          this.notFound = true;
          this.loading = false;
          return;
        }
        const { error } = await surePromise(this.fetchAllHeadquaterRelectedWithRestaurant(headquaters));
        this.loading = false;
        if (error) {
          this.displayErrorSnackbar = true;
          return;
        }
        const hasData = Boolean(this.headquaters.length);
        if (hasData) this.setVariables();
        document.getElementById("container").style.backgroundColor = theme.secondary;
      },
      setVariables() {
        const match = this.headquaters.find(item => item.id === window.router.currentRoute.params.headquater);
        match.selected = true;
        this.restaurant = match;
        this.restaurant.isFavorite = match.likes.hasOwnProperty(window.user.uid) && Boolean(match.likes[window.user.uid]);
        this.center = { lat: this.restaurant.lat, lng: this.restaurant.lng };
        this.renderPath = true;
      },
      async fetchAllHeadquaterRelectedWithRestaurant(headquaters) {
        for (const headquater of headquaters) {
          const { result } = await surePromise(fetchHeadquater(headquater, {}));
          if (result) {
            const hasPostion = result.hasOwnProperty("l");
            if (hasPostion) {
              result.lat = Number(result.l._latitude);
              result.lng = Number(result.l._longitude);
              const distance = getDistanceFromLatLonInKm(this.lat, this.lng, result.lat, result.lng);
              result.distance_stars = `${distance.toFixed(2)}km, ${result.stars} Estrellas`;
              result.selected = false;
              this.headquaters.push(result);
            }
          }
        }
        return Promise.resolve(this.headquaters);
      },
      convertStringTotDate(fulldate, time) {
        const [, , day] = fulldate.split("-");
        const [hour, minute] = this.convert12To24(time);
        const date = new Date(fulldate);
        date.setDate(day);
        date.setHours(hour);
        date.setMinutes(minute);
        return date;
      },
      convert12To24(time = "") {
        const plainTime = time
          .replace("pm", "")
          .replace("am", "")
          .split(":");
        const pm = time.includes("pm");
        if (pm) {
          plainTime[0] = `${Number(plainTime[0]) + 12}`;
        }
        if (pm === false) {
          plainTime[0] = `0${plainTime[0]}`;
        }
        return plainTime;
      },
      async handleRequestReserv() {
        const { hour, persons, date } = this;
        const data = {
          hour,
          persons,
          date: this.convertStringTotDate(date, hour)
        };
        data.belongsTo = this.restaurant.id;
        data.name = this.restaurant.name;
        data.dateDisplay = this.dateTitle;
        this.creatingReservation = true;
        const { ok, result, error } = await surePromise(createReservation(data));
        if (ok) return this.handleReservationSuccess(result);
        this.handleReservationError(error);
      },
      handleReservationSuccess(response) {
        this.creatingReservation = false;
        if (response.ok) {
          this.displaySuccessSnackbar = true;
          this.displayErrorSnackbar = false;
          return;
        }
        this.displaySuccessSnackbar = false;
        this.displayErrorSnackbar = true;
      },
      handleReservationError() {
        this.creatingReservation = false;
        this.displaySuccessSnackbar = false;
        this.displayErrorSnackbar = true;
      },
      handleSelectRestaurant(item) {
        this.unselectAllHeadquaters();
        this.center = { lat: item.lat, lng: item.lng };
        item.selected = true;
        this.renderPath = true;
        this.restaurant = item;
      },
      unselectAllHeadquaters() {
        this.headquaters.forEach(restaurant => {
          restaurant.selected = false;
        });
      },
      async handleReserv() {
        this.showTabs = false;
        this.loading = true;
        this.showReserv = true;
        const { headquater: id = "" } = window.router.history.current.params;
        const { error } = await surePromise(fetchHeadquater(id, {}));
        this.loading = false;
        this.$store.dispatch(STORE.ACTIONS.CART.OPEN_CART);
        if (error) this.displayErrorSnackbar = true;
      },
      handleGoBack() {
        if (window.prevRoute) return window.router.push({ path: window.prevRoute });
        window.router.push({ name: "dashboard" });
      },
      async handleLike(id) {
        const { error } = await surePromise(requests.setLike({ id, state: { like: true } }));
        if (error) this.displayErrorSnackbar = true;
      },
      async handleDislike(id) {
        const { error } = await surePromise(requests.setLike({ id, state: { dislike: true } }));
        if (error) this.displayErrorSnackbar = true;
      },
      async handleComments() {
        const { headquater } = window.router.currentRoute.params;
        const { ok, result: comments } = await surePromise(requests.getComments(headquater));
        if (ok) {
          this.comments = comments;
          return;
        }
        this.displayErrorSnackbar = true;
      },
      showCommentModal() {
        if (this.comments[0]) this.showComments = true;
      }
    }
  };
</script>
