<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import ItemsLists from "@components/lists/ItemsLists";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import MenuForm from "@components/forms/MenuForm";
import CardBusinessList from "@components/lists/CardBusinessList";
import ArticleFrame from "@components/generals/ArticleFrame";
import CreateDishModal from "@components/modals/CreateDishModal";
import ClientTables from "@components/tables/ClientTables";
import { notifyEvent } from "@/utils/notifyEvent";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import uuid from "@utils/uuid";
import mixins from "@/mixins";

const { DateManager, MenuMixin } = mixins;

export default {
  name: "AdminBusiness",
  components: {
    Checkbox,
    ArticleFrame,
    CardBusinessList,
    ClientTables,
    Loading,
    ItemsLists,
    UserBasicInfo,
    HeadquatersSelect,
    MenuForm,
    CreateDishModal,
    ReservationsComboTables
  },
  mixins: [DateManager, MenuMixin],
  data() {
    const headquater = this.getHeadquaterSelectedFromUserProfile();
    return {
      tableSuscription: null,
      orderSuscription: null,
      orderReservation: null,
      loading: false,
      showOverlay: false,
      freeTables: 0,
      busyTables: 0,
      activeWaiters: 0,
      unActiveWaiters: 0,
      requestedOrders: 0,
      pendingOrders: 0,
      completeOrders: 0,
      headquater,
      dataset: [],
      waiters: [],
      orders: [],
      tables: [],
      displayNewOrder: false
    };
  },
  async mounted() {
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
    this.loading = true;
    await this.initialize();
    await this.$nextTick();
    this.loading = false;
  },
  created() {
    this.tableKey = uuid();
    this.cardsKey = uuid();
  },
  beforeDestroy() {
    if (this.tableSuscription) this.tableSuscription();
    if (this.orderSuscription) this.orderSuscription();
    if (this.orderReservation) this.orderReservation();
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  computed: {
    cardData() {
      const { freeTables, busyTables, activeWaiters, unActiveWaiters, dataset, loading, requestedOrders, pendingOrders, completeOrders } = this;
      return {
        freeTables,
        busyTables,
        activeWaiters,
        unActiveWaiters,
        dataset,
        loading,
        requestedOrders,
        pendingOrders,
        completeOrders
      };
    }
  },
  methods: {
    async initialize() {
      this.tableSuscription = this.watchTables();
      this.headquater = this.getHeadquaterSelectedFromUserProfile();
      this.waiters = await this.getEmployeesByHeadquater(this.headquater);
      this.orderSuscription = await this.watchOrders();
      this.orderReservation = await this.watchReservations();
      this.activeWaiters = this.filterWaitersByActive(true).length;
      this.unActiveWaiters = this.filterWaitersByActive(false).length;
    },
    async handleSelectHeadquater() {
      this.loading = true;
      await this.initialize();
      this.tableKey = uuid();
      this.cardsKey = uuid();
      await this.$nextTick();
      this.loading = false;
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
      notifyEvent({ name: "overlay", detail: this.showOverlay });
    },
    populateChart(date, splitByHour) {
      const { id = "" } = this.headquater;
      const payload = { headquater: id };
      const [year, month, day] = date.split("-");
      if (year) payload.year = Number(year);
      if (month) payload.month = Number(month);
      if (day) payload.day = Number(day);
      this.getSellAggregate(payload, splitByHour);
    },
    sortByDate(a, b) {
      return new Date(a.createAt._seconds * 1000) - new Date(b.createAt._seconds * 1000);
    },
    async getSellAggregate(payload = {}, splitByHour = false) {
      this.loading = true;
      let { result } = await surePromise(requests.getOrdersByDate(payload));
      result = result.sort(this.sortByDate);
      const shouldAgregateByYear = payload.hasOwnProperty("year") && payload.hasOwnProperty("month") === false;

      const shouldAggregateByMonth = payload.hasOwnProperty("year") && payload.hasOwnProperty("month");
      if (shouldAgregateByYear) {
        const months = this.aggregateByMonth(result);
        this.dataset = this.mapToDataset(months);
      }
      if (shouldAggregateByMonth) {
        const days = this.aggregateByDay(result, splitByHour);
        this.dataset = this.mapToDataset(days);
      }
      this.loading = false;
    },
    aggregateByDay(data, splitByHour = false) {
      const days = {};
      for (const item of data) {
        const { createAt, price } = item;
        const date = new Date(createAt._seconds * 1000);
        const [, hours] = date.toLocaleString("es-CO", { timeZone: "America/Bogota" }).split(",");
        const key = splitByHour ? hours : `Dia - ${date.getDate()}`;
        if (days.hasOwnProperty(key)) {
          days[key].push(price);
          const calc = days[key].reduce((accumulator, currentValue) => {
            accumulator += currentValue;
            return accumulator;
          }, 0);
          days[key] = [calc];
        } else {
          days[key] = [price];
        }
      }
      return days;
    },
    mapToDataset(months) {
      const result = [];
      const keys = Object.keys(months);
      for (const key of keys) {
        const [val] = months[key];
        result.push([key, val]);
      }
      return result;
    },
    aggregateByMonth(data) {
      const months = {};
      for (const item of data) {
        const { createAt, price } = item;
        const date = new Date(createAt._seconds * 1000);
        const month = date.getMonth();
        const monthName = this.findMonthName(month);
        if (months.hasOwnProperty(monthName)) {
          months[monthName].push(price);
          const calc = months[monthName].reduce((accumulator, currentValue) => {
            accumulator += currentValue;
            return accumulator;
          }, 0);
          months[monthName] = [calc];
        } else {
          months[monthName] = [price];
        }
      }
      return months;
    },
    sumPrices(data) {
      return Object.keys(data).reduce((accumulator, key) => {
        const price = data[key].reduce((accu, val) => accu + val, 0);
        accumulator += price;
        return accumulator;
      }, 0);
    },
    findMonthName(index) {
      const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      return months[index];
    },
    filterByStatus(collection, status = "") {
      return collection.filter(item => item.status === status);
    },
    filterWaitersByActive(state) {
      return this.waiters.filter(waiter => waiter.isActive === state);
    },
    countTablesByStatus() {
      this.freeTables = this.filterByStatus(this.tables, "FREE").length;
      this.busyTables = this.filterByStatus(this.tables, "BUSY").length;
    },
    countOrdersByStatus() {
      this.requestedOrders = this.filterByStatus(this.orders, "REQUESTED").length;
      this.pendingOrders = this.filterByStatus(this.orders, "PENDING").length;
      this.completeOrders = this.filterByStatus(this.orders, "COMPLETE").length;
    },
    ignoreUselessRoles(roles) {
      return roles.replace(/USER|OWNER|ADMIN_GEOCHEF|,/g, "");
    },
    translateRoles(roles) {
      roles = roles.replace(/WAITER/g, "Mesero");
      roles = roles.replace(/CHEF/g, "Chef");
      roles = roles.replace(/ADMIN/g, "Administrador");
      return roles;
    },
    formatItems(items) {
      return items.map(item => {
        item.title = this.translateRoles(this.ignoreUselessRoles(item.roles.join(",")));
        return item;
      });
    },
    rejectAdmins(employees) {
      const isAllow = window.user.roles.includes("OWNER") || window.user.roles.includes("ADMIN_GEOCHEF");
      if (isAllow) return employees;
      return employees.filter(employee => !employee.roles.includes("ADMIN"));
    },
    rejectCurrentUser(employees) {
      return employees.filter(employee => employee.id !== window.user.uid);
    },
    async getEmployeesByHeadquater(headquater) {
      if (headquater) {
        // eslint-disable-next-line prefer-const
        let { ok, result = {} } = await surePromise(requests.fetchEmployeesByHeadquater(headquater.id));
        if (ok && result.length > 0) {
          result = this.formatItems(this.rejectCurrentUser(this.rejectAdmins(result)));
        }
        console.log("getEmployeesByHeadquater");
        console.log(headquater);
        if (ok) return this.mapWaiterActives(result, headquater);
        return [];
      }
      return [];
    },
    mapWaiterActives(waiters, headquater) {
      const { id = "" } = headquater;
      return waiters.map(waiter => {
        waiter.isActive = waiter.workingAt.hasOwnProperty(id);
        return waiter;
      });
    },
    watchOrders() {
      const db = window.firebase.firestore();
      const OrderRef = db.collection("Orders");
      const { id = "" } = this.headquater;
      const yesterday = this.subtractDay(new Date(), 1);
      const tomorrow = this.addDay(new Date(), 1);
      return OrderRef.where("belongsTo", "==", id)
        .where("createAt", ">", yesterday)
        .where("createAt", "<", tomorrow)
        .onSnapshot(snapshot => {
          const docs = this.mapSnapshotResponse(snapshot);
          this.orders = docs;
          this.countOrdersByStatus();
        });
    },
    watchReservations() {
      const db = window.firebase.firestore();
      const ReservationsRef = db.collection("Reservations");
      ReservationsRef.where("belongsTo", "==", this.headquater.id)
        .where("status", "==", "PENDING")
        .onSnapshot(async snapshots => {
          snapshots.docChanges().forEach((change) => {
            if (change.type === "added") {
              this.displayNewOrder = true;
            }
          });
        });
    },
    watchTables() {
      const db = window.firebase.firestore();
      const TablesRef = db.collection("Tables");
      const { id = "" } = this.headquater;
      return TablesRef.where("belongsTo", "==", id).onSnapshot(snapshot => {
        const docs = this.mapSnapshotResponse(snapshot);
        this.tables = docs;
        this.countTablesByStatus();
      });
    },
    mapSnapshotResponse(snapshot) {
      const result = [];
      const { docs = [] } = snapshot;
      docs.forEach(doc => result.push(doc.data()));
      return result;
    }
  }
};
</script>
