<style scoped lang="scss">
.wrap-dish-btns {
  display: flex;
  flex-direction: column;
}
.btn-save-dish {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: auto;
  border-radius: 12px;
  height: 50px;
  border: 1px solid #16202f;
  font-family: Gibson-Bold;
  font-size: 13px;
  color: #16202f !important;
  background: transparent !important;
}
.btn-open-headquaters-modal {
  width: 100%;
  margin-left: 0px;
  height: 45px;
  border-radius: 12px;
  margin-bottom: 15px;
  font-family: Gibson-Bold;
  font-size: 13px;
  border: 1px solid #16202f;
  background-color: transparent !important;
  color: #16202f !important;
}
.btn-open-category-modal {
  font-family: Gibson-Bold;
  font-size: 13px;
  width: 100%;
  margin-bottom: 25px;
  margin-left: 0px;
  border-radius: 12px;
  height: 45px;
  border: 1px solid #16202f;
  background-color: transparent !important;
  color: #16202f !important;
}
.v-form {
  margin-top: 60px;
}
::v-deep .invalid {
  .field-msg {
    color: red;
  }
}
</style>

<template>
  <v-form class="menu-form">
    <HeadquatersModal
      ref="headquatersModal"
      :data="headquaters"
      :open="showHeadquatersModal"
      @close="showHeadquatersModal = false"
    ></HeadquatersModal>

    <div :class="{'wrap-dish-btns': mode === 'create'}">
      <v-btn
        flat
        text
        color="secondary"
        class="btn-open-headquaters-modal"
        :disabled="loading === true"
        @click="handleOpenHeadquaterModal"
      >
        Ver sedes
      </v-btn>

      <v-btn
        flat
        text
        color="secondary"
        class="btn-open-category-modal"
        :disabled="loading === true"
        @click="handleDisplayCategory"
      >
        Ver categorias
      </v-btn>
    </div>

    <CategoriesModal
      v-if="showCategories === true"
      :open="showCategories === true"
      :categories="data.categories"
      @close="handleCloseCategoriesModal"
    ></CategoriesModal>

    <BasicInput
      :disabled="loading === true"
      :default-value="data.name"
      label="*Nombre del producto"
      :valid="validity.name"
      styl
      :dark="true"
      :msg="nameInvalidMsg"
      :overlay="true"
      :reset-input="reset"
      @input-change="handleFieldChange('name', $event)"
    ></BasicInput>

    <BasicInput
      :disabled="loading === true"
      :default-value="mode === 'edit' ? data.price : '0'"
      label="*Precio"
      :valid="validity.price"
      styl
      type="number"
      :dark="true"
      :msg="priceInvalidMsg"
      :show-hint="true"
      :hint="changes.price | filterMoney | dollarSign"
      :overlay="true"
      :reset-input="reset"
      @input-change="handleFieldChange('price', $event)"
    ></BasicInput>

    <DescriptionByLanguage
      ref="descriptionField"
      :disabled="loading === true"
      :mode="mode"
      :default-description="data.description || {}"
      @input-change="handleFieldChange('description', $event)"
    ></DescriptionByLanguage>

    <PromotionsSelect
      ref="promotionSelect"
      :disabled="loading === true"
      :default="mapPromotion"
    ></PromotionsSelect>

    <FileUploadInput
      :id="`dish-image-${mode}`"
      ref="menuImage"
      :disabled="loading === true"
      accept="image/*"
      :multiple="false"
      caption="Cargar Imagen"
      @change="handleFileUploadChange"
    ></FileUploadInput>

    <SaveAndDeleteCombo
      ref="saveAndDeleteCombo"
      :mode="mode"
      :disabled="mode === 'create' ? allFieldsValids === false : false"
      @delete="deleteMenuFromHeadquaterSelected"
      @save="handleSave"
    ></SaveAndDeleteCombo>
  </v-form>
</template>

<script>
import BasicInput from '@inputs/BasicInput';
import FileUploadInput from '@inputs/FileUploadInput';
import validators from '@utils/validators';
import DescriptionByLanguage from '@components/inputs/DescriptionByLanguage';
import CategoriesModal from '@components/modals/CategoriesModal';
import SaveAndDeleteCombo from '@components/generals/SaveAndDeleteCombo';
import HeadquatersModal from '@components/modals/HeadquatersModal';
import PromotionsSelect from '@components/selects/PromotionsSelect';
import surePromise from '@utils/surePromise';
import requests from '@/helpers/requests';

export default {
  name: 'MenuForm',
  components: {
    BasicInput,
    SaveAndDeleteCombo,
    DescriptionByLanguage,
    FileUploadInput,
    CategoriesModal,
    PromotionsSelect,
    HeadquatersModal
  },
  props: {
    headquaters: {
      type: Array,
      default: () => ([])
    },
    showConfirmBox: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'create'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
     reset: false,
     fetchingCategories: false,
     loading: false,
     available: this.data.status === 'available',
     showHeadquatersModal: false,
     showCategories: false,
     validity: {
        name: this.mode === 'edit',
        price: this.mode === 'edit',
        description: false,
        image: false
     },
     changes: {
        price: this.data.price || '0',
        name: this.data.name,
        categories: []
      },
      nameInvalidMsg: 'Escribe un nombre valido',
      priceInvalidMsg: 'Escribe un precio valido'
    };
  },
  computed: {
    allFieldsValids() {
      return Object
               .keys(this.validity)
               .every(field => this.validity[field] === true);
    },
    computedStatus() {
      return this.data.status;
    },
    translateStatus() {
      let result = '';
      if (this.data.status === 'available') result = 'Disponible';
      if (this.data.status === 'notAvailable') result = 'No disponible';
      return result;
    },
    mapPromotion() {
      const { promotion } = this.data;
      if (promotion && promotion.available === true) {
        return {
          type: promotion.type,
          name: promotion.type
        };
      }
      return {};
    }
  },
  watch: {
    data(val) {
      const fields = Object.keys(val);
      fields.forEach((field) => {
        const inValidation = this.validity.hasOwnProperty(field);
        const inChanges = this.changes.hasOwnProperty(field);
        if (inValidation) this.validity[field] = true;
        if (inChanges) this.changes[field] = val[field];
      });
      const { descriptionField, menuImage } = this.$refs;
      descriptionField.reset();
      menuImage.reset();
    }
  },
  methods: {
   handleOpenHeadquaterModal() {
     this.showHeadquatersModal = true;
   },
   resetForm() {
     const { descriptionField, promotionSelect, menuImage } = this.$refs;
     if (descriptionField) descriptionField.reset();
     if (promotionSelect) promotionSelect.reset();
     if (menuImage) menuImage.reset();
     this.reset = true;
     this.validity = {
        name: this.mode === 'edit',
        price: this.mode === 'edit',
        description: false,
        image: false
     };
     this.changes = {
        price: this.data.price || '0',
        name: this.data.name,
        categories: []
      };
   },
   handleSelectHeadquater() {
     /*
      this.headquaters.forEach((headquater) => {
        const selected = payload.find(item => item.id === headquater.id);
        headquater.selected = Boolean(selected);
      });
      */
   },
   handleFileUploadChange(file) {
     this.validity.image = Boolean(file);
   },
   handleSave() {
     if (this.mode === 'create') {
       this.saveDish();
     } else {
       this.saveChanges();
     }
   },
   async saveDish() {
     this.loading = true;
     const files = this.$refs.menuImage.getFiles();
     const payload = {};
     const file = files[0];
     const storageRef = window.firebase.storage().ref(`images/Dishes/${file.name}`);
     await storageRef.put(file);
     const BASE = 'https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o/images%2FDishes';
     payload.image = `${BASE}%2F${file.name}?alt=media`;

     const promotion = this.$refs.promotionSelect.getSelectedPromotion();
     if (promotion !== 'NONE') {
         payload.promotion = {
           type: promotion,
           available: true
         };
     }
     payload.name = this.changes.name;
     payload.price = this.changes.price;
     payload.description = this.$refs.descriptionField.descriptions;
     payload.availableAt = this.$refs.headquatersModal.getSelectedHeadquaters();
     payload.categories = this.changes.categories.map(category => category.id);
     payload.categories = payload.categories.reduce(
         (accumulator, current) => {
            accumulator[current] = true;
            return accumulator;
       }, {}
      );

     const response = await requests.saveDish(payload);
     this.loading = false;
     this.$refs.saveAndDeleteCombo.saving = false;
     if (response.ok === true) {
        this.$emit('success', { ...payload, id: response.id });
        this.$emit('close');
     } else {
        this.$emit('error');
     }
   },
   handleDisplayCategory() {
      this.showCategories = true;
   },
   handleCloseCategoriesModal(categories) {
     this.showCategories = false;
     this.changes.categories = categories;
   },
   handleFieldChange(field, value) {
     this.changes[field] = value;
     if (value === '') {
       this.validity[field] = false;
     }

     if (field === 'name') {
       this.validity[field] = validators.validName(value);
     }

     if (field === 'price') {
       this.validity[field] = validators.validNumber(value) && value !== '0';
     }

     if (field === 'description') {
       const isValid = this.$refs.descriptionField.isValid();
       this.validity[field] = isValid;
     }
   },
   async deleteMenuFromHeadquaterSelected() {
     const { ok, error } = await surePromise(
       requests.deleteDish(this.data.id)
     );
     this.loading = false;
     this.$refs.saveAndDeleteCombo.deleting = false;
     const success = ok === true;
     if (success) {
       this.$emit('deleted', this.data.id);
     } else if (error.status === 406) {
       this.$emit('busy');
     } else {
       this.$emit('error');
     }
   },
   getDifferences() {
     const result = {};
     const { name, price } = this.data;
     if (name !== this.changes.name) result.name = this.changes.name;
     if (String(price) !== String(this.changes.price)) result.price = this.changes.price;
     if (this.changes.categories.length) {
       result.categories = this.changes.categories.map(category => category.id);
       result.categories = result.categories.reduce(
          (accumulator, current) => {
            accumulator[current] = true;
            return accumulator;
          }, {}
       );
     }
     const description = this.$refs.descriptionField.getDifferences();
     if (Object.keys(description).length) result.description = description;
     return result;
   },
   async saveChanges() {
     this.loading = true;
     if (this.mode === 'edit') {
       const files = this.$refs.menuImage.getFiles();
       let payload = {};
       if (files.length) {
          const file = files[0];
          const storageRef = window.firebase.storage().ref(`images/Dishes/${file.name}`);
          await storageRef.put(file);
          const BASE = 'https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o/images%2FDishes';
          const filename = encodeURIComponent(file.name.trim());
          payload.image = `${BASE}%2F${filename}?alt=media`;
       }
       const differences = this.getDifferences();
       const type = this.$refs.promotionSelect.getSelectedPromotion();
       if (type === 'NONE') {
         if (this.data.promotion) {
           payload.promotion = this.data.promotion;
           payload.promotion.available = false;
         }
       } else {
         payload.promotion = {
           type,
           available: true
         };
       }
       payload.availableAt = this.$refs.headquatersModal.getSelectedHeadquaters();
       payload = { ...differences, ...payload };
       const { ok, error } = await surePromise(
         requests.updateDish(this.data.id, payload)
       );
       this.loading = false;
       this.$refs.saveAndDeleteCombo.saving = false;
       const success = ok === true;
       if (success) {
         payload.descriptions = { ...payload.description };
         this.$emit('success', { ...payload, id: this.data.id });
       } else if (error.status === 406) {
         this.$emit('busy');
       } else {
         this.$emit('error');
       }
     }
   }
  }
};
</script>
