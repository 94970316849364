import UrlDataPersist from "./UrlDataPersist";
import DataProvider from "./DataProvider";
import DateManager from "./DateManager";
import Accounting from "./Accounting";
import MenuMixin from "./Menu";

export default {
  Accounting,
  DataProvider,
  DateManager,
  UrlDataPersist,
  MenuMixin
};
