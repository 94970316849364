 <style scoped lang="scss">
.cards__container {
  display: flex;
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 12px 0px;
  padding-top: 0px;
  /*z-index: 9999;*/
  overflow-x: auto;
  height: 117px;
}
.cards__container-mask {
  width: 100%;
  height: 117px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.cards__container-card:first-child {
  margin-left: 0px;
}

.cards__container-card {
  position: relative;
  bottom: -9px;
  height: 60px;
  width: auto;
  min-width: 180px;
  max-width: 250px;
  height: 100px;
  background: #16202f;
  padding: 10px;
  border-radius: 8px;
  margin-left: 12px;
  display: inline-table;
  cursor: pointer;
  text-align: left;

  &.cards__container-card--active {
    background: #ed2236 !important;
  }

  .logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 100%;
    position: relative;
  }
  .cards__container-card__name {
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    padding-left: 8px;
    padding-top: 8px;
    width: calc(100% - 50px);
  }
  .cards__container-card__distance {
    color: hsla(0, 0%, 100%, 0.9);
    margin-bottom: 2px;
    border-radius: 6px;
    display: inline-block;
    padding: 2px 2px;
    position: absolute;
    bottom: 6px;
    left: 8px;
  }
}
@media (max-width: 500px) {
  .cards__container-mask::before {
    content: " ";
    background: -webkit-gradient(linear, right top, left top, from(transparent), to(#16202f));
    background: linear-gradient(to left, transparent, #16202f);
    height: 117px;
    width: 50px;
    position: absolute;
    left: -1px;
    /*z-index: 10000;*/
  }

  .cards__container-mask::after {
    content: " ";
    background: linear-gradient(to left, #16202f, transparent);
    width: 100px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 117px;
    /*z-index: 10000;*/
  }
  .cards__container-card {
    height: 98px !important;
    bottom: -17px;
  }
}
</style>

<template>
  <div class="cards__container-mask">
    <ul class="cards__container">
      <li
        v-for="item in list"
        :key="item.id"
        :class="{'cards__container-card--active': item.selected}"
        class="cards__container-card appear-enter-active"
        @click="() => handleSelectItem(item)"
      >
        <div class="wrap-caption">
          <div class="logo" :style="{'background-image': `url(${item.logo})`}"></div>
          <p class="cards__container-card__name">
            <v-clamp autoresize :max-lines="2">{{ item.name }}</v-clamp>
          </p>
          <div>
            <p class="cards__container-card__distance">
              <v-clamp autoresize :max-lines="1">{{ item.distance_stars }}</v-clamp>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import VClamp from "vue-clamp";
import logo from "@assets/default-logo.png";

export default {
  name: "Carrousel",
  components: {
    VClamp
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.list.forEach(item => {
      item.selected = false;
      if (!item.logo) item.logo = logo;
    });
  },
  methods: {
    handleSelectItem(item) {
      this.$emit("select", item);
    }
  }
};
</script>
