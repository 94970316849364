<template>
  <div>
    <v-dialog
      v-model="open"
      max-width="500"
      :fullscreen="fullscreen"
      :hide-overlay="fullscreen"
      :transition="fullscreen ? 'dialog-bottom-transition': 'scale-transition'"
      :content-class="fullscreen ? 'qr-modal--fullscreen' : 'qr-modal' "
    >
      <v-container class="qrmodal-modal__container">
        <div class="qrmodal-modal__container__header" v-if="!fullscreen">
          <span class="qrmodal-modal__container__header__caption">Código QR</span>
          <v-btn icon class="qrmodal-modal__container__header__btn-close" @click="open = false"></v-btn>
        </div>

        <div class="qrmodal-modal__container__qr">
          <vue-qr
            :logo-src="logo"
            :text="url"
            :size="300"
            :dot-scale="0.6"
            :callback="handleQrData"
          ></vue-qr>
        </div>

        <div class="qrmodal-modal__container__footer" v-if="fullscreen">
          <v-btn
            class="qrmodal-modal__container__footer__btn-ok"
            color="primary"
            @click="open = false"
          >Cerrar</v-btn>
        </div>

        <div class="qrmodal-modal__container__footer" v-if="!noDownload">
          <v-btn
            class="qrmodal-modal__container__footer__btn-download"
            color="primary"
            @click="handleDownload"
          >Descargar</v-btn>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import logo from "@/assets/icons/geochef-192x192.png";

const FileSaver = require("file-saver");

export default {
  name: "QrModal",
  components: {
    VueQr
  },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    noDownload: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: this.display,
      fullscreen: false,
      qrContent: "",
      logo
    };
  },
  watch: {
    open(val) {
      if (val === false) this.$emit("close");
    },
    display(val) {
      this.open = val;
    }
  },
  mounted() {
    this.fullscreen = window.matchMedia("(max-width: 500px)").matches;
  },
  methods: {
    handleQrData(url) {
      this.qrContent = url;
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);

      const mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      const ab = new ArrayBuffer(byteString.length);

      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    handleDownload() {
      const blob = this.dataURItoBlob(this.qrContent);
      FileSaver.saveAs(blob, `Mesa - ${this.name}.png`);
    }
  }
};
</script>

<style scoped lang="scss">
.qrmodal-modal__container__qr {
  position: relative;
  margin: 0px auto;
  display: grid;
  margin-right: 0px;
  width: 100%;
  justify-content: center;
  top: calc(50% - 150px);
  position: relative;
}

.btn {
  border-radius: 8px;
  font-family: Gibson;
  font-size: 16px;
  text-transform: uppercase;
  width: 150px;
  box-shadow: inherit !important;
}

.qrmodal-modal__container__footer {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &__btn-ok {
    height: 50px;
    margin-top: 30px;
    @extend .btn;
  }

  &__btn-download {
    @extend .btn;
  }
}

::v-deep .qr-modal {
  border-radius: 12px;
  background: white;
}

::v-deep .qr-modal--fullscreen {
  border-radius: 0px;
  background: white;
  display: flex;
  place-items: center;
}

.qrmodal-modal__container__header {
  font-size: 25px;
  font-family: Futura;
  color: #161f2f;
  padding-left: 15px;
}
.qrmodal-modal__container__header__btn-close {
  background-image: url("~@assets/icons/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  float: right;
  position: relative;
  top: -14px;
  right: -14px;
}
</style>

