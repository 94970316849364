export const TOAST = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const TYPE_MEDIA = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
};

export const TYPE_ACTION_MEDIA_MANAGER = {
  DELETE_IMAGE: 'DELETE_IMAGE',
  DELETE_ALBUM: 'DELETE_ALBUM'
};
