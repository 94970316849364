<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>
<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import ItemsLists from "@components/lists/ItemsLists";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import SaveAndDeleteCombo from "@components/generals/SaveAndDeleteCombo";
import InvitateModal from "@components/modals/InvitateModal";
import requests from "@/helpers/requests";
import mixin from '@/mixins';

const { MenuMixin } = mixin;

export default {
  name: "AdminEmployees",
  components: {
    Checkbox,
    InvitateModal,
    Loading,
    ItemsLists,
    UserBasicInfo,
    HeadquatersSelect,
    SaveAndDeleteCombo,
    ReservationsComboTables
  },
  mixins: [MenuMixin],
  data() {
    const { headquaters = {} } = window.user;
    const headquater = this.getHeadquaterSelectedFromUserProfile();
    return {
      tableSelected: {},
      displayInvitateModal: false,
      showDetails: false,
      tables: [],
      notifications: [],
      headquatersSelected: headquater,
      headquater,
      user: {},
      employees: [],
      headquaters,
      showOverlay: false,
      fetchingEmployees: false,
      success: false,
      error: false,
      active: false,
      suspend: false,
      loading: true
    };
  },
  computed: {
    getHeadquaterSelected() {
      const headquater = Object.keys(this.headquaters).find(key => this.headquaters[key].selected === true);
      return this.headquaters[headquater];
    },
    hasUserSelected() {
      return Object.keys(this.user).length !== 0;
    },
    headquatersValues() {
      return Object.values(this.headquaters);
    },
    headquaterList() {
      return Object.values(window.user.headquaters).map(item => item.name);
    },
    isPopulated(obj) {
      return Object.keys(obj).length !== 0;
    }
  },
  watch: {
    showOverlay(val) {
      const event = new CustomEvent("overlay", {
        detail: val
      });
      window.dispatchEvent(event);
    }
  },
  mounted() {
    this.loading = false;
    this.setSubscription();
    this.fetchingEmployees = true;
    this.employees = [];
    this.user = {};
    this.loadEmployees();
    window.addEventListener("notification", this.handleNewNotification);
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
  },
  beforeDestroy() {
    if (this.tableUnsuscription) this.tableUnsuscription();
    if (this.orderUnsuscribe) this.orderUnsuscribe();
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.removeEventListener("notification", this.handleNewNotification);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  methods: {
    loadEmployees() {
      this.getEmployeesByHeadquater(this.headquater)
        .then(employees => {
          this.fetchingEmployees = false;
          if (employees.length) this.employees = this.formatItems(this.rejectCurrentUser(this.rejectAdmins(employees)));
        })
        .catch(() => {
          this.fetchingEmployees = false;
          this.error = true;
        });
    },
    rejectAdmins(employees) {
      const isAllow = window.user.roles.includes("OWNER") || window.user.roles.includes("ADMIN_GEOCHEF");
      if (isAllow) return employees;
      return employees.filter(employee => !employee.roles.includes("ADMIN"));
    },
    rejectCurrentUser(employees) {
      return employees.filter(employee => employee.id !== window.user.uid);
    },
    resolveChange() {
      const operations = [];
      const activeChange = this.active !== this.user.workingAt[this.headquatersSelected.id];
      const detailSelected = this.$refs.detailsSelectHeadquater.selected.id;
      const changeHeadquater = detailSelected !== this.headquatersSelected.id;
      if (activeChange) {
        if (this.active === true) operations.push({ type: "setActive" });
        if (this.active === false) operations.push({ type: "setUnactive" });
      }

      if (changeHeadquater) {
        operations.push({ type: "leaveHeadquater", headquater: detailSelected });
        operations.push({ type: "addHeadquater", headquater: detailSelected });
      }

      if (this.suspend === true) operations.push({ type: "suspend" });

      return operations;
    },
    mapOpeationsToRequests(operations) {
      const { headquatersSelected, user } = this;
      return operations
        .map(operation => {
          operation.employee = user.id;
          if (operation.type !== "addHeadquater") {
            operation.headquater = headquatersSelected.id;
          }
          return operation;
        })
        .map(operation => {
          const request = requests.updateEmployeeFromHeadquater(operation);
          request
            .then(response => this.handleResponseOperation(operation, response))
            .catch(error => {
              this.handleFailOperation(operation, error);
            });
          return request;
        });
    },
    handleResponseOperation(operation, response) {
      const { type = "", employee, headquater } = operation;
      if (response.ok === true) {
        const currentEmployee = this.employees.find(item => item.id === employee);
        if (type === "setActive") {
          currentEmployee.workingAt[headquater] = true;
        }
        if (type === "setUnactive") {
          currentEmployee.workingAt[headquater] = false;
        }
        if (type === "suspend") {
          currentEmployee.suspend = true;
        }
        this.success = true;
      } else {
        this.error = true;
      }
    },
    handleFailOperation(operation, error) {
      console.log("error ", error);
      this.error = true;
    },
    async saveEmployeeChanges() {
      let operations = this.resolveChange();
      if (operations.length) {
        operations = this.mapOpeationsToRequests(operations);
        try {
          const results = await Promise.all(operations);
          const allOperationSuccess = results.every(result => result.ok === true);
          this.$refs.saveAndDeleteCombo.saving = false;
          if (allOperationSuccess) {
            const changeHeadquater = this.$refs.detailsSelectHeadquater.selected.id !== this.headquatersSelected.id;
            if (changeHeadquater) {
              this.employees = this.employees.filter(employee => employee.id !== this.user.id);
            }
            this.success = true;
          } else {
            this.error = true;
          }
        } catch (error) {
          console.log("error ", error);
        }
      } else {
        this.$refs.saveAndDeleteCombo.saving = false;
      }
    },
    async deleteEmployeeFromHeadquaterSelected() {
      const { headquatersSelected, user } = this;
      try {
        const response = await requests.updateEmployeeFromHeadquater({
          employee: user.id,
          headquater: headquatersSelected.id,
          type: "leaveHeadquater"
        });
        if (response.ok) {
          this.$refs.saveAndDeleteCombo.deleting = false;
          this.success = true;
          this.employees = this.employees.filter(employee => employee.id !== user.id);
          this.user = {};
        } else {
          this.$refs.saveAndDeleteCombo.deleting = false;
          this.error = true;
          console.log("error ", response);
        }
      } catch (error) {
        console.log("error ", error);
        this.$refs.saveAndDeleteCombo.deleting = false;
        this.error = true;
      }
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    async getEmployeesByHeadquater(headquater) {
      if (headquater) {
        try {
          let result = await requests.fetchEmployeesByHeadquater(headquater.id);
          console.log("getEmployeesByHeadquater");
          console.log(result);
          result = result.map(item => {
            if (item.photoURL) item.image = item.photoURL;
            if (item.photo) item.image = item.photo;
            return item;
          });
          return Promise.resolve(result);
        } catch (error) {
          console.log("error ", error);
          return Promise.reject(error);
        }
      }
      return [];
    },
    ignoreUselessRoles(roles) {
      return roles.replace(/USER|OWNER|ADMIN_GEOCHEF|,/g, "");
    },
    translateRoles(roles) {
      roles = roles.replace(/WAITER/g, "Mesero");
      roles = roles.replace(/CHEF/g, "Chef");
      roles = roles.replace(/ADMIN/g, "Administrador");
      return roles;
    },
    formatItems(items) {
      return items.map(item => {
        item.title = this.translateRoles(this.ignoreUselessRoles(item.roles.join(",")));
        return item;
      });
    },
    setHeadquaterSelected(headquater) {
      this.headquaters[this.headquatersSelected.id].selected = false;
      this.headquatersSelected = headquater;
      this.headquaters[headquater.id].selected = true;
    },
    handleSelectHeadquater(event) {
      const { headquaterSelect } = this.$refs;
      const headquater = event.detail;
      this.setHeadquaterSelected(headquater);
      this.fetchingEmployees = true;
      this.employees = [];
      this.user = {};
      headquaterSelect.selected = headquater;
      headquaterSelect.selectedByDefault = headquater;
      this.getEmployeesByHeadquater(headquater)
        .then(employees => {
          if (employees.length) this.employees = this.formatItems(this.rejectCurrentUser(this.rejectAdmins(employees)));
          this.fetchingEmployees = false;
          this.showDetails = false;
        })
        .catch(() => {
          this.showDetails = false;
          this.fetchingEmployees = false;
          this.error = true;
        });
      this.loading = true;
      this.headquater = headquater;
      this.currentOrder = {};
      this.currentClient = {};
      this.setSubscription();
    },
    handleSelectedItem(user) {
      this.user = user;
      this.active = user.workingAt[this.headquatersSelected.id] === true;
      this.suspend = Boolean(user.suspend);
      this.showDetails = true;
    }
  }
};
</script>
