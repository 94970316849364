<style scoped lang="scss">
.confirm-modal__container__map {
  position: relative;
  top: 20px;
}
.confirm-modal__container__header__caption {
  font-size: 25px;
  font-family: Gibson-Bold;
  margin-left: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}
 .confirm-modal__container__footer {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
    top: 10px;
    button {
       border-radius: 8px;
       height: 40px;
       font-family: Gibson-Bold;
       font-size: 16px;
       text-transform: capitalize;
       width: 350px;
    }
 }

::v-deep .v-dialog--active {
  border-radius: 12px
}
.confirm-modal__container {
   background: white;
}
 .confirm-modal__container__header {
  font-size: 25px;
  font-family: Gibson-Bold;
  color: #161f2f;
  padding-left: 15px;
}
.confirm-modal__container__header__btn-close {
  background-image: url('~@assets/icons/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  float: right;
  position: relative;
  top: -14px;
  right: -14px;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="open"
      max-width="800"
      persistent
    >
      <v-container class="confirm-modal__container">
        <div class="confirm-modal__container__header">
          <span class="confirm-modal__container__header__caption">{{ title }}</span>
          <v-btn
            icon
            :disabled="loading === true"
            class="confirm-modal__container__header__btn-close"
            @click="open = false"
          >
          </v-btn>
        </div>

        <LocationMap
          ref="locationMap"
          :location="location"
          :mode="mode"
          title="Elige la ubicación de la sede"
          class="confirm-modal__container__map"
        >
        </LocationMap>

        <div class="confirm-modal__container__footer">
          <v-btn
            v-if="loading === false"
            class="confirm-modal__container__footer__btn-confirm"
            color="primary"
            @click="handleConfirm"
          >
            Ok
          </v-btn>
        </div>
      </v-container>
    </v-dialog>

    <v-snackbar
      v-model="error"
      color="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >
      Ocurrio un error intenta mas tarde.
    </v-snackbar>

    <v-snackbar
      v-model="success"
      color="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
    >
      Operacion completada con exito.
    </v-snackbar>
  </div>
</template>

<script>
import LocationMap from '@components/maps/LocationMap';

export default {
  name: 'LocationMapModal',
  components: { LocationMap },
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    location: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    display: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: this.display,
      success: false,
      error: false,
      loading: false
    };
  },
  watch: {
    open(val) {
      if (val === false) this.$emit('close');
    },
    display(val) {
      this.open = val;
    }
  },
  methods: {
    handleConfirm() {
      const { position } = this.$refs.locationMap;
      this.$emit('confirm', position);
      this.$emit('close');
    }
  }
};
</script>
