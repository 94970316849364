<style scoped lang="scss">
.wrap-details-btns {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 100px;
  display: grid;
  align-items: center;
  .v-progress-circular {
    margin: 0px auto;
  }
  button {
    width: 100%;
    height: 47px;
    margin-left: 0px;
    border-radius: 12px;
    margin-top: 10px;
    margin-bottom: 14px;
    font-family: Gibson-Bold;
    font-size: 13px;
  }
}
</style>


<template>
  <div class="wrap-details-btns">
    <v-progress-circular v-if="showLoading" indeterminate color="primary"></v-progress-circular>
    <div v-if="showLoading === false">
      <v-btn
        class="wrap-details-btns__btn-save"
        :disabled="disabled"
        @click="handleSaveClick"
      >Guardar</v-btn>

      <v-btn v-if="mode === 'edit'" color="primary" @click="handleDeleteClick">Eliminar</v-btn>
    </div>
    <ConfirmModal
      :display="showConfirmBox"
      @confirm="handleConfirmDelete"
      @close="showConfirmBox = false"
    ></ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from "@components/modals/ConfirmModal";

export default {
  name: "SaveAndDeleteCombo",
  components: {
    ConfirmModal
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showConfirmBox: false,
      saving: false,
      deleting: false
    };
  },
  computed: {
    showLoading() {
      return this.saving || this.deleting;
    }
  },
  methods: {
    handleConfirmDelete() {
      this.showConfirmBox = false;
      this.deleting = true;
      this.$emit("delete");
    },
    handleSaveClick() {
      this.saving = true;
      this.$emit("save");
    },
    handleDeleteClick() {
      this.showConfirmBox = true;
    }
  }
};
</script>

