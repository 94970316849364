<style scoped lang="scss">
  @media only screen and (max-width: 959px) {
    .v-stepper__label {
      display: block !important;
    }
  }

  .v-divider {
    border-color: #ed2236 !important;
    border-style: dashed !important;
    border-width: 1px !important;
  }

  .v-stepper__content {
    padding: 0px !important;
  }

  .none-dish {
    margin-top: -25px;
    font-size: 16px;
  }

  .order-wrap-loading {
    align-content: center;
    min-height: 350px;
    display: grid;
    justify-content: space-evenly;
  }

  .menu-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 34px;
    justify-content: center;
    padding-left: 15px;
    margin-top: -5px;

    .menu-list {
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .menu-container {
      display: block;
    }
  }

  /*
  @media only screen and (max-width: 960px) {
    .menu-container {
      margin-top: -60px;
    }
  }
  */
</style>

<template>
  <v-stepper-content :step="id">
    <section v-if="loading" class="order-wrap-loading text-xs-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </section>

    <transition name="appear">
      <section v-if="loading === false">
        <article>
          <div class="none-dish">
            <EmptySection
              v-if="loading === false && items.length === 0"
              title="No se encontro plato para esta categoria."
              :show-btn="false"
            >
              <template slot="image">
                <img src="@/assets/icons/waiter-dish.svg" alt="" />
              </template>
            </EmptySection>
          </div>

          <ul class="menu-container">
            <li v-for="item in items" :key="item.id" class="menu-list">
              <MenuCard
                :show-order="showOrder"
                :show-select-option="showSelectOption"
                :id="item.id"
                :lang="lang"
                :show-options="mode === 'user'"
                :show-description="mode === 'user'"
                :display-suggestions="mode === 'waiter' || mode === 'admin' || mode === 'user'"
                :promotion="item.promotion && item.promotion.available === true ? item.promotion : ({})"
                :lock-minus="Boolean(item.lock)"
                :image="item.image"
                :title="item.name"
                :price="Number(item.price)"
                :initial-amount="item.amount || 0"
                :initial-suggestion="item.suggestion || ''"
                :description="item.description"
                @changeAmount="handleChangeAmount"
                @suggestion="handleChangeSuggestion"
              ></MenuCard>
            </li>
          </ul>
        </article>
        <div class="menu-card_next">
          <v-progress-circular v-if="ordering" indeterminate color="primary"></v-progress-circular>
          <v-btn
            v-if="ordering === false"
            color="primary"
            :disabled="disabledContinue"
            @click="$emit('next')"
          >{{ nextLabel }}
          </v-btn>
        </div>
      </section>
    </transition>
  </v-stepper-content>
</template>

<script>
  import MenuCard from "@components/cards/MenuCard.vue";
  import EmptySection from "@/components/generals/EmptySection";

  export default {
    name: "Order",
    components: { MenuCard, EmptySection },
    props: {
      lang: {
        type: String,
        default: ""
      },
      mode: {
        type: String,
        default: "user"
      },
      nextLabel: {
        type: String,
        default: "Continuar"
      },
      fetching: {
        type: Boolean,
        default: true
      },
      ready: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: 0
      },
      menus: {
        type: Array,
        default: () => []
      },
      showSelectOption: {
        type: Boolean,
        default: true
      },
      disabledContinue: {
        type: Boolean,
        default: false
      },
      showOrder: {
        type: Boolean,
        default: false
      },
      endOrder: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return { ordering: false, loading: this.fetching, items: this.menus };
    },
    watch: {
      fetching(val) {
        this.loading = val;
      },
      menus: {
        deep: true,
        immediate: true,
        handler(val) {
          if (val.length) this.loading = false;
          this.items = val;
        }
      },
      endOrder(val) {
        console.log("endOrder");
        console.log(val);
        this.ordering = val;
      }
    },
    methods: {
      handleChangeAmount(id, amount) {
        this.$emit("amount", id, amount);
      },
      handleChangeSuggestion(val, id) {
        this.$emit("suggestion", val, id);
      }
    }
  };
</script>
