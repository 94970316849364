<style scoped lang="scss">
.confirm-container__wrap-picker {
  background: white !important;
}
::v-deep .v-messages__message {
  padding-top: 7px;
  padding-bottom: 10px;
  color: #0000008a !important;
  font-size: 13px;
}

::v-deep .v-input__slot {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2) !important;
}

.confirm-container__picker-input ::v-deep input {
  color: black !important;
}
.wrap-dialog {
  background: white;
}
</style>

<template>
  <v-dialog
    class-content="wrap-dialog"
    ref="dialog"
    v-model="pickerDialog"
    persistent
    lazy
    width="500px"
  >
    <template v-slot:activator="{ on }">
      <div>
        <v-text-field
          v-model="displayTimeInFormat12"
          color="secondary"
          :label="label"
          :hide-details="true"
          prepend-icon
          readonly
          class="confirm-container__picker-input"
          :hint="hint"
          persistent-hint
          :disabled="disable"
          dark
          v-on="on"
        ></v-text-field>
      </div>
    </template>
    <div class="confirm-container__wrap-picker">
      <v-time-picker
        v-if="pickerDialog"
        ref="pickerTime"
        v-model="displayTime"
        full-width
        format="ampm"
        :min="minTime | fitTo12Format"
        :max="maxTime | fitTo12Format"
        :disabled="disable"
        @change="(payload) => handleSelectChange(payload)"
      >
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="pickerDialog = false">Cancelar</v-btn>
        <v-btn flat color="primary" @click="(time) => handleConfirmTime(time)">OK</v-btn>
      </v-time-picker>
    </div>
  </v-dialog>
</template>

<script>
import date from "@utils/date";

const { convertTime12to24, convert24To12 } = date;

export default {
  name: "TimePickerInput",
  filters: {
    fitTo12Format(value = "") {
      return convertTime12to24(value);
    }
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    maxTime: {
      type: String,
      default: ""
    },
    minTime: {
      type: String,
      default: ""
    }
  },
  data() {
    return { displayTime: this.value, pickerDialog: false, time: new Date() };
  },
  computed: {
    displayTimeInFormat12() {
      const time = this.displayTime;
      return this.convertTime(time);
    }
  },
  methods: {
    convertTime(time = "") {
      if (!time) return "";
      const letPass = time.includes("am") || time.includes("pm");
      if (letPass) return time;
      const [hours, minutes] = time.split(":");
      const ampm = Number(hours) > 12 ? "pm" : "am";
      return `${convert24To12(`${hours}:${minutes}`)}${ampm}`;
    },
    handleConfirmTime(time) {
      this.$refs.dialog.save(time);
      const converted = this.convertTime(this.displayTime);
      this.$emit("change", converted);
    },
    reset() {
      this.displayTime = "";
    },
    handleSelectChange(value) {
      this.$emit("change", value);
    }
  }
};
</script>
