<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import PayuForm from "@forms/PayuForm.vue";
  import { mapState } from "vuex";
  import requests from "@/helpers/requests";
  import surePromise from "@/utils/surePromise";
  import PayPal from "vue-paypal-checkout";

  const { fetchHeadquater } = requests;

  export default {
    name: "OrderPaymentOptions",
    components: { PayuForm, PayPal },
    props: {
      title: {
        type: String,
        default: "Metodo de pago"
      },
      setOrderPaymentOption: {
        type: Function,
        default: () => {
        }
      },
      orderId: {
        type: String,
        default: ""
      },
      mode: {
        type: String,
        default: "select"
      },
      total: {
        type: Number,
        default: 0
      },
      paymentSuccess: {
        type: Function,
        default: () => {
        }
      }
    },
    computed: {
      ...mapState({
        cart_items: state => state.cart.cart,
        restaurant: state => state.cart.restaurant,
        headquater: state => state.cart.headquater
      }),
      subtotal() {
        let total = 0;
        for (const i in this.cart_items) {
          if (this.cart_items[i].amount > 0) {
            total += this.cart_items[i].price * this.cart_items[i].amount;
          }
        }
        return total;
      },
      cost() {
        return 15000;
      }
    },
    data() {
      return {
        paymentType: "efectivo",
        buyerEmail: window.user.email,
        responseUrl: `${window.location.origin}/dashboard`,
        confirmationUrl: "",
        tax: 0,
        taxReturnBase: 0,
        paypal: {
          /* <sandbox client id> test:AdHPgnP9Wmsh40PPNApnJ32a6OoWsxnd4ysZv5F5xFP83NhybDr46Hh02JdBH7hHBt1IwMS5C8ddgeXQ */
          sandbox: "",
          production: ""
        },
        payu: {
          accountId: "",
          merchantId: "",
          apiKey: ""
        },
        experienceOptions: {}
      };
    },
    async created() {
      this.setOrderPaymentOption(this.paymentType);

      const token = window.localStorage.getItem("token");
      this.confirmationUrl = `https://us-central1-geochef-dev.cloudfunctions.net/api/order/confirmation?token=${token}`;
      // this.confirmationUrl = `http://localhost:5000/geochef-dev/us-central1/api/order/confirmation?token=${token}`;

      const { error: fetchHeadquaterException, result: headquater } = await surePromise(
        fetchHeadquater(this.headquater, {})
      );
      console.log("headquater****");
      console.log("headquater", headquater);
      if (fetchHeadquaterException) return this.handleRestaurtansResponseError(fetchHeadquaterException);
      if (headquater.paypal) {
        this.paypal.sandbox = headquater.paypal.clientId;
        this.paypal.production = headquater.paypal.clientId;
      }

      if (headquater.payu) {
        this.payu.accountId = headquater.payu.accountId;
        this.payu.merchantId = headquater.payu.merchantId;
        this.payu.apiKey = headquater.payu.apiKey;
      }
    },
    mounted() {
      //
    },
    methods: {
      setPaymentType(type) {
        this.paymentType = type;
        this.setOrderPaymentOption(this.paymentType);
      },
      tryWebcheckout() {
        this.$refs.payuForm.send();
      },
      paymentauthorized(test) {
        console.log(test);
        this.paymentSuccess(test);
      },
      handleRestaurtansResponseError() {
        //
      }
    },
    watch: {}
  };
</script>
