export default function refreshToken() {
  return window.firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(token => {
      window.token = token;
      window.localStorage.setItem("token", token);
      return Promise.resolve(token);
    })
    .catch(error => Promise.reject(error));
}
