<style lang="scss" scoped>
  .picker-dialog {
    background: white !important;
  }

  .confirm-container {
    padding: 10px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    ::v-deep .v-text-field__details {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__person_select .v-messages__wrapper {
      color: white;
      font-size: 14px;
    }

    &__picker-input-closed-msg {
      color: white;
      font-size: 17px;
      text-align: left;
    }

    &__wrap-picker {
      background: white !important;
    }

    &__picker-input {
      .v-messages__message {
        color: white;
        padding-top: 17px;
        padding-left: 8px;
        font-size: 16px;
      }

      .v-input__slot {
        background: white;
        height: 50px;
        width: 98%;
        margin: 0px auto;
        padding: 16px;
        border-radius: 12px;

        .v-label--active {
          color: transparent !important;
        }
      }
    }

    &__person_select {
      .v-input__slot {
        border-radius: 12px !important;
        width: 98%;
        height: 50px;
      }

      .v-input__icon--append {
        i {
          background-image: url(~@assets/icons/arrow-down.png);
          background-size: 36px;
          background-repeat: no-repeat;
          background-position: center;
          color: transparent !important;
          width: 20px;
          height: 20px;
        }
      }
    }

    .layout {
      align-items: baseline;
    }
  }

  .ligth-hint {
    ::v-deep .v-messages {
      color: white !important;
    }
  }

  @media (max-width: 600px) {
    .confirm-container {
      grid-template-columns: 1fr;
    }
  }

  .no-padding {
    padding: 0 !important;
    display: block;
  }
</style>
<template>
  <v-container class="confirm-container" :class="{'no-padding': noPadding}">
    <v-text-field
      v-if="showPersons"
      v-model="persons"
      :disabled="isClose && showTimePicker"
      type="number"
      class="confirm-container__person_select"
      :class="{ 'ligth-hint': ligth }"
      :items="list"
      solo
      min="1"
      label="Personas"
      :hint="Boolean(persons) === false ? (showTimePicker ? 'Elige una fecha válida y escribe el número de personas' : 'Escribe el número de personas') : ''"
      persistent-hint
      @keypress="onlyNumber"
      @keyup="handlePressUp"
      @change="payload => handleSelectChange('persons', payload)"
    ></v-text-field>

    <v-dialog
      v-if="showTimePicker"
      ref="dialog"
      content-class="picker-dialog"
      v-model="pickerDialog"
      :return-value.sync="time"
      persistent
      lazy
      width="500px"
    >
      <template v-slot:activator="{ on }">
        <div>
          <v-text-field
            v-model="displayTime"
            :disabled="isClose || Boolean(persons) === false"
            label="Hora"
            prepend-icon
            readonly
            solo
            class="confirm-container__picker-input"
            :hint="isClose ? `El restaurante esta cerrado para el dia ${choosenDate}` : ''"
            persistent-hint
            v-on="on"
          ></v-text-field>
        </div>
      </template>
      <div class="confirm-container__wrap-picker">
        <v-time-picker
          v-if="pickerDialog"
          ref="pickerTime"
          v-model="time"
          full-width
          format="ampm"
          :min="minTime"
          :max="maxTime"
          @change="payload => handleSelectChange('time', `${payload}${$refs.pickerTime.period}`)"
        >
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="pickerDialog = false">Cancelar</v-btn>
          <v-btn flat color="primary" @click="$refs.dialog.save(time)">OK</v-btn>
        </v-time-picker>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: "PersonsAndTimeForm",
    props: {
      noDefault: {
        type: Boolean,
        default: false
      },
      noPadding: {
        type: Boolean,
        default: false
      },
      row: {
        type: Boolean,
        default: false
      },
      ligth: {
        type: Boolean,
        default: false
      },
      schedule: {
        type: Object,
        default: () => ({})
      },
      currentDay: {
        type: String,
        default: ""
      },
      startFromNow: {
        type: Boolean,
        default: false
      },
      choosenDate: {
        type: String,
        default: ""
      },
      max: {
        type: Number,
        default: 1
      },
      pickPersons: {
        type: Function,
        default: () => {}
      },
      pickTime: {
        type: Function,
        default: () => {}
      },
      showPersons: {
        type: Boolean,
        default: true
      },
      showTimePicker: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        dayweek: this.currentDay,
        timeSchedule: { ...this.schedule },
        personsFieldValid: false,
        persons: "",
        displayTime: "",
        time: "",
        to: "",
        from: "",
        ready: false,
        isClose: false,
        menu2: false,
        isValid: false,
        pickerDialog: false
      };
    },
    watch: {
      dayweek() {
        this.calculateSchedule();
      },
      time(val) {
        this.displayTime = this.convert24To12(val);
        if (this.isClose === false) this.$emit("pickTime", this.displayTime);
      },
      currentDay(val) {
        this.dayweek = val;
        this.calculateSchedule();
      },
      startFromNow(val) {
        if (val) {
          const { from, to, minTime, maxTime, time, list, isClose } = this.initialize();
          this.isClose = isClose;
          this.from = from;
          this.to = to;
          this.minTime = minTime;
          this.maxTime = maxTime;
          this.time = time;
          this.list = list;
          this.isClose = this.timeAGreatteThanTimeB(this.time, this.to);
        } else {
          this.calculateSchedule();
        }
      },
      showPersons(val) {
        if (!val) {
          this.persons = "1";
        } else {
          this.persons = "";
        }
      }
    },
    created() {
      const { from = "", to = "", minTime = "", maxTime = "", time = "", list = [], isClose = false } = this.initialize();
      this.isClose = isClose;
      this.list = list;
      this.to = to;
      this.from = from;
      this.minTime = minTime;
      this.maxTime = maxTime;
      if (this.currentDay) {
        this.minTime = time;
      }
      if (!this.noDefault) {
        this.time = time;
        this.displayTime = this.convert24To12(time);
      } else {
        this.time = time;
      }

      if (!this.showPersons) {
        this.persons = "1";
      }
    },
    mounted() {
      if (this.isClose === false) {
        this.isClose = this.timeAGreatteThanTimeB(this.time, this.to);
      }
      if (this.displayTime) this.$emit("pickTime", this.displayTime);
    },
    methods: {
      reset() {
        this.dayweek = this.currentDay;
        this.timeSchedule = { ...this.schedule };
        this.personsFieldValid = false;
        this.persons = "";
        this.displayTime = "";
        this.$emit("onReset");
        this.to = "";
        this.from = "";
        this.ready = false;
        this.isClose = false;
        this.menu2 = false;
        this.isValid = false;
        this.pickerDialog = false;
      },
      updateVariables(val) {
        this.timeSchedule = val;
        const { from = "", to = "", minTime = "", maxTime = "", time = "", list = [], isClose = false } = this.initialize();
        this.isClose = isClose;
        if (val) {
          this.displayTime = this.convert24To12(time);
          this.time = time;
        } else {
          this.displayTime = this.convert24To12(minTime);
          this.time = minTime;
        }
        this.list = list;
        this.to = to;
        this.from = from;
        this.minTime = minTime;
        this.maxTime = maxTime;
        if (this.displayTime) {
          this.$emit("pickTime", this.displayTime);
        }
      },
      onlyNumber($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (this.persons === "" && keyCode === 48) return $event.preventDefault();
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault();
        }
      },
      calculateSchedule() {
        const { morning = "", evening = "", afternoon = "" } = this.timeSchedule[this.dayweek] || {};
        let init;
        let end;
        if (morning) init = morning;
        if (!morning && afternoon) init = afternoon;
        if (!morning && !afternoon && evening) init = afternoon;
        if (evening) end = evening;
        if (!evening && afternoon) end = afternoon;
        if (!evening && !afternoon && morning) end = morning;
        this.minTime = this.formatHourAnMinutes(init.from);
        this.maxTime = this.formatHourAnMinutes(end.to);
        this.time = this.formatHourAnMinutes(init.from);
        this.isClose = false;
        this.displayTime = this.convert24To12(this.time);
        if (this.isClose === false) this.$emit("pickTime", this.displayTime);
      },
      initialize() {
        const list = this.generateList(this.max);
        const daytime = this.getDayTime();
        const dateAt = this.timeSchedule[this.dayweek];
        let todaysSchedule = {};
        if (dateAt && !dateAt[daytime]) {
          if (dateAt.hasOwnProperty("morning")) todaysSchedule = { ...dateAt.morning };
          if (dateAt.hasOwnProperty("afternoon")) todaysSchedule = { ...dateAt.afternoon };
          if (dateAt.hasOwnProperty("evening")) todaysSchedule = { ...dateAt.evening };
        } else if (dateAt && dateAt[daytime]) {
          todaysSchedule = { ...dateAt[daytime] };
        }
        const isClose = Object.keys(todaysSchedule).length === 0;
        const to = this.formatHourAnMinutes(todaysSchedule.to);
        const maxTime = to;
        let time = this.getCurrentTime();
        const formatFrom = todaysSchedule.from ? this.formatHourAnMinutes(todaysSchedule.from) : "";
        const from = this.startFromNow && this.timeAGreatteThanTimeB(time, formatFrom) ? this.getCurrentTime() : formatFrom;
        const shouldStarInFrom = this.startFromNow && !this.timeAGreatteThanTimeB(time, formatFrom);
        if (shouldStarInFrom) time = formatFrom;
        const minTime = from;
        return {
          from,
          to,
          minTime,
          maxTime,
          time,
          list,
          isClose
        };
      },
      convert24To12(value) {
        if (!value) return "";
        const plainTime = value.split(":");
        let pm = false;
        let hour = Number(plainTime[0]);
        if (hour > 12) {
          hour -= 12;
          pm = true;
        }
        const minute = plainTime[1];
        return `${hour}:${minute}${pm ? "pm" : "am"}`;
      },
      timeAGreatteThanTimeB(a, b) {
        const dateA = new Date();
        const dateB = new Date();
        dateA.setHours(a.split(":")[0]);
        dateA.setMinutes(a.split(":")[1]);
        dateB.setHours(b.split(":")[0]);
        dateB.setMinutes(b.split(":")[1]);
        return dateA > dateB;
      },
      getCurrentTime() {
        const currentTime = new Date();
        let minutes = currentTime.getMinutes();
        const hour = currentTime.getHours();
        if (minutes < 10) minutes = `0${minutes}`;
        return `${hour}:${minutes}`;
      },
      handlePressUp() {
        if (this.persons[0] === "0") this.persons = this.persons.substring(1);
        this.handleSelectChange("persons");
      },
      handleSelectChange(field) {
        if (field === "time") {
          this.displayTime = this.convert24To12(this.time);

          if (this.pickTime) {
            this.pickTime(this.displayTime);
          }

          this.$emit("pickTime", this.displayTime);
        }
        if (field === "persons") {
          if (this.pickPersons) {
            this.pickPersons(this.persons);
          }
          this.$emit("pickPersons", this.persons);
        }
      },
      convert12To24(time = "") {
        const plainTime = time
          .replace("pm", "")
          .replace("am", "")
          .split(":");
        const pm = time.includes("pm");
        if (pm) {
          plainTime[0] = `${Number(plainTime[0]) + 12}`;
        }
        if (pm === false) {
          plainTime[0] = `0${plainTime[0]}`;
        }
        return plainTime;
      },
      formatHourAnMinutes(time) {
        let result = "";
        const plainTime = this.convert12To24(time);
        result = `${plainTime[0]}:${plainTime[1]}`;
        return result;
      },
      getDayTime() {
        const today = new Date();
        const curHr = today.getHours();
        if (curHr < 12) return "morning";
        if (curHr < 18) return "afternoon";
        return "evening";
      },
      generateList(max = 1) {
        const items = [];
        for (let i = 0; i < max; i += 1) {
          items.push(i + 1);
        }
        return items;
      }
    }
  };
</script>
