import { BASE } from "./index";

const cancelOrder = (id = "", data = {}) => {
  console.log(data);
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/order/cancel/${id}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      token,
      "content-type": "application/json"
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default cancelOrder;
