const state = {
  mode: 'newReservation',
  isActive: false,
  detail: {
    name: '',
    hour: '',
    persons: '',
    status: '',
    address: '',
    date: '',
    dateTimeStamp: {
      seconds: 0,
      nanoseconds: 0
    },
    description: '',
    logo: '',
    id: '',
    user: {
      phone: '',
      note: ''
    }
  }
};
export default state;


