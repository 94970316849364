import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { fetchClients } = requests;
const allMonths = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const actions = {
  async getClients({ commit }, id) {
    // const { id = "" } = this.headquater;
    const { page = 1, batch = 10 } = this;
    console.log(id);
    const { ok, result = {} } = await surePromise(fetchClients(id, { page, batch }));
    console.log(ok);
    console.log(result);
    if (ok) {
      const { clients = [] } = result;
      commit('setClients', clients);
    }
  },
  async getOrders({ commit, dispatch }, id) {
    const payload = { headquater: id };
    let { result } = await surePromise(requests.getOrdersByDate(payload));
    dispatch('orderByMonth', result);
    result = result.sort((a, b) => new Date(a.createAt._seconds * 1000) - new Date(b.createAt._seconds * 1000));
    const months = await dispatch('aggregateByMonth', result);
    const currentMonth = allMonths[new Date().getMonth()];
    commit('setTotalByMonth', months[currentMonth] || 0);
  },
  orderByMonth({ commit }, orders) {
    const presentYear = new Date().getFullYear();
    const presentMonth = new Date().getMonth();
    const presentDay = new Date().getDay();
    // eslint-disable-next-line array-callback-return
    const ordersByStatus = orders.filter(order => {
      if (presentYear === new Date(order.createAt._seconds * 1000).getFullYear()) {
        return order;
      }
      // eslint-disable-next-line array-callback-return
    }).filter(order => {
      if (order.status === "COMPLETE") {
        return order;
      }
    });

    // eslint-disable-next-line array-callback-return
    const totalMonth = ordersByStatus.filter(order => {
      if (presentMonth === new Date(order.createAt._seconds * 1000).getMonth()) {
        return order;
      }
    });
    // eslint-disable-next-line array-callback-return
    const totalDay = totalMonth.filter(order => {
      if (presentDay === new Date(order.createAt._seconds * 1000).getDay()) {
        return order;
      }
    });
    let totalOfDay = 0;
    totalDay.forEach(order => {
      totalOfDay += order.price;
    });
    let totalOfMonth = 0;
    totalMonth.forEach(order => {
      totalOfMonth += order.price;
    });
    commit('setTotals', { day: totalOfDay, month: totalOfMonth });
  },
  sortByDate(a, b) {
    return new Date(a.createAt._seconds * 1000) - new Date(b.createAt._seconds * 1000);
  },
  // eslint-disable-next-line no-unused-vars
  aggregateByMonth({ commit }, data) {
    const quantity = 0;
    const months = {};
    // eslint-disable-next-line array-callback-return
    let orderFilter = data.filter((value) => {
      if (value.rol === 'USER' && value.status === 'COMPLETE') {
        return value;
      }
    });
    const hash = {};
    orderFilter = orderFilter.filter((current) => {
      const exists = !hash[current.user];
      hash[current.user] = true;
      return exists;
    });
    for (const item of orderFilter) {
      const { createAt } = item;
      const date = new Date(createAt._seconds * 1000);
      const month = date.getMonth();
      const monthName = allMonths[month];
      if (months.hasOwnProperty(monthName)) {
        months[monthName].push(+1);
        const calc = months[monthName].reduce((accumulator, currentValue) => {
          accumulator += currentValue;
          return accumulator;
        }, 0);
        months[monthName] = [calc];
      } else {
        months[monthName] = [quantity];
      }
    }
    return months;
  }
};

export default actions;
