import Vue from 'vue';
import HomeAdmin from "@components/cards/HomeAdmin/HomeAdmin.vue";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";

export default Vue.extend({
  name: 'Home',
  components: {
    HomeAdmin,
    ReservationsComboTables
  },
  computed: {
    loading() {
      return this.$store.getters['menu/getStateLoading'];
    }
  }
});
