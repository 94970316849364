const mutations = {
  setClients(state, clients) {
    state.clients = clients;
  },
  setTotalByMonth(state, quantity) {
    state.totalByMonth = quantity;
  },
  setOrders(state, orders) {
    state.orders = orders;
  },
  setTotals(state, total) {
    state.totalsOrders.day = total.day;
    state.totalsOrders.month = total.month;
  }
};

export default mutations;
