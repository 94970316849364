import Vue from "vue";
import BasicInput from "@/components/inputs/BasicInput.vue";
import validators from "@utils/validators";

export default Vue.extend({
  name: "UpdatePassword",
  props: {
    showUpdatePassword: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BasicInput
  },
  data: () => ({
    snackBar: {
      showSnackbar: false,
      timeOut: 25000,
      color: "error",
      text: "Tenemos un error en este momento"
    },
    resetInput: false,
    loading: false,
    nameMsg: "El campo no es válido",
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  }),
  computed: {
    isValidPassword() {
      return {
        oldPassword: validators.validPassword(this.oldPassword),
        newPassword: validators.validPassword(this.newPassword),
        confirmPassword: this.confirmPassword === this.newPassword
      };
    },
    isValidForm() {
      return !(this.isValidPassword.oldPassword && this.isValidPassword.newPassword && this.isValidPassword.confirmPassword);
    }
  },
  methods: {
    handleSnackBar(color = "error", text = "Ocurrió un error, intenta más tarde") {
      this.snackBar = Object.assign(this.snackBar, {
        showSnackbar: true,
        color,
        text
      });
    },
    updatePassword(user) {
      user
        .updatePassword(this.oldPassword)
        .then(() => {
          this.handleSnackBar("success", "Contraseña actualizada con éxito");
          // this.$emit("onUpdatePassword", true);
          setTimeout(() => {
            this.loading = false;
            this.clearData();
          }, 800);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reauthenticate() {
      this.loading = true;
      const user = window.firebase.auth().currentUser;
      const { email } = window.user;
      // eslint-disable-next-line
      const credential = new firebase.firebase_.auth.EmailAuthProvider.credential(email, this.oldPassword);
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          this.updatePassword(user);
        })
        .catch(() => {
          this.loading = false;
          this.handleSnackBar("error", "La contraseña actual no es correcta");
          // this.$emit("onUpdatePassword", false);
        });
    },
    clearData() {
      this.$refs.oldPassword.reset();
      this.$refs.newPassword.reset();
      this.$refs.confirmPassword.reset();
      this.$emit("closeUpdatePassword");
    }
  }
});
