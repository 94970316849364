<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import PayuForm from "@forms/PayuForm.vue";
import calcPromotion from "@/utils/calcPromotion.js";
import calcPercantage from "@/utils/calcPercentage";
import filterMoney from "@/utils/filterMoney";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";

export default {
  name: "",
  components: { PayuForm },
  filters: {
    calcPromotion,
    filterMoney
  },
  props: {
    iva: {
      type: Number,
      default: 0
    },
    table: {
      type: String,
      default: ""
    },
    waiter: {
      type: String,
      default: ""
    },
    dishes: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: "user"
    },
    order: {
      type: Object,
      default: () => ({})
    },
    apiKey: {
      type: String,
      default: ""
    },
    merchantId: {
      type: Number,
      default: 0
    },
    accountId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    computedIva() {
      const { discount = 0, service = 0, iva = {} } = this.order;
      const { value } = iva;
      const dishes = this.getMapedDishes();
      const basePrice = this.calculateBasePrice(dishes);
      let price = basePrice;
      price -= Number(discount);
      price += Number(service);
      const result = calcPercantage(price, Number(value));
      return result;
    }
  },
  data() {
    return {
      displaySuccessSnackbar: false,
      displayErrorSnackbar: false,
      loading: false,
      main: [],
      drinks: [],
      dessert: [],
      total: 0,
      showPayOptions: false,
      buyerEmail: window.user.email,
      tax: 0,
      taxReturnBase: 0,
      responseUrl: `${window.location.origin}/dashboard`,
      mainCategory: "",
      dessertCategory: "",
      drinksCategory: "",
      confirmationUrl: "",
      error: false,
      success: false,
      errorMessage: "Ocurrio un error, intenta mas tarde",
      successMessage: "Peticion enviada",
      canLeave: false
    };
  },
  created() {
    const token = window.localStorage.getItem("token");
    const dishes = this.getMapedDishes();
    this.confirmationUrl = `https://us-central1-geochef-dev.cloudfunctions.net/api/order/confirmation?token=${token}`;
    this.total = this.calculateTotalValue();
    this.mainCategory = "32ceb392-f5ae-4811-ba97-e43539a69a7a";
    this.dessertCategory = "2cd158eb-5fca-424e-acd3-f9accb9a9955";
    this.drinksCategory = "9c4e239e-6e8e-4447-937e-53a234d038e8";
    this.main = dishes.filter(item => item.categories[this.mainCategory]);
    this.drinks = dishes.filter(item => item.categories[this.drinksCategory]);
    this.dessert = dishes.filter(item => item.categories[this.dessertCategory]);
  },
  mounted() {
    window.addEventListener("waiterMessage", this.handleWaiterMessage);
    window.addEventListener("orderConfirmed", this.handleWaiterOrderConfirmed);
  },
  beforeDestroy() {
    window.removeEventListener("waiterMessage", this.handleWaiterMessage);
    window.removeEventListener("orderConfirmed", this.handleWaiterOrderConfirmed);
  },
  methods: {
    getMapedDishes() {
      return Object.keys(this.dishes).map(id => {
        const { name, price, promotion = "", categories } = this.dishes[id];
        const { amount } = this.dishes[id];
        let discount = 0;
        if (promotion) discount = this.calcPromotion(promotion, amount);
        return {
          name,
          price,
          id,
          promotion,
          categories,
          amount,
          discount
        };
      });
    },
    calculateBasePrice(dishes = []) {
      let total = 0;
      dishes.forEach(item => {
        if (item.promotion) {
          total += item.discount * item.price;
        } else {
          total += item.amount * item.price;
        }
      });
      return total;
    },
    calculateTotalValue() {
      let total = 0;
      const dishes = this.getMapedDishes();
      const basePrice = this.calculateBasePrice(dishes);
      total = basePrice;
      if (this.order.hasOwnProperty("discount")) total -= Number(this.order.discount);
      if (this.order.hasOwnProperty("service")) total += Number(this.order.service);
      const hasIvaActive = this.order && this.order.iva && this.order.iva.active;
      if (hasIvaActive) {
        total += calcPercantage(total, Number(this.iva));
      }
      return total;
    },
    handleWaiterMessage() {
      this.success = true;
      this.successMessage = "Listo ya recibí tu mensaje";
    },
    handleWaiterOrderConfirmed() {
      this.success = true;
      this.successMessage = "Pago confirmado";
      this.$emit('onPaymentSuccess', {
        total: this.total,
        success: true
      });
    },
    handleWaiterNotificationSuccess(response) {
      if (response.ok) {
        console.log("notification sended");
        this.successMessage = "Peticion enviada.";
        this.success = true;
      } else {
        this.error = true;
        this.errorMessage = "Ocurrio un error, por favor intenta mas tarde.";
      }
    },
    handleWaiterNotificationError(error) {
      console.log("error ", error);
      this.error = true;
      this.errorMessage = "Ocurrio un error, por favor intenta mas tarde.";
    },
    requestNotification(body) {
      return requests
        .waiterNotification(this.waiter, {
          body,
          table: this.table,
          sender: window.user.uid
        })
        .then(this.handleWaiterNotificationSuccess)
        .catch(this.handleWaiterNotificationError);
    },
    handleLeave() {
      window.router.push({ name: "dashboard" });
    },
    handleAcceptSuccess() {
      this.displaySuccessSnackbar = false;
      const { headquater = "" } = window.router.currentRoute.params;
      window.router.push({ name: "dashboard" });
      window.router.replace({ query: { headquater } });
    },
    async handleConfirmAnonymusOrder() {
      this.loading = true;
      const db = window.firebase.firestore();
      const orderRef = db.collection("Orders");
      const tableRef = db.collection("Tables");
      const { ok } = await surePromise(orderRef.doc(this.order.id).update({ status: "COMPLETE" }));
      if (ok) {
        /* eslint-disable */
        let { result: tableDoc, error: getTableException } = await surePromise(tableRef.doc(this.order.table).get());
        if (getTableException) {
          this.loading = false;
          this.displayErrorSnackbar = true;
          return;
        }
        tableDoc = tableDoc.data();
        tableDoc.activesWaiters[window.user.uid] = false;
        const { ok: success, error: updateTableException } = await surePromise(
          tableRef.doc(this.order.table).update({
            status: "FREE",
            currentClient: "",
            currentOrder: "",
            activesWaiters: tableDoc.activesWaiters
          })
        );
        if (success) {
          this.loading = false;
          this.displaySuccessSnackbar = true;
          return;
        }
        this.loading = false;
        this.displayErrorSnackbar = true;
      } else {
        this.loading = false;
        this.displayErrorSnackbar = true;
      }
    },
    tryWebcheckout() {
      this.$refs.payuForm.send();
    },
    calcPromotion
  }
};
</script>
