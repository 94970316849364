<style lang="scss" scoped>
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0px 2px 16px 3px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.notfound-container {
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  margin-top: -37px;
  margin-bottom: 15px;
  border-radius: 0px 0px 12px 12px;
  font-size: 14px;
}

.notfound-container-caption {
  position: relative;
  top: 7px;
  word-break: break-word;
}

.notfound-container-caption-value {
  font-family: Gibson-Bold;
}

.container-loading-wrap-field {
  border-radius: 12px 12px 0px 0px;
}
.filter-off .v-input__prepend-inner i {
  background-image: url("~@/assets/icons/filter-off.png") !important;
}
.filter-on .v-input__prepend-inner i {
  background-image: url("~@/assets/icons/filter.png");
}

.search-restaurants > .filter-content > .container-list {
  margin-left: -8px !important;
}
.container-login--open {
  height: 89%;
}
.container-login--close {
  height: 65%;
}

.container-loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #f4f4f494;
  border-radius: 12px;
  z-index: 1;
  overflow-y: hidden;
}

.loading {
  position: absolute;
  z-index: 1;
}
.container-input .v-progress-linear {
  padding-bottom: 0px;
  margin-bottom: 0px;
  overflow-y: hidden;
  position: absolute;
  top: -15px;
}
.wrap-box {
  position: absolute;
  left: 0px;
  top: 25px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  /*z-index: 10000;*/
}
.container-input {
  border-radius: 12px 12px 0px 0px;
  overflow-y: hidden;
}
.container-input .wrap-filter-box .wrap-filter-box__split-line {
  border: 1px solid #f3f3f3;
  margin-top: 20px;
}

.container-input .wrap-filter-box .wrap-filter-box__score {
  padding-top: 10px;
  padding-left: 0px;
  margin-bottom: 5px;
}

.container-input .wrap-filter-box .wrap-filter-box__kitchen {
  padding-top: 20px;
  padding-left: 0px;
  margin-bottom: 10px;
}

.container-input .wrap-filter-box .v-card {
  overflow-y: scroll;
  padding-right: 10px;
  height: 100%;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    height: 20px;
    background-color: rgb(237, 34, 54);
  }
}

.container-input .wrap-filter-box .filter-label {
  font-size: 28px;
  font-family: Gibson-Bold;
}

.container-input .wrap-filter-box {
  background: white;
  position: relative;
  top: -58px;
  z-index: -1;
  margin: 0px auto;
  box-shadow: none;
  height: 455px;
  border-radius: 12px;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}
.container-input .container-input__input {
  .v-input__icon--prepend-inner {
    .v-icon {
      background-color: red !important;
      color: transparent !important;
      width: 25px;
      height: 25px;
      background-size: contain;
      border-radius: 100%;
      position: relative;
      right: 8px;
    }
  }
  .v-input__append-inner {
    .close-icon {
      background-image: url("~@/assets/icons/close.svg");
      width: 30px;
      height: 30px;
      background-position: center;
      background-size: contain;
    }
  }
}
.container-input {
  flex: none;
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0px auto;
  max-width: 500px;
}
.v-input__slot {
  border-radius: 12px !important;
  padding: 10px 20px !important;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.24), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.v-label {
  font-weight: bold;
  color: #8b8989;
}

::v-deep .theme--light.v-icon {
  color: #ED2236;
}
</style>

<template>
  <div class="wrap-box search-restaurants">
    <div class="container-input">
      <transition name="appear">
        <div
          v-if="loading"
          class="container-loading"
          :class="{'container-login--open': showFilter, 'container-login--close': !showFilter}"
        >
          <v-progress-linear :indeterminate="true"></v-progress-linear>
        </div>
      </transition>
      <v-text-field
        v-model="value"
        solo
        :label="label"
        prepend-inner-icon="menu"
        :append-icon="value ? 'close-icon': ''"
        class="container-input__input"
        :class="{'filter-off': showFilter, 'filter-on': showFilter === false}"
        @input="handleChange"
        @keyup="startCountDown"
        @keydown="clearTypingInterval"
        @click:append="onClearInput"
        @click:prepend-inner="handleDisplayFilter"
      ></v-text-field>
      <transition name="appear">
        <div v-if="notfound && value" class="notfound-container">
          <p class="notfound-container-caption">
            No hay resultados de:
            <span class="notfound-container-caption-value">"{{ value }}"</span>
          </p>
        </div>
      </transition>

      <transition name="appear">
        <div
          v-if="showFilter"
          class="wrap-filter-box"
          :class="{'appear': showFilter, 'disappear': showFilter === false }"
        >
          <v-card flat>
            <span :style="{'color': primary}" class="filter-label font-weight-black">Filtros</span>
            <p class="wrap-filter-box__score">Calificación</p>

            <div class="filter-content">
              <StarList
                :point="point"
                @select="(payload) => $emit('star', payload)"
                @unselect="$emit('noPoints')"
              ></StarList>
            </div>
            <hr class="wrap-filter-box__split-line" />
            <p class="wrap-filter-box__kitchen">Cocinas</p>
            <CategoriesList
              @selectAll="$emit('selectAll')"
              caption="Todas"
              :categories="categories"
              @select="(payload) => $emit('select', payload)"
              @unselect="$emit('unselect')"
            ></CategoriesList>
          </v-card>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import StarList from "@lists/StarList.vue";
import CategoriesList from "@lists/CategoriesList.vue";
import ClickOutside from "vue-click-outside";
import theme from "@/theme.js";

export default {
  name: "SearchRestaurants",
  components: { StarList, CategoriesList },
  directives: {
    ClickOutside
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    },
    notfound: {
      type: Boolean,
      default: false
    },
    point: {
      type: Number,
      default: 1
    },
    msg: {
      type: String,
      default: ""
    },
    valid: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    change: {
      type: Function,
      default() {}
    }
  },
  data() {
    const result = {
      value: "",
      primary: theme.primary,
      showFilter: false,
      shouldHideFilter: true,
      typingTimer: null,
      doneTypingInterval: 2000
    };
    if (this.defaultValue) result.value = this.defaultValue;
    return result;
  },
  watch: {
    showFilter(val) {
      this.$emit("filterChange", val);
    },
    defaultValue(val) {
      this.value = val;
    }
  },
  methods: {
    handleDisplayFilter() {
      this.showFilter = !this.showFilter;
    },
    hideFilter() {
      if (this.showFilter) this.showFilter = false;
    },
    doneTyping() {
      this.$emit("doneTyping", this.value);
    },
    clearTypingInterval() {
      window.clearTimeout(this.typingTimer);
    },
    startCountDown() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.doneTyping, this.doneTypingInterval);
    },
    handleChange(payload) {
      this.$emit("input-change", payload);
    },
    onClearInput() {
      this.value = "";
      this.$emit("doneTyping", this.value);
    }
  }
};
</script>
