import { BASE } from "./index";
const confirmDishPromo = (order = "", dish = "", data = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/order/dishes/confirm/${order}/${dish}`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default confirmDishPromo;
