import buildUrlQuery from "@utils/buildUrlQuery.js";
import { BASE } from "./index";
const fetchReservations = (query = {}) => {
  const params = buildUrlQuery(query);
  const url = `${BASE}/reservations?${params}`;
  const token = window.localStorage.getItem("token");
  return fetch(url, {
    method: "GET",
    headers: { token }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default fetchReservations;
