import { BASE } from "./index";
const switchTable = payload => {
  const { from = "", to = "" } = payload;
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/table/switch/${from}/${to}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      token,
      "content-type": "application/json"
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default switchTable;
