<style lang="scss" scoped>
@import "./style.scss";
</style>
<template src="./template.html"></template>>

<script>
import BasicInput from "@inputs/BasicInput";
import validators from "@utils/validators";
import LoginHeader from "@components/Headers/LogoRed";
import authStrategies from "@/helpers/authStrategies.js";

const { validEmail, validPassword } = validators;
export default {
  name: "LoginForm",
  components: { BasicInput, LoginHeader },
  computed: {
    registerLink() {
      let result = "/register";
      const { router } = window;
      const { currentRoute = {} } = router;
      const { hash = "" } = currentRoute;
      if (hash) {
        hash.includes("#continueUrl=");
        result = `${result}${hash}`;
      }
      return result;
    }
  },
  data() {
    return {
      validity: { email: false, password: false, invalidForm: true },
      email: "",
      password: "",
      passwordMsg: "Escribe minimo 6 caracteres maximo 20, sin espacios",
      showPasswordMsg: false,
      emailMsg: "Escribe una direccion de correo electrónico valida",
      wrongPassword: false,
      authenticated: false,
      notFound: false,
      loading: false,
      fallback: false,
      credential: ""
    };
  },
  beforeDestroy() {
    window.removeEventListener("ready", this.handleUserReady);
  },
  mounted() {
    window.addEventListener("ready", this.handleUserReady);
  },
  methods: {
    handleUserReady() {
      this.loading = false;
    },
    handleLoginWithGoogle() {
      const method = "google.com";
      this.loading = true;
      const provider = authStrategies.getProviderForProviderId({
        method,
        firebase: window.firebase,
        auth: window.auth
      });
      authStrategies.trySignIn({
        provider,
        auth: window.auth,
        showProfile() {
          window.localStorage.setItem("authProvider", "email");
          this.authenticated = true;
        }
      });
    },
    handleLoginWithFacebook() {
      const self = this;
      this.loading = true;
      const provider = authStrategies.getProviderForProviderId({
        method: "facebook",
        firebase: window.firebase,
        auth: window.auth
      });
      authStrategies.trySignIn({
        provider,
        auth: window.auth,
        firebase: window.firebase,
        loginFallback({ email, credential }) {
          self.email = email;
          self.validity.email = true;
          self.validity.password = false;
          self.fallback = true;
          self.credential = credential;
          self.showPasswordMsg = true;
          self.passwordMsg =
            "En el pasado te has autenticado con el metodo de 'Correo y contraseña', por favor escribe la contraseña que utilizaste la ultima vez.";
        },
        showProfile() {
          window.localStorage.setItem({ authProvider: "facebook" });
          this.authenticated = true;
        }
      });
    },
    handleFieldChange(field, value) {
      const isEmail = field === "email";
      const isPassword = field === "password";
      if (isEmail) {
        this.validity.email = validEmail(value);
        this.email = value;
        this.emailMsg = "Escribe una direccion de correo electrónico valida";
      }
      if (isPassword) {
        this.validity.password = validPassword(value);
        this.password = value;
        this.passwordMsg = "Escribe minimo 6 caracteres maximo 20, sin espacios";
        this.showPasswordMsg = false;
      }
      const { password, email } = this.validity;
      this.validity.invalidForm = !(password === true && email === true);
    },
    handleSubmit(e) {
      e.preventDefault();
      const isValid = this.validity.invalidForm === false;
      if (isValid) {
        this.loading = true;
        if (this.fallback) {
          const config = { email: this.email, password: this.password };
          const EmailProvider = authStrategies.getProviderForProviderId({
            method: "password",
            config,
            auth: window.auth,
            firebase: window.firebase
          });
          this.fallback = false;
          EmailProvider.then(response => response.user.linkWithCredential(this.credential))
            .then(() => {
              window.localStorage.setItem("authProvider", "email/password");
              this.authenticated = true;
              window.router.push("dashboard");
            })
            .catch(error => {
              this.loading = false;
              const wrongPassword = error.code === "auth/wrong-password";
              if (wrongPassword) {
                this.validity.password = false;
                this.passwordMsg = "contraseña errada.";
              }
            });
        } else {
          window.auth
            .signInWithEmailAndPassword(this.email, this.password)
            .then(() => {
              console.log("autenticated waiting... for get user profile");
              window.localStorage.setItem("authProvider", "email/password");
              this.authenticated = true;
            })
            .catch(error => {
              this.loading = false;
              const { code } = error;
              const wrongPassword = code === "auth/wrong-password";
              const notFound = code === "auth/user-not-found";
              if (wrongPassword) {
                this.validity.password = false;
                this.passwordMsg =
                  "contraseña errada o  no tiene contraseña establecida por que te autenticaste con facebook, o google anteriormente.";
              }
              if (notFound) {
                this.validity.email = false;
                this.emailMsg = "usuario no encontrado";
              }
            });
        }
      }
    }
  }
};
</script>
