import { BASE } from "./index";
const updateHeadquater = (order = "", dish = "", data = {}) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/order/deliverys/status/${order}/${dish}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};
export default updateHeadquater;
