import Vue from 'vue';
import moment from 'moment';
import BasicInput from "@/components/inputs/BasicInput";
export default Vue.extend({
  name: 'InvoiceDetail',
  components: {
    BasicInput
  },
  props: {
    img: {
      type: String,
      // eslint-disable-next-line global-require
      default: require("@/assets/images/logo.png")
    },
    show: {
      type: Boolean,
      default: false
    },
    currentOrder: {
      type: Object,
      default: () => ({
        paidMethod: '',
        dispatchedBy: {
          name: ''
        },
        createAt: {
          _seconds: 0
        },
        iva: {
          computedValue: 0
        },
        table: {},
        basePrice: 0
      })
    },
    headquater: {
      type: Object,
      default: () => ({
        name: ''
      })
    }
  },
  data: () => ({
    mainCategory: "32ceb392-f5ae-4811-ba97-e43539a69a7a",
    dessertCategory: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
    drinksCategory: "9c4e239e-6e8e-4447-937e-53a234d038e8",
    ctrlEdit: false,
    invoice: {
      nit: '',
      companyAddress: '',
      companyPhone: '',
      clientName: '',
      userName: '',
      userNit: '',
      userAddress: '',
      userPhone: ''
    }
  }),
  computed: {
    order() {
      if (!this.currentOrder.dishes) {
        return {
          dishes: [{
            name: '',
            price: 0,
            amount: 0
          }],
          paidMethod: '',
          dispatchedBy: {
            name: ''
          },
          createAt: {
            _seconds: 0
          },
          iva: {
            computedValue: 0
          },
          table: {},
          basePrice: 0
        };
      }
      return this.currentOrder;
    },
    payMethod() {
      return this.order.paidMethod === 'CASH' ? 'Efectivo' : this.order.paidMethod === 'CARD' ? 'Débito o Crédito' : 'Transacción';
    },
    clientDetail() {
      return Boolean(this.invoice.userName || this.invoice.userPhone || this.invoice.userNit || this.invoice.userAddress);
    },
    createAts() {
      try {
        return moment((this.currentOrder.createAt._seconds * 1000) || 0).format('ll');
      } catch (e) {
        return 0;
      }
    }
  },
  methods: {
    printInvoice() {
      this.$htmlToPaper("invoice-detail");
    }
  }
});
