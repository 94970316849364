 <style scoped lang="scss">
.wrap-form-loader {
  text-align: center;
}
.btn-submit {
  width: calc(100% - 20px);
  border-radius: 12px;
  height: 45px;
  font-family: Gibson-Bold;
}
.v-form {
  margin-top: 50px;
}
::v-deep .invalid {
  .field-msg {
    color: red;
  }
}
</style>

<template>
  <v-form>
    <div class="wrap-fields">
      <BasicInput
        ref="nameField"
        :default-value="data.name"
        label="*Nombre"
        :valid="validity.name"
        styl
        :dark="true"
        :msg="nameInvalidMsg"
        :overlay="true"
        :disabled="disabled"
        @input-change="handleFieldChange('name', $event)"
      ></BasicInput>

      <FileUploadInput
        id="category-logo"
        ref="categoryLogo"
        accept="image/*"
        :dark="true"
        :multiple="false"
        :disabled="disabled"
        caption="Cargar logo"
      ></FileUploadInput>

      <v-divider class="mt-3 mb-3"></v-divider>
    </div>

    <v-btn
      v-if="loading === false"
      color="primary"
      class="btn-submit"
      :disabled="isValid === false || disabled === true"
      @click="submitForm"
    >Enviar</v-btn>

    <v-container v-if="loading=== true" class="wrap-form-loader">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-container>
  </v-form>
</template>

<script>
import BasicInput from "@inputs/BasicInput";
import FileUploadInput from "@inputs/FileUploadInput";
import validators from "@utils/validators";
import requests from "@/helpers/requests";

export default {
  name: "CategoriesForm",
  components: {
    BasicInput,
    FileUploadInput
  },
  props: {
    mode: {
      type: String,
      default: "create"
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showCategories: false,
      disabled: false,
      loading: false,
      validity: {
        name: false
      },
      changes: {
        name: this.data.name
      },
      nameInvalidMsg: "Escribe un nombre valido"
    };
  },
  computed: {
    isValid() {
      return Object.keys(this.validity).every(
        key => this.validity[key] === true
      );
    }
  },
  watch: {
    data(val) {
      const fields = Object.keys(val);
      fields.forEach(field => {
        const inValidation = this.validity.hasOwnProperty(field);
        const inChanges = this.changes.hasOwnProperty(field);
        if (inValidation) this.validity[field] = true;
        if (inChanges) this.changes[field] = val[field];
      });
      const { categoryLogo } = this.$refs;
      categoryLogo.reset();
    }
  },
  methods: {
    reset() {
      const { nameField, categoryLogo } = this.$refs;
      nameField.reset();
      categoryLogo.reset();
      this.changes.name = "";
      this.validity.name = false;
    },
    uploadFiles(files) {
      return new Promise(async (resolve, reject) => {
        const snaps = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          try {
            const storageRef = window.firebase
              .storage()
              .ref(`images/Categories/${file.name}`);
            const snapshot = await storageRef.put(file);
            snaps.push(snapshot);
          } catch (error) {
            reject(error);
            break;
          }
        }
        return resolve(snaps);
      });
    },
    async submitForm() {
      if (this.mode === "create") {
        const { categoryLogo } = this.$refs;
        const files = categoryLogo.getFiles();
        this.loading = true;
        try {
          const payload = { ...this.changes };
          this.disabled = true;
          if (files.length) {
            const [{ metadata = "" }] = await this.uploadFiles(files);
            const BASE =
              "https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o/images%2FCategories";
            payload.logo = `${BASE}%2F${metadata.name}?alt=media`;
          }
          const createResponse = await requests.createCategory(payload);
          this.loading = false;
          this.disabled = false;
          this.$emit("success");
        } catch (error) {
          console.log("error ", error);
          this.disabled = false;
          this.loading = false;
          this.$emit("error");
        }
      }
    },
    handleFieldChange(field, value = "") {
      this.changes[field] = value;
      if (value === "") {
        this.validity[field] = false;
      } else if (field === "name" && value) {
        this.validity[field] = validators.validName(value);
      }
    }
  }
};
</script>
