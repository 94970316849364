import { BASE } from "./index";
const deleteTable = id => {
  const url = `${BASE}/table/delete/${id}`;
  const token = window.localStorage.getItem("token") || "";
  return fetch(url, {
    method: "DELETE",
    headers: {
      token,
      "content-type": "application/json"
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default deleteTable;
