<template src="./template.html"></template>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<script>
import ConfirmModal from "@components/modals/ConfirmModal";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { removeDishFromOrder } = requests;

export default {
  name: "DishesList",
  components: {
    ConfirmModal
  },
  props: {
    dishes: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showConfirmBox: false,
      success: false,
      error: false,
      selectedDish: null
    };
  },
  methods: {
    handleConfirmDelete() {
      this.showConfirmBox = false;
      const { id = "" } = this.order;
      this.requestRemoveDish(id, this.selectedDish.id);
    },
    getDishIndex(id) {
      return this.dishes.map(dish => dish.id).indexOf(id);
    },
    handleRemoveDish(dish) {
      this.showConfirmBox = true;
      this.selectedDish = dish;
    },
    async removeDish(order = "", id = "") {
      console.log("removeDish");
      const dish = this.dishes.find(item => item.id === id);
      dish.loading = true;
      const { ok } = await surePromise(removeDishFromOrder(order, id));
      dish.loading = false;
      if (ok) {
        this.success = true;
        this.selectedDish = null;
        this.dishes.splice(this.getDishIndex(dish), 1);
        return this.$emit("removed", id);
      }
      this.error = true;
    },
    async requestRemoveDish(order, id = "") {
      if (order && id) return this.removeDish(order, id);
      const dish = this.dishes.find(item => item.id === id);
      dish.loading = true;
      setTimeout(() => {
        this.$emit("removed", id);
      }, 1000);
    },
    handleCloseModal() {
      this.showConfirmBox = false;
      this.selectedDish = null;
    }
  }
};
</script>