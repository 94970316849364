import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";

const { origin: BASE } = window.location;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [`${BASE}/font.css`, `${BASE}/invoiceDetail.css`, `${BASE}/invoice.css`]
};

Vue.use(VueHtmlToPaper, options);
