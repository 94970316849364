<style scoped lang="scss">
.action-container {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;
  &__left {
    background-image: url("~@assets/icons/key_arrow_left.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
  }
  &__rigth {
    background-image: url("~@assets/icons/key_arrow_righ.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    vertical-align: middle;
    display: inline-block;
  }
  .v-btn--disabled {
    opacity: 0.5;
  }
}
</style>

<template>
  <v-container class="action-container">
    <v-btn :disabled="disableBack" class="action-container__left" icon flat @click="$emit('back', $event)"></v-btn>
    <v-btn class="action-container__rigth" :disabled="disableNext" icon flat @click="$emit('next', $event)"></v-btn>
  </v-container>
</template>

<script>
export default {
  name: "TableFooter",
  props: {
    disableBack: {
      type: Boolean,
      default: false
    },
    disableNext: {
      type: Boolean,
      default: false
    }
  }
};
</script>
