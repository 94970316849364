<template>
  <div>
    <div v-if="reservations.length === 0">
      <EmptySection
        title=" Aún no tienes reservas."
        text="Haz click en el botón reservar"
        :show-btn="false"
      >
        <template slot="image">
          <img src="@/assets/icons/calendar-fill-color.svg" />
        </template>
      </EmptySection>
    </div>
    <RestaurantsList
      v-else
      :mode="mode"
      :show-open-state="false"
      :caption="$t('lang.RestaurantsList.captionReservations')"
      :no-padding="false"
      :data="reservations"
      @openReservation="$emit('openReservation', $event)"
    ></RestaurantsList>
  </div>
</template>

<script>
import RestaurantsList from "@components/lists/RestaurantsList";
import requests from "@/helpers/requests";
import DispatchReservationClock from "@/helpers/DispatchReservationClock";
import EmptySection from "@/components/generals/EmptySection";

export default {
  name: "Reservations",
  components: { RestaurantsList, EmptySection },
  props: {
    mode: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: "APPROVED"
    },
    headquater: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cache: {},
      reservations: [],
      reservationUnsuscribe: null,
      loading: false
    };
  },
  watch: {
    headquater() {
      if (this.reservationUnsuscribe) this.reservationUnsuscribe();
      this.reservations = [];
      this.reservationUnsuscribe = this.listenReservations(this.handleReservations);
    }
  },
  beforeDestroy() {
    if (this.reservationUnsuscribe) this.reservationUnsuscribe();
    if (this.clockReservation) this.clockReservation.clear();
  },
  mounted() {
    this.reservationUnsuscribe = this.listenReservations(this.handleReservations);
    this.clockReservation = new DispatchReservationClock();
  },
  methods: {
    mapReservationsToExpired(reservation) {
      // const timestamp = reservation.date.seconds;
      const date = new Date(reservation.date.toDate());
      const isExpired = new Date() > date;
      if (isExpired) reservation.status = "EXPIRED";
      return reservation;
    },
    handleReservations(data) {
      const pendings = data.filter(this.filterReservationsPendings);
      if (pendings.length) pendings.forEach(this.pushPendingReservations);

      this.reservations = data.filter(this.filterReservationsPendings).map(this.mapReservationsToExpired);
    },
    pushPendingReservations(pending) {
      this.clockReservation.push(pending);
    },
    filterReservationsPendings(reservation) {
      return reservation.status === "PENDING";
    },
    listenReservations(callback) {
      const db = window.firebase.firestore();
      const ReservationsRef = db.collection("Reservations");
      // console.log('status ', this.status, ' belongsTo ', this.headquater.id);
      return ReservationsRef.where("belongsTo", "==", this.headquater.id || "")
        .where("status", "==", this.status)
        .orderBy("createAt", "desc")
        .onSnapshot(async snapshots => {
          // console.log({ snapshots })
          const result = [];
          // console.log('snapshots ', snapshots);
          if (snapshots.empty === false) {
            for (let i = 0; i < snapshots.docs.length; i++) {
              const current = snapshots.docs[i].data();
              const hasUser = current.hasOwnProperty("user") && current.user.hasOwnProperty("name");
              if (hasUser) {
                current.madeBy = current.user;
              } else {
                const inCache = this.cache.hasOwnProperty(current.madeBy);
                if (inCache) {
                  current.madeBy = { ...this.cache[current.madeBy] };
                } else {
                  const user = await requests.fetchUser(current.madeBy);
                  this.cache[current.madeBy] = { ...user };
                  current.madeBy = { ...user };
                }
              }
              current.reservations = current;
              current.restaurant = current.restaurant;
              current.name = current.madeBy.name;
              if (current.madeBy.photoURL) current.logo = current.madeBy.photoURL;
              // current.description = `Puntos: ${current.madeBy.points}`;
              if (this.headquater) {
                current.address = this.headquater.address;
                if (this.headquater.banner) current.banner = this.headquater.banner;
              }
              result.push(current);
            }
          }
          callback(result);
        });
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/_colors.scss";

.empty-reservation {
  text-align: center;
  color: $secondary;

  &__img {
    margin-top: 100px;
    margin-bottom: 20px;
    height: 120px;
    width: 120px;
    opacity: 0.5;
  }

  &__text {
    font-size: 16px;
  }

  &__btn {
    width: 80%;
    border-radius: 12px;
    height: 50px;
    padding: 0px;
    font-family: Gibson-Bold;
    margin: 0px;
  }
}

::v-deep .wrap-restaurants__content__wrap-list-ul {
  display: block !important;
}
::v-deep .wrap-restaurants__content__wrap-list {
  padding-top: 20px;
}
</style>
