<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import calcPromotion from "@/utils/calcPromotion";
import calcPercentage from "@/utils/calcPercentage";
import requests from "@/helpers/requests";
import surePromise from "@/utils/surePromise";

export default {
  name: "Invoice",
  filters: {
    calcPromotion
  },
  props: {
    totalAmount: {
      type: Number,
      default: () => 0
    },
    discount: {
      type: Number,
      default: () => 0
    },
    service: {
      type: Number,
      default: () => 0
    },
    table: {
      type: String,
      default: ""
    },
    dark: {
      type: Boolean,
      default: false
    },
    dishes: {
      type: Object,
      default: () => ({})
    },
    client: {
      type: Object,
      default: () => ({})
    },
    headquaters: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: () => ""
    },
    number: {
      type: String,
      default: () => ""
    },
    order: {
      type: String,
      default: () => ""
    },
    iva: {
      type: Number,
      default: 0
    },
    domicile: {
      type: Number,
      default: 0
    },
    douceur: {
      type: Number,
      default: 0
    }
  },
  computed: {
    computedIva() {
      const menu = this.getMapedDishes(this.dishes);
      const basePrice = this.calculateBasePrice(menu);
      let price = basePrice;
      price -= Number(this.discount);
      price += Number(this.service);
      return calcPercentage(price, Number(this.iva));
    }
  },
  data() {
    return {
      main: [],
      drinks: [],
      dessert: [],
      mainCategory: "32ceb392-f5ae-4811-ba97-e43539a69a7a",
      dessertCategory: "2cd158eb-5fca-424e-acd3-f9accb9a9955",
      drinksCategory: "9c4e239e-6e8e-4447-937e-53a234d038e8",
      total: 0,
      confirm: false,
      loading: false,
      showConfirmBtn: false,
      success: false,
      error: false
    };
  },
  created() {
    const { main, drinks, dessert, total } = this.calculatePrices(this.dishes);
    this.main = main;
    this.drinks = drinks;
    this.dessert = dessert;
    this.total = total;
  },
  watch: {
    totalAmount(val) {
      this.total = val;
      return val;
    },
    dishes(val) {
      const { main, drinks, dessert, total } = this.calculatePrices(val);
      this.main = main;
      this.drink = drinks;
      this.dessert = dessert;
      this.total = total;
      return val;
    }
  },
  methods: {
    hasClient() {
      return Object.keys(this.client || {}).length !== 0;
    },
    async handleConfirm() {
      this.loading = true;
      const { ok, result } = await surePromise(
        requests.dispatchOrder({
          orderId: this.order,
          tableId: this.table
        })
      );
      this.loading = false;
      if (ok) {
        const success = result.ok === true;
        this.success = success;
        this.$emit("confirmed");
      } else {
        this.success = false;
        this.error = true;
      }
    },
    handlePay() {
      const { belongsTo, id } = this.headquaters.find(headquater => headquater.selected);
      this.loading = true;
      requests
        .showInvoiceScreen(this.order, {
          restaurant: belongsTo,
          headquater: id,
          order: this.order,
          table: this.table
        })
        .then(() => {
          this.loading = false;
          this.success = true;
          this.showConfirmBtn = true;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    handleAddProduct() {
      const { belongsTo, id } = this.headquaters.find(headquater => headquater.selected);
      let url = `${window.origin}/restaurant/${belongsTo}/${id}?table=${this.table}`;
      if (this.hasClient()) url += `&user=${this.client.id}`;
      if (this.order) url += `&order=${this.order}`;
      window.open(url, "_blank");
    },
    getMapedDishes(dishes) {
      return Object.keys(dishes).map(id => {
        const { name, price, promotion = "", categories } = dishes[id];
        const { amount } = dishes[id];
        let discount = 0;
        if (promotion) discount += this.calcPromotion(promotion, amount);
        return {
          name,
          price,
          id,
          promotion,
          categories,
          amount,
          discount
        };
      });
    },
    calculateBasePrice(menu) {
      let total = 0;
      menu.forEach(item => {
        if (item.promotion) {
          total += item.discount * item.price;
        } else {
          total += item.amount * item.price;
        }
      });
      return total;
    },
    calculatePrices(dishes = {}) {
      const menu = this.getMapedDishes(dishes);
      const main = menu.filter(item => item.categories[this.mainCategory]);
      const drinks = menu.filter(item => item.categories[this.drinksCategory]);
      const dessert = menu.filter(item => item.categories[this.dessertCategory]);
      let total = this.calculateBasePrice(menu);
      total -= this.discount;
      total += this.service;
      if (this.iva) {
        const percentage = calcPercentage(total, Number(this.iva));
        total += percentage;
      }
      return {
        main,
        drinks,
        dessert,
        total
      };
    },
    handleConfirmOrder() {
      this.loading = false;
    },
    calcPromotion
  }
};
</script>
