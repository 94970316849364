import Hammer from "hammerjs";
export function disableZoomingEvents() {
  const hammertime = new Hammer(document, {
    prevent_default: false,
    touchAction: "pan"
  });
  hammertime.get("pinch").set({
    enable: true
  });
  hammertime.on("pinch pinchend pinchstart doubletap", e => {
    e.preventDefault();
  });
}
