<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import BasicSelect from "@components/selects/BasicSelect";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { switchTable } = requests;

export default {
  name: "EditOrderForm",
  components: { BasicSelect },
  props: {
    tables: {
      type: Array,
      default: () => []
    },
    defaultTable: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      isValid: false,
      selected: "",
      groups: []
    };
  },
  created() {
    this.groups = this.formatGroupsToSelect(this.groupTablesByStatus(this.tables));
    this.selected = this.defaultTable.id;
  },
  watch: {
    selected(val) {
      this.isValid = val !== this.defaultTable.id;
    }
  },
  methods: {
    translateStatus(status) {
      const isBusy = status === "BUSY";
      const isFree = status === "FREE";
      const isReserved = status === "RESERVED";
      if (isBusy) return "Ocupada";
      if (isFree) return "Libre";
      if (isReserved) return "Reservada";
      return "";
    },
    groupTablesByStatus(tables) {
      return tables.reduce((accumulator, table) => {
        if (accumulator.hasOwnProperty(table.status)) {
          accumulator[table.status].push(table);
        } else {
          accumulator[table.status] = [table];
        }
        return accumulator;
      }, {});
    },
    formatGroupsToSelect(groups) {
      const result = [];
      const keys = Object.keys(groups);
      keys.forEach((key, index) => {
        result.push({ header: this.translateStatus(key) });
        Object.values(groups[key]).forEach(({ name, id }) => {
          result.push({ name, id });
        });
        if (index < keys.length - 1) result.push({ divider: true });
      });
      return result;
    },
    async handleConfirm() {
      this.loading = true;
      const from = this.defaultTable.id;
      const to = this.selected;
      const table = this.tables.find(item => item.id === this.selected);
      this.$emit("switching", true, table);
      const { ok, error } = await surePromise(switchTable({ from, to }));
      this.$emit("switching", false, table);
      this.loading = false;
      if (ok) {
        this.$emit("success", true);
      } else {
        this.$emit("error", true);
        console.log({ error });
      }
    }
  }
};
</script>
