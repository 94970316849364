import { BASE } from "./index";
const confirmUserRequest = to => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/waiter/notification/request/${to}`;
  return fetch(url, {
    method: "GET",
    headers: {
      token
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default confirmUserRequest;
