<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>
<script>
  import Loading from "@components/generals/Loading";
  import ReservationsComboTables from "@components/generals/ReservationsComboTables";
  import UserBasicInfo from "@components/generals/UserBasicInfo";
  import ItemsLists from "@components/lists/ItemsLists";
  import HeadquatersSelect from "@components/selects/HeadquatersSelect";
  import Checkbox from "@components/generals/Checkbox";
  import HeadquaterForm from "@components/forms/HeadquaterForm";
  import HeadquaterModal from "@components/modals/HeadquaterModal";
  import CreateHeadquaterModal from "@components/modals/CreateHeadquaterModal";
  import requests from "@/helpers/requests";
  import mixin from "@/mixins";

  const { MenuMixin } = mixin;
  export default {
    name: "AdminHeadquaters",
    components: {
      Checkbox,
      Loading,
      ItemsLists,
      UserBasicInfo,
      HeadquatersSelect,
      HeadquaterForm,
      HeadquaterModal,
      CreateHeadquaterModal,
      ReservationsComboTables
    },
    mixins: [MenuMixin],
    data() {
      const { headquaters = {} } = window.user;

      const headquater = this.getHeadquaterSelectedFromUserProfile();
      return {
        tables: [],
        toggle: false,
        headquaterSelected: {},
        showCreateHeadquaterModal: false,
        showDetails: false,
        headquaterBusy: false,
        headquaters,
        headquater,
        showOverlay: false,
        success: false,
        error: false,
        loading: true
      };
    },
    computed: {
      hasHeadquaterSelected() {
        const result = Object.keys(this.headquaterSelected).length !== 0;
        if (result) this.toggle = true;
        return result;
      },
      headquatersValues() {
        return Object.values(this.headquaters);
      },
      headquaterList() {
        return Object.values(window.user.headquaters).map(item => item.name);
      },
      isPopulated(obj) {
        return Object.keys(obj).length !== 0;
      }
    },
    watch: {
      showOverlay(val) {
        const event = new CustomEvent("overlay", {
          detail: val
        });
        window.dispatchEvent(event);
      }
    },
    mounted() {
      this.loading = false;
      this.setSubscription();
      window.addEventListener("notification", this.handleNewNotification);
      window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
      window.addEventListener("toggleOverlay", this.handleShowOverlay);
    },
    beforeDestroy() {
      if (this.tableUnsuscription) this.tableUnsuscription();
      if (this.orderUnsuscribe) this.orderUnsuscribe();
      window.removeEventListener(
        "headquaterSelected",
        this.handleSelectHeadquater
      );
      window.removeEventListener("notification", this.handleNewNotification);
      window.removeEventListener("toggleOverlay", this.handleShowOverlay);
    },
    methods: {
      handleToggle() {
        this.toggle = !this.toggle;
      },
      handleSelectHeadquater(event) {
        const headquater = event.detail;
        this.loading = true;
        this.headquater = headquater;
        this.currentOrder = {};
        this.currentClient = {};
        this.setSubscription();
      },
      handleSelectedItem(item) {
        console.log(this.headquaters[item.id]);
        this.headquaterSelected = this.headquaters[item.id];
        if (!this.headquaterSelected.paypal) {
          this.headquaterSelected.paypal = {
            clientId: ""
          };
        }
        if (!this.headquaterSelected.payu) {
          this.headquaterSelected.payu = {
            accountId: "",
            merchantId: "",
            apiKey: ""
          };
        }
        this.headquaterSelected.image = this.headquaterSelected.logo;
        this.showDetails = true;
      },
      handleDeleteHeadquater(id = "") {
        /*
        const index = this.headquaters.map(item => item.id).indexOf(id);
        this.headquaters.splice(index, 1);
        */
        delete this.headquaters[id];
        this.success = true;
      },
      handleEditHeadquaterSuccess(changes = {}) {
        this.headquaters[changes.id] = {
          ...this.headquaters[changes.id],
          ...changes
        };
        this.headquaterSelected = this.headquaters[changes.id];
        this.success = true;
      },
      handleAddHeadquaterSuccess(headquater) {
        this.headquaters[headquater.id] = headquater;
        this.success = true;
      },
      mapItemData(data) {
        return Object.keys(data).map(key => {
          const { id = "", name = "", address = "", banner = "" } = data[key];
          return {
            id,
            image: banner,
            title: name,
            subtitle: address
          };
        });
      },
      resolveChange() {
        const operations = [];
        const activeChange =
          this.active !== this.headquater.workingAt[this.headquatersSelected.id];
        const detailSelected = this.$refs.detailsSelectHeadquater.selected.id;
        const changeHeadquater = detailSelected !== this.headquatersSelected.id;
        if (activeChange) {
          if (this.active === true) operations.push({ type: "setActive" });
          if (this.active === false) operations.push({ type: "setUnactive" });
        }

        if (changeHeadquater) {
          operations.push({ type: "addHeadquater", headquater: detailSelected });
        }

        if (this.suspend === true) operations.push({ type: "suspend" });
        return operations;
      },
      mapOpeationsToRequests(operations) {
        const { headquatersSelected, headquater } = this;
        return operations
          .map(operation => {
            operation.employee = headquater.id;
            if (operation.type !== "addHeadquater") {
              operation.headquater = headquatersSelected.id;
            }
            return operation;
          })
          .map(operation => {
            const request = requests.updateEmployeeFromHeadquater(operation);
            request
              .then(response => this.handleResponseOperation(operation, response))
              .catch(error => {
                this.handleFailOperation(operation, error);
              });
            return request;
          });
      },
      handleResponseOperation(operation, response) {
        const { type = "", employee, headquater } = operation;
        if (response.ok === true) {
          const currentEmployee = this.employees.find(
            item => item.id === employee
          );
          if (type === "setActive") {
            currentEmployee.workingAt[headquater] = true;
          }
          if (type === "setUnactive") {
            currentEmployee.workingAt[headquater] = false;
          }
          if (type === "suspend") {
            currentEmployee.suspend = true;
          }
        } else {
          this.error = true;
        }
      },
      handleFailOperation(operation, error) {
        console.log("error ", error);
        this.error = true;
      },
      async saveEmployeeChanges() {
        let operations = this.resolveChange();
        if (operations.length) {
          operations = this.mapOpeationsToRequests(operations);
          try {
            const results = await Promise.all(operations);
            const allOperationSuccess = results.every(
              result => result.ok === true
            );
            this.$refs.saveAndDeleteCombo.saving = false;
            if (allOperationSuccess) {
              this.success = true;
            } else {
              this.error = true;
            }
          } catch (error) {
            console.log("error ", error);
          }
        } else {
          this.$refs.saveAndDeleteCombo.saving = false;
        }
      },
      async deleteEmployeeFromHeadquaterSelected() {
        const { headquatersSelected, headquater } = this;
        try {
          const response = await requests.updateEmployeeFromHeadquater({
            employee: headquater.id,
            headquater: headquatersSelected.id,
            type: "leaveHeadquater"
          });
          if (response.ok) {
            this.$refs.saveAndDeleteCombo.deleting = false;
            this.success = true;
            this.headquaters = this.employees.filter(
              employee => employee.id !== headquater.id
            );
            this.headquater = {};
          } else {
            this.$refs.saveAndDeleteCombo.deleting = false;
            this.error = true;
            console.log("error ", response);
          }
        } catch (error) {
          console.log("error ", error);
          this.$refs.saveAndDeleteCombo.deleting = false;
          this.error = true;
        }
      },
      toggleOverlay() {
        this.showOverlay = !this.showOverlay;
      },
      async getheadquateresByHeadquater(headquater) {
        if (headquater) {
          try {
            let result = await requests.fetchheadquateres({
              availableAt: headquater.id
            });
            result = result.map(item => {
              if (item.image) item.image = item.image;
              return item;
            });
            console.log("headquateres ", result);
            return Promise.resolve(result);
          } catch (error) {
            console.log("error ", error);
            return Promise.reject(error);
          }
        }
        return [];
      },
      handleChooseTable() {
        console.log("choose table");
      },
      handleOpenReservation() {
        console.log("open reservation");
      }
    }
  };
</script>
