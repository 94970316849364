<template>
  <ul class="container-list">
    <li
      v-for="(item, index) in points"
      :key="index"
      class="container-list__item"
      :class="{ 'container-list__item--pull-up': pullUp }"
    >
      <img
        v-if="item.selected"
        width="21px"
        height="21px"
        :src="starChecked"
        alt="iphone"
        @click="() => handleSelect(item)"
      >
      <img
        v-if="item.selected === false"
        width="21px"
        height="21px"
        :src="star"
        @click="() => handleSelect(item)"
      >
    </li>
  </ul>
</template>
<style scoped lang="scss">
  .split-line {
    opacity: 0.25;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .container-list__item--pull-up {
    bottom: 5px !important;
  }
.container-list {
    padding: 0px;
  }
  .container-list__item {
    list-style: none;
    display: inline-block;
    cursor: pointer;
    position: relative;
    bottom: 0px;
    margin-right: 10px;
  }
@media (max-width: 1024px) {
  .container-list__item {
    margin-right: 3px;
  }
}
</style>
<script>
import star from '@assets/icons/star-silver.png';
import starChecked from '@assets/icons/star-gold.png';

export default {
  name: 'StarList',
  props: {
    point: {
      type: Number,
      default: 0
    },
    pullUp: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let points = [
     {
        selected: false,
        id: 1
     },
      {
        selected: false,
       id: 2
     },
     {
       selected: false,
       id: 3
     },
      {
        selected: false,
       id: 4
     },
       {
        selected: false,
       id: 5
     }
   ];
    points = points.map((point) => {
      point.selected = this.point >= point.id;
      return point;
    });
   return {
     star,
     starChecked,
     points
   };
  },
  methods: {
    flushStars() {
      this.points = this.points.map((item) => {
        item.selected = false;
        return item;
      });
      this.$emit('unselect');
    },
    handleSelect(point) {
      if (this.disabled) return;
      const alreadySelected = this.points.some(item => item.id === point.id && item.selected && point.selected);
      if (alreadySelected) return this.flushStars();
      this.points = this.points.map((item) => {
        item.selected = point.id >= item.id;

        return item;
      });
      this.$emit('select', point.id);
    }
  }
};
</script>
