<style scoped lang="scss">
  @import './style.scss';
</style>
<template src="./template.html"></template>
<script>
import BasicInput from '@inputs/BasicInput';
import validators from '@utils/validators';
import getUrlParams from '@utils/getUrlParams';
import Header from '@components/Headers/LogoRed';

const { validPassword } = validators;
export default {
    name: 'RecoveryForm',
    components: { BasicInput, Header },
    data() {
      return {
        validity: { confirm: false, password: false, invalidForm: true },
        loading: false,
        confirm: '',
        password: '',
        serverError: false,
        expiredError: false
      };
    },
    methods: {
      handleResetPassword(data) {
        const {
          auth, actionCode, newPassword
        } = data;
          auth
           .verifyPasswordResetCode(actionCode)
           .then(() => {
              auth.confirmPasswordReset(actionCode, newPassword).then(() => {
              // alert(`La contraseña de ${email} fue actualizada con exito!`);
              window.location.href = `${window.location.origin}/login`;
           })
          .catch((error) => {
            this.loading = false;
            this.serverError = true;
            console.log('error ', error);
           });
         })
         .catch((error) => {
            const { code } = error;
            const isExpired = code === 'auth/invalid-action-code';
            if (isExpired) {
              this.loading = false;
              this.validity.invalidForm = true;
              this.expiredError = true;
              // alert('ERROR: No se puede utilizar este enlace mas de una vez o ya expiro su vida util, debes generar otro');
            } else {
              console.log('error ', error);
            }
        });
      },
      handleFieldChange(field, value) {
        if (field === 'password') {
          this.password = value;
          this.validity.password = validPassword(value);
        }
        if (field === 'confirm') {
          this.confirm = value;
          this.validity.confirm = validPassword(value) && value === this.password;
        }
        this.validity.invalidForm = !(this.validity.confirm && this.validity.password);
      },
      handleSubmit(e) {
        e.preventDefault();
        this.loading = true;
        // const mode = getUrlParams('mode');
        const actionCode = getUrlParams('oobCode');
        const continueUrl = getUrlParams('continueUrl');
        this.handleResetPassword({
 actionCode, newPassword: this.password, auth: window.auth, continueUrl
});
      }
    }
};
</script>
