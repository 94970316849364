<template src="./template.html"></template>
<script>
import StarList from "@lists/StarList.vue";
import ConfirmModal from "@components/modals/ConfirmModal";
import CancelDescriptionModal from "@components/modals/CancelDescriptionModal";
import ReaseonModal from "@components/modals/ReaseonModal";
import banner from "@assets/default-banner.png";
import logo from "@assets/default-logo.png";
import VClamp from "vue-clamp";
import i18n from "@/lang";
import ReservationDetail from "@components/modals/ReservationDetail/ReservationDetail.vue";

export default {
  name: "RestaurantCard",
  components: {
    StarList,
    VClamp,
    ConfirmModal,
    CancelDescriptionModal,
    ReaseonModal,
    ReservationDetail
  },
  filters: {
    translateStatus(val) {
      if (!val) return "";
      let result = "";
      if (val === "PENDING") result = i18n.tc("lang.RestaurantCard.pending");
      if (val === "REJECT") result = i18n.tc("lang.RestaurantCard.reject");
      if (val === "COMPLETE") result = i18n.tc("lang.RestaurantCard.complete");
      if (val === "CANCEL") result = i18n.tc("lang.RestaurantCard.cancel");
      if (val === "CONFIRM") result = i18n.tc("lang.RestaurantCard.confirm");
      if (val === "EXPIRED") result = i18n.tc("lang.RestaurantCard.expired");
      if (val === "APPROVED") result = i18n.tc("lang.RestaurantCard.approved");
      return result;
    }
  },
  props: {
    cancelReaseon: {
      type: String,
      default: ""
    },
    lang: {
      type: String,
      default: "ES"
    },
    showOpenState: {
      type: Boolean,
      default: true
    },
    schedule: {
      type: Object,
      default: () => ({})
    },
    date: {
      type: String,
      default: ""
    },
    dateTimeStamp: {
      type: Object,
      default: () => ({})
    },
    address: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    hour: {
      type: String,
      default: ""
    },
    persons: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    description: {
      type: Object,
      default: Function
    },
    mode: {
      type: String,
      default: ""
    },
    like: {
      type: Boolean,
      default: false
    },
    banner: {
      type: String,
      default: banner
    },
    logo: {
      type: String,
      default: logo
    },
    point: {
      type: Number,
      default: 0
    },
    link: {
      type: String,
      default: ""
    },
    disableCancel: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const favorite = this.like;
    let openNow = false;
    const dayweek = weekDays[new Date().getDay() - 1];
    const horary = this.schedule[dayweek];
    if (horary) {
      const daytime = this.getDayTime();
      const { to = "" } = horary[daytime] || {};
      const hour = this.getCurrentTime();
      const formatedTo = this.formatHourAnMinutes(to);
      openNow = this.timeAGreatteThanTimeB(formatedTo, hour);
    }
    return {
      showDetailReservation: false,
      favorite,
      showReaseonModal: false,
      loading: false,
      disabled: this.disableCancel,
      dayweek,
      openNow,
      showConfirmBox: false
    };
  },
  watch: {
    disableCancel(val) {
      this.disabled = val;
    }
  },
  computed: {
    menu() {
      return localStorage.getItem("selectedRol") === "ADMIN"
        ? [
            { title: "ver detalle", function: this.handleClick },
            { title: "eliminar", function: this.showConfirmBoxModal }
          ]
        : [{ title: "ver detalle", function: this.handleClick }];
    }
  },
  methods: {
    handleCancelReservation() {
      this.showDetailReservation = false;
      this.showConfirmBox = true;
    },
    showConfirmBoxModal() {
      this.showConfirmBox = true;
    },
    handleOpenReaseonModal() {
      // console.log("OPEN REASEON");
    },
    handleConfirmCancelReservation(message = "") {
      this.showConfirmBox = false;
      this.$emit("cancelReservation", message);
    },
    convert12To24(time = "") {
      const plainTime = time
        .replace("pm", "")
        .replace("am", "")
        .split(":");
      const pm = time.includes("pm");
      if (pm) {
        plainTime[0] = `${Number(plainTime[0]) + 12}`;
      }
      if (pm === false) {
        plainTime[0] = `0${plainTime[0]}`;
      }
      return plainTime;
    },
    formatHourAnMinutes(time) {
      let result = "";
      const plainTime = this.convert12To24(time);
      result = `${plainTime[0]}:${plainTime[1]}`;
      return result;
    },
    getDayTime() {
      const today = new Date();
      const curHr = today.getHours();
      if (curHr < 12) return "morning";
      if (curHr < 18) return "afternoon";
      return "evening";
    },
    timeAGreatteThanTimeB(a, b) {
      const dateA = new Date();
      const dateB = new Date();
      dateA.setHours(a.split(":")[0]);
      dateA.setMinutes(a.split(":")[1]);
      dateB.setHours(b.split(":")[0]);
      dateB.setMinutes(b.split(":")[1]);
      return dateA > dateB;
    },
    getCurrentTime() {
      const currentTime = new Date();
      return `${currentTime.getHours()}:${currentTime.getMinutes()}`;
    },
    openReservationReseonModal() {
      this.showReaseonModal = true;
    },
    handleClick(e) {
      const isWaiterOrAdmin = this.mode === "waiter" || this.mode === "admin";
      if (isWaiterOrAdmin) {
        const isAdmin = this.mode === "admin";
        if (isAdmin) this.showDetailReservation = true;
        return this.$emit("openReservation", this.id);
      }
      this.showDetailReservation = true;
      const clickOnCard = e.target.classList.contains("container__like") === false;
      const clickOnBtnCancel = e.target.classList.contains("v-btn__content") || e.target.classList.contains("restaurant-band-btn-cancel");
      if (clickOnBtnCancel) return;
      const [id, headquater] = this.link.split("/").slice(1, 3);
      if (clickOnCard) window.router.push({ name: "restaurant", params: { id, headquater } });
    },
    handleLike() {
      this.favorite = !this.favorite;
      const like = this.favorite;
      if (like) return this.$emit("like");
      this.$emit("unlike");
    }
  }
};
</script>

<style scoped lang="scss">
@import "./style.scss";
</style>
