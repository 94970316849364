const mutations = {
  add_to_cart(state, payload) {
    console.log(payload);
    state.cart.push(payload.item);
    state.headquater = payload.headquater;
    state.restaurant = payload.restaurant;
    localStorage.setItem("cart", JSON.stringify(state.cart));
    localStorage.setItem("headquater", state.headquater);
    localStorage.setItem("restaurant", state.restaurant);
  },
  remove_cart_item(state, payload) {
    state.cart.splice(payload.index, 1);
    localStorage.setItem("cart", JSON.stringify(state.cart));
    if (state.cart.length === 0) {
      state.headquater = "";
      localStorage.removeItem("headquater");
    }
  },
  remove_cart_items(state) {
    state.cart = [];
    state.headquater = "";
    state.restaurant = "";
    localStorage.setItem("cart", JSON.stringify(state.cart));
    localStorage.removeItem("headquater");
    localStorage.removeItem("restaurant");
  },
  update_cart(state, payload) {
    state.cart[payload.index].amount = payload.amount;
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  open_cart(state) {
    state.show_cart = true;
  },
  close_cart(state) {
    state.show_cart = false;
  },
  current_order(state, payload) {
    state.current_order = payload;
    if (!state.current_order) {
      localStorage.removeItem("current_order");
    } else {
      localStorage.setItem("current_order", state.current_order);
    }
  },
  open_menu(state) {
    state.show_menu = true;
  },
  close_menu(state) {
    state.show_menu = false;
  },
  show_success_order(state) {
    state.showSuccessOrder = true;
  },
  close_success_order(state) {
    state.showSuccessOrder = false;
  },
  current_menu(state, payload) {
    state.currentMenu = payload;
    if (!state.currentMenu) {
      localStorage.removeItem("current_menu");
    } else {
      localStorage.setItem("current_menu", state.currentMenu);
    }
  }
};

export default mutations;
