<template>
  <div
    class="container"
  >
    <v-icon
      large
      color="primary"
      class="mail"
    >
    </v-icon>
    <div class="container__message">
      {{ message }}
    </div>
  </div>
</template>

<style scoped lang="scss">

.container {
 text-align: center;
 .container__message {
   padding-top: 10px;
   padding-bottom: 10px;
   color: white;
 }
.mail {
  width: 200px;
  height: 200px;
  background-image: url('../../assets/email.png');
   background-size: contain;
   margin: 0px auto;
   position: relative;
   text-align: center;
}
}
</style>


<script>
export default {
  name: 'EmailBox',
  props: {
    message: { type: String, default: '' },
    title: { type: String, default: '' }
  }
};

</script>
