<style scoped lang="scss">
  @import "style";
</style>

<template src="./template.html"></template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "Cart",
    props: {},
    components: {},
    computed: {
      ...mapState({
        show_cart: state => state.cart.show_cart,
        show_menu: state => state.cart.show_menu,
        cart_items: state => state.cart.cart,
        currentMenu: state => state.cart.currentMenu,
        current_order: state => state.cart.current_order,
        headquater: state => state.cart.headquater
      }),
      subtotal() {
        let total = 0;
        for (const i in this.cart_items) {
          if (this.cart_items[i].amount > 0) {
            total += this.cart_items[i].price * this.cart_items[i].amount;
          }
        }
        return total;
      },
      cost() {
        return 0;
      },
      total() {
        return this.subtotal + this.subtotal * 0.08 + this.cost;
      },
      canShowCartButton() {
        return (this.cart_items.length > 0 && !this.show_cart) && window.localStorage.getItem("selectedRol") === "USER";
      },
      canShowCurrentMenu() {
        return this.currentMenu && this.show_menu && window.localStorage.getItem("selectedRol") === "USER";
      }
    },
    data() {
      return {
        showCurrentMenu: true,
        restaurant: {}
      };
    },
    async created() {
      this.restaurant = await window.db.collection("Headquaters").where("id", "==", this.headquater).limit(1).get();
    },
    mounted() {

    },
    methods: {
      goToCart() {
        this.$router.push("/cart");
      },
      goToCurrentMenu() {
        this.$router.push(
          {
            force: true,
            path: `/${this.currentMenu}`
          });
        this.showCurrentMenu = false;
      }
    },
    watch: {
      showCurrentMenu(val) {
        if (val) {
          this.showCurrentMenu = true;
        } else {
          this.showCurrentMenu = false;
        }
      }
    }
  };
</script>