<template>
  <YourOrder></YourOrder>
</template>
<script>
  import YourOrder from "@/components/YourOrder";

  export default {
    name: "Dashboard",
    components: {
      YourOrder
    },
    data: () => ({}),
    created() {

    },
    methods: {}
  };
</script>
