const state = {
  cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
  show_cart: false,
  current_order: localStorage.getItem('current_order') ? localStorage.getItem('current_order') : '',
  show_promo: true,
  show_menu: false,
  showSuccessOrder: false,
  headquater: localStorage.getItem('headquater') ? localStorage.getItem('headquater') : '',
  restaurant: localStorage.getItem('restaurant') ? localStorage.getItem('restaurant') : '',
  currentMenu: localStorage.getItem('current_menu') ? localStorage.getItem('current_menu') : false
};

export default state;
