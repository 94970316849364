import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { getAccountings, getAccounting } = requests;

export default {
  name: "Accounting",
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentValue: 0,
      dateRanges: [],
      displayBoxFlowModal: false,
      displayAccountingModal: false,
      success: false,
      error: false,
      filterDate: "1",
      flow: {
        initialized: false,
        complete: false,
        initialDate: null,
        endDate: null,
        initialAmount: 0,
        endAmount: 0
      },
      accounting: null,
      payrolls: null,
      upZoom: false,
      downZoom: false,
      loading: false
    };
  },
  created() {
    this.flow.initialized = this.headquater.boxflow;
    if (this.accounting === null) this.currentValue = 0;
    const [start, end] = this.generateDates(Number(this.filterDate));
    this.dateRanges = [start, end];
  },
  async mounted() {
    if (this.flow.initialized) {
      await this.getTodayAccounting();
      const accountingNotFound = this.accounting === null;
      const hasAccountings =
        this.headquater.hasOwnProperty("accountings") && Array.isArray(this.headquater.accountings) && this.headquater.accountings.length !== 0;
      if (accountingNotFound && hasAccountings) {
        this.getLastAccounting();
      }
    }
  },
  computed: {
    haveAccounting() {
      return this.accounting !== null;
    },
    getPayrollsToDataset() {
      if (!this.accounting) return [];
      const isToday = this.filterDate === "1";
      if (isToday) return this.aggregateByHour(this.accounting.payrolls);
      return this.aggregateByDate(this.accounting.payrolls);
    },
    getPayrollsToPieset() {
      const CASH = "Efectivo";
      const CARD = "Tarjeta";
      const TRANSACTION = "Transaccion";
      const TRANSACTIONS_CODES = ["PSE", "BANK_REFERENCED", "SPEI", "ACH", "REFERENCED", "PAYPAL", "PAYU"];
      const { accounting } = this;
      if (!accounting) return [];
      const { payrolls = [] } = accounting;
      const result = payrolls.reduce(
        (accumulator, payroll) => {
          const isCash = payroll.paidMethod === "CASH";
          const isTransaction = TRANSACTIONS_CODES.includes(payroll.paidMethod);
          const isCard = payroll.paidMethod === "CREDIT_CARD";
          if (isCash) accumulator[CASH] += 1;
          if (isTransaction) accumulator[TRANSACTION] += 1;
          if (isCard) accumulator[CARD] += 1;
          return accumulator;
        },
        { [CASH]: 0, [CARD]: 0, [TRANSACTION]: 0 }
      );
      return Object.entries(result);
    },
    getPayrolls() {
      const { accounting } = this;
      if (!accounting) return [];
      let { payrolls = [] } = accounting;
      if (payrolls) {
        payrolls = this.mapTimestampToDate(payrolls);
        const isToday = this.filterDate === "1";
        if (isToday) {
          payrolls = this.groupBy(payrolls, "hour");
        } else {
          payrolls = this.groupBy(payrolls, "day");
        }
        const group = this.mapGroups(payrolls);
        payrolls = Object.values(group);
        this.payrolls = payrolls.flat();
      }
      return this.payrolls;
    }
  },
  methods: {
    async refreshData(headquater) {
      this.flow.initialized = headquater.boxflow;
      if (this.accounting === null) this.currentValue = 0;
      const [start, end] = this.generateDates(Number(this.filterDate));
      this.dateRanges = [start, end];
      if (this.flow.initialized) {
        await this.getTodayAccounting(headquater);
        const accountingNotFound = this.accounting === null;
        const hasAccountings =
          headquater.hasOwnProperty("accountings") && Array.isArray(headquater.accountings) && headquater.accountings.length !== 0;
        if (accountingNotFound && hasAccountings) {
          this.getLastAccounting(headquater);
        }
        return;
      }
      Object.assign(this.$data, (this.$options).data());
    },
    countInitialValues(accumulator, accounting) {
      accumulator += accounting.initialValue;
      return accumulator;
    },
    async handleDateChange(option, ranges) {
      this.filterDate = option;
      const isToday = option === "1";
      if (isToday) {
        if (this.flow.initialized) {
          const hasAccountings =
            this.headquater.hasOwnProperty("accountings") && Array.isArray(this.headquater.accountings) && this.headquater.accountings.length !== 0;
          if (hasAccountings) await this.getLastAccounting();
          return;
        }
        this.accounting = null;
        this.currentValue = 0;
        return;
      }
      this.loading = true;
      const isDays = option !== "range";
      const multiplesDates = option === "7" || option === "15" || option === "30" || option === "range";
      let startAt;
      let endAt;
      if (isDays) {
        const [start, end] = this.generateDates(Number(option));
        startAt = start;
        endAt = end;
      } else {
        const [start, end] = ranges;
        startAt = start.replace(/-/g, "/");
        endAt = end.replace(/-/g, "/");
      }
      this.dateRanges = [startAt, endAt];
      this.accounting = null;
      this.payrolls = null;
      const { id: belongsTo } = this.headquater;
      const { ok, result = [] } = await surePromise(getAccountings({ startAt, endAt, belongsTo, populate: true }));
      this.loading = false;
      if (ok) {
        const haveData = result && result.length;
        if (haveData) {
          const [accounting] = result;
          if (accounting) {
            const total = result.reduce(this.countInitialValues, 0);
            accounting.initialValue = total;
          }
          if (multiplesDates) {
            this.accounting = accounting;
            this.accounting.payrolls = this.concatPayrollsFromAccountings(result);
          } else {
            this.accounting = accounting;
          }
          const revenues = this.accounting.payrolls.filter(item => this.filterByType(item, "REVENUE"));
          const egress = this.accounting.payrolls.filter(item => this.filterByType(item, "EGRESS"));
          this.currentValue = this.flow.initialAmount + this.aggregateTotalPayrolls(revenues) - this.aggregateTotalPayrolls(egress);
        } else {
          this.currentValue = 0;
        }
        return;
      }
      this.error = true;
    },
    filterByType(payroll, type) {
      return payroll.type === type;
    },
    aggregateTotalPayrolls(payrolls) {
      const values = payrolls.map(payroll => payroll.value);
      return values.reduce((accumulator, value) => {
        accumulator += value;
        return accumulator;
      }, 0);
    },
    concatPayrollsFromAccountings(accountings) {
      let result = [];
      accountings.forEach(accounting => {
        result = [...result, ...accounting.payrolls];
      });
      return result;
    },
    async getLastAccounting(headquater) {
      console.log("getLastAccounting");
      this.loading = true;
      const { accountings = [] } = headquater || this.headquater;
      const id = accountings[accountings.length - 1];
      const { ok, result: accounting } = await surePromise(getAccounting(id, { populate: true }));
      this.loading = false;
      if (ok) {
        const haveData = Boolean(accounting);
        if (haveData) {
          this.accounting = accounting;
          if (accounting.closed) {
            this.flow.closed = accounting.closed;
          } else {
            this.flow.initialAmount = accounting.initialValue;
            const revenues = this.accounting.payrolls.filter(item => this.filterByType(item, "REVENUE"));
            const egress = this.accounting.payrolls.filter(item => this.filterByType(item, "EGRESS"));
            this.currentValue = this.flow.initialAmount + this.aggregateTotalPayrolls(revenues) - this.aggregateTotalPayrolls(egress);
          }
        }
        return;
      }
      this.error = true;
    },
    async getTodayAccounting(headquater) {
      this.loading = true;
      const startAt = this.mapDateToString(new Date());
      const endAt = this.mapDateToString(new Date());
      const { id: belongsTo } = headquater || this.headquater;
      const { ok, result = [] } = await surePromise(getAccountings({ startAt, endAt, belongsTo, populate: true }));
      this.loading = false;
      if (ok) {
        const haveData = result && result.length;
        if (haveData) {
          const [accounting] = result;
          if (accounting) {
            const total = result.reduce(this.countInitialValues, 0);
            accounting.initialValue = total;
          }
          if (accounting.closed) {
            this.flow.endAmount = accounting.closed;
            this.currentValue = 0;
          } else {
            this.accounting = accounting;
            this.flow.initialAmount = accounting.initialValue;
            const revenues = this.accounting.payrolls.filter(item => this.filterByType(item, "REVENUE"));
            const egress = this.accounting.payrolls.filter(item => this.filterByType(item, "EGRESS"));
            this.currentValue = this.aggregateTotalPayrolls(revenues) - this.aggregateTotalPayrolls(egress);
          }
        }
        Object.assign(this.$data, (this.$options).data());
        this.flow.initialized = true;
        return;
      }

      this.error = true;
    },
    fixPrefixDigit(digit) {
      if (Number(digit) <= 9) return `0${digit}`;
      return digit;
    },
    mapDateToString(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}/${this.fixPrefixDigit(month)}/${this.fixPrefixDigit(day)}`;
    },
    generateDates(day) {
      const today = new Date();
      const daysBack = new Date(today);
      const isToday = day === 1;
      if (isToday) {
        daysBack.setDate(daysBack.getDate());
      } else {
        daysBack.setDate(daysBack.getDate() - day);
      }
      const nextDay = new Date();
      const startAt = this.mapDateToString(daysBack);
      const endAt = this.mapDateToString(nextDay);
      return [startAt, endAt];
    }
  }
};
