<style scoped lang="scss">
@import "./style.scss";
</style>
<template src="./template.html"></template>

<script>
import TableFooter from "@components/tables/TableFooter";
import OrderDetailsModal from "@components/modals/OrderDetailsModal";

export default {
  name: "OrderTables",
  components: {
    TableFooter,
    OrderDetailsModal
  },
  props: {
    headquater: {
      type: Object,
      default: () => ({})
    },
    orders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      displayDetailsModal: false,
      orderSelected: {},
      page: 1,
      batch: 10,
      error: false,
      success: false,
      pagination: {
        sortBy: "createAt"
      },
      selected: [],
      headers: [
        {
          text: "Fecha de consumo",
          align: "left",
          sortable: false,
          value: "createAt"
        },
        { text: "Valor del consumo", value: "price", align: "left" },
        { text: "Medio de pago", value: "email", align: "left" },
        { text: "Detalle del consumo", value: "lastVisit", align: "left" }
      ],
      body: []
    };
  },
  filters: {
    noneField(val) {
      if (!val) {
        return "No hay dato";
      }
      return val;
    }
  },
  mounted() {
    this.body = this.orders;
  },
  methods: {
    openDetails(order) {
      this.orderSelected = order;
      this.displayDetailsModal = true;
    },
    loadMore(direction) {
      if (direction === "next") {
        this.pagination.page += 1;
      }
      if (direction === "back") {
        this.pagination.page -= 1;
      }
      this.body = this.orders;
    },
    filterRepeats(client) {
      const { id = "" } = client;
      const isNotIn = this.body.some(item => item.id === id) === false;
      return isNotIn;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.body.slice();
    }
  }
};
</script>
