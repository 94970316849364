import { notifyEvent } from "@/utils/notifyEvent";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import STORE from "@/store/types";

const { updateProfile, fetchUser, fetchUserByBatch, populateOrders } = requests;

export default {
  name: "DataProvider",
  data() {
    return { batchCache: { users: {}, orders: {} } };
  },
  methods: {
    getHeadquaterSelectedFromUserProfile() {
      if (window.router.currentRoute.query.hasOwnProperty("id") || window.router.currentRoute.query.hasOwnProperty("headquater")) {
        const headquaterId = window.router.currentRoute.query.hasOwnProperty("id") ? window.router.currentRoute.query.id : window.router.currentRoute.query.headquater;
        return this.updateSelectedHeadQuater(headquaterId);
      }
      const { headquaters = {} } = window.user;
      const headquater = Object.keys(headquaters).find(key => headquaters[key].selected === true);
      const noneHeadquater = headquater === undefined;
      if (noneHeadquater) {
        Object.keys(headquaters).forEach(key => {
          headquaters[key].selected = false;
        });
        const [firstHeadquater = {}] = Object.keys(headquaters);
        const hasHeadquater = headquaters.hasOwnProperty(firstHeadquater);
        if (hasHeadquater) {
          headquaters[firstHeadquater].selected = true;
          return headquaters[firstHeadquater];
        }
      }
      return headquaters[headquater];
    },
    updateSelectedHeadQuater(headquaterId) {
      const { headquaters = {} } = window.user;
      Object.keys(headquaters).forEach(key => {
        headquaters[key].selected = false;
      });
      if (headquaters[headquaterId]) {
        headquaters[headquaterId].selected = true;
      }
      return headquaters[headquaterId];
    },
    notifyLanguageChange(lang) {
      return notifyEvent({ name: "languageChange", detail: { lang } });
    },
    displayLangAvailable(payload = {}) {
      const { lang = "" } = window.user;
      const isAvailable = payload.hasOwnProperty(lang);
      if (isAvailable) return payload[lang];
      const keys = Object.keys(payload);
      if (keys.length !== 0) {
        const [available] = keys;
        return payload[available];
      }
      return payload.ES;
    },
    handleShowOverlay(show) {
      this.showOverlay = show.detail;
    },
    handleStagesResolves({ docs, indexes, result: accumulator }) {
      docs.forEach(doc => {
        const deep = Array.isArray(doc);
        if (deep) {
          return doc.forEach(element => {
            const isNotRepeat = indexes.hasOwnProperty(element.id) === false;
            if (isNotRepeat) {
              indexes[element.id] = true;
              accumulator.push(element);
            }
          });
        }
        const isNotRepeat = indexes.hasOwnProperty(doc.id) === false;
        if (isNotRepeat) {
          indexes[doc.id] = true;
          accumulator.push(doc);
        }
      });
      return accumulator;
    },
    orQuery(stages) {
      const indexes = {};
      const accumulator = [];
      return Promise.all(stages)
        .then(docs => this.handleStagesResolves({ docs, indexes, accumulator }))
        .catch(error => error);
    },
    async resolveSnapshot(querySnapshot, callback) {
      const result = [];
      querySnapshot.forEach(doc => result.push(doc.data()));
      callback(result);
    },
    listenOrderChanges(headquater = {}, callback) {
      const db = window.firebase.firestore();
      const OrdersRef = db.collection("Orders");
      const pending = OrdersRef.where("belongsTo", "==", headquater.id || "")
        .where("status", "==", "PENDING")
        .onSnapshot(querySnapshot => this.resolveSnapshot(querySnapshot, callback));

      const requested = OrdersRef.where("belongsTo", "==", headquater.id || "")
        .where("status", "==", "REQUESTED")
        .onSnapshot(querySnapshot => this.resolveSnapshot(querySnapshot, callback));
      const subscriptions = [pending, requested];
      return this.orQuery(subscriptions).then(() => subscriptions);
    },
    getTables(headquater = {}, callback) {
      const db = window.firebase.firestore();
      const TablesRef = db.collection("Tables");
      const { id: belongsTo } = headquater;
      return TablesRef.where("belongsTo", "==", belongsTo).onSnapshot(response => {
        this.handleTableSnapshot(response, callback);
      });
    },
    assignOrderToTable(payload = {}) {
      const { table, orders } = payload;
      orders.forEach(order => {
        const hasWaiter = table.currentOrder === order.id;
        if (hasWaiter) table.currentOrder = order;
      });
    },
    assignWaiterToTable(payload = {}) {
      const { waiters = [], table = {} } = payload;
      waiters.forEach(waiter => {
        const hasWaiter = table.activesWaiters[waiter.id] === true;
        if (hasWaiter) table.activesWaiters[waiter.id] = waiter;
      });
    },
    assignClientToTable(payload = {}) {
      const { table = {}, clients = [] } = payload;
      const notAssined = typeof table.currentClient === "string";
      if (notAssined) {
        clients.forEach(client => {
          const hasClient = table.currentClient === client.id;
          if (hasClient) {
            table.currentClient = client;
          }
        });
      }
    },
    getActiveWaiters(tables) {
      return tables.reduce((accumulator, table) => {
        const waiters = Object.keys(table.activesWaiters).filter(waiter => table.activesWaiters[waiter] === true);
        accumulator = [...accumulator, ...waiters];
        return accumulator;
      }, []);
    },
    getClients(tables) {
      return tables.reduce((accumulator, table) => {
        const hasClient = table.hasOwnProperty("currentClient") && table.currentClient !== "" && table.currentClient !== "ANONYMUS";
        if (hasClient && typeof table.currentClient === "string") accumulator.push(table.currentClient);
        return accumulator;
      }, []);
    },
    getOrders(tables) {
      return tables.reduce((accumulator, table) => {
        const hasOrder = table.hasOwnProperty("currentOrder") && table.currentOrder !== "";
        if (hasOrder) {
          accumulator.push(table.currentOrder);
        }
        return accumulator;
      }, []);
    },
    getDataFromCache(batch, type) {
      const result = batch.filter(id => this.batchCache[type].hasOwnProperty(id));
      return result.map(id => this.batchCache[type][id]);
    },
    setResultInCache(result, type) {
      result.forEach(item => {
        this.batchCache[type][item.id] = item;
      });
    },
    removeItemFromCache(type, id) {
      delete this.batchCache[type][id];
    },
    async cacheBatchRequest(type, request, batch) {
      const outOfCache = batch.filter(id => !this.batchCache[type].hasOwnProperty(id));
      if (outOfCache.length === 0) return Promise.resolve(this.getDataFromCache(batch, type));
      const { ok, result = [], error } = await surePromise(request({ batch: outOfCache }));
      if (ok) {
        const inCache = this.getDataFromCache(batch, type);
        this.setResultInCache(result, type);
        const response = [...inCache, ...result];
        return Promise.resolve(response);
      }
      return Promise.reject(error);
    },
    async populateUsers(tables) {
      const activesWaiters = this.getActiveWaiters(tables);
      const clients = this.getClients(tables);
      const batch = [...activesWaiters, ...clients];

      if (batch.length) {
        const { ok, result: users } = await surePromise(this.cacheBatchRequest("users", fetchUserByBatch, batch));
        if (ok) {
          const waiters = [...users];
          const clients = [...users];
          for (const table of tables) {
            this.assignWaiterToTable({ table, waiters });
            this.assignClientToTable({ table, clients });
          }
        }
      }
      return Promise.resolve(tables);
    },
    async populateOrders(tables) {
      const batch = this.getOrders(tables);
      if (batch.length) {
        const { ok, result: orders } = await surePromise(populateOrders({ batch }));
        if (ok) {
          for (const table of tables) this.assignOrderToTable({ table, orders });
        }
      }
      return Promise.resolve(tables);
    },
    async populateTables(tables) {
      await this.populateUsers(tables);
      await this.populateOrders(tables);
    },
    async handleTableSnapshot(querySnapshot, callback) {
      const tables = [];
      querySnapshot.forEach(doc => tables.push(doc.data()));
      await this.populateTables(tables);
      this.$store.dispatch(STORE.ACTIONS.TABLE.TABLES, tables);
      return callback(tables);
    },
    updateCurrentOrder(tables) {
      if (this.currentOrder) {
        const { table = "" } = this.currentOrder;
        const currentTable = tables.find(item => item.id === table);
        if (currentTable && currentTable.status === "FREE") {
          this.currentOrder = {};
        }
      }
      this.tables = [...tables];
      this.loading = false;
    },
    findTableByOrder(order) {
      if (this.tables) {
        this.tables.forEach(async table => {
          const matchOrder = table.currentOrder && table.currentOrder.id === order.id;
          if (matchOrder) {
            table.currentOrder = { ...order };
            const hasUser = table.currentOrder.user && table.currentOrder.user !== "ANONYMUS";
            if (hasUser) {
              const { result } = await surePromise(fetchUser(table.currentOrder.user));
              table.currentOrder.user = result;
              this.currentOrder = table.currentOrder;
            }
          }
        });
      }
    },
    handleOrderChange(orders) {
      orders.forEach(this.findTableByOrder);
    },
    async setSubscription() {
      if (this.tableUnsuscription) this.tableUnsuscription();
      this.tableUnsuscription = this.getTables(this.headquater || {}, tables => this.updateCurrentOrder(tables));
      if (this.orderUnsuscribe) this.orderUnsuscribe();
      const [unSubscribePending, unSubscribeRequests] = await this.listenOrderChanges(this.headquater, orders => this.handleOrderChange(orders));
      this.orderUnsuscribe = () => {
        unSubscribePending();
        unSubscribeRequests();
      };
    },
    async logout() {
      this.removeAllSubscriptions();
      const { error: updateProfileException } = await surePromise(updateProfile({ notifications: {} }));
      if (updateProfileException) {
        this.error = true;
        return;
      }
      const { error } = await surePromise(window.firebase.auth().signOut());
      if (error) this.error = true;
      window.localStorage.clear();
      this.$store.dispatch(STORE.ACTIONS.CART.REMOVE_CART_ITEMS);
      return window.router.push({ name: "login" });
    },
    removeAllSubscriptions() {
      if (this.orderUnsuscribe) this.orderUnsuscribe();
      if (this.tableUnsuscription) this.tableUnsuscription();
      if (this.listenReservations) this.listenReservations();
      if (this.notificationSubscribe) this.notificationSubscribe();
      if (window.unsubscribe) window.unsubscribe();
      if (window.notificationSubscribe) window.notificationSubscribe();
    }
  }
};
