<style scoped lang="scss">
 .disabled {
  pointer-events:none;
  opacity:0.35;
}
.contact-label {
  position: relative;
  top: 17px;
}
.info-tab-contact {
  padding: 0px;
  list-style: none;
   min-width: 315px;
}
.info-tab-contact li {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: #16202f;
  box-shadow: 0px 0px 10px #00000052;
  margin-right: 18px;
  margin-top: 10px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center -10px;
  transition: all 0.25s ease-in-out;
}

.info-tab-contact li{
  transition: all 0.25s ease-in;
}

.info-tab-contact span {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin-right: 10px;
}

.website {
  background-image: url('~@assets/icons/website.svg');
}
 .website:hover {
  background-image: url('~@assets/icons/website-active.svg');
}

.facebook {
  background-image: url('~@assets/icons/fb.svg');
}

.facebook:hover {
  background-image: url('~@assets/icons/fb-active.svg');
}

.whatsapp {
  background-image: url('~@assets/icons/wp.svg');
}
.whatsapp:hover {
  background-image: url('~@assets/icons/wp-active.svg');
}

.instagram {
  background-image: url('~@assets/icons/instagram.svg');
}
 .instagram:hover {
  background-image: url('~@assets/icons/instagram-active.svg');
}

.phone {
  background-image: url('~@assets/icons/phone.svg');
}
.phone:hover {
  background-color: #ED2236;
}
.anchor {
  text-decoration: none;
}

@media (max-width: 350px) {
  .info-tab-contact li {
     margin-right: 11px !important;
  }
}
</style>
<template>
  <article>
    <span class="info-tab-title">Contáctanos</span>
    <ul class="info-tab-contact">
      <a
        :href="`tel:${phone}`"
        class="anchor"
      >
        <li
          :class="{'disabled': !phone }"
          class="phone"
          @click="$emit('selectContact', 'phone')"
        ></li>
      </a>
      <li
        :class="{'disabled': !website}"
        class="website"
        @click="$emit('selectContact', 'website')"
      ></li>

      <a
        :href="`//api.whatsapp.com/send?phone=57${whatsapp}&text=Hola vi tu restaurante en GeoChef`"
        target="_blank"
      >
        <li
          :class="{'disabled': !whatsapp}"
          class="whatsapp"
          @click="$emit('selectContact', 'whatsapp')"
        ></li>
      </a>
      <li
        :class="{'disabled': !facebook}"
        class="facebook"
        @click="$emit('selectContact', 'facebook')"
      ></li>
      <li
        :class="{'disabled': !instagram}"
        class="instagram"
        @click="$emit('selectContact', 'instagram')"
      ></li>
      <p
        v-if="displayPhone"
        class="contact-label"
      >
        {{ phone }}
      </p>
      <p
        v-if="displayWhatsapp"
        class="contact-label"
      >
        {{ whatsapp }}
      </p>
    </ul>
  </article>
</template>
<script>
export default {
  name: 'SocialMediaList',
  props: {
    phone: {
      type: String,
      default: ''
    },
    whatsapp: {
      type: String,
      default: ''
    },
    facebook: {
      type: String,
      default: ''
    },
    instagram: {
      type: String,
      default: ''
    },
    website: {
      type: String,
      default: ''
    },
    showWhatsapp: {
      type: Boolean,
      default: false
    },
    showPhone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { displayWhatsapp: this.showWhatsapp, displayPhone: this.showPhone };
  },
  watch: {
   showWhatsapp(val) {
     console.log('change');
     this.displayWhatsapp = val;
   },
   showPhone(val) {
     this.displayPhone = val;
   }
  }
};
</script>
