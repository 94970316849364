<template>
  <pie-chart :width="width" :height="height" :data="dataset"></pie-chart>
</template>

<script>
export default {
  name: "CircleChart",
  props: {
    width: {
      type: Number,
      default: 450
    },
    height: {
      type: Number,
      default: 190
    },
    dataset: {
      type: Array,
      default: () => []
    }
  }
};
</script>