const calcPromotion = (promotion, base) => {
  let counter = 0;
  const x2 = promotion === 'X2';
  const x3 = promotion === 'X3';
  const x4 = promotion === 'X4';
  for (let i = 0; i < base; i++) {
    if (x2 && i % 2 === 0) counter++;
    if (x3 && i % 3 === 0) counter++;
    if (x4 && i % 4 === 0) counter++;
  }
  return counter;
};

export default calcPromotion;
