const APP = {
    GETTERS: {
      TABLE: {
        GET_TABLE_ORDER: 'table/getTableOrder',
        GET_STATE_MODAL_WAITER: 'table/getStateModal',
        GET_ORDER: 'table/getOrder',
        GET_TABLES: 'table/getTables'
      },
      PROFILE: {
        GET_PROFILE: 'profile/getProfile'
      },
      ALBUMS: {
        GET_ALBUM: 'albums/getAlbumsGetter',
        GET_PANORAMICS: 'albums/getPanoramicsGetter',
        GET_RENDER_PANRAMICS: 'albums/renderPanoramicsGetter',
        GET_STATUS_SHOW: 'albums/statusShowFileUpdateGetter',
        GET_DATA_FILE_UPDATE: 'albums/getDataFileUpdateGetter'
      },
      UTILS_INTERFACE: {
        GET_LOADING_STATUS: 'utilsInterface/loadingStatusGetter',
        GET_TOAST_STATUS: 'utilsInterface/toastStatusGetter'
      },
      RESERVATION_DETAIL: {
        GET_RESERVATION: 'reservationDetail/getReservation'
      },
      CART: {
        GET_CART: 'cart/get_cart',
        SHOW_CART: 'cart/show_cart'
      }
    },
    MUTATIONS: {
      TABLE: {
        SET_TABLE_ORDER: 'table/getTableOrder',
        SET_NEW_ORDER: 'table/setNewOrder',
        SET_STATE_MODAL_WAITER: 'table/setStateModalAssignWaiter',
        SET_TABLES: 'tables/setTables'
      },
      PROFILE: {
        SET_PROFILE: 'profile/setProfile'
      },
      ALBUMS: {
        SET_ALBUMS: 'albums/setDataAlbumsMutation',
        SET_PANORAMICS: 'albums/setDataPanoramicsMutation',
        SET_RENDER_PANORAMICS: 'albums/renderPanoramicsMutation',
        SET_STATUS_PANORAMICS: 'albums/statusMediaManagerPanoramicsMutation',
        SET_SHOW_FILE_UPDATE: 'albums/managerShowFileUpdateMutation',
        SET_DATA_FILE_UPDATE: 'albums/setDataFileUpdateMutation'
      },
      UTILS_INTERFACE: {
        SET_LOADING_STATUS: 'utilsInterface/loadingStatusMutation',
        SET_TOAST_STATUS: 'utilsInterface/toastStatusMutation'
      },
      RESERVATION_DETAIL: {
        SET_RESERVATION: 'reservationDetail/updateReservationState',
        SET_DETAIL_NAME: 'reservationDetail/setDetailName',
        SET_DETAIL_NOTE: 'reservationDetail/setDetailNote',
        SET_DETAIL_PHONE: 'reservationDetail/setDetailPhone',
        SET_DETAIL_TIMESTAMP: 'reservationDetail/setDetailTimestamp'
      }
   },
    ACTIONS: {
      TABLE: {
        TABLES: 'table/tables',
        TABLE_ORDER: 'table/setTableOrder',
        NEW_ORDER: 'table/newOrder'
      },
      ALBUMS: {
        DATA_ALBUM: 'albums/setDataAlbumsAction',
        PICTURES: 'albums/getPictures',
        DELETE_ALBUM: 'albums/deleteAlbumAction',
        DATA_PANORAMIC: 'albums/setDataPanoramicsAction',
        RENDER_PANORAMICS: 'albums/renderPanoramicsAction',
        SHOW_FILE_UPDATE: 'albums/managerShowFileUpdateAction',
        DATA_FILE_UPDATE: 'albums/setDataFileUpdateAction'
      },
      UTILS_INTERFACE: {
        LOADING_STATUS: 'utilsInterface/loadingStatusAction',
        TOAST_STATUS: 'utilsInterface/toastStatusAction'
      },
      CART: {
        ADD_TO_CART: 'cart/add_to_cart',
        REMOVE_CART_ITEM: 'cart/remove_cart_item',
        REMOVE_CART_ITEMS: 'cart/remove_cart_items',
        UPDATE_CART: 'cart/update_cart',
        OPEN_CART: 'cart/open_cart',
        CLOSE_CART: 'cart/close_cart',
        OPEN_MENU: 'cart/open_menu',
        CLOSE_MENU: 'cart/close_menu',
        OPEN_SUCCESS_ORDER: 'cart/show_success_order',
        CLOSE_SUCCESS_ORDER: 'cart/close_success_order'
      },
      YOUR_ORDER: {
        OPEN_YOUR_ORDER: 'yourOrder/open_your_order',
        CLOSE_YOUR_ORDER: 'yourOrder/close_your_order'
      }
   }
};

export default APP;
