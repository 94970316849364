export default {
  name: "DateManager",
  methods: {
    sameMonth(a, b) {
      return a.getMonth() === b.getMonth();
    },
    sameYear(a, b) {
      return a.getFullYear() === b.getFullYear();
    },
    generateIsoDate(defaultDate) {
      let date = new Date();
      if (defaultDate) date = new Date(defaultDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}-${this.fixPrefixDigit(month)}-${this.fixPrefixDigit(day)}`;
    },
    fixPrefixDigit(digit) {
      if (Number(digit) <= 9) return `0${digit}`;
      return digit;
    },
    addDay(date, day) {
      date.setDate(date.getDate() + day);
      return date;
    },
    subtractDay(date, day) {
      date.setDate(date.getDate() - day);
      return date;
    }
  }
};
