<style lang="scss">
.form__field-caption {
  color: white;
  font-family: Futura;
  font-size: 16px;
  margin-left: 2px;
  margin-bottom: 8px;
}
.update-user-form-container {
  margin-top: 20px;
}

.update-user-form-container .v-snack__content {
  height: 60px;
}

.update-user-form-container .v-btn__content {
  font-weight: 700;
  font-family: Gibson;
  font-size: 13px;
}

.update-user-form-container .btn-submit {
  text-align: center;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  margin-left: 0px;
  margin-bottom: 30px;
}
.submit-container {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
<template>
  <v-container class="update-user-form-container">
    <v-form>
      <v-flex xs12 md6 class="mx-auto form">
        <BasicSwitcher
          ref="notificationSwitch"
          :active="activeNotification"
          caption="Notificaciones"
        ></BasicSwitcher>

        <p class="form__field-caption">Nombre</p>
        <BasicInput
          :default-value="name"
          class="input-name"
          :label="$t('lang.Profile.fullnameField')"
          :valid="validity.name"
          styl
          :msg="nameMsg"
          :overlay="true"
          @input-change="handleFieldChange('name', $event)"
        ></BasicInput>

        <p class="form__field-caption">{{ $t('lang.Profile.phoneNumberField') }}</p>
        <BasicInput
          ref="phone"
          :default-value="phone"
          class="input-phone"
          :label="$t('lang.Profile.phoneNumberField')"
          :valid="validity.phone"
          styl
          :msg="phoneMsg"
          :overlay="true"
          @input-change="handleFieldChange('phone', $event)"
        ></BasicInput>

        <p class="form__field-caption">{{ $t('lang.Profile.emailField') }}</p>
        <BasicInput
          :default-value="email"
          class="input-email"
          :label="`*${$t('lang.Profile.emailField')}`"
          :valid="validity.email"
          styl
          :msg="emailMsg"
          :overlay="true"
          @input-change="handleFieldChange('email', $event) "
        ></BasicInput>

        <p class="form__field-caption">{{ $t('lang.Profile.passwordField') }}</p>
        <BasicInput
          :default-value="password"
          class="input-password"
          type="password"
          :label="`*${$t('lang.Profile.passwordField')}`"
          :valid="validity.password"
          styl
          :msg="passwordMsg"
          :overlay="true"
          @input-change="handleFieldChange('password', $event)"
        ></BasicInput>

        <p class="form__field-caption">Idioma</p>
        <LanguageSelect ref="languageSelect"></LanguageSelect>

        <v-container class="text-xs-center submit-container">
          <v-btn
            v-if="loading === false"
            color="primary"
            class="btn-submit"
            @click="event => handleSubmit(event)"
          >{{ $t('lang.Profile.updateProfile') }}</v-btn>

          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </v-container>
      </v-flex>
    </v-form>

    <v-snackbar
      v-model="success"
      :bottom="true"
      :left="true"
      :timeout="8000"
      color="success"
    >{{ $t('lang.Toast.success') }}</v-snackbar>

    <v-snackbar
      v-model="error"
      :bottom="true"
      :left="true"
      :timeout="5000"
      color="error"
    >{{ $t('lang.Toast.error') }}</v-snackbar>
  </v-container>
</template>

<script>
import BasicInput from "@inputs/BasicInput";
import validators from "@utils/validators";
import LanguageSelect from "@components/selects/LanguageSelect";
import BasicSwitcher from "@components/switchers/BasicSwitcher.vue";
import requests from "@/helpers/requests";

const { updateProfile } = requests;
const { validEmail, validPassword, validName, validCellPhone } = validators;

export default {
  name: "UpdateProfileForm",
  components: { BasicInput, LanguageSelect, BasicSwitcher },
  props: {
    showPassword: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Object,
      default: () => ({})
    },
    isWaiter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { email = "", name = "", phone = "", activeNotification } = this.profile;
    return {
      validity: {
        email: Boolean(email),
        phone: Boolean(phone),
        name: Boolean(name),
        password: false
      },
      email,
      name,
      phone,
      activeNotification,
      password: "",
      passwordMsg: "Escribe minimo 6 caracteres maximo 20, sin espacios",
      emailMsg: "Escribe una direccion de correo electrónico valida",
      nameMsg: "Escribe minimo 3 caracteres maximo 50",
      phoneMsg: "Escribe tu numero de celular",
      success: false,
      error: false,
      loading: false
    };
  },
  methods: {
    handleUpdateSuccess(response) {
      if (response.ok) {
        const data = this.getDifference();
        Object.keys(data).forEach(key => {
          this.profile[key] = this[key];
        });
        if (data.lang) {
          window.user.lang = data.lang;
          this.notifyLanguageChange(data.lang);
        }
        if (data.activeNotification) {
          this.profile.activeNotification = this.$refs.notificationSwitch.switcher;
          window.user.activeNotification = this.$refs.notificationSwitch.switcher;
        }
        this.$emit("update", { ...data });
        this.success = true;
      } else if (response.duplicatePhone) {
        this.validity.phone = false;
        this.phoneMsg = "Este numero ya existe, intenta con otro";
      } else if (response.duplicateEmail) {
        this.validity.email = false;
        this.emailMsg = "Este correo electronico ya existe, intenta con otro";
      }
      this.loading = false;
    },
    async handleUpdateError(response) {
      this.loading = false;
      const error = await response;
      const hasCode = error && error.hasOwnProperty("code");
      if (hasCode) {
        const duplicatePhone = error.code === "duplicatePhone";
        const duplicateEmail = error.code === "duplicateEmail";
        if (duplicatePhone) {
          this.validity.phone = false;
          this.phoneMsg = "Este numero ya existe";
        }
        if (duplicateEmail) {
          this.validity.email = false;
          this.emailMsg = "Este correo electronico ya existe, intenta con otro";
        }
        return;
      }
      this.error = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      const data = this.getDifference();
      const difference = Boolean(Object.keys(data || {}).length);
      if (difference) {
        this.loading = true;
        updateProfile(data)
          .then(this.handleUpdateSuccess)
          .catch(this.handleUpdateError);
      }
    },
    getDifference() {
      const result = {};
      const { languageSelect } = this.$refs;
      const valids = Object.keys(this.validity).filter(key => this.validity[key]);
      valids.forEach(item => {
        const hasDifference = this[item] !== this.profile[item];
        if (hasDifference) result[item] = this[item];
      });
      const lang = languageSelect.getLanguageSelected();
      if (lang.code !== window.user.lang) result.lang = lang.code;
      const notificationChange = this.profile.activeNotification !== this.$refs.notificationSwitch.switcher;
      if (notificationChange) result.activeNotification = this.$refs.notificationSwitch.switcher;
      return result;
    },
    handleFieldChange(field, value) {
      const isName = field === "name";
      const isPhone = field === "phone";
      const isPassword = field === "password";
      const isEmail = field === "email";
      if (isName) {
        this.validity.name = validName(value);
        this.name = value;
      }

      if (isPhone) {
        this.validity.phone = validCellPhone(value);
        this.phone = value;
        this.phoneMsg = "Escribe tu numero de celular";
      }

      if (isEmail) {
        this.validity.email = validEmail(value);
        this.email = value;
        this.emailMsg = "Escribe una direccion de correo electrónico valida";
      }
      if (isPassword) {
        this.validity.password = validPassword(value);
        this.password = value;
      }
    }
  }
};
</script>
