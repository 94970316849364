<style>
.map-container__content .cards__container-card {
  background: rgba(0, 0, 0, 0.5);
}
.map-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
  transition: all 0.5s;
  z-index: 10000;
}

.map-container__content {
  height: 206px;
  position: relative;
  overflow: hidden;
  padding: 20px;
  padding-left: 5px;
  padding-top: 30px;
  color: white;
  line-height: 1.4;
}
.map-container__content-title {
  padding-bottom: 10px;
  font-size: 22px;
}
.resturant-map {
  background-color: white;
  width: calc(100% - 30px);
  height: 400px;
  bottom: 11px;
  border-radius: 10px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
 @media (max-width: 959px) {
  .resturant-map {
    height: 250px;
    width: 100%;
  }
}
</style>

<template>
  <div>
    <div class="map-container__content">
      <span class="map-container__content-title">{{ title }}</span>
      <Carrousel
        :list="headquaters"
        @select="handleSelectRestaurant"
      ></Carrousel>
    </div>
    <gmap-map
      ref="map"
      :center="center"
      :zoom="14"
      :options="mapOptions"
      class="resturant-map"
    >
      <gmap-marker
        :animation="2"
        :icon="require('@/assets/icons/marker-now.svg')"
        :position="center"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import Carrousel from '@lists/Carrousel.vue';
import mapStyle from '@/utils/mapStyle.json';

export default {
  name: 'RouteMap',
  components: { Carrousel },
  props: {
   lng: {
     type: Number,
     default: 0
   },
   lat: {
      type: Number,
      default: 0
   },
   title: {
     type: String,
     default: ''
   },
   center: {
     type: Object,
     default: () => ({})
   },
   headquaters: {
     type: Array,
     default: () => ([])
   }
  },
 data() {
    const mapOptions = {
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
      scaleControl: true,
      styles: mapStyle
    };
    return { mapOptions, queue: [] };
  },
  mounted() {
    const IntervalId = setInterval(() => {
       if (window.google) {
        for (let i = this.queue.length - 1; i >= 0; i--) {
          this.getRoute();
          this.queue.pop();
        }
        clearInterval(IntervalId);
      }
    }, 1000);
 },
  methods: {
   handleSelectRestaurant(item) {
     this.$emit('select', item);
    },
    getRoute() {
      if (!window.google) return this.queue.push(this.queue.length + 1);
      this.directionsService = new window.google.maps.DirectionsService();
      if (!this.directionsDisplay) {
        this.directionsDisplay = new window.google.maps.DirectionsRenderer();
      }
      if (this.$refs.map) this.directionsDisplay.setMap(this.$refs.map.$mapObject);
      const vm = this;
      const origin = { lat: this.lat, lng: this.lng };
      const destination = { ...this.center };
      vm.directionsService.route({
        origin,
        destination,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          vm.directionsDisplay.setDirections(response);
        } else {
          console.log(`Directions request failed due to ${status}`);
        }
      });
    }
  }
};

</script>
