<template src="./template.html"></template>
<style scoped lang="scss">
@import "./style.scss";
</style>

<script>
import CategoriesList from "@lists/CategoriesList";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
const { linkTables, timeout } = requests;

export default {
  name: "JoinTableModal",
  components: {
    CategoriesList
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    table: {
      type: Object,
      default: () => ({})
    },
    tables: {
      type: Array,
      default: () => []
    },
    headquater: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    open(val) {
      const isClose = val === false;
      if (isClose) {
        this.resetState();
      } else {
        this.data = this.tables.map(table => ({ ...table }));
      }
      this.display = val;
    },
    tables(val) {
      this.data = val;
    }
  },
  data() {
    return {
      display: this.open,
      data: [],
      loading: false
    };
  },
  created() {
    this.data = this.tables.map(table => ({ ...table }));
  },
  methods: {
    resetState() {
      Object.assign(this.$data, this.$options.data());
    },
    findAlreadyLinked(selectes = []) {
      const result = {};
      selectes.forEach(table => {
        const hasLinks = table.hasOwnProperty("links") && Object.keys(table.links).length !== 0;
        if (hasLinks) {
          Object.keys(table.links).forEach(link => {
            result[link] = true;
          });
        }
      });
      return Object.keys(result);
    },
    removeRepeats(tables = []) {
      const result = {};
      tables.forEach(table => {
        result[table] = true;
      });
      return Object.keys(result);
    },
    async handleJoin() {
      this.loading = true;
      const main = this.table.id;
      const selectes = this.getSelectedData();
      const links = this.removeRepeats([...this.getIds(selectes)]).filter(id => id !== main);
      const { id: headquater = "" } = this.headquater;
      this.$emit("close");
      this.$emit("loading", true);
      const { ok } = await surePromise(linkTables({ headquater, links, main }));
      this.loading = false;
      this.$emit("loading", false);
      // this.$emit("close");
      if (ok) return this.$emit("success", { links, main: this.table });
      return this.$emit("error");
    },
    getIds(tables = []) {
      return tables.map(table => table.id);
    },
    getSelectedData() {
      return this.data.filter(table => table.selected);
    }
  },
  computed: {
    noneSelected() {
      return this.data.every(table => table.selected === false);
    }
  }
};
</script>