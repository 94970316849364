<style scoped lang="scss">
.container-cancel-description {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
.btn-send {
  border-radius: 10px;
}
</style>

<template>
  <v-dialog
    v-model="open"
    color="white"
    persistent
    max-width="400"
  >
    <div
      color="white"
      class="container-cancel-description"
    >
      <v-textarea
        v-if="loading === false"
        v-model="message"
        outline
        height="200"
        label="Escribe la razon de la cancelacion"
        :rules="[validateMessage]"
      ></v-textarea>
      <div
        v-if="loading"
        class="wrap-loading text-xs-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-card-actions
        v-if="loading === false"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          flat="flat"
          @click="handleCancel"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          class="btn-send"
          :disabled="!isValid"
          @click="handleSend"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </div>
  </v-dialog>
</template>

<script>
import validators from '@/utils/validators.js';

export default {
  name: 'CancelDescriptionModal',
  props: {
   display: {
     type: Boolean,
     default: false
   }
  },
  data() {
    return {
      loading: false,
      open: this.display,
      message: '',
      isValid: false
    };
  },
  watch: {
    display(val) {
      this.open = val;
    }
  },
  methods: {
   validateMessage() {
     this.isValid = validators.validText(this.message);
     const fail = this.isValid === false;
     if (fail) return 'Escribe un mensaje valido';
     return '';
   },
    handleCancel() {
      this.$emit('cancel');
      this.message = '';
      this.isValid = false;
    },
    handleSend() {
      this.$emit('confirm', this.message);
      this.message = '';
      this.isValid = false;
    }
  }
};
</script>
