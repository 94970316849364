const state = {
  loadingStatus: false,
  toastStatus: {
    show: false,
    status: '',
    message: ''
  }
};

export default state;
