<style lang="scss">
//@import url("/qr.css");
.wrap-profile .v-image__image {
  background-size: contain !important;
  background-position: center !important;
  background-color: #cccccc !important;
}
.wrap-profile {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.profile-container {
  margin: 0px auto;
}
.v-responsive__content {
  position: relative;
  .profile-container__wrap-info {
    bottom: 5px;
    padding: 30px;
    position: absolute;
    display: block;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 20px;
  }
}
.profile-container__wrap-info-points,
.profile-container__wrap-info-email {
  color: white;
  font-size: 17px;
  margin-bottom: 10px;
  text-shadow: 0px 0px 10px #0000006b;
}
.profile-container__wrap-info-name {
  font-size: 33px;
  font-weight: bold;
  color: white;
  text-shadow: 0px 0px 10px #00000070;
  margin-bottom: 5px;
}
.profile-container__wrap-back-icon {
  position: absolute;
  top: 17px;
  left: 17px;
  .back-icon {
    position: relative;
    right: 14px;
  }
}

.btn-qr {
  float: right;
  position: relative;
  right: 10px;
  top: 20px;
}

.qr-figure {
  font-size: 35px;
  color: #333 !important;
}

@media (max-width: 500px) {
  .wrap-profile .v-image__image {
    background-size: cover !important;
  }
}
</style>

<template>
  <div fluid grid-list-sm class="wrap-profile">
    <v-flex xs12 md10 sm12 class="profile-container">
      <v-img
        class="profile-container--image"
        position="center center"
        :height="322"
        :src="img"
        gradient="to bottom, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.5) 68% 20%"
      >
        <template v-slot:placeholder>
          <v-layout fill-height align-center justify-center ma-0>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-layout>
        </template>
        <section class="profile-container__wrap-back-icon">
          <v-btn
            v-if="onPWA === false"
            flat
            icon
            class="back-icon"
            color="white"
            @click="handleBack"
          >
            <v-icon>keyboard_backspace</v-icon>
          </v-btn>
        </section>

        <section class="profile-container__wrap-qr-icon">
          <v-btn flat icon class="btn-qr" @click="handleDisplayQr" color="primary">
            <span class="qr-figure"></span>
          </v-btn>
        </section>

        <section class="profile-container__wrap-info">
          <p class="profile-container__wrap-info-name">
            <v-clamp autoresize :max-lines="2">{{ name }}</v-clamp>
          </p>
          <!-- <p
            class="profile-container__wrap-info-points"
          >{{ points }} {{ $t('lang.Profile.points') }}</p> -->
          <p class="profile-container__wrap-info-email">{{ email }}</p>
        </section>
      </v-img>
    </v-flex>

    <QrModal
      v-if="id"
      :no-download="true"
      :display="showQR"
      :url="`${id}#geochef`"
      :name="name"
      @close="showQR = false"
    ></QrModal>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import QrModal from "@components/modals/QrModal";
import anonymus from "@assets/icons/anonymus.svg";
import background from "@/assets/icons/anonymus.svg";
import onPWA from "@/utils/onPWA.js";

export default {
  name: "ProfileHeader",
  components: {
    QrModal,
    VClamp
  },
  props: {
    preventDefaultBack: {
      type: Boolean,
      default: false
    },
    notificationId: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    points: {
      type: Number,
      default: 0
    }
  },
  data() {
    const img = this.image === "" ? anonymus : this.image;
    return { img, onPWA: onPWA(), showQR: false };
  },
  watch: {
    image(val) {
      if (val === "") {
        this.img = anonymus;
      } else {
        this.img = val;
      }
    }
  },
  mounted() {
    if (this.img === "") this.img = background;
  },
  methods: {
    handleBack() {
      if (this.preventDefaultBack) return this.$emit("onGoBack");
      return window.router.push({ name: "dashboard" });
    },
    handleDisplayQr() {
      this.showQR = true;
    }
  }
};
</script>
