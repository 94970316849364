<style scoped lang="scss">
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
.v-dialog__content--active {
  z-index: 20001 !important;
  background: #16202f;
}
.content-qr {
  padding-top: 80px;
}
.content-qr__title {
  text-align: center;
  font-weight: 100;
  font-size: 15px;
  color: white;
}
.content-qr__video {
  max-width: 500px;

  position: relative;
  width: calc(100% - 50px);
  margin: 0px auto;
  margin-top: 45px;
}
.v-progress-circular {
  height: 32px;
  width: 32px;
  position: absolute;
  top: calc(50% - 32px / 2);
  left: calc(50% - 32px / 2);
}
#qrcode {
  max-height: 350px !important;
  overflow: hidden !important;
}
.pull-up {
  padding-top: 50px;
}
.content-qr__error {
  max-width: 500px;
  position: relative;
  margin: 0px auto;
  text-align: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.camera-corners {
  width: 60px;
  height: 60px;
  position: absolute;
}
.content-qr__video-coner-left {
  left: -4px;
  top: -4px;
  border-left: 4px solid rgb(239, 79, 53);
  border-top: 4px solid rgb(239, 79, 53);
}

.content-qr__video-coner-right {
  right: -4px;
  top: -4px;
  border-right: 3px solid rgb(239, 79, 53);
  border-top: 3px solid rgb(239, 79, 53);
}
.content-qr__video-coner-bottom-right {
  bottom: -4px;
  border-bottom: 3px solid #ed2236;
  border-right: 3px solid #ed2236;
  right: -4px;
}

.content-qr__video-coner-bottom-left {
  bottom: -4px;
  left: -4px;
  border-bottom: 3px solid rgb(239, 79, 53);
  border-left: 3px solid rgb(239, 79, 53);
}

.hide-mask {
  opacity: 0;
}

.btn-close {
  background-image: url(~@assets/icons/arrow-left.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 8px;
  left: 2px;
}
</style>

<template>
  <v-dialog v-model="display" fullscreen transition="dialog-bottom-transition" persistent>
    <div class="content-qr" :class="{'pull-up': noBack}">
      <v-btn v-if="noBack === false" class="btn-close" icon @click="$emit('close')"></v-btn>
      <h1 v-if="error == false" class="content-qr__title">{{ $t('lang.QRcode.scanDescription') }}</h1>

      <div v-if="msgError" class="content-qr__error">{{ msgError }}</div>
      <div v-show="error === false" class="content-qr__video">
        <div
          class="camera-corners content-qr__video-coner-left"
          :style="{'border-color': (isValid ? 'green': '#ED2236') }"
        ></div>
        <div
          class="camera-corners content-qr__video-coner-right"
          :style="{'border-color': (isValid ? 'green': '#ED2236') }"
        ></div>
        <div
          class="camera-corners content-qr__video-coner-bottom-left"
          :style="{'border-color': (isValid ? 'green': '#ED2236') }"
        ></div>
        <div
          class="camera-corners content-qr__video-coner-bottom-right"
          :style="{'border-color': (isValid ? 'green': '#ED2236') }"
        ></div>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <qrcode-stream id="qrcode" :camera="camera" @decode="onDecode" @init="onInit">
          <div v-if="validationSuccess" class="validation-success">{{ $t('lang.QRcode.ready') }}</div>
          <div v-if="validationFailure" class="validation-failure">{{ $t('lang.QRcode.invalidQR') }}</div>
          <div v-if="validationPending" class="validation-pending">{{ $t('lang.QRcode.scaning') }}</div>
        </qrcode-stream>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "ReadQR",
  components: { QrcodeStream },
  props: {
    noBack: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: this.open,
      justMounted: false,
      data: null,
      loading: false,
      error: false,
      msgError: "",
      isValid: undefined,
      camera: "off",
      result: null
    };
  },
  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },
    validationSuccess() {
      return this.isValid === true;
    },
    validationFailure() {
      return this.isValid === false;
    }
  },
  watch: {
    isValid(val) {
      if (val) {
        this.$emit("data", this.result);
      }
    },
    open(val) {
      this.display = val;
      if (val) {
        this.loading = true;
        this.turnCameraOn();
      } else {
        this.turnCameraOff();
        this.loading = false;
      }
    }
  },
  mounted() {
    this.justMounted = true;
  },
  methods: {
    onInit(promise) {
      promise
        .then(() => {
          this.error = false;
          this.msgError = false;
          this.loading = false;
          this.resetValidationState();
        })
        .catch(error => {
          this.error = true;
          this.loading = false;
          if (error.name === "NotAllowedError") {
            console.log("user denied camera access permisson");
            this.msgError = "Es necesario que concedas permisos a la camara del dispositivo";
          } else if (error.name === "NotFoundError") {
            console.log("no suitable camera device installed");
            this.msgError = "No se encontro camera disponible";
          } else if (error.name === "NotSupportedError") {
            console.log("page is not served over HTTPS (or localhost)");
          } else if (error.name === "NotReadableError") {
            console.log("maybe camera is already in use");
            this.msgError = "La camara esta siendo utilizada por otra aplicacion";
          } else if (error.name === "OverconstrainedError") {
            console.log("did you requested the front camera although there is none?");
          } else if (error.name === "StreamApiNotSupportedError") {
            console.log("browser seems to be lacking features");
            this.msgError = "Tu dispositivo no soporta esta caracteristica";
          } else {
            this.msgError = "Ha ocurrido un error interno en la aplicacion.";
          }
        });
    },
    resetValidationState() {
      this.isValid = undefined;
    },
    async onDecode(content = "") {
      console.log('qr lectura', content);
      this.result = content;
      this.isValid = content.includes("?table");
      await this.timeout(window.router.currentRoute.hash);
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    turnCameraOff() {
      this.camera = "off";
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms);
      });
    },
    validData() {
      return true;
    }
  }
};
</script>
