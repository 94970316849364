<style></style>
<template>
  <form
    ref="payuForm"
    method="post"
    action="https://checkout.payulatam.com/ppp-web-gateway-payu/"
  >
    <input
      name="merchantId"
      type="hidden"
      :value="merchantId"
    >
    <input
      name="accountId"
      type="hidden"
      :value="accountId"
    >
    <input
      name="description"
      type="hidden"
      value="Factura del restaurante"
    >
    <input
      name="referenceCode"
      type="hidden"
      :value="referenceCode"
    >
    <input
      name="amount"
      type="hidden"
      :value="amount"
    >
    <input
      name="tax"
      type="hidden"
      :value="tax"
    >
    <input
      name="taxReturnBase"
      type="hidden"
      :value="taxReturnBase"
    >
    <input
      name="currency"
      type="hidden"
      value="COP"
    >
    <input
      name="signature"
      type="hidden"
      :value="signature"
    >
    <input
      name="test"
      type="hidden"
      :value="test"
    >
    <input
      name="buyerEmail"
      type="hidden"
      :value="buyerEmail"
    >
    <input
      name="responseUrl"
      type="hidden"
      :value="responseUrl"
    >
    <input
      name="confirmationUrl"
      type="hidden"
      :value="confirmationUrl"
    >
  </form>
</template>
<script>
import md5 from 'js-md5';

export default {
  name: 'PayuForm',
  props: {
    apiKey: {
      type: String,
      default: ''
    },
    accountId: {
      type: Number,
      default: 0
    },
    merchantId: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 0
    },
    buyerEmail: {
      type: String,
      default: ''
    },
    responseUrl: {
      type: String,
      default: ''
    },
   confirmationUrl: {
     type: String,
     default: ''
   },
   test: {
      type: Number,
      default: 1
    },
   referenceCode: {
     type: String,
     default: ''
   },
   tax: {
      type: Number,
      default: 0
   },
   taxReturnBase: {
      type: Number,
      default: 0
    }
  },
  data() {
    const signature = this.generateSignature();
    return { signature };
  },
  methods: {
    send() {
      const self = this;
      this.signature = this.generateSignature();
      setTimeout(() => {
        self.$refs.payuForm.submit();
      }, 100);
    },
    generateSignature() {
     const {
       apiKey, merchantId, referenceCode, amount
     } = this;
     console.log(`${apiKey}~${merchantId}~${referenceCode}~${amount}~COP`);
     return md5(`${apiKey}~${merchantId}~${referenceCode}~${amount}~COP`);
    }
  }
};

</script>
