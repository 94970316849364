<style scoped lang="scss">
    @import "style";
</style>

<template src="./template.html"></template>

<script>

  export default {
    name: "ShareAccount",
    components: { },
    props: {
      title: {
        type: String,
        default: 'COMPARTIR CUENTA'
      },
      setOrderType: {
        type: Function,
        default: () => {}
      }
    },
    filters: {

    },
    computed: {

    },
    data() {
      return {
        orderTypeTabs: 'domicilio'
      };
    },
    created() {
      this.setOrderType(this.orderTypeTabs);
    },
    mounted() {

    },
    methods: {
      setOrderTypeTab(type) {
        this.orderTypeTabs = type;
        this.setOrderType(this.orderTypeTabs);
      }
    },
    watch: {

    }
  };
</script>
