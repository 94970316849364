import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "Comments",
  props: {
    comments: {
      type: Array,
      default: () => [
        {
          enable: false,
          user: {
            email: "",
            id: "",
            name: "",
            photoURL: ""
          },
          avatar: "",
          text: "",
          points: 0,
          id: "",
          headquater: "",
          createAt: {
            _seconds: 0,
            _nanoseconds: 0
          }
        }
      ]
    },
    styles: {
      type: String,
      default: "black"
    }
  },
  components: {
    StarList: () => import("../../lists/StarList")
  },
  computed: {
    creteAt() {
      return this.comments.map(comment => moment(comment.createAt._seconds * 1000).fromNow());
    }
  }
});
