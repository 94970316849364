import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';
import Vuetify from "vuetify";
import theme from "../theme";
import searchMenu from "../components/icons/menu/menu";
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';

Vue.component('search-menu', searchMenu);

Vue.use(Vuetify, {
  theme,
  iconfont: 'mdi',
  icons: {
    iconfont: 'mdi',
    values: {
      menuSearch: {
        component: searchMenu
      }
    }
  }
});
// Vue.use(Vuetify);


