<style scoped lang="scss">
.time-schedule-container__wrap-picker {
  border-left: 1px solid #16202f;
  padding-left: 10px;
  margin-bottom: 5px;
}
::v-deep .v-input--switch {
  margin-top: 0px;
  padding-top: 0px;
  height: 40px;
}
::v-deep .confirm-container__picker-input {
  padding-top: 0px;
  position: relative;
  bottom: 0px;
  margin-bottom: 20px;
}
.time-schedule-container {
  padding-top: 5px;
  margin-bottom: 10px;
}
.time-schedule-container__daytime {
  color: #ef4f34;
  margin-bottom: 0px;
  margin-top: 9px;
  margin-left: 10px;
  position: relative;
  top: 5px;
}
.time-schedule-container {
  &__title {
    color: #16202f;
    font-family: Gibson-Bold;
    font-size: 17px;
    padding-left: 10px;
    margin-bottom: 10px;
  }
}
</style>

<template>
  <div class="time-schedule-container">
    <PickerTimeForm
      ref="morningPicker"
      :key="`${day}-morning`"
      label="Mañana"
      :open-min-time="morning.minTime"
      :open-max-time="morning.maxTime"
      :open-value="morning.value"
      :close-max-time="morning.maxTime"
      :close-value="morning.maxCloseTime"
      :display="displayByDayTime('morning')"
      :disable="disable"
      @change-open="(value) => handleChangeOpen('morning', value)"
    ></PickerTimeForm>

    <PickerTimeForm
      ref="afternoonPicker"
      :key="`${day}-afternoon`"
      label="Tarde"
      :open-min-time="afternoon.minTime"
      :open-max-time="afternoon.maxTime"
      :open-value="afternoon.value"
      :close-max-time="afternoon.maxTime"
      :close-value="afternoon.maxCloseTime"
      :display="displayByDayTime('afternoon')"
      :disable="disable"
      @change-open="(value) => handleChangeOpen('afternoon', value)"
    ></PickerTimeForm>

    <PickerTimeForm
      ref="eveningPicker"
      :key="`${day}-evening`"
      label="Noche"
      :open-min-time="evening.minTime"
      :open-max-time="evening.maxTime"
      :open-value="evening.value"
      :close-max-time="evening.maxTime"
      :close-value="evening.maxCloseTime"
      :display="displayByDayTime('evening')"
      :disable="disable"
      @change-open="(value) => handleChangeOpen('evening', value)"
    ></PickerTimeForm>
  </div>
</template>

<script>
import PickerTimeForm from "@components/forms/PickerTimeForm";

export default {
  name: "TimeSchedule",
  components: {
    PickerTimeForm
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    day: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const { data = {}, day } = this;
    const {
      morning = {
        from: "6:00am",
        to: "12:00pm"
      },
      afternoon = {
        from: "12:00pm",
        to: "18:00pm"
      },
      evening = {
        from: "6:00pm",
        to: "11:59pm"
      }
    } = data[day] || {};

    return {
      morning: {
        minTime: "6:00am",
        maxTime: "12:00pm",
        maxCloseTime: morning.to,
        value: morning.from
      },
      afternoon: {
        minTime: "12:00pm",
        maxTime: "6:00pm",
        maxCloseTime: afternoon.to,
        value: afternoon.from
      },
      evening: {
        minTime: "6:00pm",
        maxTime: "11:59pm",
        maxCloseTime: evening.to,
        value: evening.from
      }
    };
  },
  watch: {
    day(val) {
      const { data = {} } = this;
      const {
        morning = {
          from: "7:00am",
          to: "12:00pm"
        },
        afternoon = {
          from: "12:00pm",
          to: "18:00pm"
        },
        evening = {
          from: "6:00pm",
          to: "11:59pm"
        }
      } = data[val] || {};

      this.morning = {
        minTime: "6:00am",
        maxTime: "12:00pm",
        maxCloseTime: morning.to,
        value: morning.from
      };

      this.afternoon = {
        minTime: "12:00pm",
        maxTime: "6:00pm",
        maxCloseTime: afternoon.to,
        value: afternoon.from
      };

      this.evening = {
        minTime: "6:00pm",
        maxTime: "11:59pm",
        maxCloseTime: evening.to,
        value: evening.from
      };
    }
  },
  methods: {
    displayByDayTime(daytime = "") {
      const { data, day } = this;
      return (
        data.hasOwnProperty(day) === true && data[day].hasOwnProperty(daytime)
      );
    },
    getData() {
      const result = {};
      const { morningPicker, afternoonPicker, eveningPicker } = this.$refs;
      const morningData = morningPicker.getData();
      const afternoonData = afternoonPicker.getData();
      const eveningData = eveningPicker.getData();
      if (morningData.active === true) {
        result.morning = {
          to: morningData.to,
          from: morningData.from
        };
      }
      if (afternoonData.active === true) {
        result.afternoon = {
          to: afternoonData.to,
          from: afternoonData.from
        };
      }
      if (eveningData.active === true) {
        result.evening = {
          to: eveningData.to,
          from: eveningData.from
        };
      }
      return result;
    },
    handleChangeOpen(type, value) {
      if (type === "morning") this.morning.value = value;
      if (type === "afternoon") this.afternoon.value = value;
      if (type === "evening") this.evening.value = value;
    }
  }
};
</script>
