import { BASE } from "./index";

const fetchDelivery = (id = '', status = '') => {
  const url = `${BASE}/deliverys/${id}?status=${status}`;
  const token = window.localStorage.getItem("token");
  return fetch(url, {
    method: "GET",
    headers: { token }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default fetchDelivery;
