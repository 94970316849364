import { BASE } from "./index";
const checkPhoneNumber = payload => {
  const url = `${BASE}/checkphone/${payload}`;
  return fetch(url, {
    method: "GET"
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default checkPhoneNumber;
