const state = {
  clients: [],
  totalByMonth: 0,
  orders: [],
  totalsOrders: {
    day: 0,
    month: 0
  }
};

export default state;
