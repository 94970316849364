<style scoped lang="scss">
.wrap-invitation-form {
  padding-left: 15px;
  padding-right: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("~@assets/images/splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: grid;
  align-items: center;
  min-height: 700px;
}
@media (orientation: landscape) {
  .wrap-invitation-form {
    height: auto !important;
  }
}
@media (max-width: 345px) {
  .wrap-invitation-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 784px) {
  .wrap-invitation-form {
    height: 100% !important;
  }
}

@media screen and (min-height: 572px) {
  .fit-container {
    align-items: center;
    height: 100% !important;
  }
}
</style>

<template>
  <div class="wrap-invitation-form">
    <InvitationForm></InvitationForm>
  </div>
</template>

<script>
import InvitationForm from "@forms/Invitation";

export default {
  name: "Login",
  components: { InvitationForm }
};
</script>