import { BASE } from "./index";
const adminNotification = (table, payload) => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/admin/notification/${table}`;
  return fetch(url, {
    method: "POST",
    headers: {
      token,
      "content-type": "application/json"
    },
    body: JSON.stringify({ ...payload })
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default adminNotification;
