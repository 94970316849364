<style scoped lang="scss">
.dish-modal__title {
  color: #ef4f34;
  font-family: Futura;
  font-size: 25px;
  margin-left: 12px;
  padding: 10px;
}
::v-deep .v-form {
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

::v-deep .v-input__slot {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
}
.dish-modal__wrap-category-list {
  max-height: 430px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
}
.dish-modal__close {
  background-position: center;
  background-image: url("~@assets/icons/close.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;
  top: 5px;
  left: calc(100% - 20px);
}

::v-deep .v-dialog--active {
  background: white;
  border-radius: 12px;
  padding-top: 5px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  .dish-modal__title {
    color: #ed2236;
    font-size: 19px;
    font-family: Futura;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
</style>

<template>
  <div>
    <v-dialog v-model="display" max-width="650" class="dish-modal" persistent>
      <div class="dish-modal__close" @click="handleCloseModal"></div>
      <p class="dish-modal__title">Añadir</p>
      <div class="dish-modal__wrap-category-list scroll-bar">
        <MenuForm
          ref="menuForm"
          mode="create"
          :headquaters="headquatersValues"
          @success="handleSubmitSuccess"
          @error="handleSubmitError"
          @close="$emit('close')"
        ></MenuForm>
      </div>
    </v-dialog>

    <v-snackbar
      v-model="success"
      :bottom="true"
      :left="true"
      :timeout="2500"
      color="success"
    >Operación completada con exito.</v-snackbar>

    <v-snackbar
      v-model="error"
      :bottom="true"
      :left="true"
      :timeout="2500"
      color="error"
    >Ocurrio un error intenta mas tarde.</v-snackbar>
  </div>
</template>

<script>
import MenuForm from "@components/forms/MenuForm";

export default {
  name: "CreateDishModal",
  components: { MenuForm },
  props: {
    headquaters: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: this.open,
      ready: false,
      error: false,
      success: false
    };
  },
  computed: {
    headquatersValues() {
      return Object.values(this.headquaters);
    }
  },
  watch: {
    open(val) {
      this.display = val;
    }
  },
  methods: {
    handleSubmitSuccess(response) {
      console.log(response);
      this.$emit("close");
      this.$emit("success", response);
      if (this.$refs.menuForm) this.$refs.menuForm.resetForm();
    },
    handleCloseModal() {
      this.$emit("close");
      if (this.$refs.menuForm) this.$refs.menuForm.resetForm();
    },
    handleSubmitError(error = {}) {
      console.log("error ", error);
      this.error = true;
      this.$emit("error");
      if (this.$refs.menuForm) this.$refs.menuForm.resetForm();
    }
  }
};
</script>
