<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import UserBasicInfo from "@components/generals/UserBasicInfo";
import ItemsLists from "@components/lists/ItemsLists";
import HeadquatersSelect from "@components/selects/HeadquatersSelect";
import Checkbox from "@components/generals/Checkbox";
import OrderForm from "@components/forms/OrderForm";
import BillingAdmin from "@components/generals/BillingAdmin";
import OrderModal from "@components/modals/OrderModal";
import requests from "@/helpers/requests";
import surePromise from "@utils/surePromise";
import uuid from "@utils/uuid";
import { filterMoney } from "@/utils";
import { notifyEvent } from "@/utils/notifyEvent";
import mixin from '@/mixins';

const { MenuMixin } = mixin;
const { fetchDishes, fetchOrder, fetchTable } = requests;

export default {
  name: "AdminOrder",
  components: {
    Checkbox,
    Loading,
    ItemsLists,
    UserBasicInfo,
    HeadquatersSelect,
    OrderForm,
    OrderModal,
    BillingAdmin,
    ReservationsComboTables
  },
  mixins: [MenuMixin],
  data() {
    const { headquaters = {} } = window.user;
    const headquater = this.getHeadquaterSelectedFromUserProfile();
    return {
      orderKey: uuid(),
      tables: [],
      dish: {},
      currentOrder: {},
      headquatersSelected: headquater,
      boxFlowClosed: false,
      dishes: [],
      showDetails: false,
      displayModal: false,
      dishStep: "",
      tableName: "",
      headquaters,
      headquater,
      error: false,
      showOverlay: false,
      loading: false,
      success: false,
      billingKey: uuid()
    };
  },
  watch: {
    showOverlay(val) {
      notifyEvent({ name: "overlay", detail: val });
    }
  },
  mounted() {
    this.initialize();
    window.addEventListener("notification", this.handleNewNotification);
    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.addEventListener("toggleOverlay", this.handleShowOverlay);
  },
  beforeDestroy() {
    if (this.orderUnsuscribe) this.orderUnsuscribe();
    window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
    window.removeEventListener("notification", this.handleNewNotification);
    window.removeEventListener("toggleOverlay", this.handleShowOverlay);
  },
  computed: {
    hasOrder() {
      return Boolean(Object.keys(this.currentOrder || {}).length);
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const { ok, result: dishes } = await surePromise(this.getDishesByHeadquater(this.headquater));
      if (ok) {
        if (dishes.length) this.dishes = dishes;
        const table = window.router.currentRoute.query.hasOwnProperty("table");
        if (table) await this.setTable(dishes);
        this.loading = false;
        return;
      }
      this.error = true;
    },
    initialize() {
      this.loading = false;
      this.dishes = [];
      this.dish = {};
      this.currentOrder = {};
      this.currentClient = {};
      this.fetchData();
      const hasTableName = window.router.currentRoute.query.hasOwnProperty("name");
      if (hasTableName) this.tableName = window.router.currentRoute.query.name;
    },
    async setTable(dishes) {
      const id = window.router.currentRoute.query.table;
      const { error, result } = await surePromise(fetchTable(id));
      if (error) {
        this.error = true;
        return Promise.reject(error);
      }
      this.table = result;
      const hasPrevOrder = window.router.currentRoute.query.hasOwnProperty("order");
      if (hasPrevOrder) {
        const { error: failFetchingOrder } = await surePromise(this.fetchPrevOrder(dishes));
        if (failFetchingOrder) {
          this.error = true;
          return Promise.reject(failFetchingOrder);
        }
      }
      return Promise.resolve(this.table);
    },
    async fetchPrevOrder() {
      const { order: id = "" } = window.router.currentRoute.query;
      const { result: order, error } = await surePromise(fetchOrder(id));
      if (order) {
        this.currentOrder = { ...order };
        return Promise.resolve(this.currentOrder);
      }
      return Promise.reject(error);
    },
    handleSaveOrder(options, ignoreSnackBar = false) {
      const { service, discount, withService, withIva, computedIva, price, iva = {} } = options;
      this.currentOrder.service = service;
      this.currentOrder.discount = discount;
      this.currentOrder.withService = withService;
      this.currentOrder.withIva = withIva;
      this.currentOrder.computedIva = computedIva;
      this.currentOrder.price = price;
      this.currentOrder.iva = iva;
      if (!ignoreSnackBar) this.success = true;
    },
    handleOpenModal() {
      const calcule = this.$refs.billingAdmin.getOrderCalcule();
      this.handleSaveOrder(calcule, true);
      this.displayModal = true;
    },
    async handleError(response) {
      if (response) {
        const error = await response;
        this.boxFlowClosed = error.code === "boxFlowClosed";
      }
    },
    handleSuccess(success) {
      this.success = success;
      if (success) {
        this.orderKey = uuid();
        this.handlerResetBilling();
        this.tableName = "";
      }
    },
    handleServiceChange(val) {
      this.hasService = val;
    },
    handleDiscountChange(val) {
      this.reduction = val;
    },
    handleOrderChange(order = {}) {
      this.currentOrder = order;
      this.billingKey = uuid();
    },
    handleRemoveDish(id) {
      this.billingKey = uuid();
      return notifyEvent({ name: "resetMenuCard", detail: id });
    },
    handlerResetBilling() {
      this.currentOrder = {};
    },
    mapItemData(data) {
      return data.map(item => {
        item.title = item.name;
        item.subtitle = `$${filterMoney(item.price)}`;
        item.available = item.status === "available";
        return item;
      });
    },
    async getDishesByHeadquater(headquater = {}) {
      const hasHeadquater = headquater.hasOwnProperty("id");
      if (hasHeadquater) {
        const { id: availableAt } = headquater;
        const { ok, error, result } = await surePromise(fetchDishes({ availableAt }));
        if (ok) return Promise.resolve(result);
        return Promise.reject(error);
      }
      return [];
    },
    setHeadquaterSelected(headquater) {
      this.headquaters[this.headquatersSelected.id].selected = false;
      this.headquatersSelected = headquater;
      this.headquaters[headquater.id].selected = true;
    },
    handleSelectHeadquater(event) {
      const headquater = event.detail;
      this.setHeadquaterSelected(headquater);
      this.headquater = headquater;
      this.initialize();
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    }
  }
};
</script>
