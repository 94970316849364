const actions = {
  open_your_order({ commit }) {
    commit('open_your_order');
  },
  close_your_order({ commit }) {
    commit('close_your_order');
  }
};

export default actions;
