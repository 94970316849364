<style scoped lang="scss">
.wrap-login-form {
  padding-left: 15px;
  padding-right: 15px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%), url('~@assets/images/splash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: grid;
  align-items: center;
  min-height: 700px;
}
@media (orientation: landscape) {
  .wrap-login-form {
     height: auto !important;
  }
}
@media (min-width: 784px) {
  .wrap-login-form {
     height: 100% !important;
  }
}

@media screen and (min-height: 572px) {
  .fit-container {
    align-items: center;
    height: 100% !important;
  }
}
</style>

<template>
  <div class="wrap-login-form">
    <LoginForm></LoginForm>
  </div>
</template>

<script>
import LoginForm from '@forms/LoginForm';

export default {
  name: 'Login',
  components: { LoginForm },
  data() {
    return { radius: 100 };
  }
};
</script>
