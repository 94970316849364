<style scoped lang="scss">
@import "style";
</style>

<template src="./template.html"></template>

<script>
import Loading from "@components/generals/Loading";
import Checkbox from "@components/generals/Checkbox";
import BasicInput from "@inputs/BasicInput";
import ClientTables from '@components/tables/ClientTables';
import ReservationsComboTables from "@components/generals/ReservationsComboTables";
import QuationsTable from "@components/tables/QuationsTable/QuationsTable.vue";
import mixin from '@/mixins';
// import requests from "@/helpers/requests";
// import { filterMoney } from "@/utils";

const { MenuMixin } = mixin;

export default {
  name: "AdminQuotation",
  components: {
    QuationsTable,
    ReservationsComboTables,
    ClientTables,
    Checkbox,
    Loading,
    BasicInput
  },
  mixins: [MenuMixin],
  data: () => ({
    ctrlQuatations: false,
    headquater: {}
  }),
  created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
    console.log('headquater', this.headquater);
  }
};
</script>
