 <style scoped lang="scss">
.not-available {
  background: red !important;
}

.items-list-container__available {
  background: green;
  width: 25px;
  height: 25px;
  border: 4px solid white;
  border-radius: 100px;
  position: relative;
  top: 17px;
  left: -6px;
  z-index: 1;
}
.items-list-container {
  margin-top: 30px;
  width: 100%;
  ul {
    padding-left: 0px;
    grid-template-columns: repeat(auto-fill, 177px);
    grid-gap: 20px;
    display: grid;
    li {
      display: inline-block;
      color: white !important;
      position: relative;
      height: 195px;
      max-width: 200px;
    }
  }
}
</style>

<template>
  <div class="items-list-container">
    <ul>
      <li v-for="item of data" :key="item.id">
        <ItemCard
          :title="item.title ? item.title : type"
          :subtitle="item.subtitle ? item.subtitle : item.name"
          :image="item.image"
          :size="size"
          :selected="itemSelected.id === item.id"
          @select="handleClick(item)"
        ></ItemCard>
      </li>
    </ul>
  </div>
</template>

<script>
import ItemCard from "@components/cards/ItemCard";

export default {
  name: "ItemsLists",
  components: { ItemCard },
  props: {
    showAvailable: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "100% - 73px"
    },
    type: {
      type: String,
      default: ""
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemSelected: {}
    };
  },
  methods: {
    handleClick(item) {
      this.itemSelected = item;
      this.$emit("selected", item);
    }
  }
};
</script>
