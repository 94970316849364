import Vue from "vue";
import updateHeadquater from "@/helpers/requests/updateHeadquater";
import createComment from "@/helpers/requests/createComment";
import SocialShare from "@components/generals/socialShare/SocialShare.vue";

export default Vue.extend({
  name: "RatingRestaurant",
  props: {
    totalPayment: {
      type: Number,
      default: 0
    },
    restaurantId: {
      type: Number,
      default: 0,
      require: true
    }
  },
  components: {
    StarList: () => import("@/components/lists/StarList"),
    SocialShare
  },
  data: () => ({
    rating: {
      commit: null,
      stars: 0
    },
    loading: false,
    snackbar: {
      show: false,
      color: "error",
      msg: "Tenemos errores en este momento"
    }
  }),
  computed: {
    canSubmit() {
      return !!(this.rating.commit && this.rating.stars);
    }
  },
  methods: {
    async handleFinish() {
      this.loading = true;
      const { headquater } = window.router.currentRoute.params;
      const { uid } = window.user;
      await createComment({
        headquater,
        user: uid,
        text: this.rating.commit
      });
      const data = await updateHeadquater(headquater, {
        stars: this.rating.stars
      });
      if (data) {
        this.snackbar = {
          show: true,
          color: "success",
          msg: "Muchas gracias por dejar su comentario"
        };
        setTimeout(() => {
          this.loading = false;
          this.$router.push({
            name: "dashboard"
          });
        }, 2000);
        return;
      }
      this.loading = false;
      this.snackbar = {
        show: true,
        color: "error",
        msg: "Tenemos errores internos en este momento"
      };
    },
    onSelectStars(rating) {
      this.rating.stars = rating;
    }
  }
});
