import { BASE } from "./index";

const finishBoxflow = id => {
  const token = window.localStorage.getItem("token") || "";
  const url = `${BASE}/headquater/finishflow/${id}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      token,
      "content-type": "application/json"
    }
  }).then(res => {
    if (res.status !== 200) return Promise.reject(res);
    return res.json();
  });
};

export default finishBoxflow;
