<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import BasicSelect from "@components/selects/BasicSelect";
import PickerDate from "@components/generals/PickerDate";
import DateRange from "@components/generals/DateRange";
import moment from "moment";
moment.locale("es");

export default {
  name: "AccountinHeader",
  components: { BasicSelect, PickerDate, DateRange },
  props: {
    showOpenBtn: {
      type: Boolean,
      default: false
    },
    defaultDate: {
      type: String,
      default: "1"
    }
  },
  computed: {
    hasDates() {
      return this.initialDate && this.endDate;
    },
    isNotToday() {
      return this.selected !== "1";
    }
  },
  created() {
    this.selected = this.defaultDate;
  },
  data() {
    return {
      selected: "1",
      displayDateRange: false,
      initialDate: null,
      endDate: null,
      dates: [
        {
          name: "Hoy",
          id: "1"
        },
        {
          name: "7 dias",
          id: "7"
        },
        {
          name: "15 dias",
          id: "15"
        },
        {
          name: "30 dias",
          id: "30"
        },
        {
          name: "Rango de fechas",
          id: "range"
        }
      ]
    };
  },
  filters: {
    formatDate(val) {
      const date = new Date(val);
      date.setDate(date.getDate() + 1);
      return moment(date).format("LL");
    }
  },
  methods: {
    handlePickDates(date) {
      const { initial, end } = date;
      this.initialDate = initial;
      this.endDate = end;
      this.$emit("dateChange", "range", [initial, end]);
    },
    handleSelectDate(option) {
      const isRange = option === "range";
      this.displayDateRange = isRange;
      this.initialDate = null;
      this.endDate = null;
      this.selected = option;
      if (!isRange) {
        this.$emit("dateChange", option);
      }
    }
  }
};
</script>
