<style lang="scss" scoped>
.none-data-container {
  color: gray;
  position: relative;
  top: 0px;
}
.wrap-spinner {
  position: relative;
  top: 15px;
}

.chart-container {
  max-width: 532px;
  overflow-x: auto;
  padding-left: 0px;
  padding-bottom: 10px;
}
</style>

<template>
  <div class="chart-container scroll-bar">
    <column-chart
      v-if="!loading && !noneData"
      :colors="[color]"
      label="Valor"
      :curve="true"
      :width="computedWidth"
      height="125px"
      prefix="$"
      :discrete="true"
      thousands=","
      :library="chartOptions"
      :data="dataset"
      :dataset="{minBarLength: 8}"
    ></column-chart>

    <div v-if="noneData && loading === false" class="container text-xs-center none-data-container">
      <span>No hay ningun dato aun.</span>
    </div>

    <v-container class="text-xs-center wrap-spinner" v-if="loading === true">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "BarsChart",
  computed: {
    computedWidth() {
      const width = this.dataset.length * 150;
      return `${width}px`;
    },
    noneData() {
      return this.dataset.length === 0;
    }
  },
  props: {
    color: {
      type: String,
      default: "#2ecc71"
    },
    dataset: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartOptions: {
        scales: {
          yAxes: [
            {
              display: false
            }
          ]
        }
      }
    };
  },
  methods: {}
};
</script>

