var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"form-container",attrs:{"flat":""}},[_c('span',{staticClass:"text-xs-center form-title"},[_vm._v("\n    Listado de correos\n  ")]),_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Busca una opcion","multiple":"","small-chips":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-tile',[_c('span',{staticClass:"subheading"},[_vm._v("Añadir: ")]),_c('v-chip',{attrs:{"color":((_vm.colors[_vm.nonce - 1]) + " lighten-3"),"label":"","small":""}},[_vm._v("\n          "+_vm._s(_vm.search)+"\n        ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"selected":selected,"label":"","small":""}},[_c('span',{staticClass:"pr-2"},[_vm._v("\n          "+_vm._s(item.text)+"\n        ")]),_c('v-icon',{staticClass:"close",attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}})],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-tile-content',[(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"dark":"","label":"","small":""}},[_vm._v("\n          "+_vm._s(item.text)+"\n        ")])],1),_c('v-spacer'),_c('v-list-tile-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',{class:{ 'edit-icon': _vm.editing !== item, 'check-icon': _vm.editing === item  }})],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(_vm.loading === false && _vm.showButton)?_c('v-btn',{staticClass:"btn-confirm",attrs:{"disabled":_vm.isEmpty && _vm.isValid === false,"color":"primary"},on:{"click":_vm.sendInvoice}},[_vm._v("\n    Confirmar\n  ")]):_vm._e(),(_vm.loading === true)?_c('div',{staticClass:"text-xs-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }