<style lang="scss" scoped>
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import AccountingForm from "@/components/forms/AccountingForm";

export default {
  name: "AccountingModal",
  components: { AccountingForm },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ""
    },
    accounting: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    onSubmitAccountingForm: {
      type: Function,
      default: () => function onSubmitAccountingForm() {}
    }
  },
  watch: {
    display(val) {
      this.open = val;
    },
    async currentTab(val) {
      const isRevenue = val === 0;
      if (isRevenue) {
        await this.$refs.accountingRevenueForm.resetState();
      } else {
        await this.$refs.accountingEgressForm.resetState();
      }
    }
  },
  created() {
    this.open = this.display;
  },
  data() {
    return {
      currentTab: 0,
      open: false,
      loading: false
    };
  },
  methods: {
    handleChangePaymenthod() {
      // console.log(arguments);
    },
    async handleCloseModal() {
      await this.$refs.accountingEgressForm.resetState();
      await this.$refs.accountingRevenueForm.resetState();
      this.$emit("close");
    }
  }
};
</script>
