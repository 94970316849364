<style scoped lang="scss">
@import "./styles.scss";
</style>
<template src="./template.html"></template>

<script>
import SearchRestaurants from "@inputs/SearchRestaurants";
import BottomNav from "@components/footers/BottomNav";
import RestaurantsList from "@components/lists/RestaurantsList";
import theme from "@/theme";
import onPWA from "@/utils/onPWA";
import requests from "@/helpers/requests";
import DispatchReservationClock from "@/helpers/DispatchReservationClock";
import EmptySection from "@/components/generals/EmptySection";

export default {
  name: "OptionMenu",
  components: { SearchRestaurants, BottomNav, RestaurantsList, EmptySection },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    rol: {
      type: String,
      default: "USER"
    },
    selected: {
      type: String,
      default: "restaurants"
    }
  },
  data() {
    const { secondary, primary } = theme;
    const { lang = "ES" } = window.user || {};
    return {
      onPWA: onPWA(),
      clockReservation: null,
      fetchOptions: { limit: 10 },
      lang,
      recoredPrevFetch: {},
      hasOptions: false,
      showLoadMoreSpinner: false,
      showSearchField: true,
      restaurants: [],
      notMoreItems: false,
      reservations: [],
      title: "Restaurantes",
      focus: this.selected,
      scrollY: 0,
      openMenu: false,
      secondary,
      primary,
      point: 0,
      loading: true
    };
  },
  watch: {
    focus(val) {
      this.changeTitle(val);
    },
    openMenu(open) {
      if (open) {
        setTimeout(() => window.scrollTo(0, 0), 500);
        window.router.push({
          name: "dashboardState",
          params: { state: "menu" },
          hash: window.router.currentRoute.hash
        });
        this.showSearchField = true;
        this.focus = "restaurants";
        this.categories.forEach(category => {
          category.selected = false;
        });
        this.changeTab();
      } else {
        if (this.clockReservation) this.clockReservation.clear();
        this.restaurants = [];
        this.point = 0;
        this.description = "";
        this.focus = "restaurants";
        this.$refs.tabsMenu.selected = "restaurants";
        window.router.push({ name: "dashboard", hash: "" });
        if (this.$refs.searchComponent) this.$refs.searchComponent.value = "";
        this.categories.forEach(category => {
          category.selected = false;
        });
        setTimeout(() => {
          const event = new CustomEvent("backsnack", { detail: { title: "Geochef", open: false } });
          if (event) window.dispatchEvent(event);
          window.scrollTo(0, 0);
        }, 250);
      }
    }
  },
  mounted() {
    window.addEventListener("menuStateChange", this.handleMenuStateChange);
    window.addEventListener("menuStateChangeTab", this.changeTab);
    window.addEventListener("DispatchReservationSuccess", this.mapReservationExpired);
  },
  beforeDestroy() {
    window.removeEventListener("menuStateChange", this.handleMenuStateChange);
    window.removeEventListener("DispatchReservationSuccess", this.mapReservationExpired);
    window.removeEventListener("menuStateChangeTab", this.changeTab);
  },
  updated() {
    const popup = document.getElementsByClassName("v-dialog__content--active");
    const fullscreen = document.getElementsByClassName("v-dialog--fullscreen");
    if (this.openMenu) {
      if (popup.length) popup[0].classList.add("menu-background");
      if (fullscreen.length) fullscreen[0].onscroll = this.handleScroll;
    } else if (popup.length) {
      popup[0].classList.remove("menu-background");
      popup[0].onscroll = null;
    }
  },
  methods: {
    mapReservationExpired(event) {
      const { detail } = event;
      const [{ id }] = detail;
      const reservation = this.reservations.find(item => item.id === id);
      if (reservation) reservation.status = "EXPIRED";
    },
    changeTitle(focus) {
      const focusReservation = focus === "reservations";
      const focusRestaurants = focus === "restaurants";
      const focusFavorites = focus === "favorites";
      const focusMaps = focus === "maps";
      const focusEvents = focus === "events";
      let event = null;
      if (focusFavorites) {
        event = new CustomEvent("backsnack", { detail: { title: "Favoritos", open: true } });
      } else if (focusReservation) {
        event = new CustomEvent("backsnack", { detail: { title: "Reservaciones", open: true } });
      } else if (focusRestaurants) {
        event = new CustomEvent("backsnack", { detail: { title: "Restaurantes", open: true } });
      } else if (focusMaps) {
        event = new CustomEvent("backsnack", { detail: { title: "Geochef", open: false } });
      } else if (focusEvents) {
        event = new CustomEvent("backsnack", { detail: { title: "Eventos", open: true } });
      }
      if (event) window.dispatchEvent(event);
      window.scrollTo(0, 0);
    },
    handleClickOutside(event) {
      const specifiedElement = document.querySelector(".wrap-box");
      if (specifiedElement) {
        const isClickInside = specifiedElement.contains(event.target);
        if (!isClickInside) {
          if (this.$refs.searchComponent) this.$refs.searchComponent.hideFilter();
        }
      }
    },
    handleSwipe(direction) {
      if (direction === "top") this.openMenu = true;
    },
    changeTab() {
      const focusReservation = window.router.currentRoute.hash === "#tab=reservations";
      const focusRestaurants = window.router.currentRoute.hash === "#tab=restaurants";
      const focusFavorites = window.router.currentRoute.hash === "#tab=favorites";
      const focusMaps = window.router.currentRoute.hash === "#tab=maps";
      const focusEvents = window.router.currentRoute.hash === "#tab=events";
      if (focusFavorites) {
        this.focus = "favorites";
        if (this.clockReservation) this.clockReservation.clear();
      } else if (focusReservation) {
        this.focus = "reservations";
        this.clockReservation = new DispatchReservationClock();
      } else if (focusRestaurants) {
        this.focus = "restaurants";
        if (this.clockReservation) this.clockReservation.clear();
      } else if (focusMaps) {
        this.focus = "maps";
        if (this.clockReservation) this.clockReservation.clear();
      } else if (focusEvents) {
        this.focus = "events";
        if (this.clockReservation) this.clockReservation.clear();
      }
      this.changeTitle(this.focus);
      this.handleSelectTab(this.focus);
    },
    handleMenuStateChange(e) {
      const isOpen = e.detail.open;
      this.openMenu = isOpen;
    },
    handleScroll(event) {
      this.scrollY = event.target.scrollTop;
      const reachTheEnd = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (reachTheEnd) {
        if (this.restaurants.length === 0) {
          this.showLoadMoreSpinner = false;
        } else if (this.notMoreItems) {
          this.showLoadMoreSpinner = false;
        }
      }
    },
    requestMoreItems() {
      this.showLoadMoreSpinner = true;
      if (this.hasOptions) {
        this.notMoreItems = true;
        return;
      }
      if (this.focus === "restaurants" || this.focus === "favorites") {
        this.findHeadquaters(this.generateQueryPayloads())
          .then(response => this.handleRestaurantsResponse(response, "loadmore"))
          .catch(this.handleRestaurtansResponseError);
      }
      if (this.focus === "reservations") {
        requests
          .fetchReservations(this.fetchOptions)
          .then(response => this.handleReservationsResponse(response, "loadmore"))
          .catch(this.handleReservationsResponseError);
      }
    },
    handleLike(id) {
      requests.setLike({ id, state: { favorite: true, like: true } }).then(() => {
        const onReserv = this.focus === "reservations";
        if (onReserv) {
          this.restaurants = this.restaurants.map(item => {
            const like = item.id === id;
            item.like = like;
            return item;
          });
        }
      });
    },
    handleUnlike(id) {
      requests.setLike({ id, state: { favorite: true, dislike: true } }).then(() => {
        const onFavorites = this.focus === "favorites";
        const onReserv = this.focus === "reservations";
        if (onFavorites) this.restaurants = this.restaurants.filter(item => item.id !== id);
        if (onReserv) {
          this.restaurants = this.restaurants.map(item => {
            const found = item.id === id;
            if (found) item.like = false;
            return item;
          });
        }
      });
    },
    handleRestaurantsResponse(response, mode) {
      this.notMoreItems = response.length === 0;
      if (this.notMoreItems) {
        this.showLoadMoreSpinner = false;
        this.loading = false;
        if (this.$refs.searchComponent) {
          this.$refs.searchComponent.hideFilter();
          this.$refs.searchComponent.$el.querySelector("input").blur();
        }
        return;
      }
      const responseParsed = response.map(item => {
        const { name, id, description = "Sin description", banner = "", logo, stars = 0, belongsTo, likes, schedule } = item;
        const like = item.hasOwnProperty("likes") ? Boolean(item.likes[window.user.uid]) : false;
        const map = {
          name,
          id,
          description,
          stars,
          belongsTo,
          likes,
          like,
          schedule
        };
        if (logo) map.logo = logo;
        if (banner) map.banner = banner;
        return map;
      });
      if (mode === "loadmore") {
        this.showLoadMoreSpinner = false;
        this.restaurants = this.restaurants.concat(responseParsed);
      } else {
        this.restaurants = responseParsed;
      }
      this.fetchOptions.startAt = response[response.length - 1].id;
      this.loading = false;
      if (this.$refs.searchComponent) {
        this.$refs.searchComponent.hideFilter();
        this.$refs.searchComponent.$el.querySelector("input").blur();
      }
    },
    handleRestaurtansResponseError() {
      this.loading = false;
      this.showloadMoreSpinner = false;
    },
    async handleReservationsResponse(response, mode) {
      let result = [];
      this.notMoreItems = response.length === 0;
      if (this.notMoreItems) {
        this.showLoadMoreSpinner = false;
        this.loading = false;
        return;
      }
      result = response.map(current => {
        const headquater = current.belongsTo;
        if (headquater.hasOwnProperty("likes")) {
          const like = headquater.likes.hasOwnProperty(window.user.uid);
          headquater.like = like;
        }
        headquater.reservations = current;
        return headquater;
      });

      if (mode === "loadmore") {
        this.restaurants = this.restaurants.concat(result);
        this.reservations = this.reservations.concat(response);
        this.showLoadMoreSpinner = false;
      } else {
        this.restaurants = result;
        this.reservations = response;
      }
      const pendings = this.reservations.filter(this.filterReservationsPendings);
      if (pendings.length) pendings.forEach(this.pushPendingReservations);
      this.fetchOptions.startAt = response[response.length - 1].id;
      this.loading = false;
    },
    pushPendingReservations(pending) {
      this.clockReservation.push(pending);
    },
    filterReservationsPendings(reservation) {
      return reservation.status === "PENDING";
    },
    handleReservationsResponseError() {
      this.loading = false;
      this.showLoadMoreSpinner = false;
    },
    handleSelectTab(select) {
      this.focus = select;
      this.notMoreItems = false;
      this.fetchOptions = { limit: 10, populate: "belongsTo" };
      if (select === "reservations") {
        this.showSearchField = false;
        this.title = "Reservas";
        this.reservations = [];
        this.loading = true;
        window.router.push({ hash: "#tab=reservations" });
        this.restaurants = [];
        requests
          .fetchReservations(this.fetchOptions)
          .then(this.handleReservationsResponse)
          .catch(this.handleReservationsResponseError);
      }

      if (select === "maps") {
        this.showSearchField = true;
        window.router.push({ hash: "#tab=maps" });
        this.openMenu = false;
        // this.focus = 'restaurants';
      }
      if (select === "favorites") {
        this.showSearchField = true;
        window.router.push({ hash: "#tab=favorites" });
        this.title = "Favoritos";
        this.restaurants = [];
        this.loading = true;
        this.findHeadquaters(this.generateQueryPayloads())
          .then(this.handleRestaurantsResponse)
          .catch(this.handleRestaurtansResponseError);
      }
      if (select === "restaurants") {
        this.showSearchField = true;
        window.router.push({ hash: "#tab=restaurants" });
        this.title = "Restaurantes";
        this.restaurants = [];
        this.loading = true;
        this.findHeadquaters(this.generateQueryPayloads())
          .then(this.handleRestaurantsResponse)
          .catch(this.handleRestaurtansResponseError);
      }
    },
    generateQueryPayloads() {
      const payload = {};
      if (this.point) payload.stars = this.point;
      const categories = this.categories.filter(item => item.selected === true);
      if (this.description) payload.description = this.description;
      if (categories.length) payload.categories = categories.map(item => item.id).join(",");
      if (this.focus === "favorites") payload.favorite = true;
      return payload;
    },
    checkOptions() {
      const categories = this.categories.filter(item => item.selected);
      this.hasOptions = this.description !== "" || this.point !== 0 || categories.length !== 0;
      delete this.fetchOptions.startAt;
    },
    handleDoneTyping(value) {
      this.loading = true;
      this.description = value;
      this.restaurants = [];
      this.checkOptions();
      this.findHeadquaters(this.generateQueryPayloads())
        .then(this.handleRestaurantsResponse)
        .catch(this.handleRestaurtansResponseError);
    },
    handleSelectStar(star) {
      this.loading = true;
      this.point = star;
      this.restaurants = [];
      this.checkOptions();
      this.findHeadquaters(this.generateQueryPayloads())
        .then(this.handleRestaurantsResponse)
        .catch(this.handleRestaurtansResponseError);
    },
    handleNoPoints() {
      this.loading = true;
      this.point = null;
      this.restaurants = [];
      this.checkOptions();
      this.findHeadquaters(this.generateQueryPayloads())
        .then(this.handleRestaurantsResponse)
        .catch(this.handleRestaurtansResponseError);
    },
    handleUnSelectCategorie() {
      this.loading = true;
      this.restaurants = [];
      this.categories.forEach(item => {
        item.selected = false;
      });
      this.checkOptions();
      this.findHeadquaters(this.generateQueryPayloads())
        .then(this.handleRestaurantsResponse)
        .catch(this.handleRestaurtansResponseError);
    },
    handleSelectCategorie() {
      this.loading = true;
      this.restaurants = [];
      this.checkOptions();
      this.findHeadquaters(this.generateQueryPayloads())
        .then(this.handleRestaurantsResponse)
        .catch(this.handleRestaurtansResponseError);
    },
    findHeadquaters(payload) {
      return requests.fetchHeadquaters({ ...payload, ...this.fetchOptions });
    }
  }
};
</script>
