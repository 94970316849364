<style scoped lang="scss">
.checkbox {
  width: 26px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('~@assets/icons/unchecked.svg');
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  transition: all 0.35s;
}

.label {
  color: #16202f;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.active {
  background-image: url('~@assets/icons/checked.svg');
}
</style>
<template>
  <div class="container-checkbox">
    <div
      class="checkbox"
      :class="{'active': active}"
      @click="handleClick"
    ></div>
    <span
      v-if="label"
      class="label"
      @click="handleClick"
    >
      {{ label }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'Checkbox',
  props: {
   checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      active: this.checked
    };
  },
  watch: {
    checked(val) {
      this.active = val;
    }
  },
  methods: {
    handleClick() {
      this.active = !this.active;
      this.$emit('change', this.active);
    }
  }
};
</script>
