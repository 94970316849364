<style scoped lang="scss">
  @import "style";
</style>
<template src="./template.html"></template>
<script>
  import Loading from "@components/generals/Loading";
  import ReservationsComboTables from "@components/generals/ReservationsComboTables";
  import BillingAdmin from "@components/generals/BillingAdmin";
  import TableList from "@lists/TableList";
  import QrModal from "@components/modals/QrModal";
  import JoinTableModal from "@components/modals/JoinTableModal";
  import BasicSelect from "@components/selects/BasicSelect";
  import TableModal from "@/components/modals/TableModal";
  import EmptySection from "@/components/generals/EmptySection";
  import geochefLogo from "@/assets/icons/geochef.svg";
  import { notifyEvent } from "@/utils/notifyEvent";
  import uuid from "@utils/uuid";
  import surePromise from "@utils/surePromise";
  import requests from "@/helpers/requests";
  import mixins from "@/mixins";

  const { MenuMixin } = mixins;
  const { fetchUserByBatch } = requests;

  export default {
    name: "AdminTables",
    components: {
      Loading,
      TableList,
      BillingAdmin,
      BasicSelect,
      ReservationsComboTables,
      JoinTableModal,
      QrModal,
      TableModal,
      EmptySection
    },
    mixins: [MenuMixin],
    data() {
      return {
        displayAddTableModal: false,
        displayJoinTable: false,
        billingKey: uuid(),
        currentOrder: {},
        currentClient: {},
        tableSelected: {},
        headquater: {},
        tables: [],
        waiters: [],
        notifications: [],
        tableSelect: [
          {
            name: "Todas las mesas",
            id: "ALL"
          },
          {
            name: "Ocupadas",
            id: "BUSY"
          },
          {
            name: "Libres",
            id: "FREE"
          }
        ],
        showOverlay: false,
        displayQrModal: false,
        tableListKey: uuid(),
        tableQrUrl: "",
        tableName: "",
        status: "ALL",
        toggle: false,
        success: false,
        error: false,
        loadingTables: true,
        loading: true
      };
    },
    created() {
      this.headquater = this.getHeadquaterSelectedFromUserProfile();
    },
    computed: {
      shouldShow() {
        return this.toggle === true;
      },
      tablesReadyToJoin() {
        const links = Object.fromEntries(Object.entries(this.tableSelected.links || {}));
        return this.tables
          .filter(table => table.id !== this.tableSelected.id)
          .map(_table => {
            const table = { ..._table };
            table.selected = links.hasOwnProperty(table.id);
            table.logo = geochefLogo;
            return table;
          });
      },
      hasMainTableSelected() {
        if (this.tableSelected) {
          const hasLinks = this.tableSelected.hasOwnProperty("links");
          if (hasLinks) return Object.keys(this.tableSelected.links).length === 0;
          return false;
        }
        return false;
      },
      hasTableSelected() {
        const result = Object.keys(this.tableSelected).length !== 0;
        if (result) this.toggle = true;
        return result;
      },
      hasTableFree() {
        return this.tables.filter(item => this.filterByStatus(item, "FREE")).length > 0;
      },
      hasTableBusy() {
        return this.tables.filter(item => this.filterByStatus(item, "BUSY")).length > 0;
      }
    },
    watch: {
      tables() {
        this.loadingTables = false;
        if (this.$refs.tableList) this.$refs.tableList.updateList();
      },
      showOverlay(val) {
        return notifyEvent({ name: "overlay", detail: val });
      }
    },
    async mounted() {
      this.loading = false;
      await this.getWaiters();
      this.setSubscription(this.handleTableChange);
      window.addEventListener("notification", this.handleNewNotification);
      window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
      window.addEventListener("toggleOverlay", this.handleShowOverlay);
    },
    beforeDestroy() {
      if (this.tableUnsuscription) this.tableUnsuscription();
      if (this.orderUnsuscribe) this.orderUnsuscribe();
      window.removeEventListener("headquaterSelected", this.handleSelectHeadquater);
      window.removeEventListener("notification", this.handleNewNotification);
      window.removeEventListener("toggleOverlay", this.handleShowOverlay);
    },
    methods: {
      handleLoadingRequest(state) {
        this.loadingTables = state;
        this.toggle = false;
      },
      async handleLinkTablesSuccess(payload = {}) {
        const { main: table = {} } = payload;
        const { currentOrder = {} } = table;
        if (currentOrder && currentOrder.id) {
          this.removeItemFromCache("orders", currentOrder.id);
          let tables = [...this.tables];
          tables = tables
            .filter(item => Boolean(item.currentOrder))
            .map(item => {
              item.currentOrder = item.currentOrder.id;
              return item;
            });
          this.loadingTables = true;
          this.toggle = false;
          await this.populateTables(tables);
          this.loadingTables = false;
          if (this.$refs.tableList) this.$refs.tableList.updateList();
        }
      },
      async handleAddProduct() {
        const { ok } = await surePromise(this.$refs.tableList.handleOccupy(this.tableSelected));
        if (ok) {
          const { id, name } = this.tableSelected;
          this.tableSelected.status = "BUSY";
          return window.router.push({
            path: `/dashboard/order?table=${id}&name=${name}`
          });
        }
      },
      async getWaiters() {
        const { waiters: batch } = this.headquater;
        const { ok, result = [] } = await surePromise(fetchUserByBatch({ batch }));
        if (ok) {
          result.push({ ...window.user });
          this.waiters = result;
        }
      },
      filterByStatus(item, status) {
        return item.status === status;
      },
      resetBilling() {
        this.toggle = false;
        this.currentOrder = {};
        this.currentClient = {};
        this.tableSelected = {};
        this.billingKey = uuid();
        this.tableListKey = uuid();
      },
      async handleSwitching(state, table) {
        this.toggle = false;
        this.loadingTables = true;
        const { currentOrder = {} } = table;
        let tables = [...this.tables];
        if (currentOrder && currentOrder.id) this.removeItemFromCache("orders", currentOrder.id);
        tables = tables
          .filter(item => Boolean(item.currentOrder))
          .map(item => {
            item.currentOrder = item.currentOrder.id;
            return item;
          });
        await this.populateTables(tables);
        this.loadingTables = false;
        if (this.$refs.tableList) this.$refs.tableList.updateList();
      },
      handleSwitchTable() {
        this.toggle = false;
      },
      handlePaymentSuccess() {
        this.resetBilling();
      },
      handleChangeStatus(val) {
        this.status = val;
        this.resetBilling();
      },
      handleUnselectTable() {
        this.toggle = false;
        this.currentOrder = {};
      },
      handleDownloadQr(table) {
        const { belongsTo } = this.headquater;
        const { id = "", name = "" } = table;
        this.tableQrUrl = `${belongsTo}/${this.headquater.id}?table=${id}`;
        this.tableName = name;
        this.displayQrModal = true;
      },
      toggleOverlay() {
        this.showOverlay = !this.showOverlay;
      },
      handleOpenReservation(reservation) {
        console.log("open reservation ", reservation);
      },
      handleShowOverlay(e) {
        this.showOverlay = e.detail;
      },
      handleChangePrice() {
        console.log("change price");
      },
      handleNewNotification(e) {
        const { detail } = e;
        this.notifications.unshift(detail.notification);
      },
      handleChooseTable(table) {
        const self = this;
        const hasOrder = table.hasOwnProperty("currentOrder");
        const { currentOrder: order, currentClient: client } = table;
        if (hasOrder) {
          this.currentOrder = order;
        } else {
          this.currentOrder = {};
        }
        const hasClient = table.hasOwnProperty("currentClient");
        const isAnonymus = hasClient && client !== "ANONYMUS";
        if (isAnonymus) {
          this.currentClient = { name: "Anonimo" };
        } else {
          this.currentClient = {};
        }
        const hasData = Object.keys(this.currentOrder).length !== 0 && Object.keys(this.currentClient).length !== 0;
        if (hasData) this.tableSelected = table;
        // const isBusy = status === "BUSY";
        this.toggle = true;
        this.billingKey = uuid();
        this.tableSelected = table;
        // const shouldGoToOrderPage = isBusy && hasOrder && Object.keys(order).length === 0;
        // if (shouldGoToOrderPage) window.router.push({ path: "/dashboard/order", query: { table: id, name } });
        this.$refs.billingAdmin.tableUpdated();

        if (hasOrder) {
          if (self.currentOrder && self.currentOrder.id) {
            window.db.collection("Orders")
              .where("id", "==", self.currentOrder.id)
              .onSnapshot(snapshots => {
                snapshots.docChanges().forEach((change) => {
                  if (change.type === "modified") {
                    if (change.doc.data().id === self.currentOrder.id) {
                      if (self.currentOrder.status === "CANCEL") {
                        self.removeItemFromCache("orders", self.currentOrder.id);
                        self.handleCancelOrder();
                      }
                    }
                  }
                });
              });
          }
        }
      },
      handleCancelOrder() {
        this.currentClient = {};
        this.currentOrder = {};
        this.tableSelected = {};
        this.toggle = false;
        this.billingKey = uuid();
      },
      handleSelectHeadquater(e) {
        const headquater = e.detail;
        this.loading = true;
        this.headquater = headquater;
        this.currentOrder = {};
        this.currentClient = {};

        this.getTables(this.headquater, this.handleTableChange);

        this.setSubscription(this.handleTableChange);
      },
      handleTableChange() {

      },
      handleDowloadQr(table) {
        const { belongsTo } = this.headquater;
        const { id = "", name = "" } = table;
        this.tableQrUrl = `https://geochef.firebaseapp.com/restaurant/${belongsTo}/${this.headquater.id}?table=${id}`;
        console.log(this.tableQrUrl);
        this.tableName = name;
        this.displayQrModal = true;
      }
    }
  };
</script>
