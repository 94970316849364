<template src="./template.html"></template>

<style scoped lang="scss">
  @import "style";
</style>

<script>
  export default {
    name: "BackButton",
    props: {
      onClickBack: {
        type: Function,
        default: null
      }
    },
    components: {},
    created() {

    },
    methods: {
      onClick() {
        if (this.onClickBack) {
          this.onClickBack();
        } else {
          this.$router.go(-1);
        }
      }
    },
    data() {
      return {};
    }
  };
</script>