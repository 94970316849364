<style scoped lang="scss">
@import "style";
</style>
<template src="./template.html"></template>

<script>
import DispatchReservationClock from "@/helpers/DispatchReservationClock";
import ProfileHeader from "@components/Headers/ProfileHeader.vue";
import Loading from "@components/generals/Loading.vue";
import RestaurantsList from "@components/lists/RestaurantsList.vue";
import EmptySection from "@/components/generals/EmptySection";
import UpdateProfileForm from "@forms/UpdateProfileForm.vue";
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
const { fetchHeadquater, fetchReservations, fetchOrders, setLike } = requests;

export default {
  name: "Profile",
  components: {
    EmptySection,
    ProfileHeader,
    Loading,
    RestaurantsList,
    UpdateProfileForm,
    ProfileWaiter: () => import("../../../components/generals/Profile/ProfileWaiter.vue")
  },
  data() {
    return {
      schedule: {},
      profile: {},
      name: "",
      email: "",
      phone: "",
      lang: "",
      activeNotification: null,
      showPassword: false,
      error: false,
      points: 0,
      image: "",
      loading: true,
      loadingProfile: true,
      clockReservation: null,
      tab: 0,
      categories: [],
      orders: [],
      restaurants: [],
      reservations: [],
      showLoadMoreSpinner: false,
      notMoreItems: false,
      fetchOptions: { limit: 10 }
    };
  },
  watch: {
    tab(val) {
      const onReserv = val === 1;
      const onOrders = val === 0;
      this.reservations = [];
      this.restaurants = [];
      this.orders = [];
      this.fetchOptions = { limit: 10 };
      if (onReserv) {
        this.loading = true;
        this.fetchOptions.populate = "belongsTo";
        this.findReservations();
      }
      if (onOrders) {
        this.loading = true;
        this.findOrders();
      }
    }
  },
  computed: {
    isWaiter() {
      return localStorage.getItem("selectedRol") === "WAITER";
    }
  },
  created() {
    this.handleGetQuater();
  },
  mounted() {
    this.showPassword = window.localStorage.getItem("authProvider") === "email/password";
    this.lang = window.user.lang || "ES";
    const [$wrapper] = document.getElementsByClassName("application--wrap");
    $wrapper.style.background = "#16202f";
    this.clockReservation = new DispatchReservationClock();
    window.addEventListener("DispatchReservationSuccess", this.mapReservationExpired);
    const { name = "", email = "", points = 0, image = "", phone = "", id = "", activeNotification } = window.user;
    this.profile = window.user;
    this.name = name;
    this.activeNotification = activeNotification;
    this.email = email;
    this.points = points;
    this.image = image;
    this.id = id;
    this.phone = phone;
    this.loadingProfile = false;
    this.findOrders();
  },
  beforeDestroy() {
    window.removeEventListener("DispatchReservationSuccess", this.mapReservationExpired);
  },
  methods: {
    handleWaiterGoBack(headquater) {
      const query = { headquater: headquater.id };
      window.router.push({ name: "dashboard" });
      return window.router.replace({ query });
    },
    async findReservations() {
      const { ok, error, result } = await surePromise(fetchReservations(this.fetchOptions));
      if (ok) return this.handleReservationsResponse(result);
      this.handleReservationsResponseError(error);
    },
    async findOrders() {
      this.loading = true;
      const { ok, error, result } = await surePromise(fetchOrders(this.fetchOptions));
      if (ok) return this.handleOrdersResponse(result);
      this.handleOrdersError(error);
    },
    async handleGetQuater() {
      const selectedQuater = Object.values(window.user.workingAt).find(headquater => headquater.selected);
      let headquater = null;
      if (selectedQuater) {
        headquater = await fetchHeadquater(selectedQuater.id, {});
      } else {
        const [id = ""] = Object.keys(window.user.workingAt);
        headquater = await fetchHeadquater(id, {});
      }
      if (headquater) {
        this.schedule = headquater.schedule;
      }
    },
    mapReservationExpired(event) {
      const { detail } = event;
      const [{ id }] = detail;
      const reservation = this.reservations.find(item => item.id === id);
      if (reservation) reservation.status = "EXPIRED";
    },
    handleUpdateData(update) {
      if (update.email) this.email = update.email;
      if (update.name) this.name = update.name;
    },
    async populateHeadquatersLinkedWithOrder(response) {
      const result = [];
      for (let i = 0; i < response.length; i += 1) {
        const current = response[i];
        const inCache = result.some(item => item.id === current.belongsTo);
        const inRestaurants = this.restaurants.some(item => item.id === current.belongsTo);
        const alreadyIn = inCache || inRestaurants;
        // eslint-disable-next-line no-continue
        if (alreadyIn) continue;
        if (current.belongsTo) {
          const { ok, result: headquater } = await surePromise(fetchHeadquater(current.belongsTo));
          if (ok) {
            const like = headquater.likes.hasOwnProperty(window.user.uid);
            headquater.like = like;
            headquater.reservations = current;
            result.push(headquater);
          }
        }
      }
      return Promise.resolve(result);
    },
    async handleOrdersResponse(response, mode = "") {
      const modeLoadmore = mode === "loadmore";
      this.notMoreItems = response.length === 0;
      if (this.notMoreItems) {
        this.showLoadMoreSpinner = false;
        this.loading = false;
        return;
      }
      const headquaters = await this.populateHeadquatersLinkedWithOrder(response);
      if (modeLoadmore) {
        this.restaurants = this.restaurants.concat(headquaters);
        this.orders = this.orders.concat(response);
        this.showLoadMoreSpinner = false;
        if (headquaters.length === 0) this.notMoreItems = true;
      } else {
        this.restaurants = headquaters;
        this.reservations = response;
      }
      this.fetchOptions.startAt = response[response.length - 1].id;
      this.loading = false;
    },
    pushPendingReservations(pending) {
      this.clockReservation.push(pending);
    },
    filterReservationsPendings(reservation) {
      return reservation.status === "PENDING";
    },
    handleOrdersError() {
      this.loading = false;
      this.showLoadMoreSpinner = false;
    },
    mapReservationResponse(current) {
      const headquater = current.belongsTo;
      const like = headquater.likes.hasOwnProperty(window.user.uid);
      headquater.like = like;
      headquater.reservations = current;
      return headquater;
    },
    async handleReservationsResponse(response, mode) {
      let result = [];
      const modeLoadmore = mode === "loadmore";
      this.notMoreItems = response.length === 0;
      if (this.notMoreItems) {
        this.showLoadMoreSpinner = false;
        this.loading = false;
        return;
      }
      result = response.filter(item => item.hasOwnProperty("belongsTo")).map(this.mapReservationResponse);
      if (modeLoadmore) {
        this.restaurants = this.restaurants.concat(result);
        this.reservations = this.reservations.concat(response);
        this.showLoadMoreSpinner = false;
      } else {
        this.restaurants = result;
        this.reservations = response;
      }
      const pendings = this.reservations.filter(this.filterReservationsPendings);
      if (pendings.length) pendings.forEach(this.pushPendingReservations);
      this.fetchOptions.startAt = response[response.length - 1].id;
      this.loading = false;
    },
    async handleLike(id) {
      const { ok } = await surePromise(setLike({ id, state: { favorite: true, like: true } }));
      if (ok) {
        const onReserv = this.tab === 1;
        if (onReserv) {
          this.restaurants = this.restaurants.map(item => this.mapLikedRestaurants(item, id));
        }
        return;
      }
      this.error = true;
    },
    mapLikedRestaurants(item, id) {
      const like = item.id === id;
      item.like = like;
      return item;
    },
    mapUnlikRestaurants(item, id) {
      const found = item.id === id;
      if (found) item.like = false;
      return item;
    },
    async handleUnlike(id) {
      const { ok } = await surePromise(setLike({ id, state: { favorite: true, dislike: true } }));
      if (ok) {
        const onReserv = this.tab === 1;
        if (onReserv) {
          this.restaurants = this.restaurants.map(item => this.mapUnlikRestaurants(item, id));
        }
        return;
      }
      this.error = true;
    },
    handleReservationsResponseError() {
      this.loading = false;
      this.showLoadMoreSpinner = false;
      this.error = true;
    },
    tryLogout() {
      this.logout();
    },
    async requestMoreItems() {
      this.showLoadMoreSpinner = true;
      if (this.hasOptions) {
        this.notMoreItems = true;
        return;
      }
      const onReserv = this.tab === 1;
      const onOrders = this.tab === 0;
      if (onReserv) {
        const { ok, error, result } = await surePromise(fetchReservations(this.fetchOptions));
        if (ok) {
          return this.handleReservationsResponse(result, "loadmore");
        }
        this.handleReservationsResponseError(error);
      }
      if (onOrders) {
        const { ok, error, result } = await surePromise(fetchOrders(this.fetchOptions));
        if (ok) {
          return this.handleOrdersResponse(result, "loadmore");
        }
        return this.handleOrdersError(error);
      }
    }
  }
};
</script>
