const actions = {
  setTableOrder({ commit }, table) {
    commit('setTableOrder', table);
  },
  newOrder({ commit }, table) {
    commit('setNewOrder', table);
  },
  tables({ commit }, tables) {
    commit('setTables', tables);
  }
};

export default actions;
