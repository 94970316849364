import requests from "@/helpers/requests";
import { saveUser } from "@/utils/saveUser";
import surePromise from "@utils/surePromise";

const { fetchProfile } = requests;

export const resolveUserProfile = user => {
  const userRef = window.db.collection("Users");
  return new Promise((resolve, reject) => {
    window.unsubscribe = userRef.where("id", "==", user.uid).onSnapshot(async snapshot => {
      const hasData = snapshot && snapshot.empty === false;
      if (hasData) {
        const [doc] = snapshot.docs;
        const { exists } = doc;
        if (exists) {
          const { ok, error, result } = await surePromise(fetchProfile());
          if (ok) {
            saveUser(user, result);
            return resolve(result);
          }
          reject(error);
        }
      }
    });
  });
};
